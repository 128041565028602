import React, { useEffect, useState } from "react";
import { LoginTitle } from "../login/LoginElements";
import MainButton from "../../ui/Button";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import { useNavigate } from "react-router-dom";
import { useTeacherFormContext } from "../../context/TeacherFormContext";
import { useEmailExistenceCheck } from "../../utils/useEmailExistenceCheck";

function SettingUpForm({ currentStep }) {
  const { formData, setFormData, handleSubmit } = useTeacherFormContext();
  const allData = { ...formData };
  const navigate = useNavigate();
  const [error, setError] = useState({});

  let stepNumber = currentStep
    ? parseInt(currentStep.replace("paso-", ""), 10)
    : 0;

  const validateStep0Data = () => {
    let errors = {};

    if (!formData.first_name) {
      errors.first_name = "Nombre es requerido";
    }
    if (!formData.identification_number_type) {
      errors.identification_number_type = "Tipo de documento es requerido";
    }
    if (!formData.identification_number) {
      errors.identification_number = "Número de documento es requerido";
    }
    if (!formData.date_of_birth) {
      errors.date_of_birth = "Fecha de nacimiento es requerida";
    }
    if (!formData.country) {
      errors.country = "País es requerido";
    }
  
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const validateStep1Data = () => {
    let errors = {};

    if (!formData.address) {
      errors.address = "Dirección de residencia es requerida";
    }
    if (!formData.phone_number) {
      errors.phone_number = "Teléfono de contacto es requerido";
    }
    if (!formData.instrument_ids || formData.instrument_ids.length === 0) {
      errors.instrument_ids = "Por favor, selecciona al menos un instrumento.";
    }
    if (formData.can_teach_at_home === '') {
      errors.can_teach_at_home = "Este campo es obligatorio";
    }
  
    if (formData.can_teach_remote  === '') {
      errors.can_teach_remote = "Este campo es obligatorio";
    }
  
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const validateStep2Data = () => {
    let errors = {};

    if (!formData.bank) {
      errors.bank = "Selecciona una entidad bancaria.";
    }
    if (!formData.account_type) {
      errors.account_type = "Selecciona un tipo de cuenta.";
    }
  
    if (!formData.bank_account || formData.bank_account.trim() === "") {
      errors.bank_account = "El número de cuenta bancaria es obligatorio.";
    }
  
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleNext = (e) => {
    e.preventDefault();

    let nextStep;
    if (stepNumber === 0) {
      if (validateStep0Data()) {
        nextStep = `paso-${stepNumber + 1}`;
        navigate(`/setting-up/${nextStep}`);
      }
    } else if (stepNumber === 1) {
      if (validateStep1Data()) {
      nextStep = `paso-${stepNumber + 1}`;
      navigate(`/setting-up/${nextStep}`);
      }
    } else if (stepNumber === 2) {
      if (validateStep2Data()) {
      handleSubmit(allData);
      navigate("/reset_password");
      }
    }
  };

  // function handleNext() {
  //   let nextStep;
  //   if (stepNumber < 2) {
  //     nextStep = `paso-${stepNumber + 1}`;
  //     navigate(`/setting-up/${nextStep}`);
  //   } else if (stepNumber === 2) {
  //     handleSubmit(allData);
  //     navigate("/reset_password");
  //   }
  // }

  const handleDataChange = (data) => {
    // setFormData({ ...formData, ...data });
    const updatedFormData = { ...formData, ...data };
    const updatedErrors = { ...error };
    Object.keys(data).forEach((fieldName) => {
      if (updatedErrors[fieldName]) {
        delete updatedErrors[fieldName];
      }
    });
    setError(updatedErrors);
    setFormData(updatedFormData);
  };

  // function handleSubmit() {
  //   console.log('Submited')
  // }

  const getTitleAndContent = () => {
    switch (stepNumber) {
      case 0:
        return {
          title: "Datos básicos",
          content: "Paso 1 de 3",
        };
      case 1:
        return {
          title: "Datos complementarios",
          content: "Paso 2 de 3",
        };
      case 2:
        return {
          title: "Información para pagos",
          content: "Paso 3 de 3",
        };
      default:
        return {
          title: "",
          content: "",
        };
    }
  };
  const { title, content } = getTitleAndContent();

  return (
    <div>
      <h1
        style={{
          fontSize: "1.25rem",
          fontWeight: 500,
          marginTop: "3rem",
          textAlign: "center",
        }}
      >
        Termina de configurar tu cuenta
      </h1>
      <div style={{ marginTop: "1rem" }}>
        <p style={{ textAlign: "center", fontSize: "0.75rem" }}>{content}</p>
        <LoginTitle
          style={{
            textAlign: "center",
            marginTop: "0",
            marginBottom: "1.5rem",
          }}
        >
          {title}
        </LoginTitle>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "55vh",
        }}
      >
        <div>
          {stepNumber === 0 && (
            <StepOne formData={formData} onDataChange={handleDataChange} error={error} />
          )}
          {stepNumber === 1 && (
            <StepTwo formData={formData} onDataChange={handleDataChange} error={error} />
          )}
          {stepNumber === 2 && (
            <StepThree formData={formData} onDataChange={handleDataChange} error={error} />
          )}
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <MainButton onClick={handleNext}>
            {stepNumber === 2 ? "Finalizar" : "Siguiente"}
          </MainButton>
        </div>
      </div>
    </div>
  );
}

export default SettingUpForm;
