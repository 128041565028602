import React from "react";
import TextInputField from "../../ui/TextInputField";
import SelectComponent from "../../ui/Select";
import { ThemeProvider } from "@mui/material";
import { theme } from "../Styles/FormStyles";
import { banks } from "../admin/CreateUser/TeacherForm/StepThree";

function StepThree({ formData, onDataChange, error }) {
  const handleChange = (e, fieldName) => {
    const updatedFormData = { ...formData };

    if (e.target) {
      updatedFormData[fieldName] = e.target.value;
    } else {
      updatedFormData[fieldName] = e;
    }

    onDataChange(updatedFormData);
  };

  return (
    <div style={{ height: "50vh" }}>
      <div>
        <ThemeProvider theme={theme}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              marginBottom: "1.5rem",
            }}
          >
            <SelectComponent
              label="Entidad bancaria"
              menuItems={banks}
              onChange={(e) => handleChange(e, "bank")}
              value={formData.bank}
              name="bank"
              error={error.bank !== undefined}
              helperText={error.bank}
            />
            <SelectComponent
              label="Tipo de cuenta"
              menuItems={[
                {
                  label: "Ahorros",
                  value: "Ahorros",
                },
                {
                  label: "Corriente",
                  value: "Corriente",
                },
              ]}
              onChange={(e) => handleChange(e, "account_type")}
              value={formData.account_type}
              name="account_type"
              error={error.account_type !== undefined}
              helperText={error.account_type}
            />
            <TextInputField
              label="Número de cuenta"
              onChange={(e) => handleChange(e, "bank_account")}
              value={formData.bank_account}
              name="bank_account"
              error={error.bank_account !== undefined}
              helperText={error.bank_account}
            />
          </div>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default StepThree;
