import React from 'react';
import desktop from '../../images/Desktop_Login.png';
import LoginForm from './loginForm';
import google from '../../images/google.svg';
import {LoginSection, LoginContainer,ImageWrapper, ImageDesktop, LoginWrapper, HeadingWrapper, Logo, MobileLogo, LoginTitle, Divider, GoogleBtn, GLogo, Connection, NavLink} from './LoginElements';

const Login = ({onLogin}) => {

  return (
    <LoginSection>
      <LoginContainer>
        <ImageWrapper>
            <ImageDesktop src={desktop} />
        </ImageWrapper>
        <LoginWrapper style={{height: '100vh'}}>
          <HeadingWrapper>
            <Logo src='./logo_desktop.svg'/>
            <MobileLogo src='./logo_mobile.svg'/>
            <LoginTitle>Ingresar</LoginTitle>
          </HeadingWrapper>
          <LoginForm onLogin={onLogin}/>
          {/* <Divider>O continuar con</Divider> */}
          {/* <GoogleBtn href='#'><GLogo src={google} /></GoogleBtn> */}
          <Connection>¿Aún no tienes cuenta?<NavLink to='/registro'>Regístrate</NavLink></Connection>
        </LoginWrapper>
      </LoginContainer>
     </LoginSection>
  )
}

export default Login