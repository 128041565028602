import React, { useEffect } from "react";
import AdminPageContainer from "../../../ui/AdminPageContainer";
import {
  Content,
  SearchWrapper,
  // TableWrapper,
  Title,
} from "../Students/StudentsTablesElements";
import TableMui from "./TableMui";
import styled from "styled-components";
import axios from "axios";

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 75vh;
  padding-bottom: 2rem;
  z-index: 1;
  overflow: auto;

  &&::-webkit-scrollbar {
    display: flex;
    scroll-behavior: smooth;
  }
`;

function CreateGroup() {

  const [instruments, setInstruments] = React.useState([]);
  const [groupRequests, setGroupRequests] = React.useState([]);
  const [instrumentsFetched, setInstrumentsfetched] = React.useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/instrument/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        const values = response.data.map((instrument) => ({
          label: instrument.name,
          value: instrument.id,
          art_form: instrument.art_form,
          name: instrument.name,
        }));
        setInstruments(values);
        setInstrumentsfetched(true);
      })
      .catch((error) => {
        console.error("Error fetching salon options from API:", error);
      });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let data = [];
        for (const musicInstr of instruments) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/lesson/group_requests`,
            {
              params: {
                instrument_id: musicInstr.value,
              },
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            }
          );
          const resultData = response.data;
          console.log(`Group Requests for ${musicInstr.label}: ${resultData}`);
          data = data.concat({ "instrument": musicInstr, "results": resultData });
        }
        setGroupRequests(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [instruments]);

  useEffect(() => {
    console.log("Group Requests:", groupRequests);
  }, [groupRequests]);

  return (
    <AdminPageContainer>
      <Content>
        <SearchWrapper >
          <div style={{ display: "flex", justifyContent: "space-between", zIndex: '1' }}>
            <Title style={{ margin: '0rem', zIndex: '1' }}>Administración de  clases grupales</Title>
          </div>
        </SearchWrapper>
        {instrumentsFetched && (
          <TableWrapper >
            {groupRequests.map((groupRequest) => (
              <TableMui
                group_request={groupRequest}
              />
            ))}
          </TableWrapper>
        )}
      </Content>
    </AdminPageContainer>
  );
}

export default CreateGroup;
