import { useState} from 'react';
import axios from 'axios';

export const useEmailExistenceCheck = () => {
  const [emailExists, setEmailExists] = useState("");

  const checkEmailExistence = async (email) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/email_used?email=${email}`
      );
      console.log(response.data);
      setEmailExists("");
    } catch (error) {
      console.error("Error checking email existence:", error);
      setEmailExists(error.response.data.detail);
    }
  };

  return { emailExists, checkEmailExistence };
};