import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: ["DM Sans", "sans-serif"].join(","),
    fontSize: 14,
  },
});
export const themeTable = createTheme({
  typography: {
    fontFamily: ["Nunito Sans", "sans-serif"].join(","),
    fontSize: 14,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export const textFieldStyle = {
  "& .MuiInputBase-root": {
    "& fieldset": {
      borderColor: "#B7B7B7",
      height: "61px",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#215E69",
    },
  },
  [theme.breakpoints.down("sm")]: {
    width: "327px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "416px",
  },
  "& label.Mui-focused": {
    color: "#215E69",
  },
};

export const searchInput = {
  width: "327px",
  "& .MuiInputBase-root": {
    "& fieldset": {
      borderColor: "#B7B7B7",
      height: "61px",
      borderRadius: "8px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#215E69",
    },
  },
  "& label.Mui-focused": {
    color: "#215E69",
  },
};

export const buttonStyle = {
  height: "50px",
  borderRadius: "8px",
  background: "#4AA1B1",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#48C99A",
  },
  [theme.breakpoints.down("sm")]: {
    width: "327px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "416px",
  },
};

export const formBtn = {
  height: "50px",
  borderRadius: "8px",
  background: "#4AA1B1",
  marginTop: "0px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#48C99A",
  },
};

export const deleteBtn = {
  height: "50px",
  borderRadius: "8px",
  background: "#EA5A47",
  marginTop: "0px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#EA5A47",
  },
};

export const boxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "24px",
  fontSize: 14,
  fontWeight: "500",
  marginLeft: "6px",
  marginRight: "6px",
};

export const formControlStyle = {
  [theme.breakpoints.down("sm")]: {
    width: "327px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "400px",
  },
  height: 46,
  "& label.Mui-focused": {
    color: "#215E69",
  },
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#215E69",
    },
  },
};

export const radioStyle = {
  color: "#215E69"[600],
  fontFamily: 'DM Sans',
  "&.Mui-checked": { color: "#215E69" },
}

export const FormControlLabelStyle = {
  ".MuiFormControlLabel-label": {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'DM Sans',
  }
}
export const TableHeadStyle = {
  "& th": {
    color: "#5C7389",
    backgroundColor: "#F3F5F7",
    fontWeight: "700",
    padding: "0.75rem 1rem ",
    // zIndex: '1'
    // textAlign: 'left',
  },
}

export const dateFieldStyles = {
  [theme.breakpoints.down("sm")]: {
    width: "327px",
  },
  [theme.breakpoints.up("sm")]: {
    width: "416px",
    paddingTop: "0rem",
  },
  "& label.Mui-focused": {
    color: "#215E69",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    paddingTop: "0rem",
    "&.Mui-focused fieldset": {
      borderColor: "#215E69",
      paddingTop: "0rem",
    },
  }
}
