import React, { useState } from "react";
import SelectComponent from "../../../ui/Select";
import styled from "styled-components";

const DeleteBtn = styled.button`
  font-size: 0.875rem;
  font-weight: 500;
  color: #818586;
  cursor: pointer;
  &:hover {
    color: #48C99A;
  }
`

function RangeSelector({
  index,
  startTime,
  endTime,
  generateTimeIntervals,
  handleChange,
  handleDeleteRange,
  availabilities,
}) {
  const isRangeSelected = startTime && endTime;
  const [disable, setDisable] = useState(false);
  // const hasId = availabilities.some((item) => item.id);
  const hasId = availabilities[index]?.id;

  const handleDelete = () => {
    if (hasId) {
      handleDeleteRange(index);
    } else {
      setDisable(true);
    }
  };

  return (
    <div>
      <p>Rango</p>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          marginTop: "1rem",
          width: "26rem",
        }}
      >
        <SelectComponent
          name={`start_time-${index}`}
          value={startTime}
          label="Inicio del rango horario"
          menuItems={generateTimeIntervals(availabilities).map((time) => ({
            label: time.time,
            value: time.time,
          }))}
          onChange={handleChange}
        />
        <SelectComponent
          name={`end_time-${index}`}
          value={endTime}
          label="Elige final de disponibilidad"
          menuItems={generateTimeIntervals(availabilities).map((time) => ({
            label: time.time,
            value: time.time,
          }))}
          onChange={handleChange}
        />
        {isRangeSelected && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <DeleteBtn
              onClick={handleDelete}
              disabled={disable}
            >
              Eliminar rango
            </DeleteBtn>
          </div>
        )}
      </div>
    </div>
  );
}

export default RangeSelector;
