import React from 'react'
import { Heading } from '../EditProfile/EditProfileElements'
import { Checkbox, FormControlLabel, ThemeProvider, Typography } from '@mui/material'
import Box from '@mui/material/Box';
import { theme } from '../Styles/FormStyles'
import TextInputField from '../../ui/TextInputField'
import SelectComponent from '../../ui/Select'

function Step3({formData, onDataChange, error}) {

  const social_media = [
    {
      label: "Facebook",
      value: "FACEBOOK",
    },
    {
      label: "Instagram",
      value: "INSTAGRAM",
    },
    {
      label: "WhatsApp",
      value: "WHATSAPP",
    },
    {
      label: "TikTok",
      value: "TIKTOK",
    },
    {
      label: "Referido",
      value: "REFERRED",
    },
    {
      label: "Volantes",
      value: "FLYERS",
    },
    {
      label: "Otros",
      value: "OTHER",
    },
  ];

  const handleChange = (e, fieldName) => {
    const updatedFormData = { ...formData };

    if (e.target) {
      updatedFormData[fieldName] = e.target.value;
    } else {
      updatedFormData[fieldName] = e;
    }
 
    onDataChange(updatedFormData);
  };
  return (
    <div style={{height: '50vh', }}>
    <div>
      <ThemeProvider theme={theme}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            marginBottom: "1.5rem",
          }}
        >
          <Heading>Correo de recepción de estados de cuenta</Heading>
          <TextInputField
            label="Correo electrónico"
            type='email'
            onChange={(e) => handleChange(e, "billing_mail")}
            value={formData.billing_mail}
            name='billing_mail'
            error={error.billing_mail !== undefined}
            helperText={error.billing_mail}
          />
          {/* <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: 14,
            fontWeight: "500",
            marginLeft: "6px",
            marginRight: "6px",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: "#215E69"[600],
                  "&.Mui-checked": {
                    color: "#215E69",
                  },
                }}
              />
            }
            label={
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Enviar al correo de mi registro
              </Typography>
            }
          />
        </Box> */}
          <Heading style={{marginTop: '1.5rem'}}>Contacto de emergencia</Heading>
          <SelectComponent label='¿Cómo te enteraste de nosotros?' menuItems={social_media} onChange={(e) => handleChange(e, "acquisition_channel")}
              value={formData.acquisition_channel}
              name='acquisition_channel'/>
        </div>
      </ThemeProvider>
    </div>
  </div>
  )
}

export default Step3
