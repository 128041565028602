import styled  from "styled-components";

export const Descr = styled.p`
    font-size: 12px;
    margin-bottom: 20px;
    @media  screen and (max-width: 768px) {
        width: 60vh;
        text-align: center;
    }
    @media  screen and (max-width: 480px) {
        max-width: 327px;
        text-align: center;
    }
`;

export const ClassesTitle = styled.h2`
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 14px;
`;

export const ClassList = styled.div`
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 26rem;
    max-height: 360px;
    overflow-y: auto;
    max-height: 39vh;
    width: 26rem;
    padding-top: 0.2rem;

    @media  screen and (max-width: 480px) {
        width: 22rem;
    }
`;

export const ClassItem = styled.div`
    border: 1px solid #4AA1B1;
    border-radius: 8px;
    text-align: left;
    cursor: pointer;
    background-color: ${props => (props.isSelected ? '#F5EDFF' : '#fff' )};
    
    &:hover {
        transition: 0.3s;
        transform: translate(0, -2px);
    }

     @media  screen and (max-width: 768px) {
        
    }
    @media  screen and (max-width: 480px) {
        width: 100%;
    }
`;

export const ItemInfo = styled.div`
    padding: 16px;
    width: 100%;
`

export const Time = styled.h1`
    font-size: 14px;
    font-weight: 500;
`;

export const Duration = styled.span`
    font-size: 12px;
    font-weight: 500;
`;

export const Day = styled.h2`
    font-size: 14px;
    font-weight: 500;
`;

export const AdditionalInfo = styled.p`
    font-size: 10px;
    font-weight: 500;
`;

export const ClassStarts = styled.p`
    color: #6E43E7;
    font-size: 12px;
`;

export const DateTime = styled.span`
    font-weight: 500;
`;
