import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import arrow from "../../../images/ArrowBack.svg";
import Modal from "../../../ui/Modal";
import Class from "../../CalendarStudents/Class";
import CancelletionOfClass from "./CancelletionOfClass";
import { fetchClassInfo } from "../../../utils/rescheduleTools";

const Calendar = styled.div`
  display: flex;
`;

const Day = styled.div`
  width: 2.5rem;
  height: 3.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  color: #2c2e2f;
  font-size: 0.875rem;
  text-align: center;
  cursor: pointer;
  margin-right: 0.35rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-transform: capitalize;

  &:hover {
    background-color: #77d8a2;
  }

  &.active {
    background-color: #77d8a2;
  }
`;

const NavButton = styled.button`
  background-color: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  scroll-behavior: smooth;
  height: auto;
  margin-bottom: 0.25rem;
`;

const Container = styled.div`
  height: 58vh;
  overflow: auto;
  scroll-behavior: smooth;
  
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 62vh;
  }
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 65vh;
  }
`;

const CalendarWrapper = styled.div`
  margin-bottom: 2.5rem;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

function DaysCarousel() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [activeDay, setActiveDay] = useState(currentDate.getDay());
  const [classData, setClassData] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - currentDate.getDay());
    setStartDate(startDate);
  }, [currentDate]);


  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  // useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const id = localStorage.getItem("userId");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/lesson/?teacher_id=${id}&lesson_day=${formattedDate}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        setClassData(response.data);
      } catch (error) {
        console.error("Error:", error.message);
        throw error;
      }
    };
    // fetchData();
  // }, [formattedDate, selectedDay]);

  useEffect(() => {
    fetchData();
  }, [formattedDate, selectedDay]);

  const goToPreviousWeek = (e) => {
    e.preventDefault();
    const previousWeek = new Date(currentDate);
    previousWeek.setDate(currentDate.getDate() - 7);
    setCurrentDate(previousWeek);
    setSelectedDay(previousWeek);
  };

  const goToNextWeek = (e) => {
    e.preventDefault();
    const nextWeek = new Date(currentDate);
    nextWeek.setDate(currentDate.getDate() + 7);
    setCurrentDate(nextWeek);
    setSelectedDay(nextWeek);
  };

  useEffect(() => {
    const filteredClasses = classData.filter((classItem) => {
      const classStartDate = new Date(classItem.start_time);
      return classStartDate.toDateString() === selectedDay.toDateString();
    });

    setFilteredClasses(filteredClasses);
  }, [classData, selectedDay]);

  const handleDayClick = (dayIndex) => {
    setActiveDay(dayIndex);
    const selectedDay = new Date(startDate);
    selectedDay.setDate(startDate.getDate() + dayIndex);
    setCurrentDate(selectedDay);
    setSelectedDay(selectedDay);

    // const filteredClasses = classData.filter(classItem => {
    //   const classStartDate = new Date(classItem.start_time);
    //   return classStartDate.toDateString() === selectedDay.toDateString();
    // });

    // setFilteredClasses(filteredClasses);
  };

  const formatDateRange = () => {
    const startDateFormat = startDate.toLocaleDateString("es-ES", {
      year: "numeric",
      month: "long",
    });

    return startDateFormat.replace(/\bde\b/, "");
  };

  const daysOfWeek = [];
  for (let i = 0; i < 7; i++) {
    const day = new Date(startDate);
    day.setDate(startDate.getDate() + i);
    daysOfWeek.push(day);
  }

  const handleClassClick = (classInfo) => {
    const isAnyCancelled = ['CANCELLED', 'PENDING', 'COMPLETED'].includes(classInfo.status);
    
    if (!isAnyCancelled && classInfo.students.length <= 1) {
      setSelectedClass(classInfo);
      setIsOpenModal(true);
    } 
  };

  const handleCloseModal = async () => {
    setIsOpenModal(false);
    try {
      await fetchData(); 
    } catch (error) {
      console.error("Error while refetching data:", error.message);
    }
  };

  console.log(classData)
  return (
    <>
      <CalendarWrapper >
        <p style={{ marginBottom: "0.5rem", textTransform: "capitalize" }}>
          {formatDateRange()}
        </p>
        <Calendar>
          <NavButton
            style={{ marginRight: "0.32rem" }}
            onClick={goToPreviousWeek}
          >
            <img
              style={{ width: "1.2rem", height: "1.2rem" }}
              src={arrow}
              alt=""
            />
          </NavButton>
          {daysOfWeek.map((day, index) => (
            <Day
              key={index}
              className={activeDay === index ? "active" : ""}
              onClick={() => handleDayClick(index)}
            >
              <div>
                {day.toLocaleDateString("es-ES", {
                  day: "numeric",
                  weekday: "short",
                })}
              </div>
            </Day>
          ))}
          <NavButton onClick={goToNextWeek}>
            <img
              src={arrow}
              alt=""
              style={{
                transform: "rotate(180deg)",
                width: "1.2rem",
                height: "1.2rem",
              }}
            />
          </NavButton>
        </Calendar>
      </CalendarWrapper>
      <Container>
        {filteredClasses.length > 0 ? (
          filteredClasses
            .slice()
            .sort((a, b) => {
              const timeA = new Date(a.start_time).getTime();
              const timeB = new Date(b.start_time).getTime();
              return timeA - timeB;
            })
            .map((classInfo, index) => (
              <Wrapper key={index}>
                <Class
                  style={{ marginBottom: "0.25rem" }}
                  key={index}
                  {...classInfo}
                  onClick={() => handleClassClick(classInfo)}
                />
              </Wrapper>
            ))
        ) : (
          <div
            style={{
              height: "30vh",
              textAlign: "center",
              width: "24rem",
              display: "flex",
              justifyContent: "center",
              alignItems: 'center'
            }}
          >
            No tienes clases hoy
          </div>
        )}
      </Container>
      {isOpenModal && (
          <Modal onClose={handleCloseModal} props="Detalle clase">
             <CancelletionOfClass classData={selectedClass} onClose={handleCloseModal} />
          </Modal>
        )}
    </>
  );
}
export default DaysCarousel;
