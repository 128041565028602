import React, { useEffect } from "react";
import SelectComponent from "../../../ui/Select";
import { Title } from "../../Overlay/OverlayElements";
import {
  AdditionalInfo,
  ClassItem,
  ClassStarts,
  DateTime,
  Day,
  Duration,
  ItemInfo,
  Time,
} from "../../MultiStepsForm/individual/StepFive/StepFiveElements";
import {
  ClassInfo,
  ClassName,
  ProfessorName,
} from "../../MultiStepsForm/individual/StepSix/StepSixElements";
import AdminButtonContainer from "../../../ui/AdminButtonContainer";
import AdminFormButton from "../../../ui/AdminFormButton";
import axios from "axios";
import { Dialog } from "@mui/material";
import ClassCreated from "./ClassCreated";
import { useNavigate } from "react-router-dom";

function AddStudent({ instrument, group_request }) {
  const [selectedCard, setSelectedCard] = React.useState(null);
  const [classItem, setClassItem] = React.useState(null);
  const [disabled, setDisabled] = React.useState(true);
  const [groups, setGroups] = React.useState([]);
  const [groupsFetched, setGroupsFetched] = React.useState(false);
  const navigate = useNavigate();
  const [created, setCreated] = React.useState(false);


  const handleCardClick = (index, classItem) => {
    setSelectedCard(index);
    setClassItem(classItem);
    setDisabled(false);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/lesson/group_levels`,
          {
            params: {
              instrument_id: instrument.value,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        setGroups(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (groups.length > 0) {
      setGroupsFetched(true);
    }
  }, [groups]);

  const onClick = () => {
    setDisabled(true);
    axios.put(
      `${process.env.REACT_APP_API_URL}/lesson/group_level/${classItem.id}/add_student/${group_request.id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    ).then((response) => {
      console.log("response", response);
      setCreated(true);
    }).catch((error) => {
      console.error("Error:", error);
    })
  };

  return (
    <>
      <div>
        <Title style={{ marginBottom: "1rem" }}>Estudiante a agregar {group_request.student.name}</Title>
        <Title style={{ marginTop: "2rem" }}>Cursos disponibles</Title>
      </div>
      <div style={{ maxHeight: "50vh", width: "26rem", paddingTop: "0.2rem" }}>
        {groupsFetched && groups.map((item, index) => {
          return (<ClassItem
            isSelected={selectedCard === index}
            onClick={() => handleCardClick(index, groups[index])}
            style={{ borderColor: "#C7C9CA" }}
          >
            <ItemInfo>
              <p
                style={{
                  width: '20vh',
                  textAlign: 'center',
                  background: "#E8F6EE",
                  color: "#41A46D",
                  fontSize: "0.75rem",
                  borderRadius: " 0.75rem",
                  padding: "0.12rem 0.5rem",
                  fontWeight: "500",
                  marginBottom: '0.5rem'
                }}
              >
                {item.levels.length} estudiante{item.levels.length != 1 ? "s" : ""} activo{item.levels.length != 1 ? "s" : ""}
              </p>
              <ProfessorName style={{ fontWeight: 500 }}>
                Profesor(a) {item.teacher.name}
              </ProfessorName>
              <Time>
                {/* {classItem.start_time_.substring(0, 5)} */}
                <Duration> De {item.start_time_} a {item.end_time_}</Duration>
              </Time>
              <Day>
                {item.day} de cada semana
              </Day>
              <AdditionalInfo>Horarios Fijos</AdditionalInfo>
            </ItemInfo>
          </ClassItem>)
        })}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem' }}>
        <AdminButtonContainer>
          <AdminFormButton onClick={onClick} disabled={disabled}>Agregar</AdminFormButton>
        </AdminButtonContainer>
      </div>
      <Dialog
        open={created}
        onClose={() => {
          setCreated(false);
          navigate("/salones");
        }}
      >
        <ClassCreated title={"Estudiante agregado al curso"}
          info={"Se ha agregado el estudiante a la clase grupal de manera satisfactoria y ahora podrás visualizarlo en el calendario."} />
      </Dialog>
    </>
  );
}

export default AddStudent;
