import React, { useEffect, useState } from "react";
import { MainContent} from "../MainSection/InactiveMainElements";
import NotificationCard from "./NotificationCard";
import { MainContainer } from "../../ui/PageContainer";
import Spinner from "../../ui/Spinner";
import styled from "styled-components";

const TitleWrapper = styled.div`
display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding-left: 2rem;
  }
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding-left: 2rem;
  }
`
const MainWrapper = styled.div`
    width: 100%;
    padding: 5rem 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-radius: 8px;
    margin: 1.5rem 2rem;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
        margin: 0;
        padding: 0px;
        padding-top: 16px;
        /* min-height: 678px; */
        height: 100%;
    }
    @media screen and (max-width: 480px) {
        flex-direction: column;
         justify-content: flex-start;
        margin: 0;
        padding: 0px;
        padding-top: 16px;
        min-height: 678px;
        height: 100%;
    }
`


function Notifications() {
  const [loading, setLoading] = useState(true)

  useEffect(function() {
    setTimeout(() => {
      setLoading(false);
    },1000)
  },[])

  return (
    <MainContainer>
      <MainWrapper >
        <TitleWrapper>
        <h1 style={{fontSize: '1.5rem', fontWeight: 500, textAlign: 'left'}}>Notifications</h1>
        </TitleWrapper>
        {loading ? <Spinner />  : 
        <MainContent style={{gap: '1.5rem'}}>
        {/* <NotificationCard /> */}
        {/* <NotificationCard /> */}
        {/* <NotificationCard /> */}
       </MainContent>
        }
      </MainWrapper>
    </MainContainer>
  );
}

export default Notifications;
