import React, { useEffect } from "react";
import { SubmitBtn, Title } from "../Students/OverlayElements";
import { ThemeProvider } from "styled-components";
import {
  FormControlLabelStyle,
  radioStyle,
  theme,
} from "../../Styles/FormStyles";
import {
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import AdminButtonContainer from "../../../ui/AdminButtonContainer";
import AdminFormButton from "../../../ui/AdminFormButton";
import Checkmarks from "../../../ui/Checkmarks";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ClassCreated from "./ClassCreated";

function NewCourse({ group_request }) {
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [teachers, setTeachers] = React.useState([]);
  const [teacherId, setTeacherId] = React.useState(null);
  const [availability, setAvailability] = React.useState([]);
  const [disabled, setDisabled] = React.useState(true);
  const [requestIds, setRequestIds] = React.useState([]);
  const [created, setCreated] = React.useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/user/?limit=200&user_type=TEACHER&instrument_id=${group_request.instrument.value}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        const formattedTeacher = response.data.map((teacher) => ({
          label: `${teacher.first_name} ${teacher.last_name}`,
          value: teacher.id,
          first_name: teacher.first_name,
          last_name: teacher.last_name,
        }));
        setTeachers(formattedTeacher);
      })
      .catch((error) => {
        console.error("Error fetching salon options from API:", error);
      });
  }, []);

  useEffect(() => {
    if (teacherId !== null) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/lesson/availability?` + 
          `teacher_id=${teacherId}&instrument_id=${group_request.instrument.value}&` +
          `lesson_type=PRESENCIAL&groupal_art_form=${group_request.instrument.art_form}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((response) => { setAvailability(response.data); setSelectedValue(null); })
        .catch((error) => {
          console.error("Error fetching salon options from API:", error);
        });
    }
  }, [teacherId]);

  useEffect(() => {
    if (selectedValue !== null && requestIds.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [selectedValue, requestIds]);

  const handleChange = (e) => {
    setTeacherId(e.target.value);
  }

  function nextDay(dayOfWeek) {
    var today = new Date();
    var currentDayOfWeek = today.getDay();
    var days = [
      "DOMINGO",
      "LUNES",
      "MARTES",
      "MIERCOLES",
      "JUEVES",
      "VIERNES",
      "SABADO",
    ];
    var targetDayIndex = days.indexOf(dayOfWeek);
    var difference = targetDayIndex - currentDayOfWeek;
    if (difference <= 0) {
      difference += 7;
    }
    var nextDate = new Date(today.getTime() + difference * 24 * 60 * 60 * 1000);
    return nextDate;
  }

  const closestDates = availability?.reduce((acc, entry) => {
    const closestDate = nextDay(entry.day);
    const formattedDate = closestDate;
    if (!acc[formattedDate]) {
      acc[formattedDate] = [];
    }
    acc[formattedDate].push(entry);
    return acc;
  }, {});

  const handleTimeSelection = (value, teacherId, classroomId) => {
    setSelectedValue({ "times": value, "classroom": classroomId });
    const [start_time, end_time] = value.split(" - ");
    // const updatedFormData = {
    //   ...newData,
    //   start_time, end_time, teacher_id: teacherId,
    // };
    // handleDataUpdate(updatedFormData)
  };

  const onChangeHandler = (result) => {
    return (e) => {
      if (e.target.checked) {
        setRequestIds([...requestIds, result.id]);
      } else {
        setRequestIds(requestIds.filter((id) => id !== result.id));
      }
    }
  }

  const onClickCreateGroup = () => {
    setDisabled(true);
    const data = {
      instrument_id: group_request.instrument.value,
      teacher_id: teacherId,
      group_request_ids: requestIds,
      lessons: [{
        start_date: selectedValue.times.split("T")[0],
        start_time: selectedValue.times.split(" - ")[0].split("T")[1],
        end_time: selectedValue.times.split(" - ")[1].split("T")[1],
      }],
      classroom_id: selectedValue.classroom,
    }
    axios.post(`${process.env.REACT_APP_API_URL}/lesson/group_level`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(() => {
        setCreated(true);
      })
      .catch((error) => {
        console.error("Error creating group:", error);
      });
  }

  return (
    <>
      <div style={{ overflow: "auto", height: '35rem' }}>
        <Title>Profesor</Title>
        <ThemeProvider theme={theme}>
          <FormControl
            sx={{
              "& label.Mui-focused": {
                color: "#215E69",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#215E69",
                },
              },
            }}
          >
            <InputLabel id="doc-type">Nombre del profesor</InputLabel>
            <Select
              labelId="teacher"
              id="teacher-select"
              label={"Nombre del profesor"}
              IconComponent={KeyboardArrowDownOutlinedIcon}
              sx={{
                borderRadius: "8px",
                [theme.breakpoints.down("sm")]: {
                  width: "327px",
                },
                [theme.breakpoints.up("sm")]: {
                  width: "416px",
                },
              }}
              onChange={(e) => handleChange(e)}
            >
              {teachers.map((item, id) => (
                <MenuItem key={id} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Title style={{ marginTop: "2rem" }}>Horarios disponibles</Title>
          {teacherId ? Object.entries(closestDates).map(([date, entries]) => (
            <div style={{ marginTop: "1rem" }}>
              <h2
                style={{
                  fontSize: "0.875rem",
                  fontWeight: 500,
                  color: "#4FB1C3",
                }}
              >
                {new Date(date).toLocaleDateString("es-ES", {
                  weekday: "long",
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </h2>
              <FormControl>
                <RadioGroup
                  name="radio-buttons-group"
                >
                  {entries.map((entry, index) => (
                    <FormControlLabel
                      control={
                        <Radio
                          sx={{
                            color: "#215E69"[600],
                            fontSize: "0.875rem",
                            "&.Mui-checked": { color: "#215E69" },
                          }}
                          checked={selectedValue?.times === `${new Date(date).toLocaleDateString('es-ES', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit'
                          }).split('/').reverse().join('-')}T${entry.start_time_} - ${new Date(date).toLocaleDateString('es-ES', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit'
                          }).split('/').reverse().join('-')}T${entry.end_time_}`}
                          onChange={() => {
                            handleTimeSelection(
                              `${new Date(date).toLocaleDateString('es-ES', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit'
                              }).split('/').reverse().join('-')}T${entry.start_time_
                              } - ${new Date(date).toLocaleDateString('es-ES', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit'
                              }).split('/').reverse().join('-')}T${entry.end_time_
                              }`,
                              entry.user_id,
                              entry.classroom_id
                            )
                          }}
                        />
                      }
                      sx={{
                        ".MuiFormControlLabel-label": {
                          fontSize: 14,
                          fontWeight: 500,
                        },
                      }}
                      label={`${entry.start_time_.substring(0, 5)} a ${entry.end_time_.substring(0, 5)}`}
                      value={`${new Date(date).toLocaleDateString('es-ES', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                      }).split('/').reverse().join('-')}T${entry.start_time_
                        } - ${new Date(date).toLocaleDateString('es-ES', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                        }).split('/').reverse().join('-')}T${entry.end_time_
                        }`}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          )) : null}

          <div style={{ marginTop: "2rem" }}>
            <Title style={{ marginBottom: '1rem' }}>Estudiantes a agregar</Title>
            <FormGroup>
              {group_request.results.map((result, id) => (
                <FormControlLabel
                  onChange={onChangeHandler(result)}
                  control={<Checkbox sx={{
                    color: "#215E69"[600],
                    "&.Mui-checked": {
                      color: "#215E69",
                    },
                  }} />}
                  label={<Typography
                    sx={{
                      fontSize: '0.875rem',
                      fontFamily: 'DM Sans',
                      fontWeight: 400,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {result.student.first_name} {result.student.last_name} ({result.student.age} años)
                  </Typography>}
                />))}
            </FormGroup>
          </div>
        </ThemeProvider>
      </div>
      <SubmitBtn>
        <AdminButtonContainer>
          <AdminFormButton disabled={disabled} onClick={onClickCreateGroup}>Crear grupo</AdminFormButton>
        </AdminButtonContainer>
      </SubmitBtn>
      <Dialog
        open={created}
        onClose={() => {
          setCreated(false);
          navigate("/salones");
        }}
      >
        <ClassCreated title={"Creación de clase grupal exitosa!"}
          info={"Hemos creado la clase grupal de manera satisfactoria y ahora podrás visualizarla en el calendario."} />
      </Dialog>
    </>
  );
}

export default NewCourse;
