import React from 'react'
import styled from 'styled-components'
import SideNavbarProf from '../components/professors/SideNavbarProf/SideNavbarProf'
import Navbar from '../components/professors/MobileProf/NavBarProf'
import { Outlet } from 'react-router-dom'

const StyledProfessorsLayout = styled.div`
    display: grid;
    grid-template-columns: 19rem 1fr;

    @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`

function ProfessorsLayout({ loggedIn, onLogout }) {
  return (
    <StyledProfessorsLayout>
      <SideNavbarProf loggedIn={loggedIn} onLogout={onLogout} />
      <Navbar loggedIn={loggedIn} onLogout={onLogout} />
      <Outlet />
    </StyledProfessorsLayout>
  )
}

export default ProfessorsLayout
