import React from "react";
import { Outlet } from "react-router-dom";
import SideNavbar from "../components/SideNavbar/SideNavbar";
import styled from "styled-components";
import Navbar from "../components/Navbar";

const StyledStudentsLayout = styled.div`
  display: grid;
  grid-template-columns: 19rem 1fr;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;


function StudentsLayout({ loggedIn, onLogout }) {
  return (
    <StyledStudentsLayout>
      <SideNavbar loggedIn={loggedIn} onLogout={onLogout} />
      <Navbar loggedIn={loggedIn} onLogout={onLogout} />
      <Outlet />
    </StyledStudentsLayout>
  );
}

export default StudentsLayout;
