import React, { useEffect, useState } from "react";
import RequestButton from "../../ui/RequestButton";
import Modal from "../../ui/Modal";
import ApproveTable from "./ApproveTable";
import axios from "axios";

function ApproveRequest() {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [pendingUsersCount, setPendingUsersCount] = useState(0);

  useEffect(() => {
    const fetchPendingUsersCount = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/validate/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        const count = response.data.length;
        setPendingUsersCount(count);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchPendingUsersCount();
  }, []);

  return (
    <div>
      <RequestButton
        onClick={() => setIsOpenModal((show) => !show)}
        disabled={pendingUsersCount === 0}
      >
        {pendingUsersCount > 0
          ? `${pendingUsersCount} solicitudes pendientes`
          : "0 solicitudes pendientes"}
      </RequestButton>
      {isOpenModal && (
        <Modal onClose={() => setIsOpenModal(false)} props='Detalle profesor'>
          <div style={{minWidth: '80rem'}}>
          <ApproveTable />
          </div>
        </Modal>
      )}
    </div>
  );
}

export default ApproveRequest;
