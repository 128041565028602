import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { ThemeProvider, FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { theme } from "../components/Styles/FormStyles";

function SelectComponent({ label, menuItems, onChange, value, name, error, helperText }) {
 return (
    <ThemeProvider theme={theme}>
      <FormControl
      error={error}
        sx={{
          "& label.Mui-focused": {
            color: "#215E69",
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: "#215E69",
            },
          },
        }}
      >
        <InputLabel id="country">{label}</InputLabel>
        <Select
          labelId="select-country"
          id="type-class-select"
          label={label}
          onChange={onChange}
          value={value}
          name={name}
          IconComponent={KeyboardArrowDownOutlinedIcon}
          sx={{
            borderRadius: "8px",
            [theme.breakpoints.down("sm")]: {
              width: "327px",
            },
            [theme.breakpoints.up("sm")]: {
              width: "416px",
            },
          }}
        >
          {menuItems.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText sx={{color: error ? theme.palette.error.main : 'inherit'}}>{helperText}</FormHelperText> 
      </FormControl>
    </ThemeProvider>
  );
}

export default SelectComponent;
