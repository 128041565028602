import styled from "styled-components";

export const MobileContainer = styled.div`
    display: none;
  @media screen and (max-width: 768px) {
    position: fixed;
    z-index: 999;
    width: 20.5rem;
    height: 100vh;
    padding-left: 0.6rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    bottom: 0;
    right: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
    top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
  }
`;

export const UpperWrapper = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    width: 100%
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;
export const CloseBtn = styled.button``;
export const CloseIcon = styled.img``;
