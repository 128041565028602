import { ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import React, { useState } from "react";
import SelectComponent from "../../../../ui/Select";
import TextInputField from "../../../../ui/TextInputField";
import { Heading } from "../../../EditProfile/EditProfileElements";
import { theme } from "../../../Styles/FormStyles";
import { FormContainer } from "../../../login/LoginElements";

function StepTwo({ formData, onDataChange, error }) {
  const [fullName, setFullName] = useState("");
  const type_doc = [
    {
      // id: 1,
      label: "Cedula de ciudadania",
      value: "CEDULA",
    },
    {
      // id: 2,
      label: "Tarjeta de identidad",
      value: "TARJETA DE IDENTIDAD",
    },
    {
      // id: 3,
      label: "Registro Civil",
      value: "REGISTRO CIVIL",
    },
    {
      // id: 4,
      label: "Cedula de extranjeria",
      value: "CEDULA DE EXTRANJERIA",
    },
    {
      // id: 5,
      label: "Pasaporte",
      value: "PASAPORTE",
    },
    {
      // id: 5,
      label: "Permiso especial",
      value: "PERMISO ESPECIAL",
    },
  ];

  const handleChange = (e, fieldName) => {
    let updatedFormData = { ...formData };
    if (fieldName === "full_name") {
      setFullName(e.target.value);

      const names = e.target.value.split(" ");
      updatedFormData = {
        ...updatedFormData,
        first_name: names[0] || "",
        last_name: names.slice(1).join(" ") || "",
      };
    } else if (e.target) {
      updatedFormData[fieldName] = e.target.value;
    } else {
      updatedFormData[fieldName] = e;
    }

    onDataChange(updatedFormData);
  };

  return (
    <div>
      <Heading>Datos Básicos</Heading>
      <FormContainer style={{ gap: "0rem" }}>
        <ThemeProvider theme={theme}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              marginBottom: "1.5rem",
            }}
          >
            <TextInputField
              label="Nombre completo"
              onChange={(e) => handleChange(e, "full_name")}
              value={fullName}
              name="full_name"
              error={error.first_name !== undefined}
              helperText={error.first_name}
            />

            <TextInputField
              label="Correo electrónico"
              onChange={(e) => handleChange(e, "email")}
              value={formData.email}
              type="email"
              name="email"
              error={error.email !== undefined}
              helperText={error.email}
            />

            <SelectComponent
              label="Tipo de documento"
              menuItems={type_doc}
              onChange={(e) => handleChange(e, "identification_number_type")}
              value={formData.identification_number_type}
              name="identification_number_type"
              error={error.identification_number_type !== undefined}
              helperText={error.identification_number_type}
            />

            <TextInputField
              label="Número de documento"
              onChange={(e) => handleChange(e, "identification_number")}
              value={formData.identification_number}
              name="identification_number"
              error={error.identification_number !== undefined}
              helperText={error.identification_number}
            />
            <div style={{ marginTop: "-0.5rem" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateField"]}>
                  <DateField
                    label="Fecha de nacimiento"
                    onChange={(e) => handleChange(e, "date_of_birth")}
                    value={formData.date_of_birth}
                    name="date_of_birth"
                    sx={{
                      [theme.breakpoints.down("sm")]: {
                        width: "327px",
                      },
                      [theme.breakpoints.up("sm")]: {
                        width: "416px",
                      },
                      "& label.Mui-focused": {
                        color: "#215E69",
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px",
                        "&.Mui-focused fieldset": {
                          borderColor: "#215E69",
                        },
                      },
                    }}
                    error={error.date_of_birth !== undefined}
                    helperText={error.date_of_birth}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <TextInputField
              label="País"
              onChange={(e) => handleChange(e, "country")}
              value={formData.country}
              name="country"
              error={error.country !== undefined}
              helperText={error.country}
            />
          </div>
          <Heading>Información de contacto</Heading>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            <TextInputField
              label="Dirección de residencia"
              onChange={(e) => handleChange(e, "address")}
              value={formData.address}
              name="address"
              error={error.address !== undefined}
              helperText={error.address}
            />
            <TextInputField
              label="Número de contacto"
              onChange={(e) => handleChange(e, "phone_number")}
              value={formData.phone_number}
              name="phone_number"
              error={error.phone_number !== undefined}
              helperText={error.phone_number}
            />
          </div>
        </ThemeProvider>
      </FormContainer>
    </div>
  );
}

export default StepTwo;
