import styled  from "styled-components";

export const Title = styled.h2`
    font-weight: 500;
    text-align: left;
    margin-bottom: 8px;
    font-size: 16px;
`;

export const Description = styled.p`
    font-size: 12px;
    text-align: left;
    margin-bottom: 30px;

    @media  screen and (max-width: 480px) {
        max-width: 327px;
    }
`;