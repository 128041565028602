import { ThemeProvider } from "@emotion/react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import styled from 'styled-components';
import edit from '../../../images/Edit.svg';
import Modal from "../../../ui/Modal";
import { TableHeadStyle, themeTable } from "../../../components/Styles/FormStyles";
import {
  EditBtn,
  EditBtnWrapper,
  EditImg,
} from "../../../components/admin/Students/StudentsTablesElements";
import AddAvailability from "./AddAvailability";
import axios from "axios";

const NoTime = styled.p`
  color: #EA5A47;
  font-size: 0.875rem;
  font-weight: 400;
`
const Range = styled.p`
  color: #3591F5;
  font-size: 0.875rem;
  font-weight: 400;
`
const AllDay = styled.p`
  color: #41A46D;
  font-size: 0.875rem;
  font-weight: 400;
`

function Table1() {

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);

  function handleOpen(e, dayId) {
    e.preventDefault();
    setSelectedDay(dayId);
    setIsOpenModal(true)
  }

  const daysOfWeek = [
    { id: 1, name: "Lunes", value:'MONDAY' },
    { id: 2, name: "Martes", value:'TUESDAY' },
    { id: 3, name: "Miércoles", value: 'WEDNESDAY' },
    { id: 4, name: "Jueves", value: 'THURSDAY' },
    { id: 5, name: "Viernes", value:'FRIDAY' },
    { id: 6, name: "Sábado", value: 'SATURDAY' },
  ];


  useEffect(() => {
    const fetchData = async() => {
      try {
        const id = localStorage.getItem('userId');
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/${id}/availability`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('access_token')}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const result = response.data
        console.log("Data fetched successfully:", result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData()
  },[])


  const getAvailabilityForDay = () => {
    const dayData = null; 

    if (dayData) {
      if (dayData.availability === "AllDay") {
        return <AllDay>Todo el día estaré disponible</AllDay>;
      } else if (dayData.availability === "NoTime") {
        return <NoTime>No tengo tiempo este día</NoTime>;
      } else {
        return <Range>{`${dayData.start_time_} - ${dayData.end_time_}`}</Range>;
      }
    } else {
      return <NoTime>No disponible en todo el día</NoTime>;
    }
  };

  return (
    <>
      <ThemeProvider theme={themeTable}>
        <Paper sx={{ width: "100%", borderRadius: "0.5rem 0.5rem 0rem 0rem" }}>
          <TableContainer
            sx={{ maxHeight: "47vh", borderRadius: "0.5rem 0.5rem 0rem 0rem" }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={TableHeadStyle}>
                <TableRow>
                  <TableCell align="left">Day</TableCell>
                  <TableCell align="left">Horarios disponibles</TableCell>
                  <TableCell align="center">Acciones</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {daysOfWeek.map((day) => (
                  <TableRow key={day.id} hover>
                    <TableCell align="left">{day.name}</TableCell>
                    <TableCell align="left">
                      { day.id && getAvailabilityForDay(day.id)}
                    </TableCell>
                    <TableCell align="center">
                      <EditBtnWrapper>
                        <EditBtn onClick={(e)=>handleOpen(e, day.id)}><EditImg src={edit} /> Editar</EditBtn>
                      </EditBtnWrapper>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {isOpenModal && (
        <Modal onClose={() => {
            setIsOpenModal(false)
        } } props="Agregar disponibilidad">
          <AddAvailability selectedDay={daysOfWeek.find(day => day.id === selectedDay)} />
        </Modal>
      )}
      </ThemeProvider>
    </>
  );
}

export default Table1;


