import React from "react";
import RightPageContainer from "../../ui/RightPageContainer";
import ProfCalendar from "../../components/professors/Calendario/ProfCalendar";

function CalendarProfPage() {
  return (
    <RightPageContainer>
      <ProfCalendar />
    </RightPageContainer>
  );
}

export default CalendarProfPage;
