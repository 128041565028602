import React, { useEffect, useState } from "react";
import {
  AdditionalPrice,
  ClassInfo,
  ClassName,
  CyclePrice,
  PaymentContainer,
  PaymentInfo,
  PaymentWrapper,
  Price,
  ProfessorName,
  Span1,
  SpanGreen,
  TimeInfo,
  Total,
  TotalPrice,
} from "../../MultiStepsForm/individual/StepSix/StepSixElements";
import {
  AdditionalInfo,
  ClassStarts,
  DateTime,
  Day,
} from "../../MultiStepsForm/individual/StepFive/StepFiveElements";
import SelectComponent from "../../../ui/Select";

function StepFour({ formData, onDataChange }) {
  // const formatDate = (dateString) => {
  //   const options = { day: "numeric", month: "long", year: "numeric" };
  //   const date = new Date(dateString);
  //   return date.toLocaleDateString("es-CO", options);
  // };

  const [calculatedClassPrice, setCalculatedClassPrice] = useState("");
  const [calculatedDiscount, setCalculatedDiscount] = useState("");
  const [calculatedRegInitially, setCalculatedRegInitially] = useState("");
  const [calculatedTotal, setCalculatedTotal] = useState("");

  let closestStartDate = formData.lesson;

  console.log("lesson", formData.lessons);

  if (formData.lessons.length > 1) {
    closestStartDate = formData.lessons.reduce((closest, current) => {
      return Math.abs(new Date(current.start_date) - new Date()) <
        Math.abs(new Date(closest.start_date) - new Date())
        ? current
        : closest;
    });
  } else if (formData.lessons.length === 1) {
    closestStartDate = formData.lessons[0];
  }

  // const formattedStartDate = formatDate(closestStartDate.start_date);

  const lessonInfo = formData.lessons.map((lessonItem, index) => {
    const startTimeParts = lessonItem.start_time.split(":");
    const startTime = startTimeParts[0] + ":" + startTimeParts[1];
    return (
      <>
        <Day key={index}>
          {lessonItem.day.charAt(0).toUpperCase() +
            lessonItem.day.slice(1).toLowerCase()}{" "}
          de cada semana - {startTime}
        </Day>
      </>
    );
  });

  const personalClasses = () => {
    let classPrice = "";
    let discount = "";
    let regInitially = "";
    let total = "";

    if (formData.lesson_type === "PRESENCIAL" && formData.intensity === "1") {
      if (formData.volume === "semestre") {
        if (formData.pricing_model === "SEMESTRAL") {
          if (formData.is_tuition_payed === true) {
            classPrice = "900.000";
            discount = "90.000";
            total = "810.000";
          } else {
            classPrice = "900.000";
            discount = "90.000";
            regInitially = "38.000";
            total = "848.000";
          }
        } else if (formData.pricing_model === "CICLO") {
          if (formData.is_tuition_payed === true) {
            classPrice = "180.000";
            discount = "0";
            total = "180.000";
          } else {
            classPrice = "180.000";
            regInitially = "38.000";
            discount = "0";
            total = "218.000";
          }
        }
      } else if (formData.volume === "trimestre") {
        if (formData.pricing_model === "TRIMESTRAL") {
          if (formData.is_tuition_payed === true) {
            classPrice = "540.000";
            discount = "37.800";
            total = "502.000";
          } else {
            classPrice = "540.000";
            discount = "37.800";
            regInitially = "38.000";
            total = "540.000";
          }
        }
      }
    } else if (
      formData.lesson_type === "PRESENCIAL" &&
      formData.intensity === "2"
    ) {
      if (formData.volume === "semestre") {
        if (formData.pricing_model === "SEMESTRAL") {
          if (formData.is_tuition_payed === true) {
            classPrice = "1.800.000";
            discount = "180.000";
            total = "1.620.000";
          } else {
            classPrice = "1.800.000";
            discount = "180.000";
            regInitially = "38.000";
            total = "1.658.000";
          }
        } else if (formData.pricing_model === "CICLO") {
          if (formData.is_tuition_payed === true) {
            classPrice = "360.000";
            discount = "36.000";
            total = "324.000";
          } else {
            classPrice = "360.000";
            discount = "36.000";
            regInitially = "38.000";
            total = "362.000";
          }
        }
      } else if (formData.volume === "trimestre") {
        if (formData.pricing_model === "TRIMESTRAL") {
          if (formData.is_tuition_payed === true) {
            classPrice = "1.080.000";
            discount = "108.000";
            total = "972.000";
          } else {
            classPrice = "1.080.000";
            discount = "108.000";
            regInitially = "38.000";
            total = "1.010.000";
          }
        }
      }
    } else if (
      formData.lesson_type === "VIRTUAL" &&
      formData.intensity === "1"
    ) {
      if (formData.volume === "semestre") {
        if (formData.pricing_model === "SEMESTRAL") {
          if (formData.is_tuition_payed === true) {
            classPrice = "750.000";
            discount = "75.000";
            total = "675.000";
          } else {
            classPrice = "750.000";
            discount = "75.000";
            regInitially = "38.000";
            total = "713.000";
          }
        } else if (formData.pricing_model === "CICLO") {
          if (formData.is_tuition_payed === true) {
            classPrice = "150.000";
            discount = "0";
            total = "150.000";
          } else {
            classPrice = "150.000";
            regInitially = "38.000";
            discount = "0";
            total = "188.000";
          }
        }
      } else if (formData.volume === "trimestre") {
        if (formData.pricing_model === "TRIMESTRAL") {
          if (formData.is_tuition_payed === true) {
            classPrice = "450.000";
            discount = "31.500";
            total = "418.000";
          } else {
            classPrice = "450.000";
            discount = "31.500";
            regInitially = "38.000";
            total = "456.000";
          }
        }
      }
    } else if (
      formData.lesson_type === "VIRTUAL" &&
      formData.intensity === "2"
    ) {
      if (formData.volume === "semestre") {
        if (formData.pricing_model === "SEMESTRAL") {
          if (formData.is_tuition_payed === true) {
            classPrice = "1.500.000";
            discount = "150.000";
            total = "1.350.000";
          } else {
            classPrice = "1.500.000";
            discount = "150.000";
            regInitially = "38.000";
            total = "1.388.000";
          }
        } else if (formData.pricing_model === "CICLO") {
          if (formData.is_tuition_payed === true) {
            classPrice = "300.000";
            discount = "15.000";
            total = "285.000";
          } else {
            classPrice = "300.000";
            discount = "15.000";
            regInitially = "38.000";
            total = "323.000";
          }
        }
      } else if (formData.volume === "trimestre") {
        if (formData.pricing_model === "TRIMESTRAL") {
          if (formData.is_tuition_payed === true) {
            classPrice = "900.000";
            discount = "63.000";
            total = "837.000";
          } else {
            classPrice = "900.000";
            discount = "63.000";
            regInitially = "38.000";
            total = "875.000";
          }
        }
      }
    } else if (
      formData.lesson_type === "DOMICILIO" &&
      formData.intensity === "1"
    ) {
      if (formData.volume === "semestre") {
        if (formData.pricing_model === "SEMESTRAL") {
          if (formData.is_tuition_payed === true) {
            classPrice = "1.250.000";
            discount = "0";
            total = "1.250.000";
          } else {
            classPrice = "1.250.000";
            discount = "0";
            regInitially = "38.000";
            total = "1.288.000";
          }
        } else if (formData.pricing_model === "CICLO") {
          if (formData.is_tuition_payed === true) {
            classPrice = "250.000";
            discount = "0";
            total = "250.000";
          } else {
            classPrice = "250.000";
            regInitially = "38.000";
            discount = "0";
            total = "288.000";
          }
        }
      } else if (formData.volume === "trimestre") {
        if (formData.pricing_model === "TRIMESTRAL") {
          if (formData.is_tuition_payed === true) {
            classPrice = "750.000";
            discount = "0";
            total = "750.000";
          } else {
            classPrice = "750.000";
            discount = "0";
            regInitially = "38.000";
            total = "788.000";
          }
        }
      }
    } else if (
      formData.lesson_type === "DOMICILIO" &&
      formData.intensity === "2"
    ) {
      if (formData.volume === "semestre") {
        if (formData.pricing_model === "SEMESTRAL") {
          if (formData.is_tuition_payed === true) {
            classPrice = "2.500.000";
            discount = "0";
            total = "2.500.000";
          } else {
            classPrice = "2.500.000";
            discount = "0";
            regInitially = "38.000";
            total = "2.538.000";
          }
        } else if (formData.pricing_model === "CICLO") {
          if (formData.is_tuition_payed === true) {
            classPrice = "500.000";
            discount = "0";
            total = "500.000";
          } else {
            classPrice = "500.000";
            regInitially = "38.000";
            discount = "0";
            total = "538.000";
          }
        }
      } else if (formData.volume === "trimestre") {
        if (formData.pricing_model === "TRIMESTRAL") {
          if (formData.is_tuition_payed === true) {
            classPrice = "1.500.000";
            discount = "0";
            total = "1.500.000";
          } else {
            classPrice = "1.500.000";
            discount = "0";
            regInitially = "38.000";
            total = "1.538.000";
          }
        }
      }
    }
    setCalculatedClassPrice(classPrice);
    setCalculatedDiscount(discount);
    setCalculatedRegInitially(regInitially);
    setCalculatedTotal(total);
  };

  useEffect(() => {
    personalClasses();
  }, [formData.volume, formData]);

  let volumeOptions = [];
  if (formData.intensity === "1") {
    volumeOptions = [
      { label: "1 semestre (20 clases)", value: "semestre" },
      { label: "2 trimestres (24 clases)", value: "trimestre" },
    ];
  } else if (formData.intensity === "2") {
    volumeOptions = [
      { label: "1 semestre (40 clases)", value: "semestre" },
      { label: "2 trimestres (48 clases)", value: "trimestre" },
    ];
  }

  const paymentOptions =
    formData.volume === "semestre"
      ? [
          { label: "Contado", value: "SEMESTRAL" },
          { label: "5 cuotas", value: "CICLO" },
        ]
      : [{ label: "2 cuotas", value: "TRIMESTRAL" }];

  const handlePaymentTypeChange = (fieldName) => (event) => {
    const value = event.target.value;
    let updatedFormData = { ...formData, [fieldName]: value };

    onDataChange(updatedFormData);
    console.log(updatedFormData);
  };

  return (
    <div style={{ height: "70vh", overflow: "auto" }}>
      <div
        style={{
          paddingTop: "0.2rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          marginBottom: "2rem",
        }}
      >
        <SelectComponent
          label="Volumen"
          menuItems={volumeOptions}
          onChange={handlePaymentTypeChange("volume")}
          value={formData.volume}
          name="volume"
        />
        <SelectComponent
          label="Modalidad de pago"
          menuItems={paymentOptions}
          value={formData.pricing_model}
          onChange={handlePaymentTypeChange("pricing_model")}
        />
        <SelectComponent
          label="Método de pago"
          menuItems={[
            { label: "Efectivo", value: "efectivo" },
            { label: "Transferencia", value: "transferencia" },
            { label: "Tarjeta debito", value: "debito" },
            { label: "Tarjeta Crédito", value: "crédito" },
          ]}
          onChange={handlePaymentTypeChange("payment_method")}
          value={formData.payment_method}
        />
      </div>
      <PaymentWrapper>
        <PaymentContainer style={{ width: "39rem" }}>
          <PaymentInfo style={{ borderRadius: "0.5rem" }}>
            <ClassInfo>
              <ClassName>
                Clases de{" "}
                {formData.instrument_name.charAt(0).toUpperCase() +
                  formData.instrument_name.slice(1)}
              </ClassName>
              <ProfessorName>Profesor(a) {formData.teacher_name}</ProfessorName>
            </ClassInfo>
            <TimeInfo>
              <Day>Intensidad {formData.intensityName}</Day>
              <Day>Clases Personalizada</Day>
              <Day>Modalidad de clases {formData.lesson_type}</Day>
              {lessonInfo}
              <AdditionalInfo>Horarios Fijos</AdditionalInfo>
              <ClassStarts>
                Comienzo de clases<br></br>
                {closestStartDate && (
                  <DateTime>{closestStartDate.start_date}</DateTime>
                )}
              </ClassStarts>
            </TimeInfo>
            <Day style={{ marginBottom: "2rem" }}>
              {formData.pricing_model === "SEMESTRAL"
                ? "Único pago"
                : formData.pricing_model === "CICLO"
                ? "Pagas cada 28 días"
                : formData.pricing_model
                ? "Pagas 2 cuotas"
                : ""}
            </Day>
            <div>
              <Day style={{ marginBottom: "1rem" }}>
                {formData.pricing_model === "CICLO"
                  ? "Pago primera cuota (1/5)"
                  : formData.pricing_model === "TRIMESTRAL"
                  ? "Pago primera cuota (1/2)"
                  : ""}
              </Day>
              <CyclePrice>
                <Span1>Valor del clases</Span1>
                <Price>$ {calculatedClassPrice} COP</Price>
              </CyclePrice>
              <CyclePrice>
                <Span1>Valor del descuento</Span1>
                <Price>-$ {calculatedDiscount} COP</Price>
              </CyclePrice>
              {!formData.is_tuition_payed ? (
                <AdditionalPrice>
                  <Span1>Valor matrícula (única vez)</Span1>
                  <Price>$ {calculatedRegInitially} COP</Price>
                </AdditionalPrice>
              ) : (
                <AdditionalPrice></AdditionalPrice>
              )}
              <Total>
                <SpanGreen>TOTAL A PAGAR</SpanGreen>
                <TotalPrice>$ {calculatedTotal} COP</TotalPrice>
              </Total>
            </div>
          </PaymentInfo>
        </PaymentContainer>
      </PaymentWrapper>
    </div>
  );
}

export default StepFour;

// const [paymentType, setPaymentType] = useState("CICLO");
// const [isCicloActive, setIsCicloActive] = useState(true);
// const [isSemestreActive, setIsSemestreActive] = useState(false);
// const [isTrimestreActive, setIsTrimestreActive] = useState(false);

// const handlePaymentTypeChange = (type) => {
//   setPaymentType(type);
//   setIsCicloActive(type === "CICLO");
//   setIsSemestreActive(type === "SEMESTRAL");
//   setIsTrimestreActive(type === "TRIMESTRAL");
//   const updatedFormData = { ...formData, pricing_model: type };
//   onDataChange(updatedFormData);
//   console.log(updatedFormData);
// };
