import React from "react";
import styled from "styled-components";
import { Title } from "../components/Overlay/OverlayElements";
import CloseBtn from "./CloseBtn";
import { createPortal } from "react-dom";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
`;

const StyledModal = styled.div`
  transition: all 0.5s;
  background-color: #fff;
  padding: 1rem 2rem;
  /* height: auto; */
  min-height: auto;
  border-radius: 0.5rem;
  min-width: 60%;
  z-index: 1002;
`;

const Button = styled.button`
  background: none;
  border: none;
`;

function Modal({ children, onClose,props }) {
  return createPortal (
    <Overlay>
      <StyledModal>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            marginBottom: '1.5rem'
          }}
        >
          <Title style={{fontSize:'1.25rem'}}>{props}</Title>
          <Button onClick={onClose}>
            <CloseBtn/>
          </Button>
        </div>
        <div>
        {children}
        </div>
      </StyledModal>
    </Overlay>,
    document.body
  );
}

export default Modal;
