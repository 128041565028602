import React from 'react'
import {ThemeProvider } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Steps, StepTitle } from '../../StepOne/StepOneElements'
import { Title,Description } from './StepTwoElements';
import {FormControlLabelStyle, radioStyle, theme} from '../../../Styles/FormStyles'

const StepTwo = ({ formData, onDataChange }) => {

  const handleChange = (fieldName) => (event) => {
    const value = event.target.value;
    const updatedFormData = {
      ...formData,
      [fieldName]: value,
    };
    onDataChange(updatedFormData);
    console.log(`Selected ${fieldName}:`, value);
    };

  return (
    <>
      <Steps>Paso 2 de 5</Steps>
      <StepTitle>Agendamiento de clase</StepTitle>
      <Title>Modalidad de clases</Title>
      <Description>
        Elije la modalidad en la que necesitas las clases de {formData.selectedClass} con el
        profesor {formData.selectedProfessor}
      </Description>
      <ThemeProvider theme={theme}>
      <FormControl sx={{ marginBottom: "60px" }}>
              <RadioGroup
                name="lesson_type"
                value={formData.lesson_type}
                onChange={handleChange("lesson_type")}
              >
                <FormControlLabel
                  value="PRESENCIAL"
                  control={<Radio sx={radioStyle} />}
                  sx={FormControlLabelStyle}
                  label="Presencial"
                />
                <FormControlLabel
                  value="VIRTUAL"
                  control={<Radio sx={radioStyle} />}
                  sx={FormControlLabelStyle}
                  label="Virtual"
                />
                <FormControlLabel
                  value="DOMICILIO"
                  control={<Radio sx={radioStyle} />}
                  sx={FormControlLabelStyle}
                  label="Domicilio"
                />
              </RadioGroup>
            </FormControl>
      </ThemeProvider>
     
    </>
  )
}

export default StepTwo
