import React, { useEffect, useState } from "react";
import {
  Steps,
  StepTitle,
} from "../../../MultiStepsForm/StepOne/StepOneElements";
import {
  Title,
  Description,
} from "../../../MultiStepsForm/individual/StepTwo/StepTwoElements";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../../Styles/FormStyles";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import styled from 'styled-components'

export const FlexibleWrapper = styled.div`
  height: 50vh; 
  overflow: auto;
  @media screen and (max-width: 768px) {
    height: 40vh;
  }
  @media screen and (max-width: 480px) {
    height: 38vh;
  }
`

function StepTwo({ classData, teacherInfo, handleDataUpdate, newData }) {
  const [selectedValue, setSelectedValue] = useState(null);
  
  console.log(teacherInfo)
  if (!teacherInfo || teacherInfo?.length === 0) {
    return (
      <>
      
        <>
        <StepTitle>Re-Agendamiento de clase</StepTitle>
        <Title>Horarios disponibles</Title>
        <Description style={{ width: "20rem" }}>
          No hay horarios disponibles para clases de {classData.instrument_name}{" "}
          con el/la profesor {classData.teacher_name}
        </Description>
        <p>Este profesor no tiene tiempo disponible</p>
        </>
      </>
    );
  }

  function nextDay(dayOfWeek) {
    var today = new Date();
    var currentDayOfWeek = today.getDay();
    var days = [
      "DOMINGO",
      "LUNES",
      "MARTES",
      "MIERCOLES",
      "JUEVES",
      "VIERNES",
      "SABADO",
    ];
    var targetDayIndex = days.indexOf(dayOfWeek);
    var difference = targetDayIndex - currentDayOfWeek;
    if (difference <= 0) {
      difference += 7;
    }
    var nextDate = new Date(today.getTime() + difference * 24 * 60 * 60 * 1000);
    return nextDate;
  }

  const closestDates = teacherInfo?.reduce((acc, entry) => {
    const closestDate = nextDay(entry.day);
    const formattedDate = closestDate;
    if (!acc[formattedDate]) {
      acc[formattedDate] = [];
    }
    acc[formattedDate].push(entry);
    return acc;
  }, {});

  const handleTimeSelection = (value, teacherId, classroomId) => {
    setSelectedValue(value)
    const [start_time, end_time] = value.split(" - ");
    console.log("Selected start time:", start_time);
    console.log("Selected end time:", end_time);
    let updatedClassroomId = classroomId; 

    if (classData.lesson_type === "DOMICILIO") {
      updatedClassroomId = 11; 
    } else if (classData.lesson_type === "VIRTUAL") {
      updatedClassroomId = 10; 
    }
    
    const updatedFormData = {
      ...newData,
      start_time, end_time, teacher_id: teacherId, classroom_id: updatedClassroomId,
    };
    handleDataUpdate(updatedFormData)
  };

  // console.log('newData', newData)
  // console.log('classData', classData)

  return (
    <>
      <StepTitle>Re-Agendamiento de clase</StepTitle>
      <Title>Horarios disponibles</Title>
      <Description style={{ width: "20rem" }}>
        Estos son los horarios disponibles para clases de{" "}
        {classData.instrument_name} con el/la profesor {classData.teacher_name}
      </Description>
      <FlexibleWrapper>

      <ThemeProvider theme={theme}>
        {Object.entries(closestDates).map(([date, entries]) => (
          <div style={{ marginBottom: "1.5rem" }}>
            <h2
              style={{
                fontSize: "0.875rem",
                fontWeight: 500,
                color: "#4FB1C3",
              }}
            >
              {new Date(date).toLocaleDateString("es-ES", {
                weekday: "long",
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </h2>
            <FormControl>
              <RadioGroup
                name="radio-buttons-group"
              >
                {entries.map((entry, index) => (
                  <FormControlLabel
                    control={
                      <Radio
                        sx={{
                          color: "#215E69"[600],
                          fontSize: "0.875rem",
                          "&.Mui-checked": { color: "#215E69" },
                        }}
                        checked={selectedValue === `${new Date(date).toLocaleDateString('es-ES', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                        }).split('/').reverse().join('-')}T${entry.start_time_} - ${new Date(date).toLocaleDateString('es-ES', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                        }).split('/').reverse().join('-')}T${entry.end_time_}`}
                        onChange={() => {
                          handleTimeSelection(
                            `${new Date(date).toLocaleDateString('es-ES', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit'
                            }).split('/').reverse().join('-')}T${
                              entry.start_time_
                            } - ${new Date(date).toLocaleDateString('es-ES', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit'
                            }).split('/').reverse().join('-')}T${
                              entry.end_time_
                            }`,
                            entry.user_id,
                            entry.classroom_id
                          )}}
                      />
                    }
                    sx={{
                      ".MuiFormControlLabel-label": {
                        fontSize: 14,
                        fontWeight: 500,
                      },
                    }}
                    label={`${entry.start_time_.substring(0, 5)} a ${entry.end_time_.substring(0, 5)}`}
                    value={`${new Date(date).toLocaleDateString('es-ES', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    }).split('/').reverse().join('-')}T${
                      entry.start_time_
                    } - ${new Date(date).toLocaleDateString('es-ES', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    }).split('/').reverse().join('-')}T${
                      entry.end_time_
                    }`}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        ))}
      </ThemeProvider>
      </FlexibleWrapper>
    </>
  );
}
export default StepTwo;
