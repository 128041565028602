import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { ReactComponent as inicio } from "../../images/Inicio.svg";
import { ReactComponent as calendar } from "../../images/Calendario.svg";
import { ReactComponent as comprar } from "../../images/Comprar.svg";
import { ReactComponent as notif } from "../../images/Notificaciones.svg";
import { ReactComponent as avalibility } from "../../images/Availability.svg";
import { ReactComponent as estudiantes } from "../../images/StudentsIcon.svg";
import { ReactComponent as profesores } from "../../images/TeachersIcons.svg";
import { ReactComponent as users } from "../../images/UsersIcon.svg";
import { ReactComponent as groups } from "../../images/GroupsIcons.svg";
import { ReactComponent as create  } from "../../images/Create.svg";
import { ReactComponent as active  } from "../../images/ClassesActive.svg";
import { ReactComponent as createGr  } from "../../images/Groups.svg";

export const NavbarContainer = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 19rem;
  background: #fff;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavProfile = styled(NavLink)`
  display: flex;
  width: 14.5rem;
  padding: 12px;
  align-items: center;
  justify-content: space-between;
  /* gap: 8px; */
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
  border: solid 1px #f3f5f7;
  padding: 0.75rem;
  border-radius: 0.5rem;
`;
export const ProfileImg = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  border: none;
  background-color: #4aa1b1;
`;

export const Name = styled.h1`
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: #1a2732;
  text-transform: capitalize;
  width: 10rem;
`;
export const Type = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #5c7389;
`;
export const NavMenu = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 3rem 0;
  gap: 16px;
  /* margin-bottom: 6.5rem; */
`;
export const NavItem = styled.li`
  display: flex;
  width: 100%;
`;
export const NavBtn = styled(NavLink)`
  padding-left: 1.5rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  &.active {
    background-color: #e1f5f8;
    width: 18.75rem;
    border-radius: 0.5rem 0rem 0rem 0.5rem;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0rem;
      bottom: 0rem;
      left: 100%;
      width: 0.25rem;
      height: 2.5rem;
      background-color: #4aa1b1;
      border-radius: 0rem 1rem 1rem 0rem;
    }
  }
`;
export const Span = styled.p`
  font-family: "DM Sans";
  padding-top: 2px;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  color: #818586;

  ${NavBtn}:hover &,${NavBtn}.active & {
    color: #2c2e2f;
    font-weight: 500;
  }
`;

const Icon = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 8px;

  ${NavBtn}:hover &, ${NavBtn}.active & {
    & path {
      stroke: #2c2e2f;
    }
  }
`;

export const BtnInicio = styled(Icon).attrs({ as: inicio })``;
export const BtnCalend = styled(Icon).attrs({ as: calendar })``;
export const BtnNotif = styled(Icon).attrs({ as: notif })``;
export const BtnGestionar = styled(Icon).attrs({ as: avalibility })``;
export const Estudiantes = styled(Icon).attrs({ as: estudiantes })``;
export const Profesores = styled(Icon).attrs({ as: profesores })``;
export const Groups = styled(Icon).attrs({ as: groups })``;
export const ActiveClass = styled(Icon).attrs({ as: active })``;
export const Users = styled(Icon).attrs({ as: users })``;
export const Create = styled(Icon).attrs({ as: create })``;
export const CreateGroup = styled(Icon).attrs({ as: createGr })``;
export const BtnComprar = styled(Icon).attrs({ as: comprar })`

  ${NavBtn}:hover &,${NavBtn}.active & {
    & path {
      fill: #2c2e2f;
      stroke: none;
    }
  }
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 200px; */
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0rem 1.5rem 1.5rem 1.5rem;
  gap: 2rem;
`;
export const SupportBtn = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  color: #818181;
  cursor: pointer;
   @media screen and (max-width: 480px) {
    width: 15rem;
  }
`;
export const SupportImg = styled.img`
  margin-right: 0.5rem;
`;
export const LogOutBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  color: #e5281a;
  font-size: 1rem;
  font-weight: 400;
  font-family: "DM Sans";
  cursor: pointer;
`;
export const LogOutImg = styled.img`
  margin-right: 0.5rem;
`;
