import React, { useEffect, useState } from "react";
import RightPageContainer from "../../ui/RightPageContainer";
import { MainContent } from "../MultiStepsForm/MultiStepFormElements";
import { StepTitle } from "../MultiStepsForm/StepOne/StepOneElements";
import { Description } from "../MultiStepsForm/individual/StepTwo/StepTwoElements";
import {
  ClassInfo,
  ClassName,
  ProfessorName,
  TimeInfo,
} from "../MultiStepsForm/individual/StepSix/StepSixElements";
import {
  AdditionalInfo,
  ClassStarts,
  Day,
} from "../MultiStepsForm/individual/StepFive/StepFiveElements";
import axios from "axios";
import Modal from "../../ui/Modal";
import ConfirmPayment from "../admin/Students/ConfirmPayment";

function ActiveCourse() {
  const [levelData, setLevelData] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [openedModalIndex, setOpenedModalIndex] = useState(null);
  const [userData, setUserData] = useState({});

  const handleClick = (index, id) => {
    setIsOpenModal(true);
    setSelectedId(id);
    setOpenedModalIndex(index);
  };

  useEffect(() => {
    const fetchLevelData = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const id = localStorage.getItem("userId");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/lesson/level?student_id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        setLevelData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchLevelData();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/lesson/level/${selectedId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        setUserData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (selectedId) {
      fetchUserData();
    }
  }, [selectedId]);

  console.log("selectedId", selectedId);

  const handleRedirect = async (data) => {
    const token = localStorage.getItem("access_token");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/lesson/level/payment_url/${selectedId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const { url } = response.data;
      window.location.href = url; 
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  // const lessonInfo = levelData.map((levelItem, levelIndex) => {
  //   const formattedLessons = levelItem.scheduled_times.map(
  //     (scheduleItem, scheduleIndex) => {
  //       const startTimeParts = scheduleItem.start_time.split(":");
  //       const startTime = startTimeParts[0] + ":" + startTimeParts[1];
  //       return (
  //         <Day key={scheduleIndex}>
  //           {scheduleItem.dow
  //             ? scheduleItem.dow.charAt(0).toUpperCase() +
  //               scheduleItem.dow.slice(1).toLowerCase()
  //             : ""}{" "}
  //           de cada semana - {startTime}
  //         </Day>
  //       );
  //     }
  //   );
  //   return <React.Fragment key={levelIndex}>{formattedLessons}</React.Fragment>;
  // });

  // const dateInfo = levelData.map((levelItem, levelIndex) => {
  //   const formatDate2 = (dateString) => {
  //     const options = { day: "numeric", month: "long", year: "numeric" };
  //     const date = new Date(dateString);
  //     return date.toLocaleDateString("es-ES", options);
  //   };

  //   const classDate = formatDate2(levelItem.level_start_date);
  //   return <React.Fragment key={levelIndex}>{classDate}</React.Fragment>;
  // });
  const lessonInfo = levelData.map((levelItem, levelIndex) => {
    const formattedLessons = levelItem.scheduled_times.map(
      (scheduleItem, scheduleIndex) => {
        const startTimeParts = scheduleItem.start_time.split(":");
        const startTime = startTimeParts[0] + ":" + startTimeParts[1];
        return (
          <Day key={scheduleIndex}>
            {scheduleItem.dow
              ? scheduleItem.dow.charAt(0).toUpperCase() +
                scheduleItem.dow.slice(1).toLowerCase()
              : ""}{" "}
            de cada semana - {startTime}
          </Day>
        );
      }
    );
    return <React.Fragment key={levelIndex}>{formattedLessons}</React.Fragment>;
  });

  const formattedDate = formatDate(userData.date_of_birth);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function getNextDateWithDay(dayOfWeek) {
    var today = new Date();
    var currentDayOfWeek = today.getDay();
    var days = [
      "DOMINGO",
      "LUNES",
      "MARTES",
      "MIERCOLES",
      "JUEVES",
      "VIERNES",
      "SABADO",
    ];
    var targetDayIndex = days.indexOf(dayOfWeek);
    console.log("targetDayIndex", targetDayIndex);
    var difference = targetDayIndex - currentDayOfWeek;
    if (difference <= 0) {
      difference += 7;
    }
    var nextDate = new Date(today.getTime() + difference * 24 * 60 * 60 * 1000);
    return nextDate;
  }

  const lessonDate = levelData.map((levelItem, levelIndex) => {
    let earliestDate;
    levelItem.scheduled_times.forEach((scheduleItem, scheduleIndex) => {
      const nextDate = getNextDateWithDay(scheduleItem.dow);
      if (!earliestDate || nextDate < earliestDate) {
        earliestDate = nextDate;
      }
    });
  
    if (earliestDate) {
      return earliestDate.toLocaleDateString("es-ES", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    } else {
      return null; 
    }
  });

  return (
    <RightPageContainer>
      <MainContent style={{ justifyContent: "flex-start" }}>
        <div>
          <StepTitle>Cursos activos</StepTitle>
          <Description style={{ width: "25rem" }}>
            Aquí podrás encontrar los cursos que tienes activos, visualizar las
            clases restantes disponibles y gestionar pagos pendientes
          </Description>
        </div>
        <div
          style={{
            height: "80vh",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {levelData.length > 0 ? (
            levelData.map((item, index) => (
              <div
                key={index}
                style={{
                  padding: "1rem",
                  border: "1px solid #C7C9CA",
                  width: "25rem",
                  borderRadius: "0.5rem",
                }}
              >
                <ClassInfo style={{ marginBottom: "0.5rem" }}>
                  <ClassName>
                    Clases de{" "}
                    {item.instrument.name
                      ? item.instrument.name.charAt(0).toUpperCase() +
                        item.instrument.name.slice(1).toLowerCase()
                      : ""}
                  </ClassName>
                  <ProfessorName>Profesor(a) {item.teacher}</ProfessorName>
                </ClassInfo>
                <TimeInfo style={{ marginBottom: "0.5rem" }}>
                  <Day>Intensidad {item.intensity}</Day>
                  <Day>Clases Personalizada</Day>
                  {lessonInfo[index]}
                  <AdditionalInfo>Horarios Fijos</AdditionalInfo>
                  <ClassStarts>
                    Comienzo de clases<br></br>
                    {/* {dateInfo[index]} */}
                    {lessonDate[index]}
                  </ClassStarts>
                </TimeInfo>
                <div style={{ marginBottom: "1rem" }}>
                {item.next_payment_value > 0 && <Day style={{color: '#14AE5C'}}>Próximo pago: {item.next_payment} </Day>}
                  <Day>Clases restantes: {item.remaining_lessons} clases</Day>
                  <Day>
                    Plan de pago :{" "}
                    {item.payment_plan === "CICLO"
                      ? "Semestre 5 cuotas"
                      : item.payment_plan === "TRIMESTRAL"
                      ? "Trimestre 2 cuotas"
                      : item.payment_plan === "SEMESTRAL"
                      ? "Semestre Contado"
                      : ""}
                  </Day>
                  <Day>
                    Estado : {item.payment_count}/{item.total_payments} cuotas
                  </Day>
                </div>
                <div>
                  {item.payment_count !== item.total_payments ? (
                    <button
                      style={{
                        borderRadius: "0.5rem",
                        background: "#4FB1C3",
                        padding: "0.5rem 1rem",
                        color: "#FFF",
                        fontWeight: 500,
                      }}
                      // onClick={(e) => handleClick(e)}
                      onClick={(e) => {
                        e.preventDefault();
                        handleClick(index, item.id);
                      }}
                    >
                      Renovar pago
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                padding: "1rem",
                background: "#efeded",
                width: "25rem",
                borderRadius: "0.5rem",
                height: "16rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Este estudiante no tiene clases activas
            </div>
          )}
        </div>
      </MainContent>
      {/* {isOpenModal && (
        <Modal 
        // onClose={() => setIsOpenModal(false)} 
        onClose={() => {
          setIsOpenModal(false);
          setOpenedModalIndex(null);
        }}
        >
          <ConfirmPayment text='Revisa los datos para renovar el pago' levelId={selectedId}/>
        </Modal>
      )} */}
      {isOpenModal && (
        <Modal
          onClose={() => {
            setIsOpenModal(false);
            setOpenedModalIndex(null);
          }}
        >
          <ConfirmPayment
            text="Revisa los datos para renovar el pago"
            levelId={selectedId}
            userData={userData}
            handleSubmit={handleRedirect}
          />
        </Modal>
      )}
    </RightPageContainer>
  );
}

export default ActiveCourse;
