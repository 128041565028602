import styled from "styled-components";

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
export const Date = styled.span`
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
`
export const CardInfo = styled.div`
    background-color: #F6F8FE;
    padding: 1rem;
    border-radius: 0.5rem;
`
export const CardContent = styled.div`
    width: 30rem
`
export const Announcement  = styled.span`
    font-size: 0.875rem;
    font-weight: 500;
`
export const Additional  = styled.p`
    font-size: 0.75rem;
    font-weight: 400;
`