import React from 'react'
import {Date,CardContainer, CardInfo, Announcement, Additional, CardContent } from './NotificationsElements'

const NotificationCard = () => {
  return (
    <CardContainer>
      <Date>Sábado 30 Diciembre 2023</Date>
      <CardInfo>
        <CardContent>
            <Announcement>Tu estudiante Pablo Jimenez ha cancelado la clase de Piano del día:
Sábado 30 de Diciembre 2023 </Announcement>
            <Additional>Clase pendiente de re-programación</Additional>
        </CardContent>
      </CardInfo>
    </CardContainer>
  )
}

export default NotificationCard
