import React from 'react'
// import RegForm from './regForm'
import desktop from '../../images/Desktop_Reg.png'
import google from '../../images/google.svg';
import {LoginSection, LoginContainer,ImageWrapper, ImageDesktop, LoginWrapper, HeadingWrapper, Logo, MobileLogo, LoginTitle, Divider, GoogleBtn, GLogo, Connection, NavLink} from '../login/LoginElements';
import RegistrationComponent from './Registration';
import { useParams } from 'react-router-dom';

const Registration = () => {
  const { paso } = useParams();
  return (
    <LoginSection>
      <LoginContainer >
        <ImageWrapper>
            <ImageDesktop src={desktop} />
        </ImageWrapper>
        <LoginWrapper style={{height: '100vh'}}>
          <HeadingWrapper>
            <Logo src='./logo_desktop.svg'/>
            <MobileLogo src='./logo_mobile.svg'/>
            {/* <LoginTitle>Registro</LoginTitle> */}
          </HeadingWrapper>
          <RegistrationComponent currentStep={paso} />
          {paso !== "paso-1" && paso !== "paso-2" && paso !== "paso-3" && (
            <>
              {/* <Divider>O continuar con</Divider>
              <GoogleBtn href='#'>
                <GLogo src={google} />
              </GoogleBtn> */}
              <Connection>
                ¿Ya tienes cuenta?<NavLink to='/login'>Ingresar</NavLink>
              </Connection>
            </>
          )}
        </LoginWrapper>
      </LoginContainer>
     </LoginSection>
  )
}

export default Registration
