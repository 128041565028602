import React from "react";
import MultiStepsForm from "../../components/MultiStepsForm/MultiStepsForm.js";

const MultiStepFormPage = () => {
  // const [displayNavbar, setDisplayNavbar] = useState(false)

  // useEffect(() => {
  //     const handleResize = () => {
  //     setDisplayNavbar(window.innerWidth < 768);
  //     };

  //     window.addEventListener("resize", handleResize);
  //     handleResize();

  //     return () => {
  //     window.removeEventListener("resize", handleResize);
  //     };
  // }, []);

  return <MultiStepsForm />;
};

export default MultiStepFormPage;
