import React from 'react'
import { BtnsContainer, CancelBtn } from '../CreateUser/StudentForm/StudentFormElements'
import DeleteButton from '../../../ui/DeleteButton'
import icon from "../../../images/DeleteIcon.svg";
import { Dialog } from '@mui/material';
import ClassCreated from './ClassCreated';
import { useNavigate } from 'react-router-dom';

function DeletePerson({ handleDelete, isDeleted, setIsDeleted }) {
  const navigate=useNavigate();
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          minHeight: '20rem'
        }}
      >
        <img src={icon} alt="" style={{ marginBottom: "1.5rem" }} />
        <h2
          style={{
            fontSize: "1rem",
            fontWeight: 500,
            marginBottom: '0.5rem'
          }}
        >
          ¿Seguro deseas borrar a este estudiante del grupo?
        </h2>
        <p
          style={{
            color: "#757575",
            fontWeight: 400,
          }}
        >
          Si borras este perfil los datos se perderán definitivamente y no podrás recuperarlos
        </p>
      </div>
      <BtnsContainer
        style={{
          width: "100%",
          padding: "1rem 0rem 0rem 0rem",
          gap: "1.5rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "20.4375rem" }}>
          <DeleteButton onClick={handleDelete}>Borrar</DeleteButton>
        </div>
        <CancelBtn>Mantener</CancelBtn>
      </BtnsContainer>
      <Dialog
        open={isDeleted}
        onClose={() => {
          setIsDeleted(false);
          navigate("/salones");
        }}
      >
        <ClassCreated title={"Eliminación exitosa"}
          info={"El estudiante ha sido eliminado exitosamente del grupo"} />
      </Dialog>
    </>
  )
}

export default DeletePerson
