import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { Outlet } from "react-router-dom";

const FormContext = createContext();

export const useTeacherFormContext = () => {
  return useContext(FormContext);
};

export const TeacherFormProvider = () => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    instrument_ids: [],
    address: "",
    country: "",
    identification_number_type: "",
    identification_number: "",
    date_of_birth: "",
    can_teach_at_home: "",
    can_teach_remote: "",
    bank: "",
    bank_account: "",
    account_type: "",
  });

  const handleSubmit = async (data) => {
    const token = localStorage.getItem("access_token");
    const id = localStorage.getItem("userId");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/user/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("FormProvider formData:", formData);
      console.log("Form data submitted:", response.data);
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
    console.log("Form data submitted:", data);
  };

  return (
    <FormContext.Provider value={{ formData, setFormData, handleSubmit }}>
      <Outlet />
    </FormContext.Provider>
  );
};
