import React from 'react'
import Registration from '../components/registration'

const RegistationPage = () => {
  return (
    <>
      <Registration />
    </>
  )
}

export default RegistationPage
