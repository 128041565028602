import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  ThemeProvider,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import {
  FormControlLabelStyle,
  radioStyle,
  theme,
} from "../../../components/Styles/FormStyles";
import SelectComponent from "../../../ui/Select";
import { SubmitBtn } from "../../../components/admin/Students/OverlayElements";
import AdminButtonContainer from "../../../ui/AdminButtonContainer";
import AdminFormButton from "../../../ui/AdminFormButton";
import axios from "axios";

function AddAvailability({ selectedDay }) {
  const [data, setData] = useState({
    availabilities: [],
    day: selectedDay.value,
  });
  const [showInputs, setShowInputs] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [availabilities, setAvailabilities] = useState([]);
  const [showFourthRange, setShowFourthRange] = useState(false);
  const [fourthRangeStartTime, setFourthRangeStartTime] = useState("");
  const [fourthRangeEndTime, setFourthRangeEndTime] = useState("");


  const handleSubmit = async () => {
    try {
      const id = localStorage.getItem("userId");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/${id}/availability`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("Data saved successfully:", response.data);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const generateTimeIntervals = () => {
    const intervals = [];
    let startHour = 9;
    let endHour = 21;

    if (selectedDay.name === "Sábado") {
      endHour = 18;
    }

    for (let i = startHour; i <= endHour; i++) {
      const hour = i < 10 ? `0${i}` : `${i}`;
      // intervals.push(`${hour}:00`);
      const isFilled = availabilities.some(avail => avail.start_time === `${hour}:00` && avail.end_time !== "");
      intervals.push({
        time: `${hour}:00`,
        // isFilled: availabilities.some(avail => avail.start_time === `${hour}:00` && avail.end_time !== ""),
        isFilled:isFilled
      });
    }
    return intervals;
  };

  const handleChange = (event) => {
    const { name, value, type } = event.target;

    if (type === "radio") {
      setSelectedValue(value);
      setShowInputs(value === "Puedo por rango de horas");
      if (value === "Puedo por rango de horas") {
        setAvailabilities([{}]);
      } else if (value === "Todo el día estaré disponible") {
        setAvailabilities([{ start_time: "09:00", end_time: "21:00" }]);
        setData({
          ...data,
          availabilities: [{ start_time: "09:00", end_time: "21:00" }],
        });
      } else {
        setAvailabilities([]);
        setData({ ...data, availabilities: [] });
      }
    } else {
      const index = parseInt(name.split("-")[1]);
      const updatedAvailabilities = [...availabilities];
      updatedAvailabilities[index] = {
        ...updatedAvailabilities[index],
        [name.split("-")[0]]: value,
      };
      setAvailabilities(updatedAvailabilities);
      // setData({ ...data, availabilities: updatedAvailabilities });
      const updatedData = { ...data };
      updatedData.availabilities = updatedAvailabilities;
      setData(updatedData);

      if (index === 2 && value !== "") {
        setShowFourthRange(true);
      }

      if (index === 3) {
        if (name.includes("start_time")) {
          setFourthRangeStartTime(value);
        } else if (name.includes("end_time")) {
          setFourthRangeEndTime(value);
        }
      }
    }
  };

  return (
    <>
      <div>
        <p
          style={{
            fontSize: " 0.875rem",
            fontWeight: 500,
            marginBottom: "1rem",
          }}
        >
          ¿Qué horas puedes el {selectedDay.name} ?
        </p>
        <ThemeProvider theme={theme}>
          <FormControl>
            <RadioGroup
              name="selectedModalidad"
              value={selectedValue}
              onChange={handleChange}
            >
              <FormControlLabel
                value="Todo el día estaré disponible"
                control={<Radio sx={radioStyle} />}
                sx={FormControlLabelStyle}
                label="Todo el día estaré disponible"
              />
              <FormControlLabel
                value="Puedo por rango de horas"
                control={<Radio sx={radioStyle} />}
                sx={FormControlLabelStyle}
                label="Puedo por rango de horas"
              />
            </RadioGroup>
          </FormControl>
        </ThemeProvider>
        {showInputs && (
          <>
            <div
              style={{
                height: "50vh",
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                gap: "1.5rem",
                marginTop: "1.5rem",
              }}
            >
              {[...Array(3)].map((_, index) => (
                <div key={index}>
                  <p>Rango</p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                      marginTop: "1rem",
                    }}
                  >
                    <SelectComponent
                      name={`start_time-${index}`}
                      value={availabilities[index]?.start_time}
                      label="Inicio del rango horario"
                      menuItems={generateTimeIntervals().map((time) => ({
                        label: time.time,
                        value: time.time,
                      }))}
                      onChange={handleChange}
                    />
                    <SelectComponent
                      name={`end_time-${index}`}
                      value={availabilities[index]?.end_time}
                      label="Elige final de disponibilidad"
                      menuItems={generateTimeIntervals().map((time) => ({
                        label: time.time,
                        value: time.time,
                      }))}
                      onChange={handleChange}
                    />
                    {generateTimeIntervals()[index].isFilled && (
              <button>Eliminar rango</button>
            )}
                  </div>
                </div>
              ))}
              {showFourthRange && (
                <div>
                  <p>Rango</p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.5rem",
                      marginTop: "1rem",
                    }}
                  >
                    <SelectComponent
                      name={`start_time-3`}
                      label="Inicio del rango horario"
                      menuItems={generateTimeIntervals().map((time) => ({
                        label: time.time,
                        value: time.time,
                      }))}
                      onChange={handleChange}
                      value={fourthRangeStartTime}
                    />
                    <SelectComponent
                      name={`end_time-3`}
                      label="Elige final de disponibilidad"
                      menuItems={generateTimeIntervals().map((time) => ({
                        label: time.time,
                        value: time.time,
                      }))}
                      onChange={handleChange}
                      value={fourthRangeEndTime}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        <SubmitBtn>
          <AdminButtonContainer>
            <AdminFormButton onClick={handleSubmit}>
              Guardar cambios
            </AdminFormButton>
          </AdminButtonContainer>
        </SubmitBtn>
      </div>
    </>
  );
}

export default AddAvailability;
