import React from "react";
import confirm from "../../images/Confirm.svg";
import {
  BtnWrapper,
  Icon,
  InfoTextContainer,
  InfoWrapper,
  Title,
} from "../Overlay/OverlayElements";
import AdminButtonContainer from "../../ui/AdminButtonContainer";
import AdminFormButton from "../../ui/AdminFormButton";
import styled from "styled-components";

const UserInfo = styled.div`
  font-size: 1rem;
  font-weight: 400;
  color: #757575;
`;

const Descr = styled.p`
  max-width: 40rem;
`;

function SuccessfullyRegistered({successMsg, errorMsg}) {

  const handleButtonClick = () => {
    window.location.href = "/login";
  };
  return (
    <>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <InfoWrapper>
          <InfoTextContainer>
            <Icon src={confirm} />
            <Title>
              {successMsg}
              {errorMsg && 'Registro ha fallado'}
            </Title>
            <UserInfo>
              <Descr>
                {successMsg && 
                'De ahora en adelante deberás ingresar con las credenciales que acabas de crear'
                }
                {errorMsg}
              </Descr>
            </UserInfo>
          </InfoTextContainer>
        </InfoWrapper>
        <BtnWrapper>
          <AdminButtonContainer>
            <AdminFormButton onClick={handleButtonClick}>
              Continuar
            </AdminFormButton>
          </AdminButtonContainer>
        </BtnWrapper>
      </div>
    </>
  );
}

export default SuccessfullyRegistered;
