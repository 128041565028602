import React from 'react'
import {ClassContainer, ClassMessage, MessageImg, MessageText, ImgWrapper, SubscribeWrapper,SubscribeBtn } from './CardsElements'
import no_class from '../../images/NoClass.svg'

const NoClassesCard = () => {
  return (
    <>
        <ClassContainer>
            <ClassMessage>
              <ImgWrapper>
                <MessageImg src={no_class}/>
              </ImgWrapper>
              <SubscribeWrapper>
                <MessageText style={{textAlign:'left'}}>Aún no cuentas con clases disponibles, subscribete a una de nuestras clases para empezar</MessageText>
              </SubscribeWrapper>
            </ClassMessage>
        </ClassContainer>
    </>
  )
}

export default NoClassesCard
