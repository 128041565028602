
import React, {useState } from "react";
import SelectComponent from "../../../ui/Select";
import { SubmitBtn } from "../Students/OverlayElements";
import AdminButtonContainer from "../../../ui/AdminButtonContainer";
import AdminFormButton from "../../../ui/AdminFormButton";
import TextInputField from "../../../ui/TextInputField";
import Checkmarks from "../../../ui/Checkmarks";
import axios from "axios";

function CreateRoom() {
  const [data, setData] = useState({
    name: "",
    description: "",
    instrument_ids: [],
    sede: "",
  });

  const handleSubmit = async () => {
    const token = localStorage.getItem("access_token");
    try { 
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/classroom`,data,{
          headers: {
            'Authorization': `Bearer ${token}`,
            "Content-Type": "application/json",
            'Accept': "application/json",
          }},);
        console.log("Form data submitted:", response.data);
      } catch (error) {
        console.error("Error submitting form data:", error);
      }
    console.log("Form data submitted:", data);
  };

  function handleChange(e, field) {
    let updatedData = { ...data };
    if (e.target) {
      updatedData[field] = e.target.value;
    } else {
      updatedData[field] = e;
    }
    setData(updatedData)
  }

 
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          minHeight: "30rem",
          minWidth: "50rem",
        }}
      >
        <TextInputField
          label="Nombre del salón"
          value={data.name}
          onChange={(e) => handleChange(e, "name")}
          name="name"
        />
        <TextInputField
          label="Descripción de la clase"
          value={data.description}
          onChange={(e) => handleChange(e, "description")}
          name="description"
        />
        <Checkmarks
          onChange={(e) => handleChange(e, "instrument_ids")}
          value={data.instrument_ids}
          name="instrument_ids"
        />
        <SelectComponent
          label="Sede"
          menuItems={[{ label: "Principal", value: "Principal" }]}
          value={data.sede}
          onChange={(e) => handleChange(e, "sede")}
          name="sede"
        />
      </div>
      <SubmitBtn>
        <AdminButtonContainer>
          <AdminFormButton onClick={handleSubmit}>Guardar cambios</AdminFormButton>
        </AdminButtonContainer>
      </SubmitBtn>
    </>
  );
}

export default CreateRoom;
