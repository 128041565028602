import React from "react";
import styled from "styled-components";

const StepWrapper = styled.div`
  width: 26rem;
  max-height: 55vh;
  margin-top: 3rem;
  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  @media screen and (max-width: 480px) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`;

function StepTwo({ data, error }) {
  return (
    <StepWrapper>
      {error ? (
        <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
          alignItems: "center",
          textAlign: "center",
          height: "40vh",
          justifyContent: "center",
        }}
      >
        <h2 style={{ fontWeight: 500 }}>No se pudo recuperar la contraseña</h2>
        <p style={{ color: "#757575", textAlign: 'center' }}>{error}</p>
      </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            alignItems: "center",
            textAlign: "center",
            height: "40vh",
            justifyContent: "center",
          }}
        >
          <h2 style={{ fontWeight: 500 }}>Recupera tu contraseña</h2>
          <p style={{ color: "#757575" }}>
            Hemos enviado tu contraseña al correo {data.email}
          </p>
        </div>
      )}
      <div style={{ height: "50vh", marginTop: "2.13rem" }}></div>
    </StepWrapper>
  );
}

export default StepTwo;
