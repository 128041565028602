import React from 'react'
import styled from 'styled-components'

const AdminBtnContainer = styled.div`
    width: 20.4rem;
`

function AdminButtonContainer({children}) {
  return (
    <AdminBtnContainer>
      {children}
    </AdminBtnContainer>
  )
}

export default AdminButtonContainer
