import React from 'react';
import styled from 'styled-components';
import alert from "../../../images/Alert.svg";
import AdminButtonContainer from '../../../ui/AdminButtonContainer';
import AdminFormButton from '../../../ui/AdminFormButton';
import { BtnWrapperCancel } from '../../Overlay/OverlayElements';
import { CancelBtn } from '../../admin/CreateUser/StudentForm/StudentFormElements';

const MessageText = styled.p`
  width: 70vh;
  text-align: center;
  @media screen and (max-width: 768px) {
    width: 50vh;
  }
  @media screen and (max-width: 480px) {
    width: 40vh;
  }
`;

function CancelAnyway({strikeDisclaimer, timeDifferenceDisclaimer, onClose, onCancel, teacherHeading}) {
  return (
    <>
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "45vh",
    }}>
      <img
          src={alert}
          style={{ width: "2.5rem", marginBottom: "1.5rem" }}
          alt=""
        />
        <h2
          style={{ fontSize: "1rem", fontWeight: 500, marginBottom: "0.5rem" }}
        >
          {teacherHeading ? teacherHeading :'Advertencia'}
          </h2>
        <MessageText>
          {strikeDisclaimer || timeDifferenceDisclaimer}
        </MessageText>
    </div>
        <BtnWrapperCancel>
          <AdminButtonContainer>
            <AdminFormButton onClick={onClose}>Mantener clase</AdminFormButton>
          </AdminButtonContainer>
          <CancelBtn onClick={onCancel}>Cancelar definitivamente</CancelBtn>
        </BtnWrapperCancel>
    </>
  )
}

export default CancelAnyway
