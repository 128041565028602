import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AdminFormButton from "../../../../ui/AdminFormButton";
import CreateUserContainer from "../../../../ui/CreateUserContainer";
import {
  Container,
  MainContent,
} from "../../../MultiStepsForm/MultiStepFormElements";
// import { OverlayContainer } from "../../../Overlay/OverlayElements";
import UserCreated from '../Overlay/UserCreated'
import {
  BackArrow,
  BtnBack,
  BtnBackWrapper,
  BtnText,
  BtnsContainer,
  CancelBtn,
} from "../StudentForm/StudentFormElements";
import StepTwo from "./StepTwo";
import { useFormContext } from "../../../../context/CreateStudentContext";
import Modal from '../../../../ui/Modal'
import StepThree from "./StepThree";
import { useEmailExistenceCheck } from "../../../../utils/useEmailExistenceCheck";

function TeacherForm({ defaultStep }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { paso } = useParams();
  const currentStep = paso || defaultStep;
  const { formData, setFormData, handleSubmit, successMsg, errorMsg } = useFormContext();
  const allData = { ...formData };
  const [error, setError] = useState({});
  const { emailExists, checkEmailExistence } = useEmailExistenceCheck();
  const navigate = useNavigate();

  useEffect(() => {
    if (formData.email) {
      checkEmailExistence(formData.email);
    }
  }, [formData.email, checkEmailExistence]);

  let stepNumber = 1;
  const isStep2 = currentStep === "paso-2";

  if (currentStep === "paso-1") {
    stepNumber = 1;
  } else if (currentStep === "paso-2") {
    stepNumber = 2;
  }
  const userType = 'TEACHER'

  const validateStep1Data = () => {
    let errors = {};

    if (!formData.email) {
      errors.email = "Correo electrónico es requerido";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Formato de correo electrónico inválido";
    } else if (emailExists) {
      errors.email = emailExists;
    }
    if (!formData.first_name) {
      errors.first_name = "Nombre es requerido";
    }
    if (!formData.identification_number_type) {
      errors.identification_number_type = "Tipo de documento es requerido";
    }
    if (!formData.identification_number) {
      errors.identification_number = "Número de documento es requerido";
    }
    if (!formData.date_of_birth) {
      errors.date_of_birth = "Fecha de nacimiento es requerida";
    }
    if (!formData.country) {
      errors.country = "País es requerido";
    }
    if (!formData.address) {
      errors.address = "Dirección de residencia es requerida";
    }
    if (!formData.phone_number) {
      errors.phone_number = "Teléfono de contacto es requerido";
    }
    if (!formData.instrument_ids || formData.instrument_ids.length === 0) {
      errors.instrument_ids = "Selecciona al menos un instrumento.";
    }
    if (formData.can_teach_at_home === '') {
      errors.can_teach_at_home = "Este campo es obligatorio";
    }
    if (formData.can_teach_remote === '') {
      errors.can_teach_remote = "Este campo es obligatorio";
    }
    console.log("Errors:", errors)
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const validateStep2Data = () => {
    let errors = {};

    if (!formData.bank) {
      errors.bank = "Selecciona una entidad bancaria.";
    }
    if (!formData.account_type) {
      errors.account_type = "Selecciona un tipo de cuenta.";
    }
  
    if (!formData.bank_account || formData.bank_account.trim() === "") {
      errors.bank_account = "El número de cuenta bancaria es obligatorio.";
    }
  
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleNextButtonClick = (e) => {
    e.preventDefault();

    let nextStep;
    if (currentStep === "paso-1") {
      if (validateStep1Data()) {
        nextStep = "paso-2";
        navigate(`/crear-usuario/profesor/${nextStep}`);
      }
    } else {
      if (validateStep2Data()) {
      handleSubmit(allData, `${userType.toLocaleLowerCase()}`)
      // setIsOpenModal(true);
      // return;
      if (handleSubmit) {
        setIsOpenModal(true);
      }
      }
    }

    // navigate(`/crear-usuario/profesor/${nextStep}`);
  };

  const handleBackButtonClick = (e) => {
    e.preventDefault();
    let previousStep;

    if (currentStep === "paso-2") {
      previousStep = "paso-1";
    } else {
      navigate("/crear-usuario/");
      return;
    }

    navigate(`/crear-usuario/profesor/${previousStep}`);
  };

  const handleDataChange = (data) => {
    // setFormData({ ...formData, ...data });
    const updatedFormData = { ...formData, ...data };
    const updatedErrors = { ...error };
    Object.keys(data).forEach((fieldName) => {
      if (updatedErrors[fieldName]) {
        delete updatedErrors[fieldName];
      }
    });
    setError(updatedErrors);
    setFormData(updatedFormData);
  };

  return (
    <CreateUserContainer>
      <div>
        <BtnBackWrapper>
          <BtnBack onClick={handleBackButtonClick}>
            <BackArrow />
          </BtnBack>
          <BtnText>
            Creación de usuario profesor - Paso <span>{stepNumber}</span>
          </BtnText>
        </BtnBackWrapper>
      </div>
      <MainContent>
        <Container
          style={{ padding: "0rem", overflowY: "auto", width: "100%", maxHeight: '70vh' }}
        >
          {currentStep === "paso-1" && <StepTwo formData={formData} onDataChange={handleDataChange} error={error} />}
          {currentStep === "paso-2" && <StepThree formData={formData} onDataChange={handleDataChange} error={error} />}
        </Container>
        <BtnsContainer
          style={{
            width: "100%",
            padding: "1rem 0rem 0rem 0rem",
            justifyContent: "none",
            gap: "1.5rem",
          }}
        >
          <div style={{ width: "20.4375rem" }}>
            <AdminFormButton onClick={handleNextButtonClick}>
              {isStep2 ? "Crear usuario" : "Siguiente"}
            </AdminFormButton>
          </div>
          {currentStep === "paso-2" && (
            <CancelBtn onClick={(e)=> {
              e.preventDefault()
              navigate('/crear-usuario')
            }}>Cancelar definitivamente</CancelBtn>
          )}
        </BtnsContainer>
        {isOpenModal && (
          <Modal onClose={() => setIsOpenModal(false)}>
            <UserCreated formData={formData}  userType={userType} errorMsg={errorMsg} successMsg={successMsg} />
          </Modal>
        )}
      </MainContent>
    </CreateUserContainer>
  );
}

export default TeacherForm;
