import React from "react";
import SelectComponent from "../../ui/Select";
import Checkmarks from "../../ui/Checkmarks";
import { ThemeProvider } from "@mui/material";
import { theme } from "../Styles/FormStyles";
import TextInputField from "../../ui/TextInputField";

function StepTwo({ formData, onDataChange, error }) {
  const handleChange = (e, fieldName) => {
    const updatedFormData = { ...formData };

    if (e.target) {
      updatedFormData[fieldName] = e.target.value;
    } else {
      updatedFormData[fieldName] = e;
    }

    onDataChange(updatedFormData);
  };

  return (
    <div style={{ height: "50vh" }}>
      <div>
        <ThemeProvider theme={theme}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              marginBottom: "1.5rem",
            }}
          >
            <TextInputField
              label="Dirección de residencia"
              onChange={(e) => handleChange(e, "address")}
              value={formData.address}
              name="address"
              error={error.address !== undefined}
              helperText={error.address}
            />
            <TextInputField
              label="Teléfono de contacto"
              onChange={(e) => handleChange(e, "phone_number")}
              value={formData.phone_number}
              name="phone_number"
              error={error.phone_number !== undefined}
              helperText={error.phone_number}
            />
            <Checkmarks
              onChange={(e) => handleChange(e, "instrument_ids")}
              value={formData.instrument_ids}
              name="instrument_ids"
              error={error.instrument_ids !== undefined}
              helperText={error.instrument_ids}
            />
            <SelectComponent
              onChange={(e) => handleChange(e, "can_teach_at_home")}
              value={formData.can_teach_at_home}
              name="can_teach_at_home"
              label="¿Deseas dictar clases a domicilio?"
              menuItems={[
                { label: "Sí", value: true },
                { label: "No", value: false },
              ]}
              error={error.can_teach_at_home}
              helperText={error.can_teach_at_home}
            />
            <SelectComponent
              onChange={(e) => handleChange(e, "can_teach_remote")}
              value={formData.can_teach_remote}
              name="can_teach_remote"
              label="¿Deseas dictar clases virtuales?"
              menuItems={[
                { label: "Sí", value: true },
                { label: "No", value: false },
              ]}
              error={error.can_teach_remote}
              helperText={error.can_teach_remote}
            />
          </div>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default StepTwo;
