import React, { useEffect, useState } from "react";
import InactiveMain from "../../components/MainSection/InactiveMain";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "../../ui/Modal";
import calendar from "../../images/Confirm.svg";
import error from "../../images/Exclamation_circle.svg";

const HomePage = () => {
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paymentId = urlParams.get("id");

    if (paymentId) {
      const fetchPaymentStatus = async () => {
        const token = localStorage.getItem("access_token");
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/payment/${paymentId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            }
          );
          setPaymentStatus(response.data.status);
          navigate("/inicio");
        } catch (error) {
          console.error("Error fetching payment status:", error);
        }
      };
      fetchPaymentStatus();
    }
  }, [navigate]);
  console.log(paymentStatus);
  return (
    <>
      <InactiveMain paymentStatus={paymentStatus} />
      {paymentStatus && (
        <Modal onClose={() => setPaymentStatus(null)}>
          {paymentStatus === "APPROVED" ? (
            <div style={{display: 'flex',alignItems: 'center', flexDirection: 'column', height: '40vh', justifyContent: 'center'}}>
          <img
            src={calendar}
            style={{ width: "2.5rem", marginBottom: "1.5rem" }}
            alt=""
          />
          <h2
            style={{
              fontSize: "1rem",
              fontWeight: 500,
              marginBottom: "0.5rem",
            }}
          >Pago efectuado correctamente
          </h2>
          <p style={{ width: "40rem", textAlign: "center", color: '#757575' }}>
          Ahora podrás visualizar las clases que acaba de comprar en tu calendario
          </p>
          </div>
          ) : (
            <div style={{display: 'flex',alignItems: 'center', flexDirection: 'column', height: '40vh', justifyContent: 'center'}}>
            <img
              src={error}
              style={{ width: "2.5rem", marginBottom: "1.5rem" }}
              alt=""
            />
              <h2 style={{
              fontSize: "1rem",
              fontWeight: 500,
              marginBottom: "0.5rem",
            }}>El pago ha fallado</h2>
              <p style={{ width: "40rem", textAlign: "center", color: '#757575' }}> Intenta nuevamente o contáctanos para asistencia.</p>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default HomePage;
