import React from "react";
import styled, {keyframes} from "styled-components";

const rotate = keyframes`
  to {
    transform: rotate(1turn)
  }
`;

const StyledSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  animation: ${rotate} 1.5s infinite linear;
`

function Spinner() {
  return (
    <>
    <StyledSpinner>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.8753 7.37956C16.7205 4.80579 21.3392 3.64493 25.9444 4.09477C30.5496 4.54461 34.8563 6.57732 38.1308 9.84655C41.4053 13.1158 43.4449 17.4193 43.9021 22.0237C44.3593 26.6282 43.2059 31.2487 40.6383 35.0981C38.0707 38.9474 34.2478 41.7874 29.8211 43.1342C25.3943 44.4809 20.6375 44.251 16.3612 42.4838C15.3403 42.0619 14.8548 40.8923 15.2767 39.8715C15.6986 38.8507 16.8681 38.3651 17.8889 38.787C21.31 40.2008 25.1154 40.3847 28.6568 39.3073C32.1983 38.23 35.2566 35.958 37.3106 32.8785C39.3647 29.799 40.2875 26.1025 39.9217 22.419C39.5559 18.7354 37.9242 15.2926 35.3046 12.6772C32.685 10.0619 29.2396 8.43569 25.5555 8.07582C21.8713 7.71595 18.1764 8.64464 15.1002 10.7037C12.024 12.7627 9.75694 15.8246 8.68524 19.3678C7.61354 22.9109 7.80353 26.7161 9.22285 30.1348C9.64637 31.155 9.16271 32.3253 8.14256 32.7488C7.12241 33.1723 5.95208 32.6887 5.52856 31.6685C3.75442 27.3951 3.51692 22.6386 4.85655 18.2097C6.19617 13.7808 9.03002 9.95333 12.8753 7.37956Z"
          fill="#4FB1C3"
        />
      </svg>
    </StyledSpinner>
    </>
  );
}

export default Spinner;
