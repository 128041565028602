import React from "react";
// import styled from 'styled-components';
import {
  ClassWrapper,
  ClassInformation,
  ClassType,
  DateDay,
  Left,
  Place,
  Professor,
  Right,
  Room,
  Status,
  Time,
} from "../CalendarStudents/ClassStyle";

function Class({
  status,
  formattedStartDate,
  capitalizedInstrumentName,
  teacher_name,
  modality,
  formattedStartTime,
  classroomName,
}) {
  return (
    <ClassWrapper status={status}>
      <ClassInformation>
        <Left>
          <DateDay>{formattedStartDate}</DateDay>
          <ClassType>Clase de {capitalizedInstrumentName}</ClassType>
          <Professor>Profesor: {teacher_name}</Professor>
          <Place>{modality}</Place>
        </Left>
        <Right>
          <Time>{formattedStartTime}</Time>
          <Status status={status}>
            {status === "PENDING"
              ? "Pendiente"
              : status === "ACTIVE"
              ? "Activa"
              : status === "COMPLETED"
              ? "Terminado"
              : status === "CANCELLED"
              ? "Cancelada"
              : ""}
          </Status>
          <Room>{classroomName}</Room>
        </Right>
      </ClassInformation>
    </ClassWrapper>
  );
}

export default Class;
