import React from "react";
import ConfirmChanges from "./ConfirmChanges";
import Extra from "./Extra";
import StepTwo from "./StepTwo";
import AdminButtonContainer from "../../../../ui/AdminButtonContainer";
import AdminFormButton from "../../../../ui/AdminFormButton";


function RescheduleForm({ step, classData ,teacherInfo, otherTeachers, handleDataUpdate, newData , error,
  successMessage,admin,handleConfirmReschedule, disabled}) {
  const ShowStep = () => {
    switch (step) {
      case 0:
        return <StepTwo classData={classData} teacherInfo={teacherInfo} handleDataUpdate={handleDataUpdate} newData={newData} />;
      case 1:
        return teacherInfo.length === 0 || teacherInfo === null ? (
          <Extra otherTeachers={otherTeachers} classData={classData} handleDataUpdate={handleDataUpdate} newData={newData} />
        ) : (
          <ConfirmChanges error={error} successMessage={successMessage} />
        );
      case 2:
        return <ConfirmChanges error={error} successMessage={successMessage} admin={admin} />;
      default:
        return null;
    }
  };
  

  return (
    <>
      <form style={{ minHeight: "25rem"}}>{ShowStep()}</form>
      <div style={{display: 'flex', justifyContent: 'center', marginTop: '2rem', zIndex: '999'}}>
        <AdminButtonContainer>
          <AdminFormButton onClick={handleConfirmReschedule} disabled={disabled}
          >Confirmar</AdminFormButton>
        </AdminButtonContainer>
      </div>
    </>
  );
}

export default RescheduleForm;
