import React from 'react'
import RecoverPassword from '../components/RecoverPassword/RecoverPassword'

function RecoverPasswordPage() {
  return (
    <>
     <RecoverPassword /> 
    </>
  )
}

export default RecoverPasswordPage
