import TextField from "@mui/material/TextField";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import React, { useEffect, useState } from "react";
import MainButton from "../../ui/Button";
import SelectComponent from "../../ui/Select";
import { getUserData, updateUser } from "../../utils/tools";
import {
  MainContainer,
  MainWrapper,
} from "../MainSection/InactiveMainElements";
import {
  BtnsContainer,
  MainContent,
} from "../MultiStepsForm/MultiStepFormElements";
import { textFieldStyle, theme } from "../Styles/FormStyles";
import {
  ContactInfo,
  EditForm,
  EmailInfo,
  EmergencyInfo,
  Heading,
  PageTitle,
  PersonalInfo,
} from "./EditProfileElements";
import dayjs from "dayjs";
import styled from "styled-components";
import TextInputField from "../../ui/TextInputField";

const BtnWrapper = styled.div`
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    margin-bottom: 1.6rem;
  }
  @media screen and (max-width: 480px) {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
  }
`;

function EditProfile() {
  const [fullName, setFullName] = useState();
  const [userData, setUserData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    identification_number: "",
    identification_number_type: "",
    date_of_birth: null,
    phone_number: "",
    emergency_contact_name: "",
    emergency_contact_phone: "",
    country: "",
    billing_mail: "",
    acquisition_channel: "",
    bank: "string",
    bank_account: "string",
    account_type: "string",
    address: "",
  });

  const type_doc = [
    {
      label: "Cedula de ciudadania",
      value: "CEDULA",
    },
    {
      label: "Tarjeta de identidad",
      value: "TARJETA DE IDENTIDAD",
    },
    {
      label: "Registro Civil",
      value: "REGISTRO CIVIL",
    },
    {
      label: "Cedula de extranjeria",
      value: "CEDULA DE EXTRANJERIA",
    },
    {
      label: "Pasaporte",
      value: "PASAPORTE",
    },
    {
      label: "Permiso especial",
      value: "PERMISO ESPECIAL",
    },
  ];

  const [interactedFields, setInteractedFields] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getUserData(localStorage.getItem("userId"));
        setUserData(result);
        console.log("API Response:", result);
      } catch (error) {
        console.error("Error:", error.message);
      }
    };
    fetchData();
  }, []);

  const handleChange = (e, fieldName) => {
    setInteractedFields((prevInteractedFields) => ({
      ...prevInteractedFields,
      [fieldName]: true,
    }));

    setUserData((prevUserData) => {
      let updatedUserData = { ...prevUserData };

      if (fieldName === "full_name") {
        setFullName(e.target.value);
        const names = e.target.value.split(" ");
        updatedUserData = {
          ...updatedUserData,
          first_name: names[0] || "",
          last_name: names.slice(1).join(" ") || "",
        };
      } else {
        if (e.target) {
          updatedUserData[fieldName] = e.target.value;
        } else {
          updatedUserData[fieldName] = e;
        }
      }
      return updatedUserData;
    });
  };

  const hasInteracted = Object.values(interactedFields).some(Boolean);

  function handleSubmit(e) {
    e.preventDefault();
    const userId = localStorage.getItem("userId");
    if (hasInteracted) {
      updateUser({ userId, userData })
        .then((updatedUserData) => {
          console.log("User data updated successfully!", updatedUserData);
        })
        .catch((error) => {
          console.error("Error updating user data:", error.message);
        });
    } else {
      console.log("No changes to save.");
    }
  }

  return (
    <MainContainer>
      <MainWrapper style={{ padding: "1rem 5rem", alignItems: "flex-start" }}>
        <MainContent style={{ height: "88vh" }}>
          <PageTitle style={{ marginBottom: "2rem" }}>
            Edición de perfil
          </PageTitle>
          <EditForm>
            <PersonalInfo>
              <Heading>Informacíon personal</Heading>
              <ThemeProvider theme={theme}>
                <div style={{ marginBottom: "16px" }}>
                  <TextField
                    id="outlined-basic"
                    label="Nombre completo"
                    variant="outlined"
                    onChange={(e) => handleChange(e, "full_name")}
                    value={
                      fullName || `${userData.first_name} ${userData.last_name}`
                    }
                    name="full_name"
                    sx={textFieldStyle}
                  />
                </div>
                <div style={{ marginBottom: "16px" }}>
                  <SelectComponent
                    label="Tipo de documento"
                    menuItems={type_doc}
                    onChange={(e) =>
                      handleChange(e, "identification_number_type")
                    }
                    value={userData.identification_number_type || ""}
                    name="identification_number_type"
                  />
                </div>
                <div style={{ marginBottom: "8px" }}>
                  <TextField
                    id="outlined-basic"
                    label="Número de documento"
                    variant="outlined"
                    onChange={(e) => handleChange(e, "identification_number")}
                    value={userData.identification_number}
                    name="identification_number"
                    sx={textFieldStyle}
                  />
                </div>
                <div style={{ marginBottom: "16px" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DateField"]}>
                      <DateField
                        label="Fecha de nacimiento"
                        onChange={(e) => handleChange(e, "date_of_birth")}
                        value={dayjs(userData.date_of_birth)}
                        name="date_of_birth"
                        sx={{
                          [theme.breakpoints.down("sm")]: {
                            width: "327px",
                          },
                          [theme.breakpoints.up("sm")]: {
                            width: "416px",
                          },
                          "& label.Mui-focused": {
                            color: "#215E69",
                          },
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "8px",
                            "&.Mui-focused fieldset": {
                              borderColor: "#215E69",
                            },
                          },
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div>
                  {/* <SelectComponent
                    label="País"
                    menuItems={[{ label: "Colombia", value: "Colombia" }]}
                    onChange={(e) => handleChange(e, "country")}
                    value={userData.country}
                    name="country"
                  /> */}
                  <TextInputField
                    label="País"
                    onChange={(e) => handleChange(e, "country")}
                    value={userData.country}
                    name="country"
                  />
                </div>
              </ThemeProvider>
            </PersonalInfo>
            <ContactInfo>
              <Heading>Información de contacto</Heading>
              <ThemeProvider theme={theme}>
                <div style={{ marginBottom: "16px" }}>
                  <TextField
                    id="outlined-basic"
                    label="Dirección"
                    variant="outlined"
                    onChange={(e) => handleChange(e, "address")}
                    value={userData.address}
                    name="address"
                    sx={textFieldStyle}
                  />
                </div>
                <div style={{ marginBottom: "16px" }}>
                  <TextField
                    id="outlined-basic"
                    label="Número de contacto"
                    variant="outlined"
                    onChange={(e) => handleChange(e, "phone_number")}
                    value={userData.phone_number}
                    name="phone_number"
                    sx={textFieldStyle}
                  />
                </div>
              </ThemeProvider>
            </ContactInfo>
            <EmailInfo>
              <Heading>Correo de recepción de Estado de cuenta</Heading>
              <ThemeProvider theme={theme}>
                <TextField
                  id="outlined-basic"
                  label="Correo electónico"
                  variant="outlined"
                  onChange={(e) => handleChange(e, "billing_mail")}
                  value={userData.billing_mail}
                  name="billing_mail"
                  sx={textFieldStyle}
                />
              </ThemeProvider>
            </EmailInfo>
            <EmergencyInfo>
              <Heading>Contacto de emergencia</Heading>
              <ThemeProvider theme={theme}>
                <div style={{ marginBottom: "16px" }}>
                  <TextField
                    id="outlined-basic"
                    label="Nombre completo"
                    variant="outlined"
                    onChange={(e) => handleChange(e, "emergency_contact_name")}
                    value={userData.emergency_contact_name}
                    name="emergency_contact_name"
                    sx={textFieldStyle}
                  />
                </div>
                <div style={{ marginBottom: "16px" }}>
                  <TextField
                    id="outlined-basic"
                    label="Número de contacto"
                    variant="outlined"
                    onChange={(e) => handleChange(e, "emergency_contact_phone")}
                    value={userData.emergency_contact_phone}
                    name="emergency_contact_phone"
                    sx={textFieldStyle}
                  />
                </div>
              </ThemeProvider>
            </EmergencyInfo>
          </EditForm>
          <BtnsContainer style={{ marginTop: "1rem" }}>
            <ThemeProvider theme={theme}>
              <BtnWrapper>
                <MainButton disabled={!hasInteracted} onClick={handleSubmit}>
                  Guardar cambios
                </MainButton>
              </BtnWrapper>
            </ThemeProvider>
          </BtnsContainer>
        </MainContent>
      </MainWrapper>
    </MainContainer>
  );
}

export default EditProfile;
