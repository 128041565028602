import styled from "styled-components";
import {ReactComponent as camera} from '../../images/CameraIcon.svg'


export const PageTitle = styled.h1`
    margin-bottom: 3.5rem;
    font-size: 1.25rem;
    font-weight: 500;
`

export const EditForm = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2rem;
    max-height: 51rem;
    overflow-y: auto;
    margin-bottom: 1rem;
    
`

export const ImgInput = styled.div`
    height: 5rem;
    width: 5rem;
    margin-bottom: 2rem;
`

export const PersonalInfo = styled.div`
`

export const Heading = styled.h2`
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
`

export const ContactInfo = styled.div`
 
`
export const EmailInfo = styled.div`
 
`
export const EmergencyInfo = styled.div`
 
`
export const CameraIcon = styled(camera)`
 
`


               
            