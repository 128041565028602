import React from "react";
import Cancellation from "./Cancellation";
import ConfirmCancel from "./ConfirmCancel";

function CancelClass({
  step,
  error,
  successMessage,
  toReschedule,
  onCancel,
  onClose,
  teacherText,
  teacherHeading
}) {
  const steps = [
    <ConfirmCancel toReschedule={toReschedule} onCancel={onCancel} teacherHeading={teacherHeading} teacherText={teacherText} onClose={onClose}/>,
    <Cancellation
      error={error}
      successMessage={successMessage}
      onClose={onClose}
      teacherHeading={teacherHeading}
    />,
  ];
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {steps[step]}
    </div>
  );
}

export default CancelClass;
