import styled from "styled-components";

export const PaymentWrapper = styled.div`
  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 480px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const PaymentContainer = styled.div`
  width: 39rem;
  @media screen and (max-width: 768px) {
    width: 25rem;
  }
  @media screen and (max-width: 480px) {
    width: 21.5rem;
  }
`;
export const BtnWrapper = styled.div`
  display: flex;
`;

const Btn = styled.button`
  padding: 8px;
  font-size: 16px;
  text-align: center;
  width: 208px;
  border: none;
  cursor: pointer;
  background-color: ${({ active }) => (active ? "#F6F6F6" : "#FFF")};
`;

export const Btn1 = styled(Btn)`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  @media screen and (max-width: 480px) {
    width: 173px;
  }
`;

export const Btn2 = styled(Btn)`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  @media screen and (max-width: 480px) {
    width: 170px;
  }
`;
export const Btn3 = styled(Btn)`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  @media screen and (max-width: 480px) {
    width: 170px;
  }
`;

export const PaymentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #f6f6f6;
  /* width: 39rem; */
  /* height: 20rem; */
  /* padding: 0px 30px; */
  padding: 1.5rem;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  @media screen and (max-width: 480px) {
    /* width: 343px; */
    padding: 1.2rem 1rem;
  }
`;
export const ClassInfo = styled.div`
  margin-bottom: 14px;
  /* margin-top: -14px; */
`;
export const ClassName = styled.h2`
  font-size: 14px;
  font-weight: 500;
`;
export const ProfessorName = styled.p`
  font-size: 14px;
`;
export const TimeInfo = styled.div`
  margin-bottom: 23px;
`;
export const CyclePrice = styled.p`
  font-size: 12px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
`;

export const Price = styled.span`
  font-size: 14px;
  font-weight: 500;
`;
export const Span1 = styled.span`
  font-size: 0.75rem;
  font-weight: 400;
`;
export const PriceRed = styled.span`
  font-size: 14px;
  color: #bd2323;
  font-weight: 500;
  margin-left: 18px;
`;

export const AdditionalPrice = styled.p`
  font-size: 12px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
`;
export const Discount = styled.p`
  font-size: 12px;
  margin-bottom: 22px;
  margin-top: -22px;
  color: #bd2323;
  display: flex;
  justify-content: space-between;
`;

export const SpanGreen = styled.span``;
export const SpanRed = styled.span``;

export const Total = styled.span`
  color: #14ae5c;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
`;
export const TotalPrice = styled.span`
  color: #14ae5c;
  font-size: 16px;
  font-weight: 500;
  margin-left: 12px;
`;
