import { Link } from "react-router-dom";
import styled from "styled-components";
import {ReactComponent as student} from '../../../images/Btn_Student.svg'
import {ReactComponent as teacher} from '../../../images/Btn_Teacher.svg'
import {ReactComponent as admin} from '../../../images/Btn_Admin.svg'
import {ReactComponent as create} from '../../../images/Btn_Create.svg'
import {ReactComponent as sale} from '../../../images/GroupsSale.svg'


export const Title = styled.h1`
    color: #2C2E2F;
    font-size: 1.2rem;
    font-weight: 500;
`

export const BtnContainer = styled.div`
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const BtnWrapper = styled.div`
    display: flex;
    gap: 2.5rem;
   
`

export const BtnLink = styled(Link)`
    background-color:#F6F8FE;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
    gap: 1rem;
    text-align: center;
    border-radius: 0.5rem;
    width: 10rem;
    color: #006680;
    font-size: 0.875rem;
    font-weight: 500;
`

export const BtnStudent = styled(student)`
    & path {
        stroke: #006680;
    }
`
export const BtnCreate = styled(create)`
    & path {
        stroke: #006680;
    }
`
export const BtnSale = styled(sale)`
    & path {
        stroke: #006680;
    }
`

export const BtnTeacher = styled(teacher)`
     & path {
        stroke: #006680;
    }
`


export const BtnAdmin = styled(admin)`
     & path {
        stroke: #006680;
    }
`


