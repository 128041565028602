import styled from "styled-components";
import { Link } from "react-router-dom";

export const ClassContainer = styled.div`
  width: 100%;
  height: 7.46rem;
  width: 32.8rem;
  background: ${(props) => props.backgroundColor || "#F3F3F3"};
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    width: 60vh;
    height: 8.5rem;
  }
  @media screen and (max-width: 480px) {
    width: 38vh;
    height: 8.5rem;
  }
`;
export const ClassMessage = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => props.color || "#818586"};
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1.06rem 1rem 1rem 1rem;
`;

export const ImgWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const MessageImg = styled.img`
  margin-right: 1.62rem;
`;
export const MessageText = styled.h1`
  font-size: 0.875rem;
  text-align: center;
  max-width: 27.75rem;
  font-weight: 500;
`;

export const SubscribeWrapper = styled.div``;
export const SubscribeBtn = styled(Link)`
  padding: 0.5rem 1rem;
  background: #4aa1b1;
  border-radius: 0.5rem;
  color: #fff;
`;

export const ClassInformation = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1.06rem 1rem 1rem 1rem;
`;
export const Left = styled.div``;
export const Date = styled.p`
  font-size: 0.75rem;
  margin-bottom: 1.06rem;
`;
export const ClassType = styled.h2`
  font-size: 0.875rem;
  font-weight: 500;
`;
export const Professor = styled.p`
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;
export const Place = styled.p`
  font-size: 0.625rem;
  font-weight: 400;
`;
export const Right = styled.div`
  font-size: 0.875rem;
  text-align: center;
`;
export const Time = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 1.87rem;
`;
export const Status = styled.p`
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  padding: 0.12rem 0.5rem;
  background: ${(props) => props.bgColor || "#41A46D"};
  border-radius: 0.75rem;
  color: #fff;
`;
export const Room = styled.p`
  font-size: 0.625rem;
  font-weight: 400;
`;
