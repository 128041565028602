import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  ThemeProvider,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FormControlLabelStyle, radioStyle, theme, textFieldStyle } from "../../Styles/FormStyles";
import { FormContainer } from "../../login/LoginElements";
import { Title } from "../CreateUser/CreateUserElements";
import SelectComponent from "../../../ui/Select";

function StepOne({ formData, onDataChange }) {

    const [musicInst, setMusicInst] = useState([]);
    const [studentName, setStudentName] = useState([]);

    useEffect(() => {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/user/?user_type=STUDENT&limit=1000&status=ACTIVE`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          const formattedStudents = response.data.map((student) => ({
            label: `${student.first_name} ${student.last_name}`,
            value: student.id,
            is_tuition_payed: student.is_tuition_payed,
          }));
          setStudentName(formattedStudents);
        })
        .catch((error) => {
          console.error("Error fetching salon options from API:", error);
        });
    }, []);

    useEffect(() => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/instrument/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          const instruments = response.data.map((instrument) => ({
            label: instrument.name,
            value: instrument.id,
            art_form: instrument.art_form,
            name: instrument.name,
          }));
          setMusicInst(instruments);
        })
        .catch((error) => {
          console.error("Error fetching salon options from API:", error);
        });
    }, []);
  
    const type_class = [
      { label: "Música", value: "MUSICA" },
      { label: "Danza", value: "DANZA" },
      { label: "Pintura y artes plásticas", value: "PINTURA" },
      { label: "Teatro", value: "TEATRO" },
    ];
  
    const handleChange = (fieldName) => (event) => {
      const value = event.target.value;
    
      let instrumentName = "";
      let studentId = 0;
      if (fieldName === "instrument_id") {
        const selectedInstrument = musicInst.find((item) => item.value === value);
        if (selectedInstrument) {
          instrumentName = selectedInstrument.name.toLowerCase();
        }
      }
      if (fieldName === "student_id") {
        studentId = studentName.find((item) => item.value === value).value;
      }
      const updatedFormData = {
        ...formData,
        [fieldName]: value,
        ...(fieldName === "instrument_id" && { instrument_name: instrumentName }),
        ...(fieldName === "student_id" && { student_id: studentId }),
      };
      onDataChange(updatedFormData);
      console.log(`Selected ${fieldName}:`, value);
      };
  return (
    <>
      <FormContainer
        style={{ gap: "0rem", maxHeight: "70vh", overflowY: "auto" }}
      >
        <ThemeProvider theme={theme}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "26rem",
              marginTop: "1rem",
            }}
          >
            <Title style={{ fontSize: "1rem" }}>Estudiante</Title>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={studentName}
              getOptionLabel={(option) => option.label}
              sx={{ width: "26rem" }}
              name="student_id"
              value={formData.student_id ? studentName[formData.student_id] : null}
              onChange={(event, newValue) => {
                const value = newValue ? newValue.value : null;
                handleChange("student_id")({ target: { value } });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={textFieldStyle}
                  label="Nombre del estudiante"
                />
              )}/>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "416px",
              marginTop: "2rem",
            }}
          >
            <Title style={{ fontSize: "1rem" }}>Tipo de clase</Title>
            <SelectComponent
                label="Tipo de clase"
                menuItems={type_class}
                value={formData.class_type}
                onChange={handleChange("class_type")}
                name='class_type'
              />
              <SelectComponent
                label="Clase"
                name="instrument_id"
                menuItems={musicInst.filter(
                  (item) => item.art_form === formData.class_type
                )}
                value={formData.instrument_id}
                onChange={handleChange("instrument_id")}
              />
          </div>
        </ThemeProvider>
      </FormContainer>
    </>
  );
}

export default StepOne;
