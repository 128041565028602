import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import AdminFormButton from "../../../ui/AdminFormButton";
import {
  ArrowBtn,
  ArrowBtnWrapper,
  ArrowImgBack,
  ArrowImgForward,
  Container,
} from "../../MultiStepsForm/MultiStepFormElements";
import { BtnsContainer } from "../CreateUser/StudentForm/StudentFormElements";
import StepOne from "./StepOne";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
import StepFour from "./StepFour";
import CreateUserContainer from "../../../ui/CreateUserContainer";
import { Steps } from "../../MultiStepsForm/StepOne/StepOneElements";
import { Heading } from "../../EditProfile/EditProfileElements";
import { useCreateIndClassContext } from "../../../context/CreateIndividualClass";
import Modal from '../../../ui/Modal'
import ClassCreated from "./ClassCreated";

function CreateClass({ defaultStep }) {
  const { paso } = useParams();
  const currentStep = paso || defaultStep;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { formData, setFormData, handleSubmit, error,success } = useCreateIndClassContext();
  const allData = { ...formData };

  const navigate = useNavigate();

  let stepNumber = 1;
  const isStep4 = currentStep === "paso-4";

  if (currentStep === "paso-1") {
    stepNumber = 1;
  } else if (currentStep === "paso-2") {
    stepNumber = 2;
  } else if (currentStep === "paso-3") {
    stepNumber = 3;
  } else if (currentStep === "paso-4") {
    stepNumber = 4;
  }

  useEffect(() => {
    return () => {
      localStorage.removeItem('selectedCards');
    };
  }, []);

  const isNextDisabled = () => {
    if (currentStep === "paso-1") {
      return (
        formData.student_id === '' ||
        formData.instrunet_id === '' ||
        formData.lesson_type === ''
      );
    } else if(currentStep === "paso-2") {
      return (
        formData.teacher_id === ''
      );
    } else if (currentStep === "paso-3") {
      if (formData.intensity === '2') {
        return (
          formData.classroom_id === '' ||
          formData.lessons.length < 2
        );
      } else {
        return( 
          formData.classroom_id === '' ||
          formData.lessons === ''
        );
      }
    } else if (currentStep === "paso-4") {
      return(
        formData.pricing_model === ''
      );
    }
    return false;
  };

 const isBackDisabled = currentStep === "paso-1";

  const handleNextButtonClick = (e) => {
    e.preventDefault();

    let nextStep;
    if (currentStep === "paso-1") {
      nextStep = "paso-2";
      console.log(formData);
    } else if (currentStep === "paso-2") {
      nextStep = "paso-3";
      console.log(formData);
    } else if (currentStep === "paso-3") {
      nextStep = "paso-4";
    } else {
      handleSubmit(allData)
      .then(() => {
        setIsOpenModal(true);
      })
      .catch((error) => {
        console.error("Submission failed:", error);
      });
      return;
    }
    navigate(`/crear-clase-individual/${nextStep}`);
  };

  const handleBackButtonClick = (e) => {
    e.preventDefault();

    let previousStep;

    if (currentStep === "paso-2") {
      previousStep = "paso-1";
    } else if (currentStep === "paso-3") {
      previousStep = "paso-2";
    } else if (currentStep === "paso-4") {
      previousStep = "paso-3";
    } else {
      navigate("/crear-clase-individual/");
      return;
    }
    navigate(`/crear-clase-individual/${previousStep}`);
  };

  const handleDataChange = (data) => {
    setFormData({ ...formData, ...data });
  };

  return (
    <CreateUserContainer>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Container style={{ padding: "0rem" }}>
          <div>
            <Steps>Paso {stepNumber} de 4</Steps>
            <Heading>Creación de clase individual</Heading>
          </div>
          {currentStep === "paso-1" && (
            <StepOne formData={formData} onDataChange={handleDataChange} />
          )}
          {currentStep === "paso-2" && (
            <StepTwo formData={formData} onDataChange={handleDataChange} />
          )}
          {currentStep === "paso-3" && (
            <StepThree formData={formData} onDataChange={handleDataChange} />
          )}
          {currentStep === "paso-4" && (
            <StepFour formData={formData} onDataChange={handleDataChange} />
          )}
        </Container>
        <BtnsContainer
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ width: "20.4375rem" }}>
            <AdminFormButton onClick={handleNextButtonClick} disabled={isNextDisabled()}>
              {isStep4 ? "Crear clase" : "Siguiente"}
            </AdminFormButton>
          </div>
          <ArrowBtnWrapper>
            <ArrowBtn onClick={handleBackButtonClick} disabled={isBackDisabled}>
              <ArrowImgBack />
            </ArrowBtn>
            <ArrowBtn
              onClick={handleNextButtonClick}
              disabled={isNextDisabled() || currentStep === "paso-4"}
            >
              <ArrowImgForward />
            </ArrowBtn>
          </ArrowBtnWrapper>
        </BtnsContainer>
      </div>
      {isOpenModal && (
        <Modal
        onClose={() => {
          setIsOpenModal(false);
          navigate('/crear-clase-individual')
        }}
      >
        <ClassCreated error={error} success={success}/>
      </Modal>
      )}
    </CreateUserContainer>
  );
}

export default CreateClass;
