import React from 'react'
import Button from "@mui/material/Button";
import { buttonStyle, theme } from '../components/Styles/FormStyles';
import { ThemeProvider } from '@mui/material';

function MainButton({children, onClick, disabled}) {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Button
            disableElevation
            fullWidth
            variant="contained"
            type="submit"
            sx={buttonStyle}
            onClick={onClick}
            disabled={disabled}
          >
            {children}
        </Button> 
      </ThemeProvider>
    </>
  )
}

export default MainButton
