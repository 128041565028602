import { useNavigate } from "react-router-dom";
import {
    BottomWrapper,
    BtnCalend,
    BtnInicio,
    LogOutBtn,
    LogOutImg,
    Name,
    NavBtn,
    NavItem,
    NavMenu,
    NavProfile,
    ProfileImg,
    Span,
    SupportBtn,
    SupportImg,
    Type
  } from "../../SideNavbar/SideNavbarElements";
  import {
    CloseBtn,
    CloseIcon,
    MobileContainer,
    UpperWrapper,
  } from "../../MobileMenu/MobileMenuElements";
import Logo from "../../../ui/Logo";
import support from "../../../images/Circle_Help.svg";
import closeBtn from "../../../images/CloseBtn.svg";
import logout from "../../../images/Log_Out.svg";
import { BtnGestionar} from "../../SideNavbar/SideNavbarElements";
import useUserName from "../../../utils/useUserName";

const MenuProf = ({ isOpen, loggedIn, onLogout, onClose }) => {
    const { name } = useUserName(localStorage.getItem("userId"));
    const navigate = useNavigate();
    const handleLogout = () => {
      onLogout();
      navigate("/");
    };
    
    function handleClose () {
      onClose()
    }

    const handleNavBtnClick = () => {
        handleClose(); 
      }
  
    return (
      <>
        <MobileContainer isOpen={isOpen}>
          <UpperWrapper>
            <Logo style={{ height: "2rem", width: "auto" }} />
            <CloseBtn onClick={handleClose}>
              <CloseIcon src={closeBtn} />
            </CloseBtn>
          </UpperWrapper>
          <div>
            <NavProfile>
              <ProfileImg />
              <div>
                <Name style={{width:'10rem'}}>{`${name.first_name} ${name.last_name}`}</Name>
                <Type>Profesor</Type>
              </div>
            </NavProfile>
            <NavMenu>
            <NavItem>
              <NavBtn to="/home-professor" onClick={handleNavBtnClick}>
                <BtnInicio />
                <Span>Inicio</Span>
              </NavBtn>
            </NavItem>
            <NavItem>
              <NavBtn to="/manage-availibility" onClick={handleNavBtnClick}>
                <BtnGestionar />
                <Span>Gestionar disponibilidad</Span>
              </NavBtn>
            </NavItem>
            <NavItem>
              <NavBtn to="/calendar-prof" onClick={handleNavBtnClick}>
                <BtnCalend />
                <Span>Ver calendario</Span>
              </NavBtn>
            </NavItem>
            </NavMenu>
          </div>
          <BottomWrapper>
            <SupportBtn>
              <SupportImg src={support} />
              ¿Necesitas contactar a soporte? ¡Házlo aquí!
            </SupportBtn>
            {loggedIn && (
              <LogOutBtn onClick={handleLogout}>
                <LogOutImg src={logout} />
                Cerrar sesión
              </LogOutBtn>
            )}
          </BottomWrapper>
        </MobileContainer>
      </>
    );
  };
  
  export default MenuProf;