import React from "react";
import {
  Description,
  Title,
} from "../../MultiStepsForm/individual/StepTwo/StepTwoElements";
import { MainContent } from "../../MultiStepsForm/MultiStepFormElements";
import DaysCarousel from "./DaysCarousel";


function ProfCalendar() {
  return (
    <MainContent >
      <div style={{gap: "1rem", marginBottom: '2.5rem'}}>
        <Title>Calendario</Title>
        <Description>
          Aquí se muestran las clases que tienes disponibles
        </Description>
        <DaysCarousel />
      </div>
    </MainContent>
  );
}

export default ProfCalendar;
