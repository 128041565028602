import React, { useEffect, useState } from "react";
import {
  AdditionalInfo,
  ClassItem,
  ClassList,
  ClassStarts,
  ClassesTitle,
  DateTime,
  Day,
  Duration,
  ItemInfo,
  Time,
} from "../../MultiStepsForm/individual/StepFive/StepFiveElements";
import axios from "axios";
import SelectComponent from "../../../ui/Select";

function StepThree({ formData, onDataChange }) {
  const [classInfo, setClassInfo] = useState([]);
  const [selectedCards, setSelectedCards] = useState([]);
  const [selectedIntensity, setSelectedIntensity] = useState(
    formData.intensity
  );
  const [initialSelectedCards, setInitialSelectedCards] = useState([]);
  const [intensityChosen, setIntensityChosen] = useState(false);

  useEffect(() => {
    const storedSelectedCards = JSON.parse(
      localStorage.getItem("selectedCards")
    );
    if (storedSelectedCards) {
      setSelectedCards(storedSelectedCards);
    }
  }, []);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/lesson/availability?instrument_id=${formData.instrument_id}&lesson_type=${formData.lesson_type}&teacher_id=${formData.teacher_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        setClassInfo(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching class information from API:", error);
      });
  }, [formData.teacher_id, formData.instrument_id, formData.lesson_type]);


  useEffect(() => {
    if (initialSelectedCards.length === 0) {
      const selectedIndices = formData.lessons.map((lesson) =>
        classInfo.findIndex((item) => item.day === lesson.day)
      );
      setInitialSelectedCards(selectedIndices);
    }
  }, [formData.lessons, classInfo, initialSelectedCards]);

  console.log("selectedCards", selectedCards);

  const handleCardClick = (index, classItem) => {
    let updatedSelectedCards = [...selectedCards];

    if (selectedIntensity === "1") {
      updatedSelectedCards = [index];
    } else if (selectedIntensity === "2") {
      if (updatedSelectedCards.includes(index)) {
        updatedSelectedCards = updatedSelectedCards.filter(
          (cardIndex) => cardIndex !== index
        );
      } else {
        if (updatedSelectedCards.length < 2) {
          updatedSelectedCards.push(index);
        } else {
          updatedSelectedCards = [index];
        }
      }
    }

    setSelectedCards(updatedSelectedCards);

    localStorage.setItem("selectedCards", JSON.stringify(updatedSelectedCards));

    const updatedLessons = updatedSelectedCards.map((selectedIndex) => {
      const selectedClassItem = classInfo[selectedIndex];
      const localizedStartDate = getNextDateWithDay(
      selectedClassItem.day);
      const year = localizedStartDate.getFullYear();
      const month = ("0" + (localizedStartDate.getMonth() + 1)).slice(-2);
      const day = ("0" + localizedStartDate.getDate()).slice(-2);
      const formattedStartDate = `${year}-${month}-${day}`;

      return {
        day: selectedClassItem.day,
        start_time: selectedClassItem.start_time_,
        end_time: selectedClassItem.end_time_,
        start_date: formattedStartDate,
      };
    });

    const updatedFormData = {
      ...formData,
      classroom_id: classItem.classroom_id,
      lessons: updatedLessons,
      intensity: selectedIntensity,
      intensityName: selectedIntensity === "1" ? "normal" : "intensivo",
    };

    console.log("Selected Choices:", updatedFormData);
    onDataChange(updatedFormData);
  };

  const handleIntensityChange = (event) => {
    const value = event.target.value;
    setSelectedIntensity(value);
    setSelectedCards([]);

    localStorage.removeItem("selectedCards");
    setIntensityChosen(true);
  };

  function getNextDateWithDay(dayOfWeek) {
    var today = new Date();
    var currentDayOfWeek = today.getDay();
    var days = [
      "DOMINGO",
      "LUNES",
      "MARTES",
      "MIERCOLES",
      "JUEVES",
      "VIERNES",
      "SABADO",
    ];
    var targetDayIndex = days.indexOf(dayOfWeek);
    console.log('targetDayIndex',targetDayIndex)
    var difference = targetDayIndex - currentDayOfWeek;
    if (difference <= 0) {
      difference += 7;
    }
    var nextDate = new Date(today.getTime() + difference * 24 * 60 * 60 * 1000);
    return nextDate;
  }
 

  return (
    <>
      <div style={{ marginTop: "0.5rem" }}>
        <SelectComponent
          label="Intensidad"
          menuItems={[
            { label: "Normal (1 vez por semana)", value: "1" },
            { label: "Intensivo (2 veces por semana)", value: "2" },
          ]}
          value={selectedIntensity}
          onChange={handleIntensityChange}
          name="intensity"
        />
      </div>
      {(intensityChosen || selectedCards.length > 0) && (
        <>
          <ClassesTitle style={{ marginTop: "2rem" }}>
            Elige tu horario
          </ClassesTitle>
          <ClassList
            style={{ maxHeight: "50vh", width: "26rem", paddingTop: "0.2rem" }}
          >
            {classInfo.map((classItem, index) => (
              <ClassItem
                key={index}
                isSelected={selectedCards.includes(index)}
                onClick={() => handleCardClick(index, classItem)}
              >
                <ItemInfo>
                  <Time>
                    {classItem.start_time_.substring(0, 5)}
                    <Duration> (1 hora de duración)</Duration>
                  </Time>
                  <Day>
                    {classItem.day.charAt(0).toUpperCase() +
                      classItem.day.slice(1).toLowerCase()}{" "}
                    de cada semana
                  </Day>
                  <AdditionalInfo>Horarios Fijos</AdditionalInfo>
                  <ClassStarts>
                    Comienzo de clases<br></br>
                    <DateTime>{getNextDateWithDay(classItem.day).toLocaleDateString("es-ES", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                  })}</DateTime>
                  </ClassStarts>
                </ItemInfo>
              </ClassItem>
            ))}
          </ClassList>
        </>
      )}
    </>
  );
}

export default StepThree;
