import React from 'react'
import styled from 'styled-components';

export const MainContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  background: #F6F7FE;

  @media screen and (max-width: 768px) {
    background: #fff;
    height: 90vh;
  }
  @media screen and (max-width: 480px) {
    background: #fff;
    height: 90vh;
  }
`;

export const MainWrapper = styled.div`
  width: 100%;
  padding: 5rem;
  margin: 1.5rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 8px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    height: 100%;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    height: 100%;
  }
`;

function RightPageContainer({children}) {
  return (
    <MainContainer>
      <MainWrapper>
        {children}
      </MainWrapper>
    </MainContainer>
  )
}

export default RightPageContainer
