import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import CreateUserContainer from '../../../ui/CreateUserContainer';
import { ArrowBtn, ArrowBtnWrapper, ArrowImgBack, ArrowImgForward, BtnsContainer, Container } from '../../MultiStepsForm/MultiStepFormElements';
import { Steps } from '../../MultiStepsForm/StepOne/StepOneElements';
import { Heading } from '../../EditProfile/EditProfileElements';
import AdminFormButton from '../../../ui/AdminFormButton';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import { useCreateStGroupContext } from '../../../context/StudentsGroup';
import { Dialog } from '@mui/material';
import ClassCreated from "./ClassCreated";
import CreationConfirmation from './CreationConfirmation';

function SellClass({ defaultStep }) {
  const { paso } = useParams();
  const currentStep = paso || defaultStep;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [studentUpdated, setStudentUpdated] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { formData, setFormData, handleSubmit, error, success } = useCreateStGroupContext();

  const navigate = useNavigate();

  let stepNumber = 1;
  const isStep2 = currentStep === "paso-2";

  if (currentStep === "paso-1") {
    stepNumber = 1;
  } else if (currentStep === "paso-2") {
    stepNumber = 2;
  }

  // useEffect(() => {
  //   return () => {
  //     localStorage.removeItem('selectedCards');
  //   };
  // }, []);


  const handleNextButtonClick = (e) => {
    e.preventDefault();

    let nextStep;
    if (currentStep === "paso-1") {
      nextStep = "paso-2";
      // console.log(formData);
    } else {
      setIsOpenModal(true);
      console.log(isOpenModal);
      return;
    }
    setDisabled(true);
    navigate(`/vender-clase-grupal/${nextStep}`);
  };

  const handleBackButtonClick = (e) => {
    e.preventDefault();

    let previousStep;

    if (currentStep === "paso-2") {
      previousStep = "paso-1";
    } else {
      navigate("/vender-clase-grupal/");
      return;
    }
    navigate(`/vender-clase-grupal/${previousStep}`);
  };

  const handleDataChange = (data) => {
    setFormData({ ...formData, ...data });
    if (data.student_id !== formData.student_id) {
      setStudentUpdated(true);
    }
    if (data.instrument_id && studentUpdated && currentStep === "paso-1") {
      setDisabled(false);
    }
    if (data.volume && data.pricing_model && data.payment_method && currentStep === "paso-2") {
      setDisabled(false);
    }
  };

  const handleCreate = (e) => {
    handleSubmit(formData).then(() => {
      setIsCreated(true);
    })
  };

  const onCancel = () => {
    setIsOpenModal(false);
  };

  useEffect(() => {
    if (formData.instrument_id && studentUpdated) {
      setDisabled(false);
    }
  }, [formData.instrument_id, studentUpdated]);

  return (
    <CreateUserContainer>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Container style={{ padding: "0rem" }}>
          <div>
            <Steps>Paso {stepNumber} de 2</Steps>
            <Heading>Venta de clase grupal</Heading>
          </div>
          {currentStep === "paso-1" && (
            <StepOne formData={formData} onDataChange={handleDataChange} />
          )}
          {currentStep === "paso-2" && (
            <StepTwo formData={formData} onDataChange={handleDataChange} />
          )}
        </Container>
        <BtnsContainer
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ width: "20.4375rem" }}>
            <AdminFormButton disabled={disabled} onClick={handleNextButtonClick}>
              {isStep2 ? "Crear clase" : "Siguiente"}
            </AdminFormButton>
          </div>
          <ArrowBtnWrapper>
            <ArrowBtn onClick={handleBackButtonClick} >
              <ArrowImgBack />
            </ArrowBtn>
            <ArrowBtn
              onClick={handleNextButtonClick}
            >
              <ArrowImgForward />
            </ArrowBtn>
          </ArrowBtnWrapper>
        </BtnsContainer>
      </div>
      <Dialog
        open={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
          if (isCreated) {
            navigate("/crear-grupos");
          }
        }}
      >
        {isCreated ? <ClassCreated /> : <CreationConfirmation onClick={handleCreate} onCancel={onCancel} />}
      </Dialog>
    </CreateUserContainer>
  );
}

export default SellClass;
