import React, { useState } from "react";
import {
  StepTitle,
} from "../../../MultiStepsForm/StepOne/StepOneElements";
import {
  Title,
  Description,
} from "../../../MultiStepsForm/individual/StepTwo/StepTwoElements";
import { ThemeProvider } from "@emotion/react";
import { theme } from "../../../Styles/FormStyles";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FlexibleWrapper } from "./StepTwo";

function Extra({ otherTeachers, classData, handleDataUpdate, newData }) {
  // console.log("otherTeachers", otherTeachers);

  const [selectedValue, setSelectedValue] = useState();

  function getNextDateFromDay(dayOfWeek) {
    var today = new Date();
    var currentDayOfWeek = today.getDay();
    var days = [
      "DOMINGO",
      "LUNES",
      "MARTES",
      "MIERCOLES",
      "JUEVES",
      "VIERNES",
      "SABADO",
    ];
    var targetDayIndex = days.indexOf(dayOfWeek);
    var difference = targetDayIndex - currentDayOfWeek;
    if (difference <= 0) {
      difference += 7;
    }
    var nextDate = new Date(today.getTime() + difference * 24 * 60 * 60 * 1000);
    return nextDate;
  }


  const groupedTimes = otherTeachers.reduce((acc, teacher) => {
    const nextDate = getNextDateFromDay(teacher.day);
    const dateKey = nextDate;
    acc[teacher.teacher_name] = acc[teacher.teacher_name] || {};
    acc[teacher.teacher_name][dateKey] =
      acc[teacher.teacher_name][dateKey] || [];
    acc[teacher.teacher_name][dateKey].push({
      start_time: teacher.start_time_,
      end_time: teacher.end_time_,
      user_id: teacher.user_id,
      classroom_id: teacher.classroom_id,
    });
    return acc;
  }, {});

  const handleTimeSelection = (value, teacherId, classroomId) => {
    const [start_time, end_time] = value.split(" - ");
    console.log("Selected start time:", start_time);
    console.log("Selected end time:", end_time);
    const updatedFormData = {
      ...newData,
      start_time,
      end_time,
      teacher_id: teacherId,
      classroom_id: classroomId,
    };
    handleDataUpdate(updatedFormData);
    console.log(updatedFormData);
    setSelectedValue(value);
  };

  return (
    <>
      <StepTitle>Re-Agendamiento de clase</StepTitle>
      <Title>Horarios disponibles otros profesores</Title>
      <Description style={{ width: "20rem" }}>
        Estos son los horarios disponibles para clases de{" "}
        {classData.instrument_name} con nuestros profesores
      </Description>
      <FlexibleWrapper>
      <ThemeProvider theme={theme}>
        {Object.entries(groupedTimes).map(([teacher, dates], index) => (
          <div key={index} style={{ marginBottom: "1.5rem" }}>
            <h2
              style={{
                fontSize: "0.875rem",
                fontWeight: 500,
                color: "#4FB1C3",
                marginBottom: "0.5rem",
              }}
            >
              {teacher}
            </h2>
            {Object.entries(dates).map(([date, times], idx) => (
              <div key={idx}>
                <p style={{ fontSize: "0.875rem", fontWeight: 500 }}>
                  {new Date(date).toLocaleDateString("es-ES", {
                    weekday: "long",
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </p>
                <FormControl>
                  <RadioGroup name="radio-buttons-group">
                    {times.map((time, timeIdx) => (
                      <FormControlLabel
                        key={timeIdx}
                        control={
                          <Radio
                            checked={
                              selectedValue ===
                              `${new Date(date)
                                .toLocaleDateString("es-ES", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })
                                .split("/")
                                .reverse()
                                .join("-")}T${time.start_time} - ${new Date(
                                date
                              )
                                .toLocaleDateString("es-ES", {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                })
                                .split("/")
                                .reverse()
                                .join("-")}T${time.end_time}`
                            }
                            sx={{
                              color: "#215E69"[600],
                              fontSize: "0.875rem",
                              "&.Mui-checked": { color: "#215E69" },
                            }}
                            onChange={() => {
                              handleTimeSelection(
                                `${new Date(date)
                                  .toLocaleDateString("es-ES", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  })
                                  .split("/")
                                  .reverse()
                                  .join("-")}T${time.start_time} - ${new Date(
                                  date
                                )
                                  .toLocaleDateString("es-ES", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  })
                                  .split("/")
                                  .reverse()
                                  .join("-")}T${time.end_time}`,
                                time.user_id,
                                time.classroom_id,
                                date
                              );
                              setSelectedValue(
                                `${new Date(date)
                                  .toLocaleDateString("es-ES", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  })
                                  .split("/")
                                  .reverse()
                                  .join("-")}T${time.start_time} - ${new Date(
                                  date
                                )
                                  .toLocaleDateString("es-ES", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  })
                                  .split("/")
                                  .reverse()
                                  .join("-")}T${time.end_time}`
                              );
                            }}
                          />
                        }
                        sx={{
                          ".MuiFormControlLabel-label": {
                            fontSize: 14,
                            fontWeight: 500,
                          },
                        }}
                        label={`${time.start_time.substring(0, 5)} a ${time.end_time.substring(0, 5)}`}
                        value={`${new Date(date)
                          .toLocaleDateString("es-ES", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })
                          .split("/")
                          .reverse()
                          .join("-")}T${time.start_time_} - ${new Date(date)
                          .toLocaleDateString("es-ES", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          })
                          .split("/")
                          .reverse()
                          .join("-")}T${time.end_time_}`}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
            ))}
          </div>
        ))}
      </ThemeProvider>
      </FlexibleWrapper>
    </>
  );
}

export default Extra;
