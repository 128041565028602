import React, { useEffect, useState } from "react";
import RescheduleForm from "../../CalendarStudents/OverlayContent/Form/RescheduleForm";
import AdminButtonContainer from "../../../ui/AdminButtonContainer";
import AdminFormButton from "../../../ui/AdminFormButton";
import {
  fetchOtherTeachers,
  fetchTeacherInfo,
} from "../../../utils/rescheduleTools";
import axios from "axios";

function RescheduleClass({ classData, onRefresh, onClose}) {
  const [step, setStep] = useState(0);
  const [teacherInfo, setTeacherInfo] = useState([]);
  const [otherTeachers, setOtherTeachers] = useState([]);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [newData, setNewData] = useState({
    teacher_id: "",
    start_time: "",
    end_time: "",
    classroom_id: "",
  });

console.log(classData.status)

  useEffect(() => {
    const fetchData = async () => {
      const teacherInfoResponse = await fetchTeacherInfo(classData);
      setTeacherInfo(teacherInfoResponse);
    };

    fetchData();
  }, [classData]);

  useEffect(() => {
    const fetchData = async () => {
      const otherTeachersResponse = await fetchOtherTeachers(classData);
      setOtherTeachers(otherTeachersResponse);
    };

    fetchData();
  }, [classData]);

  const handleConfirmReschedule = async () => {
    if (step === 0) {
      if (teacherInfo === null || teacherInfo.length === 0) {
        setStep(1);
      } else {
        await handleRescheduleRequest();
        setStep(2);
      }
    } else if (step === 1) {
      await handleRescheduleRequest();
      setStep(2);
    } else if( step === 2) {
        onClose()
    }
  };

  const handleRescheduleRequest = async () => {
    const token = localStorage.getItem("access_token");
    let apiUrl;
    if (classData.status === "CANCELLED") {
      apiUrl = `${process.env.REACT_APP_API_URL}/lesson/level/${classData.level_id}/reschedule`;
    } else {
      apiUrl = `${process.env.REACT_APP_API_URL}/lesson/${classData.id}/cancel_and_reschedule`;
    }
    try {
      const response = await axios.put(
        apiUrl,
        newData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log(response.data);
      setSuccessMessage("Clase Re-agendada correctamente");
      onRefresh()
    } catch (error) {
      console.error("Error:", error);
      setError(error.response.data.detail);
    }
  };

  const handleDataUpdate = (data) => {
    setNewData({ ...newData, ...data });
  };
  return (
    <div>
        {/* <div style={{ minHeight: "25rem" }}> */}
      <RescheduleForm
        step={step}
        classData={classData}
        otherTeachers={otherTeachers}
        teacherInfo={teacherInfo}
        handleDataUpdate={handleDataUpdate}
        newData={newData}
        error={error}
        handleConfirmReschedule={handleConfirmReschedule}
        successMessage={successMessage}
        admin="Se notificará al estudiante  y el profesor del re-agendamiento"
      />
        {/* </div> */}
      {/* <div style={{display: 'flex', justifyContent: 'center', marginTop: '1rem'}}>
        <AdminButtonContainer>
          <AdminFormButton onClick={handleConfirmReschedule}
           disabled={
            (step === 0 && teacherInfo && teacherInfo.length > 0 && (
              !newData.teacher_id ||
              !newData.start_time ||
              !newData.end_time ||
              !newData.classroom_id
            )) || 
            ((step === 1 || step === 2) && (
              !newData.teacher_id ||
              !newData.start_time ||
              !newData.end_time ||
              !newData.classroom_id
            ))
          }
          >Confirmar</AdminFormButton>
        </AdminButtonContainer>
      </div> */}
    </div>
  );
}

export default RescheduleClass;
