import React from "react";
import confirm from "../../../../images/Confirm.svg";
import AdminButtonContainer from "../../../../ui/AdminButtonContainer";
import AdminFormButton from "../../../../ui/AdminFormButton";
import {
  BtnWrapper,
  Icon,
  InfoTextContainer,
  InfoWrapper,
  Title,
  Wrapper,
} from "../../../Overlay/OverlayElements";
import styled from "styled-components";

const UserInfo = styled.div`
    font-size: 1rem;
    font-weight: 400;
    color: #757575;
`

const Descr = styled.p`
    max-width: 40rem;
`

function UserCreated({formData, userType, successMsg, errorMsg}) {

  const handleConfirm = (e) => {
    e.preventDefault();

    window.location.href = "/crear-usuario"
  };
  let titleText = "Usuario creado correctamente"; 

  if (userType === "ADMIN") {
    titleText = `Administrador ${successMsg}`;
  } else if (userType === "STUDENT") {
    titleText = `Estudiante ${successMsg}`;
  } else if (userType === "TEACHER") {
    titleText =  `Profesor ${successMsg}`;
  }

 
  return (
    <>
      <Wrapper style={{width: 'none', height: 'none'}}>
        <InfoWrapper>
          <InfoTextContainer>
            <Icon src={confirm} />
            <Title>{successMsg && titleText}
            {errorMsg && 'Registro ha fallado'}
            </Title>
            <UserInfo>
              <Descr>
              {successMsg && 
              `Hemos enviado tu clave provisional al correo electrónico ${formData.email} para que puedas iniciar sesión en la plataforma`
              }
              {errorMsg && errorMsg}
              </Descr>
            </UserInfo>
          </InfoTextContainer>
        </InfoWrapper>
        <BtnWrapper>
          <AdminButtonContainer>
            <AdminFormButton onClick={handleConfirm}>Continuar</AdminFormButton>
          </AdminButtonContainer>
        </BtnWrapper>
      </Wrapper>
    </>
  );
}

export default UserCreated;
