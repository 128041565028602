import React from 'react'
import Cancellation from '../Cancellation';
import CancelAnyway from '../CancelAnyway';

function CancelWarning({step,
    error,strikeDisclaimer, timeDifferenceDisclaimer, onClose, onCancel,successMessage, teacherHeading}) {
    const steps = [
      <CancelAnyway onCancel={onCancel} timeDifferenceDisclaimer={timeDifferenceDisclaimer} strikeDisclaimer={strikeDisclaimer} teacherHeading={teacherHeading} onClose={onClose} />,
      <Cancellation
        error={error}
        successMessage={successMessage}
        onClose={onClose}
        teacherHeading={teacherHeading}
        strikeDisclaimer={strikeDisclaimer}
        timeDifferenceDisclaimer={timeDifferenceDisclaimer}
      />,
    ];
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {steps[step]}
      </div>
    );
  }

export default CancelWarning
