import axios from "axios";

export const fetchTeacherInfo = async (classData) => {
  try {
    const token = localStorage.getItem("access_token");
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/lesson/availability?instrument_id=${classData.instrument_id}&lesson_type=${classData.lesson_type}&teacher_id=${classData.teacher_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error.message);
    throw error;
  }
};

export const fetchOtherTeachers = async (classData) => {
  try {
    const token = localStorage.getItem("access_token");
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/lesson/availability?instrument_id=${classData.instrument_id}&lesson_type=${classData.lesson_type}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error.message);
    throw error;
  }
};

export const fetchClassInfo = async (classData) => {
  try {
    const token = localStorage.getItem("access_token");
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/lesson/level?id=${classData.level_ids[0]}&student_id=${classData.students[0].id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error:", error.message);
    throw error;
  }
};