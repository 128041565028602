import React from "react";
import AdminPageContainer from '../../../ui/AdminPageContainer';
// import MainButton from "../../../ui/Button";
import { MainContent } from "../../MultiStepsForm/MultiStepFormElements";
import {
  Description,
  Title,
} from "../../MultiStepsForm/individual/StepTwo/StepTwoElements";
import TimeTable from "./Table";
import styled from "styled-components";

const Wrapper = styled.div`
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 0;
    padding: 0rem 0.5rem;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    margin: 0;
    padding: 0rem 1rem;
  }
`

const TextWrapper = styled.div`
  width: 26rem;
  @media screen and (max-width: 768px) {
   width: 24rem;
   padding-left: 0.5rem;
  }
`


function ManageAvail() {

  return (
    <>
    <AdminPageContainer>
      <MainContent style={{height: '100%'}}>
        <Wrapper>
          <TextWrapper >
          <Title>Gestionar disponibilidad</Title>
          <Description style={{marginBottom: '4rem'}}>
          Elige las horas que estarás disponible para recibir clases para poder crear el grupo con otras personas interesadas.
          </Description>
          </TextWrapper>
          <TimeTable/>
        </Wrapper>
      </MainContent>
    </AdminPageContainer>
    </>
  );
}

export default ManageAvail;
