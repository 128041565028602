import React from 'react'
import checked from '../../../images/Confirm.svg'
import { HeadingWrapper, Logo, MobileLogo} from './RegProfElements'
import Button from '../../../ui/Button'

function Success() {

  const handleButtonClick = () => {
    window.location.href = "/join-us";
  }
  return (
    <div style={{height: '100%', display:'flex', flexDirection: 'column', justifyContent:'space-between'}}>
    <HeadingWrapper>
            <Logo src='./logo_desktop.svg'/>
            <MobileLogo src='./logo_mobile.svg'/>
    </HeadingWrapper>
    <div style={{width: '22.6rem', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
      <img src={checked} alt='' style={{marginBottom: '1.5rem'}}/>
      <h1 style={{fontSize:'1.25rem', fontWeight: '500'}}>Solicitud enviada</h1>
      <p style={{textAlign: 'center', fontSize: '0.75rem'}}>Hemos recibido tu solicitud para ser parte de nuestro equipo. Dentro de poco tendrás respuesta en el correo electrónico suministrado</p>
    </div>
    <Button onClick={handleButtonClick}>Volver al home</Button>
    </div>
  )
}

export default Success
