import React from "react";
import Calendar from "../../components/CalendarStudents/Calendar";
import RightPageContainer from "../../ui/RightPageContainer";

function CalendarPage() {
  return (
    <RightPageContainer>
      <Calendar />
    </RightPageContainer>
  )
}

export default CalendarPage;
