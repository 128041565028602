import React from 'react'
import RecoveryContainer from './RecoveryContainer'
import Recovery from './Recovery'

function RecoverPassword() {
  return (
    <RecoveryContainer>
      <Recovery />
    </RecoveryContainer>
  )
}

export default RecoverPassword
