import React from "react";
import alert from "../../images/Alert.svg";
import {
  ConfirmLink,
  Description,
  Icon,
  InfoTextContainer,
  InfoWrapper,
  Title,
  Wrapper,
} from "./OverlayElements";

const Overlay = ({ paymentUrl }) => {
  return (
    <>
      <Wrapper>
        <InfoWrapper>
          <InfoTextContainer>
            <Icon src={alert} />
            <Title>Re-direccionado</Title>
            <Description>
            Te conectaremos con la pasarela de pago para efectuar tu pago
            </Description>
          </InfoTextContainer>
        </InfoWrapper>
        <ConfirmLink to={paymentUrl}>Continuar</ConfirmLink>
      </Wrapper>
    </>
  );
};

export default Overlay;
