import React from "react";
import {
  Icon,
  InfoTextContainer,
  InfoWrapper,
  Title,
  Wrapper,
} from "../../Overlay/OverlayElements";
import confirm from "../../../images/Confirm.svg";
import errorImg from "../../../images/Exclamation_circle.svg";
import styled from "styled-components";

const UserInfo = styled.div`
  font-size: 1rem;
  font-weight: 400;
  color: #757575;
  text-align: center;
  width: 60vh;
`;

function ClassCreated({ error, success }) {
  return (
    <>
      {success && (
        <div>
          <InfoTextContainer>
            <Icon src={confirm} />
            <Title>{success}</Title>
            <UserInfo>
              El estudiante y profesor podrán visualizar las nuevas clases en su
              plataforma
            </UserInfo>
          </InfoTextContainer>
        </div>
      )}
      {error && (
        <div>
          <InfoTextContainer>
            <Icon src={errorImg}  style={{height: '2rem'}}/>
            <Title>No se pudo crear la clase</Title>
            <UserInfo>{error}</UserInfo>
          </InfoTextContainer>
        </div>
      )}
    </>
  );
}

export default ClassCreated;
