import { ThemeProvider } from "@emotion/react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { TableHeadStyle, themeTable } from "../../Styles/FormStyles";
import { EditBtnWrapper, EditImg } from "../Students/StudentsTablesElements";
import deleteIcon from '../../../images/IconDelete.svg'
import arrowsIcon from '../../../images/IconArrows.svg'

function ClassesTables({ groupLevels, onButtonClick }) {
  const handleButtonClick = (type, propsText, props) => {
    onButtonClick(type, propsText, props);
  };
  const columns = [
    { id: "id", label: "id", align: "left", minWidth: 100 },
    {
      id: "name",
      label: "Estudiante",
      align: "left",

      format: (value, row) => (
        <p key={row.id}>
          {row.first_name} {row.last_name}
        </p>
      ),
    },
    {
      id: "age",
      label: "Edad",
      align: "left",
      minWidth: 100,
    },
    {
      id: "phone_number",
      label: "Teléfono",
      align: "left",
    },
    {
      id: "modal",
      label: "Acciones",
      minWidth: 160,
      align: "center",
      headerAlign: "center",
      format: (value, row, level) => (
        <EditBtnWrapper style={{ gap: '1rem' }}>
          <button
            key={row.id}
            onClick={() => handleButtonClick('DeletePerson', '', { "level_id": level.id })}
          >
            <EditImg src={deleteIcon} />
          </button>
          <button
            key={row.id}
            onClick={() => handleButtonClick('ChangeClass', 'Cambiar clase', { "group_levels": groupLevels, "level_id": level.id })}
          >
            <EditImg src={arrowsIcon} />
          </button>
        </EditBtnWrapper>
      ),
    },
  ];



  return (
    <>
      {groupLevels &&
        groupLevels.map((group_level, index) => {
          return (
            <ThemeProvider theme={themeTable} key={group_level.id}>
              <Paper sx={{ width: "100%", borderRadius: "0.5rem 0.5rem 0rem 0rem" }}>
                <TableContainer
                  sx={{ maxHeight: 320, borderRadius: "0.5rem 0.5rem 0rem 0rem", marginTop: "2rem" }}
                >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead sx={TableHeadStyle}>
                      <TableRow>
                        <TableCell align="left" colSpan={6}>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <p style={{ textAlign: "left" }}>id: {group_level.id}</p>
                            <p style={{ textAlign: "center" }}>Profesor(a): {group_level.teacher.name}</p>
                            <p style={{ textAlign: "right" }}>{group_level.day} a las {group_level.start_time_}</p>
                          </div>
                          <div style={{ display: "flex", alignItems: "flex-start", "fontWeight": 400 }}>
                            <p>{group_level.levels.length} estudiante{group_level.levels.length > 1 ? "s" : ""}</p>
                          </div>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align="center"
                            style={{
                              top: 57,
                              minWidth: column.minWidth,
                              textAlign: column.headerAlign || column.align || "left",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {group_level.levels.map((level) => {
                        return (<TableRow>
                          {columns &&
                            columns.map((column) => {
                              const value = level.student[column.id];
                              return (
                                <TableCell key={value} align={column.align}>
                                  {column.format
                                    ? column.format(value, level.student, level)
                                    : value}
                                </TableCell>
                              );
                            })}
                        </TableRow>)
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </ThemeProvider>
          );
        })}
    </>
  );
}

export default ClassesTables;
