import React, { useEffect, useState } from "react";
import close from "../../../images/CloseBtn.svg";
import AdminButtonContainer from "../../../ui/AdminButtonContainer";
import AdminFormButton from "../../../ui/AdminFormButton";
import { BtnWrapper } from "../../Overlay/OverlayElements";
import {
  BtnImg,
  CloseBtn,
  Heading,
  MainWrapper,
  UpperWrapper,
  Wrapper,
} from "../../admin/Students/OverlayElements";
import Cancellation from "./Cancellation";
import ConfirmCancel from "./ConfirmCancel";
import RescheduleForm from "./Form/RescheduleForm";
import ClassStudent from "../ClassStudent";
import { CancelBtn } from "../../admin/CreateUser/StudentForm/StudentFormElements";
import axios from "axios";
import errorImg from "../../../images/Exclamation_circle.svg";
import {
  fetchClassInfo,
  fetchOtherTeachers,
  fetchTeacherInfo,
} from "../../../utils/rescheduleTools";
import CancelClass from "./CancelClass";
import CancelAnyway from "./CancelAnyway";
import CancelWarning from "./Form/CancelWarning";
import Spinner from '../../../ui/Spinner'

const Overlay = ({ classData, onClose }) => {
  const [step, setStep] = useState(0);
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const [showCancelWithWarrning, setShowCancelWithWarrning] = useState(false);
  const [levelInfo, setLevelInfo] = useState({});
  const [showReschedule, setShowReschedule] = useState(false);
  const [teacherInfo, setTeacherInfo] = useState([]);
  const [otherTeachers, setOtherTeachers] = useState([]);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [newData, setNewData] = useState({
    teacher_id: "",
    start_time: "",
    end_time: "",
    classroom_id: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchClassInfo(classData);
      setLevelInfo(response);
    };

    fetchData();
  }, [classData]);
 
  console.log("levelInfo", levelInfo);

  useEffect(() => {
    const fetchData = async () => {
      const teacherInfoResponse = await fetchTeacherInfo(classData);
      setTeacherInfo(teacherInfoResponse);
    };

    fetchData();
  }, [classData]);

  useEffect(() => {
    const fetchData = async () => {
      const otherTeachersResponse = await fetchOtherTeachers(classData);
      setOtherTeachers(otherTeachersResponse);
    };

    fetchData();
  }, [classData]);


  function toReschedule() {
    setShowConfirmCancel(false);
    setShowReschedule(true);
  }

  const handleCancel = () => {
    if (strikeDisclaimer || timeDifferenceDisclaimer) {
      setShowCancelWithWarrning(true);
    } else {
      setShowConfirmCancel(true);
    }
  };

  const handleReschedule = () => {
    setShowReschedule(true);
  };

  const handleConfirmCancellation = async () => {
    const token = localStorage.getItem("access_token");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/lesson/${classData.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log(response.data);
      setSuccessMessage("Clase Cancelada correctamente");
    } catch (error) {
      console.error("Error:", error);
      setError(error.response.data.detail);
    }
  };

  const handleCancellation = () => {
    if (step === 0) {
      handleConfirmCancellation();
      setStep(1);
    }
  };

  const handleConfirmReschedule = () => {
    if (step === 0) {
      if (teacherInfo === null || teacherInfo.length === 0) {
        setStep(1);
      } else {
        handleRescheduleRequest();
        setStep(2);
      }
    } else if (step === 1) {
      handleRescheduleRequest();
      setStep(2);
    } else if (step === 2) {
      window.location.href = "/calendario";
    }
  };

  const handleRescheduleRequest = async () => {
    const token = localStorage.getItem("access_token");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/lesson/${classData.id}/cancel_and_reschedule`,
        newData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log(response.data);
      setSuccessMessage("Clase Re-agendada correctamente");
    } catch (error) {
      console.error("Error:", error);
      setError(error.response.data.detail);
    }
  };

  const handleDataUpdate = (data) => {
    setNewData({ ...newData, ...data });
  };

  let strikeDisclaimer = null;

  if (levelInfo[0]?.student_last_strike && levelInfo[0]?.student_strikes >= 2) {
    const currentDate = new Date();
    const lastStrikeDate = new Date(levelInfo[0]?.student_last_strike);
    const timeDiff = currentDate.getTime() - lastStrikeDate.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

    console.log("Days difference:", daysDiff);
    console.log("Number of strikes:", levelInfo[0]?.student_strikes);

    if (levelInfo[0]?.student_strikes >= 2) {
      strikeDisclaimer = (
        <>
          <p
            style={{
              background: "#F9EBEB",
              padding: "0.5rem 1rem",
              borderRadius: " 0.5rem",
              color: "#C23934",
              marginBottom: "1.5rem",
              display: "flex",
              alignItems: "center",
              fontSize: "0.875rem",
            }}
          >
            <img src={errorImg} alt="" style={{ marginRight: "0.5rem" }} />
            Tienes más de 2 strikes en menos de 28 días. Si cancelas esta clase
            no podrás reponerla.
          </p>
        </>
      );
    }
  }

  let timeDifferenceDisclaimer = null;

  if (classData.start_time) {
    const classStartTime = new Date(classData.start_time);
    const currentDate = new Date();
    const timeDiffInMillis = classStartTime.getTime() - currentDate.getTime();
    const timeDiffInHours = timeDiffInMillis / (1000 * 60 * 60);

    console.log("Time difference in hours:", timeDiffInHours);

    if (timeDiffInHours <= 4.5) {
      timeDifferenceDisclaimer = (
        <>
          <p
            style={{
              background: "#F9EBEB",
              padding: "0.5rem 1rem",
              borderRadius: " 0.5rem",
              color: "#C23934",
              marginBottom: "1.5rem",
              display: "flex",
              alignItems: "center",
              fontSize: "0.875rem",
            }}
          >
            <img src={errorImg} alt="" style={{ marginRight: "0.5rem" }} />
            Cancelación de clase fuera de tiempo reglamentario. Si cancelas esta
            clase no podrás reponerla.
          </p>
        </>
      );
    }
  }

  console.log("classData", classData);
  return (
    <>
      <Wrapper>
        <MainWrapper>
          {showCancelWithWarrning && (
            <CancelWarning
              onClose={onClose}
              strikeDisclaimer="Si cancelas esta clase la perderás, el motivo es que tienes más de 2 strikes en tu ciclo actual."
              timeDifferenceDisclaimer="Si cancelas esta clase la perderás, el motivo es que te encuentras por fuera del tiempo reglamentario "
              step={step}
              error={error}
              successMessage={successMessage}
              onCancel={handleCancellation}
            />
          )}
          {showConfirmCancel && (
            <CancelClass
              step={step}
              error={error}
              successMessage={successMessage}
              toReschedule={toReschedule}
              onCancel={handleCancellation}
              onClose={onClose}
            />
          )}
          {showReschedule && (
            <RescheduleForm
              step={step}
              classData={classData}
              teacherInfo={teacherInfo}
              otherTeachers={otherTeachers}
              handleDataUpdate={handleDataUpdate}
              newData={newData}
              error={error}
              successMessage={successMessage}
              handleConfirmReschedule={handleConfirmReschedule}
            />
          )}
          {!showConfirmCancel && !showReschedule && !showCancelWithWarrning && (
            <div style={{ minHeight: "25rem" }}>
              {strikeDisclaimer}
              {timeDifferenceDisclaimer}
              <ClassStudent {...classData} />
            </div>
          )}
        </MainWrapper>
        <BtnWrapper style={{display: 'flex', justifyContent: 'center',gap: '1rem', padding: 0}}>
          {!showConfirmCancel && !showReschedule && !showCancelWithWarrning && (
            <>
              <AdminButtonContainer>
                <AdminFormButton
                  onClick={handleReschedule}
                  disabled={strikeDisclaimer || timeDifferenceDisclaimer}
                >
                  Re-programar clase
                </AdminFormButton>
              </AdminButtonContainer>
              <CancelBtn onClick={handleCancel}>Cancelar clase</CancelBtn>
            </>
          )}
        </BtnWrapper>
      </Wrapper>
    </>
  );
};

export default Overlay;
