import React from "react";
import info from "../../images/Info.svg";
import {
  BtnWrapper,
  ConfirmBtn,
  Description,
  Icon,
  InfoTextContainer,
  InfoWrapper,
  Title,
} from "../Overlay/OverlayElements";

function ModuleImportant({ handleContinue }) {
  return (
    <>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <InfoWrapper>
          <InfoTextContainer>
            <Icon src={info} />
            <Title>Importante</Title>
            <Description style={{width: '85vh'}}>
              Para las clases grupales debes seleccionar tu disponibilidad para
              agruparte con otros estudiantes y crear tu grupo. Serás notificado
              en cuenta tengas grupo asignado
            </Description>
          </InfoTextContainer>
        </InfoWrapper>
        <BtnWrapper>
          <ConfirmBtn onClick={handleContinue}>Continuar</ConfirmBtn>
        </BtnWrapper>
      </div>
    </>
  );
}

export default ModuleImportant;
