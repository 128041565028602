import styled from "styled-components";

export const ClassWrapper = styled.div`
  background-color: ${(props) => {
    if (props.status === 'ACTIVE') return "#E8F6EE";
    if (props.status === "COMPLETED") return "#F3F3F3";
    if(props.status === "PENDING") return '#FDF2DF';
    if(props.status === "CANCELLED") return '#FDEDEF';
  }};
  height: 7.46rem;
  width: 32.8rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 28rem;
    height: 8.5rem;
  }
  @media screen and (max-width: 480px) {
    width: 21.4rem;
    height: 8.5rem;
  }
`;

export const ClassInformation = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1.06rem 1rem 1rem 1rem;
`;

export const Left = styled.div`
  /* Add styles for Left */
`;

export const DateDay = styled.p`
  font-size: 0.75rem;
  margin-bottom: 1.06rem;
`;

export const ClassType = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
`;

export const Professor = styled.p`
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;

export const Place = styled.p`
  font-size: 0.625rem;
  font-weight: 400;
`;

export const Right = styled.div`
  font-size: 0.875rem;
  text-align: center;
`;

export const Time = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 1.87rem;
`;

export const Status = styled.p`
  background-color: ${(props) =>
    props.status === "PENDING" ? '#EF8F00' : props.status === "ACTIVE"
      ? "#41A46D"
      : props.status === "COMPLETED"
      ? "#ADB8BA"
      : props.status === "CANCELLED" ? '#EA5A47' : 'none'};
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  padding: 0.12rem 0.5rem;
  border-radius: 0.75rem;
  color: #fff;
`;

export const Room = styled.p`
  font-size: 0.625rem;
  font-weight: 400;
`;