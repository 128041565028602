import React from 'react'
import styled from 'styled-components'
import NavbarAdmin from '../components/admin/NavbarAdmin/NavbarAdmin'
import { Outlet } from 'react-router-dom'

const StyledAdminLayout = styled.div`
    display: grid;
    grid-template-columns: 19rem 1fr;
`

function AdminLayout({ loggedIn, onLogout }) {
  return (
    <StyledAdminLayout>
      <NavbarAdmin loggedIn={loggedIn} onLogout={onLogout} />
      <Outlet />
    </StyledAdminLayout>
  )
}

export default AdminLayout
