import React from "react";
import { Link, useNavigate } from "react-router-dom";
import support from "../../images/Circle_Help.svg";
import closeBtn from "../../images/CloseBtn.svg";
import logout from "../../images/Log_Out.svg";
import Logo from "../../ui/Logo";
import {
  ActiveClass,
  BottomWrapper,
  BtnCalend,
  BtnComprar,
  BtnInicio,
  Groups,
  LogOutBtn,
  LogOutImg,
  Name,
  NavBtn,
  NavItem,
  NavMenu,
  NavProfile,
  ProfileImg,
  Span,
  SupportBtn,
  SupportImg,
  Type
} from "../SideNavbar/SideNavbarElements";
import {
  CloseBtn,
  CloseIcon,
  MobileContainer,
  UpperWrapper,
} from "./MobileMenuElements";
import useUserName from "../../utils/useUserName";
import { BtnDisabled } from "../SideNavbar/SideNavbar";
import styled from "styled-components";
import edit from '../../images/EditProfile.svg'

const NavLink = styled(Link)`

`

const MobileMenu = ({ isOpen, loggedIn, onLogout, onClose }) => {
  const { name } = useUserName(localStorage.getItem("userId"));
  const navigate = useNavigate();
  const handleLogout = () => {
    onLogout();
    navigate("/");
  };
  
  function handleClose () {
    onClose()
  }

  const handleNavBtnClick = () => {
    handleClose(); 
  }

  return (
    <>
      <MobileContainer isOpen={isOpen}>
        <UpperWrapper>
          <Logo style={{height:'2rem',with:'auto'}}/>
          <CloseBtn onClick={handleClose}>
            <CloseIcon src={closeBtn} />
          </CloseBtn>
        </UpperWrapper>
        <div>
          <NavProfile>
            <ProfileImg />
            <NavLink to='/edit-profile' onClick={handleNavBtnClick}>
              <Name style={{width:'10rem'}}>{`${name.first_name} ${name.last_name}`}</Name>
              <Type>Estudiante <img src={edit} alt=""/></Type>
            </NavLink>
          </NavProfile>
          <NavMenu>
            <NavItem>
              <NavBtn to="/inicio" onClick={handleNavBtnClick} >
                <BtnInicio />
                <Span>Inicio</Span>
              </NavBtn>
            </NavItem>
            <NavItem>
              <NavBtn to="/calendario" onClick={handleNavBtnClick} >
                <BtnCalend />
                <Span>Ver calendario</Span>
              </NavBtn>
            </NavItem>
            <NavItem>
              <NavBtn to="/comprar-curso-individual" onClick={handleNavBtnClick} >
                <BtnComprar />
                <Span>Comprar curso individual</Span>
              </NavBtn>
            </NavItem>
            {/* <NavItem>
              <BtnDisabled disabled>
              <BtnComprar />
                <Span>Comprar curso individual</Span>
              </BtnDisabled>
            </NavItem> */}
            {/* <NavItem>
              <BtnDisabled disabled>
              <ActiveClass />
                <Span>Renovar clases</Span>
              </BtnDisabled>
            </NavItem> */}
            <NavItem>
              <NavBtn to="/renovar-clases" onClick={handleNavBtnClick} >
                <BtnComprar />
                <Span>Renovar clases</Span>
              </NavBtn>
            </NavItem>
          
            <NavItem>
              <BtnDisabled disabled>
              <Groups />
                <Span>Solicitar clase grupal</Span>
              </BtnDisabled>
            </NavItem>
            {/* <NavItem>
              <NavBtn to="/solicitar-clase-grupal">
                <Groups />
                <Span>Solicitar clase grupal</Span>
              </NavBtn>
            </NavItem> */}
            </NavMenu>
        </div>
        <BottomWrapper>
          <SupportBtn>
            <SupportImg src={support} />
            ¿Necesitas contactar a soporte? ¡Házlo aquí!
          </SupportBtn>
          {loggedIn && (
            <LogOutBtn onClick={handleLogout}>
              <LogOutImg src={logout} />
              Cerrar sesión
            </LogOutBtn>
          )}
        </BottomWrapper>
      </MobileContainer>
    </>
  );
};

export default MobileMenu;
