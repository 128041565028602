import React from 'react'
import {ClassContainer,ClassInformation,Left,Date,ClassType,Professor, Place, Right, Time, Status, Room } from './CardsElements'

const ActiveCard = () => {
  return (
    <>
      <ClassContainer backgroundColor='#E8F6EE'>
            <ClassInformation>
              <Left>
                <Date>Lunes <strong>15</strong> de  Enero 2024</Date>
                <ClassType>Clase de Piano</ClassType>
                <Professor>Profesor Luis Hernandez</Professor>
                <Place>Presencial en sede</Place>
              </Left>
              <Right>
                <Time>7:00pm</Time>
                <Status>Activa</Status>
                <Room>Salón 203</Room>
              </Right>
            </ClassInformation>
        </ClassContainer>
    </>
  )
}

export default ActiveCard
