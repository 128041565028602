import { ThemeProvider } from "@mui/material";
import React from "react";
import SelectComponent from "../../../../ui/Select";
import TextInputField from "../../../../ui/TextInputField";
import { Heading } from "../../../EditProfile/EditProfileElements";
import { theme } from "../../../Styles/FormStyles";
import { FormContainer } from "../../Students/OverlayElements";

export const banks = [
    { label: "BBVA BANK", value: "BBVA BANK" },
    { label: "BANCAMIA", value: "BANCAMIA" },
    { label: "AGRICULTURAL BANK", value: "AGRICULTURAL BANK" },
    { label: "AV VILLAS BANK", value: "AV VILLAS BANK" },
    { label: "CAJA SOCIAL BANK", value: "CAJA SOCIAL BANK" },
    { label: "CITIBANK", value: "CITIBANK" },
    { label: "COOPCENTRAL COOPERATIVE BANK", value: "COOPCENTRAL COOPERATIVE BANK" },
    { label: "BANK CREDIFINANCIERA SA.", value: "BANK CREDIFINANCIERA SA." },
    { label: "DAVIPLATA", value: "DAVIPLATA" },
    { label: "BANCO DE BOGOTÁ", value: "BANCO DE BOGOTÁ" },
    { label: "WESTERN BANK", value: "WESTERN BANK" },
    { label: "FALABELLA BANK", value: "FALABELLA BANK" },
    { label: "FINANDINA BANK", value: "FINANDINA BANK" },
    { label: "GNB SUDAMERIS BANK", value: "GNB SUDAMERIS BANK" },
    { label: "BANCO ITAÚ", value: "BANCO ITAÚ" },
    { label: "BANCO ITAÚ*", value: "BANCO ITAÚ*" },
    { label: "BANK J.P. MORGAN COLOMBIA S.A.", value: "BANK J.P. MORGAN COLOMBIA S.A." },
    { label: "BANCO MUNDO MUJER S.A.", value: "BANCO MUNDO MUJER S.A." },
    { label: "PICHINCHA BANK", value: "PICHINCHA BANK" },
    { label: "PEOPLE'S BANK", value: "PEOPLE'S BANK" },
    { label: "PROCREDIT BANK", value: "PROCREDIT BANK" },
    { label: "SANTANDER BANK", value: "SANTANDER BANK" },
    { label: "BANCO SERFINANZA S.A.", value: "BANCO SERFINANZA S.A." },
    { label: "TEQUENDAMA BANK", value: "TEQUENDAMA BANK" },
    { label: "BANCO SERFINANZA S.A.", value: "BANCO SERFINANZA S.A." },
    { label: "WWB BANK", value: "WWB BANK" },
    { label: "BANCOLDEX", value: "BANCOLDEX" },
    { label: "BANCOLOMBIA", value: "BANCOLOMBIA" },
    { label: "BANCOMPART", value: "BANCOMPART" },
    { label: "BANCOOMEVA", value: "BANCOOMEVA" },
    { label: "COLTEFINANCIERA S.A.", value: "COLTEFINANCIERA S.A." },
    { label: "TRUST FINANCIAL COOPERATIVE", value: "TRUST FINANCIAL COOPERATIVE" },
    { label: "COOFIANTIOQUIA", value: "COOFIANTIOQUIA" },
    { label: "COOFINEP FINANCIAL COOPERATIVE", value: "COOFINEP FINANCIAL COOPERATIVE" },
    { label: "JURISCOOP FINANCE", value: "JURISCOOP FINANCE" },
    { label: "TURNS AND FINANCES CF", value: "TURNS AND FINANCES CF" },
    { label: "IRIS", value: "IRIS" },
    { label: "LULO BANK S.A.", value: "LULO BANK S.A." },
    { label: "MOVii", value: "MOVii" },
    { label: "SCOTIABANK COLPATRIA", value: "SCOTIABANK COLPATRIA" },
    { label: "SERVIFINANSA S.A.", value: "SERVIFINANSA S.A." },
    { label: "RAPPIPAY", value: "RAPPIPAY" },
    { label: "NEQUI", value: "NEQUI" },
  ].sort((a, b) => {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();
  
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });;
  
function StepThree({ formData, onDataChange, error }) {
  const handleChange = (e, fieldName) => {
    let updatedFormData = { ...formData };
    if (e.target) {
      updatedFormData[fieldName] = e.target.value;
    } else {
      updatedFormData[fieldName] = e;
    }

    onDataChange(updatedFormData);
    console.log(updatedFormData);
  };


  return (
    <div>
      <Heading>Datos para pagos</Heading>
      <FormContainer style={{ gap: "0rem", maxHeight: "600px" }}>
        <ThemeProvider theme={theme}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              marginBottom: "1.5rem",
            }}
          >
            <SelectComponent
              label="Entidad bancaria"
              // menuItems={[{ label: "Bancolombia", value: "bank" }]}
              menuItems={banks}
              onChange={(e) => handleChange(e, "bank")}
              value={formData.bank}
              name="bank"
              error={error.bank !== undefined}
              helperText={error.bank}
            />
            <SelectComponent
              label="Tipo de cuenta"
              menuItems={[
                {
                  label: "Ahorros",
                  value: "Ahorros",
                },
                {
                  label: "Corriente",
                  value: "Corriente",
                },
              ]}
              onChange={(e) => handleChange(e, "account_type")}
              value={formData.account_type}
              name="account_type"
              error={error.account_type !== undefined}
              helperText={error.account_type}
            />
            <TextInputField
              label="Número de cuenta"
              onChange={(e) => handleChange(e, "bank_account")}
              value={formData.bank_account}
              name="bank_account"
              error={error.bank_account !== undefined}
              helperText={error.bank_account}
            />
          </div>
        </ThemeProvider>
      </FormContainer>
    </div>
  );
}

export default StepThree;
