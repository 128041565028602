import React from 'react'
import styled from 'styled-components';
import { MainContainer } from './PageContainer';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: #fff;
  border-radius: 8px;
  margin: 1.5rem 2rem;
  padding: 2.5rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 0;
    padding: 0px;
    padding-top: 16px;
    /* width: 30vh; */
    /* min-height: 678px; */
    height: 100%;
  }
`;

function AdminPageContainer({children}) {
  return (
    <MainContainer>
      <Wrapper>
        {children}
      </Wrapper>
    </MainContainer>
  )
}

export default AdminPageContainer
