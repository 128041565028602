import React from 'react'
import { Title } from '../../Overlay/OverlayElements'
import { AdditionalInfo, ClassItem, ClassStarts, DateTime, Day, Duration, ItemInfo, Time } from '../../MultiStepsForm/individual/StepFive/StepFiveElements'
import { ProfessorName } from '../../MultiStepsForm/individual/StepSix/StepSixElements'
import AdminButtonContainer from '../../../ui/AdminButtonContainer'
import AdminFormButton from '../../../ui/AdminFormButton'
import { Dialog } from '@mui/material'
import ClassCreated from './ClassCreated'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

function ChangeClass({ groupLevels, levelId }) {
  const [selectedCard, setSelectedCard] = React.useState(null);
  const [groupLevel, setGroupLevel] = React.useState(null);
  const [disabled, setDisabled] = React.useState(true);
  const [created, setCreated] = React.useState(false);
  const navigate = useNavigate();

  const handleCardClick = (index, groupLevel) => {
    setSelectedCard(index);
    setGroupLevel(groupLevel);
    setDisabled(false);
  }

  const onClick = () => {
    setDisabled(true);
    axios.put(
      `${process.env.REACT_APP_API_URL}/lesson/level/${levelId}/change_group/${groupLevel.id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    ).then((response) => {
      console.log("response", response);
      setCreated(true);
    }).catch((error) => {
      console.error("Error:", error);
    })
  };

  return (
    <>
      <div>
        <Title style={{ marginBottom: "1rem" }}>Cursos disponibles</Title>
      </div>
      <div style={{ maxHeight: "50vh", width: "26rem", paddingTop: "0.2rem" }}>
        {groupLevels.map((groupLevel, index) => {
          if (!groupLevel.levels.some(level => level.id === levelId)) {
            return (
              <ClassItem
                key={index}
                isSelected={selectedCard === index}
                onClick={() => handleCardClick(index, groupLevel)}
                style={{ borderColor: "#C7C9CA" }}
              >
                <ItemInfo>
                  <p
                    style={{
                      width: '20vh',
                      textAlign: 'center',
                      background: "#E8F6EE",
                      color: "#41A46D",
                      fontSize: "0.75rem",
                      borderRadius: " 0.75rem",
                      padding: "0.12rem 0.5rem",
                      fontWeight: "500",
                      marginBottom: '0.5rem'
                    }}
                  >
                    {groupLevel.levels.length} estudiante{groupLevel.levels.length != 1 ? "s" : ""} activo{groupLevel.levels.length != 1 ? "s" : ""}
                  </p>
                  <ProfessorName style={{ fontWeight: 500 }}>
                    Profesor(a) {groupLevel.teacher?.name}
                  </ProfessorName>
                  <Time>
                    <Duration> (1 hora de duración)</Duration>
                  </Time>
                  <Day>
                    {groupLevel.day} de cada semana a las {groupLevel.start_time_}
                  </Day>
                  <AdditionalInfo>Horarios Fijos</AdditionalInfo>
                  {/* <ClassStarts>
                  Comienzo de clases<br></br>
                  <DateTime>
                    {getNextDateWithDay(groupLevel.day).toLocaleDateString("es-ES", {
                    day: "2-digit",
                    month: "long",
                    year: "numeric",
                  })}
                  </DateTime>
                </ClassStarts> */}
                </ItemInfo>
              </ClassItem>);
          }
        })}
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem' }}>
        <AdminButtonContainer>
          <AdminFormButton onClick={onClick} disabled={disabled}>Cambiar</AdminFormButton>
        </AdminButtonContainer>
      </div>
      <Dialog
        open={created}
        onClose={() => {
          setCreated(false);
          navigate("/salones");
        }}
      >
        <ClassCreated title={"Estudiante agregado al curso"}
          info={"Se ha agregado el estudiante a la clase grupal de manera satisfactoria y ahora podrás visualizarlo en el calendario."} />
      </Dialog>
    </>
  )
}

export default ChangeClass
