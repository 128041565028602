import { Link } from "react-router-dom";
import  styled  from "styled-components";

export const OverlayContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
`;

export const Wrapper = styled.div`
    width: 64.3rem;
    height: 35.8rem;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    position: relative;

    @media  screen and (max-width: 768px) {
        width: 60vh;
        height: 70vh;
    }
    @media  screen and (max-width: 480px) {
        width: 30vh;
        height: 35vh;
        /* padding: 1.5rem 0rem; */
    }
`;

export const BtnWrapper = styled.div`
   padding: 1.5rem 1rem;
`
export const BtnWrapperCancel = styled.div`
   display: flex;
   justify-content: center;
   gap: 1rem;
   @media  screen and (max-width: 768px) {
      flex-direction: column;
    }
    @media  screen and (max-width: 480px) {
        flex-direction: column;
        /* padding: 1.5rem 0rem; */
    }
`

export const CloseBtn = styled.img`
    position: absolute;
    top: 24px;
    right: 32px;
    cursor: pointer;
`

export const InfoWrapper = styled.div`
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

`;

export const InfoTextContainer = styled.div`
  padding: 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 27rem;

  @media  screen and (max-width: 480px) {
        height: 13rem;
        padding: 2.5rem  1rem;
    }

`

export const Icon = styled.img`
    margin-bottom: 1.5rem;
`;

export const Title = styled.h1`
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 500;
`;

export const Description = styled.p`
    font-size: 1rem;
    color: #757575;
`;

export const ConfirmBtn = styled.button`
    font-size: 14px;
    font-weight: 500;
    width: 20.4rem;
    padding: 16px 0px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    background: #4AA1B1;
    color: #fff;

    @media  screen and (max-width: 480px) {
        width: 18.4rem;
        /* margin-top: 2.5rem; */
    }

`;

export const ConfirmLink = styled(Link)`
     font-size: 14px;
    font-weight: 500;
    width: 20.4rem;
    padding: 16px 0px;
    border-radius: 8px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #4AA1B1;
    color: #fff;

    @media  screen and (max-width: 480px) {
        width: 18.4rem;
    }
`
// export const RedirectionLink = styled(Link)`
//     text-decoration: none;
//     color: #fff;
// `;