import React, { useState } from "react";
import { ThemeProvider } from "@emotion/react";
import { searchInput, theme } from "../../Styles/FormStyles";
import TextField from "@mui/material/TextField";
import { Title, Content, SearchWrapper, TableWrapper } from "./StudentsTablesElements";
import AdminPageContainer from "../../../ui/AdminPageContainer";
import TableMui from "./TableMui";

function StudentsTable() {
  return (
      <AdminPageContainer>
        <Content>
          <div>
            <Title style={{marginBottom: '0.5rem'}}>Estudiantes</Title>
            {/* <ThemeProvider theme={theme}>
              <TextField
                sx={searchInput}
                type="text"
                label="Buscador"
                placeholder="Ingresa el nombre del estudiante"
                value={searchInputs}
                onChange={handleSearchInputChange}
              />
            </ThemeProvider> */}
          </div>
          <TableWrapper style={{height: '80vh'}}>
            <TableMui/>
          </TableWrapper>
        </Content>
      </AdminPageContainer>
  );
}

export default StudentsTable;
