import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { Outlet } from "react-router-dom";

const BuyIndividualContext = createContext();

export const useBuyIndClassContext = () => {
  return useContext(BuyIndividualContext);
};

export const BuyIndividualProvider = () => {
  const [formData, setFormData] = useState({
    teacher_id: "",
    instrument_id: "",
    classroom_id: "",
    student_ids: localStorage.getItem("userId") ? [parseInt(localStorage.getItem("userId"))] : [0],
    pricing_model: "",
    level: 0,
    lessons: [
      {
        start_time: "",
        end_time: "",
        start_date: "",
      },
    ],
    lesson_type: "",
    class_type: "",
    lessons_per_week: "",
    volume: "",

  });


  const [paymentUrl, setPaymentUrl] = useState("");

  const handleSubmit = async (data) => {
    const token = localStorage.getItem("access_token");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/lesson/level`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("Form data submitted:", response.data);

      const responseData = response.data[0];
      const { id } = responseData;

      const response2 = await axios.get(
        `${process.env.REACT_APP_API_URL}/lesson/level/payment_url/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const { url } = response2.data; 
      setPaymentUrl(url);
    } catch (error) {
      console.error("Error submitting form data:", error);
    }

  };

  return (
    <BuyIndividualContext.Provider
      value={{ formData, setFormData, handleSubmit, paymentUrl }}
    >
      <Outlet />
    </BuyIndividualContext.Provider>
  );
};
