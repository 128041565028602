import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import {
  FormControlLabelStyle,
  radioStyle,
  theme,
} from "../../Styles/FormStyles";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { SubmitBtn } from "../../admin/Students/OverlayElements";
import AdminButtonContainer from "../../../ui/AdminButtonContainer";
import AdminFormButton from "../../../ui/AdminFormButton";
import RangeSelector from "./Range";
import axios from "axios";

function RangeForm({ selectedDay, onClose, availabilityData, refetchData }) {
  const [data, setData] = useState({
    availabilities: [],
    day: selectedDay.value,
  });
  const [showInputs, setShowInputs] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [availabilities, setAvailabilities] = useState([]);
  const [showFourthRange, setShowFourthRange] = useState(false);
  const [fourthRangeStartTime, setFourthRangeStartTime] = useState("");
  const [fourthRangeEndTime, setFourthRangeEndTime] = useState("");

  useEffect(() => {
    if (availabilityData && availabilityData.length > 0) {
      const selectedAvailabilities = availabilityData
        .map((item) => ({
          start_time: item.start_time_.slice(0, 5),
          end_time: item.end_time_.slice(0, 5),
          user_id: item.user_id,
          day: item.day,
          id: item.id,
        }))
        .filter((item) => item.day === selectedDay.value);

      setAvailabilities(selectedAvailabilities);
      console.log("selectedAvailabilities", selectedAvailabilities);

      const isFullDayAvailable = selectedAvailabilities.some((avail) =>
        selectedDay.name === "Sábado"
          ? avail.start_time === "09:00" && avail.end_time === "18:00"
          : avail.start_time === "09:00" && avail.end_time === "21:00"
      );
      if (isFullDayAvailable) {
        console.log("Full day is available");
        setSelectedValue("Todo el día estaré disponible");
        setData({
          availabilities: [
            {
              start_time:
                selectedDay.name === "Sábado" ? "09:00:00" : "09:00:00",
              end_time: selectedDay.name === "Sábado" ? "18:00:00" : "21:00:00",
            },
          ],
          day: selectedDay.value,
        });
      } else if (selectedAvailabilities.length === 0) {
        setAvailabilities([]);
      } else {
        console.log("Setting selected value to Puedo por rango de horas");
        setSelectedValue("Puedo por rango de horas");
        setShowInputs(true);
      }
    } else {
      setAvailabilities([]);
      setSelectedValue("");
    }
  }, [availabilityData, selectedDay]);

  const handleSubmit = async () => {
    try {
      if (selectedValue === "No tengo tiempo este dia") {
        await handleDeleteRange();
      } else {
        const id = localStorage.getItem("userId");
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/user/${id}/availability`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        console.log("Data saved successfully:", response.data);
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
    onClose();
    refetchData();
  };

  const generateTimeIntervals = (availabilities) => {
    const intervals = [];
    let startHour = 9;
    let endHour = 21;

    if (selectedDay.name === "Sábado") {
      endHour = 18;
    }
    // for (let i = startHour; i <= endHour; i++) {
    //   const hour = i < 10 ? `0${i}` : `${i}`;
    //   const isFilled = availabilities.some(
    //     (avail) => avail.start_time === `${hour}:00` && avail.end_time !== ""
    //   );
    //   intervals.push({
    //     time: `${hour}:00`,
    //     isFilled: isFilled,
    //   });
    // }
    // return intervals;
    for (let i = startHour; i < endHour; i++) {
      for (let j = 0; j < 60; j += 30) {
        const hour = i < 10 ? `0${i}` : `${i}`;
        const minute = j === 0 ? '00' : `${j}`;
        const time = `${hour}:${minute}`;
        const isFilled = availabilities.some(
          (avail) => avail.start_time === `${time}` && avail.end_time !== ""
        );
        intervals.push({
          time: time,
          isFilled: isFilled,
        });
      }
    }
    const lastHour = endHour < 10 ? `0${endHour}` : `${endHour}`;
    intervals.push({
      time: `${lastHour}:00`,
      isFilled: availabilities.some(
        (avail) => avail.start_time === `${lastHour}:00` && avail.end_time !== ""
      ),
    });
  
    return intervals;
  };

  const handleChange = (event) => {
    const { name, value, type } = event.target;

    if (type === "radio") {
      setSelectedValue(value);
      setShowInputs(value === "Puedo por rango de horas");
      if (value === "Puedo por rango de horas") {
        setAvailabilities([{}]);
      } else if (value === "Todo el día estaré disponible") {
        let endHour = 21; 
        if (selectedDay.name === "Sábado") {
          endHour = 18; 
        }
        setAvailabilities([{ start_time: "09:00", end_time: `${endHour}:00` }]);
        setData({
          ...data,
          availabilities: [{ start_time: "09:00", end_time: `${endHour}:00` }],
        });
      } else {
        setAvailabilities([]);
        setData({ ...data, availabilities: [] });
      }
    } else {
      const index = parseInt(name.split("-")[1]);
      const updatedAvailabilities = [...availabilities];
      updatedAvailabilities[index] = {
        ...updatedAvailabilities[index],
        [name.split("-")[0]]: value,
      };
      setAvailabilities(updatedAvailabilities);
      const updatedData = { ...data };
      updatedData.availabilities = updatedAvailabilities;
      setData(updatedData);

      if (index === 2 && value !== "") {
        setShowFourthRange(true);
      }
      if (index === 3) {
        if (name.includes("start_time")) {
          const formattedStartTime = value.slice(0, 5);
          setFourthRangeStartTime(formattedStartTime);
        } else if (name.includes("end_time")) {
          const formattedEndTime = value.slice(0, 5);
          setFourthRangeEndTime(formattedEndTime);
        }
      }
    }
  };

  const handleDeleteRange = async () => {
    try {
      const id = localStorage.getItem("userId");
      const availabilitiesToDelete = availabilityData.filter(
        (item) => item.day === selectedDay.value
      );

      if (availabilitiesToDelete.length === 0) {
        console.error("No availabilities found for the selected day");
        return;
      }

      const token = localStorage.getItem("access_token");

      await Promise.all(
        availabilitiesToDelete.map(async (availability) => {
          const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}/user/${id}/availability/${availability.id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "*/*",
              },
            }
          );
          console.log("Data deleted successfully:", response.data);
        })
      );

      setAvailabilities([]);
      setSelectedValue("");

      onClose();
      refetchData();
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const handleDeleteSingleRange = async (index) => {
    try {
      const id = localStorage.getItem("userId");
      const availabilityToDelete = availabilities[index];
      if (!availabilityToDelete || !availabilityToDelete.id) {
        console.error("No availability found for the selected range");
        return;
      }

      const token = localStorage.getItem("access_token");
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/user/${id}/availability/${availabilityToDelete.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "*/*",
          },
        }
      );

      console.log("Data deleted successfully:", response.data);

      const updatedAvailabilities = [...availabilities];
      updatedAvailabilities.splice(index, 1);
      setAvailabilities(updatedAvailabilities);
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  return (
    <>
      <div>
        <p
          style={{
            fontSize: " 0.875rem",
            fontWeight: 500,
            marginBottom: "1rem",
          }}
        >
          ¿Qué horas puedes el {selectedDay.name} ?
        </p>
        <ThemeProvider theme={theme}>
          <FormControl>
            <RadioGroup
              name="selectedModalidad"
              value={selectedValue}
              onChange={handleChange}
            >
              <FormControlLabel
                value="Todo el día estaré disponible"
                control={<Radio sx={radioStyle} />}
                sx={FormControlLabelStyle}
                label="Todo el día estaré disponible"
              />
              <FormControlLabel
                value="No tengo tiempo este día"
                control={<Radio sx={radioStyle} />}
                sx={FormControlLabelStyle}
                label="No tengo tiempo este día"
              />
              <FormControlLabel
                value="Puedo por rango de horas"
                control={<Radio sx={radioStyle} />}
                sx={FormControlLabelStyle}
                label="Puedo por rango de horas"
              />
            </RadioGroup>
          </FormControl>
        </ThemeProvider>
        {showInputs && (
          <>
            <div
              style={{
                height: "50vh",
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                gap: "1.5rem",
                marginTop: "1.5rem",
              }}
            >
              {[...Array(3)].map((_, index) => {
                const availability = availabilities[index] || {
                  start_time: "",
                  end_time: "",
                };
                return (
                  <RangeSelector
                    key={index}
                    index={index}
                    startTime={availability.start_time}
                    endTime={availability.end_time}
                    generateTimeIntervals={generateTimeIntervals}
                    handleChange={handleChange}
                    availabilities={availabilities}
                    selectedDay={selectedDay}
                    handleDeleteRange={handleDeleteSingleRange}
                  />
                );
              })}
              {showFourthRange && (
                <RangeSelector
                  index={3}
                  startTime={fourthRangeStartTime}
                  endTime={fourthRangeEndTime}
                  generateTimeIntervals={generateTimeIntervals}
                  handleChange={handleChange}
                  handleDeleteRange={handleDeleteSingleRange}
                  availabilities={availabilities}
                />
              )}
            </div>
          </>
        )}
        <SubmitBtn>
          <AdminButtonContainer>
            <AdminFormButton
              onClick={
                selectedValue !== "No tengo tiempo este día"
                  ? handleSubmit
                  : handleDeleteRange
              }
            >
              Guardar cambios
            </AdminFormButton>
          </AdminButtonContainer>
        </SubmitBtn>
      </div>
    </>
  );
}

export default RangeForm;
