import React, { useState } from "react";
import MainButton from "../../ui/Button";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import axios from "axios";
import styled from "styled-components";

const Wrapper = styled.div`
@media screen and (max-width: 768px) {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
  }
`

function Recovery() {
  const [step, setStep] = useState(0);
  const [data, setData] = useState({
    email: ''
  })
  const [error, setError] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(null);

  const StepDisplay = () => {
    if (step === 0) {
      return <StepOne onChange={handleChange} data={data} error={error} />;
    } else if (step === 1) {
      return <StepTwo data={data} error={error}/>;
    } else {
      return null;
    }
  };

  const handleSubmit = async() =>  {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(data.email)) {
      setError('Correo electrónico inválido');
      return; 
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/forgot_password`,
        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );
      console.log('User data updated successfully!');
      console.log(response.data);
      setError('');
      setStep((cur) => cur + 1);
    } catch (error) {
      console.error('Error updating user data:', error.message);
      setError(error.response.data.detail);
    }
  }

  const handleNextClick = (e) => {
    e.preventDefault();
    if(step === 0) {
        handleSubmit()
    } else if (step === 1) {
        window.location.href = "/login"
    }
  };

  const isNextDisabled = () => {
    if (step === 0) {
      return (
        data.email === '' || error !== ''
      );
    } 
    return false;
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));

    clearTimeout(typingTimeout);
    const newTypingTimeout = setTimeout(() => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setError(value.trim() === '' ? '' : emailPattern.test(value) ? '' : 'Correo electrónico inválido');
      setError('');
    }, 500); 
    setTypingTimeout(newTypingTimeout);
  } 


  return (
    <Wrapper>
      <div>{StepDisplay()}</div>
      <div>
        <MainButton onClick={handleNextClick} disabled={isNextDisabled()}>
        {step === 1 ? "Volver al login" : "Enviar correo de recuperación"}
        </MainButton>
      </div>
    </Wrapper>
  );
}

export default Recovery;
