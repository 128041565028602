import styled  from "styled-components";

export const Container = styled.div`
    padding-top: 18px;
    width: 416px;
    height: 530px;
    display: flex;
    flex-direction: column;

    @media  screen and (max-width: 480px) {
        width: 100%;
        padding: 0px 24px;
    }
`

export const Steps = styled.h2`
    font-size: 12px;
    text-align: left;
    margin-bottom: 4px;
    font-weight: 400;

    @media  screen and (max-width: 480px) {
        max-width: 327px;
    }
`;

export const StepTitle = styled.h1`
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 15px;

    @media  screen and (max-width: 480px) {
        max-width: 327px;
    }
`;

export const ClassType = styled.h2`
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    margin: 30px 0 16px;
`

