import React, { useEffect, useState } from "react";
import { getUserData } from "../../../utils/tools";
import { Title } from "./OverlayElements";
import {
  ClassInfo,
  ClassName,
  ProfessorName,
  TimeInfo,
} from "../../MultiStepsForm/individual/StepSix/StepSixElements";
import {
  AdditionalInfo,
  ClassStarts,
  Day,
} from "../../MultiStepsForm/individual/StepFive/StepFiveElements";
import axios from "axios";
import Modal from "../../../ui/Modal";
import ConfirmPayment from "./ConfirmPayment";

function WatchStudent({ userId, onClose, onCloseWatch }) {
  const [userData, setUserData] = useState({});
  const [levelData, setLevelData] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [infoData, setInfoData] = useState({});
  console.log("levelData", levelData);

  const refetchData = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/lesson/level?student_id=${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      setLevelData(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleSubmit = async (event, item) => {
    event.preventDefault();
    try {
      const token = localStorage.getItem("access_token");
      const newData = {
        data: {
          transaction: {
            amount_in_cents: selectedItem.next_payment_value,
            reference: `pago-para-nivel${selectedItem.id}-estudiante${selectedItem.student_id}-cuota${selectedItem.payment_count}`,
            status: "APPROVED",
          },
        },
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/lesson/level/process_balance`,
        newData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("Response:", response.data);
      setInfoData(response.data)
    } catch (error) {
      console.error("Error:", error);
    }
    refetchData();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getUserData(userId);
        setUserData(result);
      } catch (error) {
        console.error("Error:", error.message);
      }
    };
    fetchData();
  }, [userId]);

  function handleClick(event, item) {
    setIsOpenModal(true);
    setSelectedItem(item);
  }

  useEffect(() => {
    const fetchLevelData = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/lesson/level?student_id=${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        setLevelData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchLevelData();
  }, [userId]);

  useEffect(() => {
    const fetchInfoData = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/lesson/level/${selectedItem.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        setInfoData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if (selectedItem) {
      fetchInfoData();
    }
  }, [selectedItem]);

  const lessonInfo = levelData.map((levelItem, levelIndex) => {
    const formattedLessons = levelItem.scheduled_times.map(
      (scheduleItem, scheduleIndex) => {
        const startTimeParts = scheduleItem.start_time.split(":");
        const startTime = startTimeParts[0] + ":" + startTimeParts[1];
        return (
          <Day key={scheduleIndex}>
            {scheduleItem.dow
              ? scheduleItem.dow.charAt(0).toUpperCase() +
                scheduleItem.dow.slice(1).toLowerCase()
              : ""}{" "}
            de cada semana - {startTime}
          </Day>
        );
      }
    );
    return <React.Fragment key={levelIndex}>{formattedLessons}</React.Fragment>;
  });

  const formattedDate = formatDate(userData.date_of_birth);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function getNextDateWithDay(dayOfWeek) {
    var today = new Date();
    var currentDayOfWeek = today.getDay();
    var days = [
      "DOMINGO",
      "LUNES",
      "MARTES",
      "MIERCOLES",
      "JUEVES",
      "VIERNES",
      "SABADO",
    ];
    var targetDayIndex = days.indexOf(dayOfWeek);
    console.log("targetDayIndex", targetDayIndex);
    var difference = targetDayIndex - currentDayOfWeek;
    if (difference <= 0) {
      difference += 7;
    }
    var nextDate = new Date(today.getTime() + difference * 24 * 60 * 60 * 1000);
    return nextDate;
  }

  const lessonDate = levelData.map((levelItem, levelIndex) => {
    let earliestDate;
    levelItem.scheduled_times.forEach((scheduleItem, scheduleIndex) => {
      const nextDate = getNextDateWithDay(scheduleItem.dow);
      if (!earliestDate || nextDate < earliestDate) {
        earliestDate = nextDate;
      }
    });
  
    if (earliestDate) {
      return earliestDate.toLocaleDateString("es-ES", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    } else {
      return null; 
    }
  });


  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          overflow: "auto",
          height: "80vh",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", gap: "0.6rem" }}
        >
          <Title style={{ marginBottom: "1rem" }}>Cursos actuales</Title>
          {levelData.length > 0 ? (
            levelData.map((item, index) => (
              <div
                key={index}
                style={{
                  padding: "1rem",
                  border: "1px solid #C7C9CA",
                  width: "25rem",
                  borderRadius: "0.5rem",
                }}
              >
                <ClassInfo style={{ marginBottom: "0.5rem" }}>
                  <ClassName>
                    Clases de{" "}
                    {item.instrument.name
                      ? item.instrument.name.charAt(0).toUpperCase() +
                        item.instrument.name.slice(1).toLowerCase()
                      : ""}
                  </ClassName>
                  <ProfessorName>Profesor(a) {item.teacher}</ProfessorName>
                  <ProfessorName>Salón {item.classroom?.name}</ProfessorName>
                </ClassInfo>
                <TimeInfo style={{ marginBottom: "0.5rem" }}>
                  <Day>Intensidad {item.intensity}</Day>
                  <Day>{item.is_groupal === true ? 'Clases Grupales' : 'Clases Personalizada'}</Day>
                  {lessonInfo[index]}
                  <AdditionalInfo>Horarios Fijos</AdditionalInfo>
                  <ClassStarts>
                    Próxima clase<br></br>
                    {lessonDate[index]}
                  </ClassStarts>
                </TimeInfo>
                <div style={{ marginBottom: "1rem" }}>
                  {item.next_payment_value > 0 && <Day style={{color: '#14AE5C'}}>Próximo pago: {item.next_payment} </Day>}
                  <Day>Clases restantes: {item.remaining_lessons} clases</Day>
                  <Day>
                    Plan de pago :{" "}
                    {item.payment_plan === "CICLO"
                      ? "Semestre 5 cuotas"
                      : item.payment_plan === "TRIMESTRAL"
                      ? "Trimestre 2 cuotas"
                      : item.payment_plan === "SEMESTRAL"
                      ? "Semestre Contado"
                      : ""}
                  </Day>
                  <Day>
                  Cuotas pagadas {item.payment_count}/{item.total_payments} 
                  </Day>
                </div>
                <div>
                  {item.payment_count !== item.total_payments ? (
                    <div style={{display: 'flex', gap: '0.5rem'}}>

                    <button
                      style={{
                        borderRadius: "0.5rem",
                        background: "#4FB1C3",
                        padding: "0.5rem 1rem",
                        color: "#FFF",
                        fontWeight: 500,
                        width: '11.75rem'
                      }}
                      onClick={(event) => handleClick(event, item)}
                    >
                      Renovar pago
                    </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                padding: "1rem",
                background: "#efeded",
                width: "25rem",
                borderRadius: "0.5rem",
                height: "16rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Este estudiante no tiene clases activas
            </div>
          )}
        </div>

        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Title style={{ marginBottom: "0rem" }}>Información personal</Title>
          <p>{`${userData.first_name} ${userData.last_name}`}</p>
          <p>
            {userData.identification_number_type === "CEDULA"
              ? "Cedula de ciudadania"
              : userData.identification_number_type === "TARJETA DE IDENTIDAD"
              ? "Tarjeta de identidad"
              : userData.identification_number_type === "REGISTRO CIVIL"
              ? "Registro Civil"
              : userData.identification_number_type === "CEDULA DE EXTRANJERIA"
              ? "Cedula de extranjeria"
              : userData.identification_number_type === "PASAPORTE"
              ? "Pasaporte"
              : userData.identification_number_type === "PERMISO ESPECIAL"
              ? "Permiso especial"
              : ""}
          </p>
          <p>{userData.identification_number}</p>
          <p>{userData.email}</p>
          <p>{formattedDate}</p>
          <p>{userData.country}</p>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Title style={{ marginBottom: "0rem" }}>
            Información de contacto
          </Title>
          <p>{userData.address}</p>
          <p>{userData.phone_number}</p>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Title style={{ marginBottom: "0rem" }}>Contacto de emergencia</Title>
          <p>{userData.emergency_contact_name}</p>
          <p>{userData.emergency_contact_phone}</p>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Title style={{ marginBottom: "0rem" }}>
            Correo de recepción de estado de cuenta
          </Title>
          <p>{userData.billing_mail ? userData.billing_mail : "-"}</p>
        </div>
      </div>
      {isOpenModal && selectedItem && (
        <Modal onClose={() => setIsOpenModal(false)}>
          <ConfirmPayment
            levelId={selectedItem.id}
            method="method"
            text="Revisa los datos para renovar el pago de este estudiante"
            userData={infoData}
            handleSubmit={handleSubmit}
          />
        </Modal>
      )}
    </>
  );
}

export default WatchStudent;
