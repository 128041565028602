import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { ThemeProvider } from "@emotion/react";
import { TableHeadStyle, searchInput, theme, themeTable } from "../../Styles/FormStyles";
// import { EditBtn, EditBtnWrapper, EditImg } from "./StudentsTablesElements";
import edit from "../../../images/Edit.svg";
import axios from "axios";
import {
  EditBtn,
  EditBtnWrapper,
  EditImg,
} from "../Students/StudentsTablesElements";
import Modal from "../../../ui/Modal";
import Overlay from "./Overlay";
import ActiveMenu from "../../../ui/ActiveMenu";
import DeleteScreen from "../DeleteScreen";
import { TablePagination, TextField } from "@mui/material";

function TableMui() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('')

  const columns = [
    { id: "identification_number", label: "Número de documento", align: "left" },
    {
      id: "name",
      label: "Profesor",
      align: "left",
      format: (value, row) => (
        <p key={row.id}>
          {row.first_name} {row.last_name}
        </p>
      ),
    },
    {
      id: "instruments",
      label: "Cursos ",
      align: "left",
      format: (value, row) => {
        return (
          <p>
            {row.instruments.map((instrument, index) => (
          <span key={instrument.id}>
            {instrument.name.charAt(0).toUpperCase() +
                  instrument.name.slice(1).toLowerCase()}
            {index !== row.instruments.length - 1 && ", "}
          </span>
        ))}
          </p>
        );
      },
    },
    {
      id: "phone_number",
      label: "Contacto",
      align: "left",
    },
    {
      id: "modal",
      label: "Acciones",
      minWidth: 160,
      align: "center",
      headerAlign: "center",
      format: (value, row) => (
        <EditBtnWrapper>
          <ActiveMenu key={row.id} onClickEdit={() => handleEditClick(row.id)} 
          onClickDelete={() => handleDeleteClick(row.id)} />
        </EditBtnWrapper>
      ),
    },
  ];

  const handleEditClick = (userId) => {
    setSelectedUserId(userId);
    setIsOpenModal(true);
  };
  const handleDeleteClick = (userId) => {
    setSelectedUserId(userId);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/`,
          {
              params: {
                limit: rowsPerPage,
                page: page + 1,
                user_type: "TEACHER",
                status: "ACTIVE",
                keyword: search
              },
              headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
          }
        );
        const resultData = response.data
        setData(resultData);
        console.log('resultData',resultData);
        const total = parseInt(response.headers['pagination-total-size']);
        console.log('Total', total)
        setTotal(total)
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, [page, rowsPerPage, search]);

  const filteredData = data.filter((item) => {
    return (
      search.trim() === "" ||
      item.first_name.toLowerCase().includes(search.toLowerCase())
    );
  });

  return (
    <>
    <div style={{marginBottom: '1.5rem', paddingTop: '0.5rem'}}>
      <ThemeProvider theme={theme}>
        <TextField
          sx={searchInput}
          type="text"
          label="Buscador"
          placeholder="Ingresa el nombre del profesor"
          onChange={(e) => setSearch(e.target.value)}
        />
      </ThemeProvider>
    </div>
      <ThemeProvider theme={themeTable}>
        <Paper sx={{ width: "100%", borderRadius: "0.5rem 0.5rem 0rem 0rem" }}>
          <TableContainer
            sx={{ maxHeight: '60vh', borderRadius: "0.5rem 0.5rem 0rem 0rem" }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={TableHeadStyle}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      style={{
                        minWidth: column.minWidth,
                        textAlign: column.headerAlign || column.align || "left",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {data.map((data) => { */}
                {filteredData.map((data) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={data.id}
                        >
                          {columns &&
                            columns.map((column) => {
                              const value = data[column.id];
                              return (
                                <TableCell key={value} align={column.align}>
                                  {column.format
                                    ? column.format(value, data)
                                    : value}
                                </TableCell>
                              );
                            })}
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
        </Paper>
      </ThemeProvider>
      {isOpenModal && (
        <Modal onClose={() => setIsOpenModal(false)} props="Detalle profesor">
          <Overlay userId={selectedUserId} />
        </Modal>
      )}
      {isOpen && (
      <Modal onClose={() => setIsOpen(false)}>
        <DeleteScreen userId={selectedUserId} onClose={handleCloseModal}/>
      </Modal>
    )}
    </>
  );
}

export default TableMui;
