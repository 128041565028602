import React from "react";
import AdminPageContainer from "../../../ui/AdminPageContainer";
import {
  Content,
  SearchWrapper,
  TableWrapper,
  Title,
} from "../Students/StudentsTablesElements";
import ApproveRequest from "../../../features/adminRequests/ApproveRequest";
import TableMui from "./TableMui";

function ProfessorsTable() {
  return (
    <AdminPageContainer>
      <Content>
        <SearchWrapper
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Title style={{ margin: "0rem" }}>Profesores</Title>
          <ApproveRequest />
        </SearchWrapper>
        <TableWrapper style={{height: '80vh'}}>
          <TableMui />
        </TableWrapper>
      </Content>
    </AdminPageContainer>
  );
}

export default ProfessorsTable;
