import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import arrow from "../../images/ArrowBack.svg";
import Modal from '../../ui/Modal';
import { MainContent } from "../MultiStepsForm/MultiStepFormElements";
import { Description, Title } from "../Overlay/OverlayElements";
import ClassStudent from "./ClassStudent";
import Overlay from "./OverlayContent/Overlay";
import { fetchClassInfo } from "../../utils/rescheduleTools";
import CancelletionOfClass from "../professors/Calendario/CancelletionOfClass";

const CalendarWrapper = styled.div`
  display: flex;
`;

const Day = styled.div`
  width: 2.5rem;
  height: 3.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  color: #2c2e2f;
  font-size: 0.875rem;
  text-align: center;
  cursor: pointer;
  margin-right: 0.35rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-transform: capitalize;

  &:hover {
    background-color: #77d8a2;
  }

  &.active {
    background-color: #77d8a2;
  }
`;

const NavButton = styled.button`
  background-color: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div``;

const Wrapper = styled.div`
  scroll-behavior: smooth;
  height: auto;
  margin-bottom: 0.25rem;
`;


function Calendar() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [classData, setClassData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [selectedClass, setSelectedClass] = useState(null);
  const [activeDay, setActiveDay] = useState(currentDate.getDay());
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [levelInfo, setLevelInfo] = useState();

  useEffect(() => {
    const startDate = new Date(currentDate);
    startDate.setDate(currentDate.getDate() - currentDate.getDay());
    setStartDate(startDate);
  }, [currentDate]);

  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  const day = currentDate.getDate().toString().padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;

  // useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("access_token");
        const id = localStorage.getItem("userId");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/lesson/?student_id=${id}&lesson_day=${formattedDate}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        setClassData(response.data);
      } catch (error) {
        console.error("Error:", error.message);
        throw error;
      }
    };
  //   fetchData();
  // }, [formattedDate, selectedDay]);

  useEffect(() => {
    fetchData();
  }, [formattedDate, selectedDay]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetchClassInfo(classData);
      setLevelInfo(response);
    };

    fetchData();
  }, [classData]);
  console.log('levelInfo', levelInfo)

  console.log('classData', classData)

  const goToPreviousWeek = (e) => {
    e.preventDefault();
    const previousWeek = new Date(currentDate);
    previousWeek.setDate(currentDate.getDate() - 7);
    setCurrentDate(previousWeek);
    setSelectedDay(previousWeek);
  };

  const goToNextWeek = (e) => {
    e.preventDefault();
    const nextWeek = new Date(currentDate);
    nextWeek.setDate(currentDate.getDate() + 7);
    setCurrentDate(nextWeek);
    setSelectedDay(nextWeek);
  };


  const formatDateRange = () => {
    const startDateFormat = startDate.toLocaleDateString("es-ES", {
      year: "numeric",
      month: "long",
    });

    return startDateFormat.replace(/\bde\b/, "");
  };

  const handleClassClick = (classInfo) => {
    const isAnyCancelled = classInfo.to_students.some(student => student.status === 'CANCELLED' || student.status === 'PENDING' || student.status === 'COMPLETED' );
    
    if (!isAnyCancelled && classInfo.students.length <= 1) {
      setSelectedClass(classInfo);
      setIsOpenModal(true);
    } 
  };

 
  useEffect(() => {
    const filteredClasses = classData.filter((classItem) => {
      const classStartDate = new Date(classItem.start_time);
      return classStartDate.toDateString() === selectedDay.toDateString();
    });

    setFilteredClasses(filteredClasses);
  }, [classData, selectedDay]);

  const handleDayClick = (dayIndex) => {
    setActiveDay(dayIndex);
    const selectedDay = new Date(startDate);
    selectedDay.setDate(startDate.getDate() + dayIndex);
    setCurrentDate(selectedDay);
    setSelectedDay(selectedDay);
  };

  const daysOfWeek = [];
  for (let i = 0; i < 7; i++) {
    const day = new Date(startDate);
    day.setDate(startDate.getDate() + i);
    daysOfWeek.push(day);
  }

  const handleCloseModal = async () => {
    setIsOpenModal(false);
    try {
      await fetchData(); 
    } catch (error) {
      console.error("Error while refetching data:", error.message);
    }
  };

  console.log(classData)

  return (
      <MainContent style={{display: 'flex', justifyContent: 'flex-start'}}>
        <div style={{marginBottom: '2rem'}}>
        <Title>Calendario</Title>
        <Description>
          Aquí se muestran las clases que tienes disponibles
        </Description>
        </div>
        <div style={{ marginBottom: "2.5rem" }}>
          <p style={{ marginBottom: "0.5rem", textTransform: "capitalize" }}>
            {formatDateRange()}
          </p>
          <CalendarWrapper>
            <NavButton
              style={{ marginRight: "0.32rem" }}
              onClick={goToPreviousWeek}
            >
              <img
                style={{ width: "1.2rem", height: "1.2rem" }}
                src={arrow}
                alt=""
              />
            </NavButton>
            {daysOfWeek.map((day, index) => (
              <Day
                key={index}
                className={activeDay === index ? "active" : ""}
                onClick={() => handleDayClick(index)}
              >
                <div>
                  {day.toLocaleDateString("es-ES", {
                    day: "numeric",
                    weekday: "short",
                  })}
                </div>
              </Day>
            ))}
            <NavButton onClick={goToNextWeek}>
              <img
                src={arrow}
                alt=""
                style={{
                  transform: "rotate(180deg)",
                  width: "1.2rem",
                  height: "1.2rem",
                }}
              />
            </NavButton>
          </CalendarWrapper>
        </div>
        <Container style={{ overflow: 'auto'}}>
        {filteredClasses.length > 0 ? (
          filteredClasses
            .map((classInfo, index) => (
              <Wrapper key={index}>
                <ClassStudent
                  key={index}
                  {...classInfo}
                  onClick={() => handleClassClick(classInfo)}
                />
              </Wrapper>
            ))
        ) : (
          <div
            style={{
              height: "30vh",
              textAlign: "center",
              width: "24rem",
              display: "flex",
              justifyContent: "center",
              alignItems: 'center'
            }}
          >
            No tienes clases hoy
          </div>
        )}
      </Container>
        {isOpenModal && (
          <Modal onClose={handleCloseModal} props="Detalle clase">
            <Overlay classData={selectedClass} onClose={handleCloseModal}/>
          </Modal>
        )}
      </MainContent>
  );
}

export default Calendar;
