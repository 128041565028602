import styled from "styled-components";
import { Link } from "react-router-dom";

export const LoginSection = styled.div`
    height: 100%; 
    width: 100%;
`
export const LoginContainer = styled.div`
    display: flex;
    height: 100vh; 

    @media  screen and (max-width: 768px) {
        flex-direction: column;
        width: 100%;
    }

    @media  screen and (max-width: 480px) {
        flex-direction: column;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

`
export const ImageWrapper = styled.div`
    width: 45%;
    height: 100%; 
    position: relative;

    @media  screen and (max-width: 768px) {
        display: none;
    }

    @media  screen and (max-width: 480px) {
        display: none;
    }
`
export const ImageDesktop = styled.img`
    position: absolute;
    top: 0;
    left: 0; 
    width: 100%;
    height: 100%;
    object-fit: cover;
`
export const LoginWrapper= styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* height: 100%; */
    width: 55%;

    @media  screen and (max-width: 768px) {
        width: 100%;
        /* height: 90vh; */
        display: flex;
        align-items: center;
        /* justify-content: center; */
        justify-content: space-evenly;
        margin-top: 50px;
    }

    @media  screen and (max-width: 480px) {
        margin-top: 0rem;
        width: 100%;
        display: flex;
        height: 100vh;
        /* justify-content: space-around; */
        align-items: center;
    }
`
export const HeadingWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 36px; */

    @media  screen and (max-width: 768px) {
        align-items: center;
    }
    @media  screen and (max-width: 480px) {
        align-items: center;
    }
`
export const Logo = styled.img`
    width: 160px;
    height: auto;
    /* margin-bottom: 59px; */

    @media  screen and (max-width: 480px) {
        display: none;
    }
`

export const MobileLogo = styled.img`
    display: none;

    @media  screen and (max-width: 480px) {
        display: block;
        margin-bottom: 30px;
        width: 144px;
        height: 74px;
    }
`

export const LoginTitle = styled.h1`
    margin-top: 34px;
    font-size: 20px;
    font-weight: 500;

    @media  screen and (max-width: 480px) {
        /* margin-bottom: -5rem; */
    }
`
export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    /* height: 30vh; */
`
export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;    
`
export const Divider = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: row;
    color: #6B7280;
    font-size: 12px;
    width: 416px;

    &::after,
    &::before {
        content: "";
        border-bottom: 1px solid #D1D5DB;
        flex: 1 1;
    }

    &::after {
        margin-left: 8px;
    }

    &::before {
        margin-right: 8px;
    }

    @media  screen and (max-width: 480px) {
        width: 327px
    }
`
export const GoogleBtn = styled.a`
    border: 1px solid #EAEAEA;
    padding: 8px 0;
    width: 416px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    margin-top: 16px;

    @media  screen and (max-width: 480px) {
        width: 327px;
    }
`
export const GLogo = styled.img`
    width: 23.51px;
    height: 24px;
`
export const Connection = styled.span`
    margin-top: 26px;
    font-size: 12px;
    font-weight: 500;
    color: #000;
   
    @media  screen and (max-width: 768px) {
       margin-bottom: 40px;
    }
 
`
export const NavLink= styled(Link)`
    margin-left: 16px;
    color: #000;
    text-decoration: none;

    &:hover {
        color: #48C99A;
        transition: 0.2s ease-in-out;
    }
`
