import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBack from "../../images/ArrowBack.svg";
import MainButton from "../../ui/Button";
import Modal from "../../ui/Modal";
import RightPageContainer from "../../ui/RightPageContainer";
import Overlay from "../Overlay/Overlay";
import {
  ArrowBtn,
  ArrowBtnWrapper,
  ArrowImgBack,
  ArrowImgForward,
  BackBtn,
  BackWrapper,
  BtnsContainer,
  Container,
  IconBtn,
  MainContent,
  TextBtn,
} from "./MultiStepFormElements";
import StepOne from "./StepOne/StepOne";
import StepFour from "./individual/StepFour/StepFour";
import StepSix from "./individual/StepSix/StepSix";
import StepThree from "./individual/StepThree/StepThree";
import StepTwo from "./individual/StepTwo/StepTwo";
import { useBuyIndClassContext } from "../../context/BuyIndividual";

const MultiStepsForm = () => {
  const [step, setStep] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { formData, setFormData, handleSubmit,paymentUrl } = useBuyIndClassContext();
  const allData = { ...formData };

  useEffect(() => {
    return () => {
      localStorage.removeItem('selectedCards');
    };
  }, []);

  const handleDataChange = (data) => {
    setFormData({ ...formData, ...data });
  };

  useEffect(() => {
    const params = new URLSearchParams();
    params.append("paso", step + 1);
    navigate({ search: `?${params.toString()}` });
  }, [step, navigate]);

  
  const steps = [
    <StepOne formData={formData} onDataChange={handleDataChange} />,
    <StepTwo formData={formData} onDataChange={handleDataChange} />,
    <StepThree formData={formData} onDataChange={handleDataChange} />,
    <StepFour formData={formData} onDataChange={handleDataChange} />,
    <StepSix formData={formData} onDataChange={handleDataChange} />,
  ];

  const isNextDisabled = () => {
    if (step === 0) {
      return (
        formData.instrument_id === ''
        );
    } else if (step === 1) {
        return(
          formData.lesson_type === ''
        )
    }
    else if(step === 2) {
      return (
        formData.teacher_id === ''
      );
    } else if (step === 3) {
      if (formData.lessons_per_week === '2') {
        return (
          formData.classroom_id === '' ||
          formData.lessons.length < 2
        );
      } else {
        return( 
          formData.classroom_id === '' ||
          formData.lessons === ''
        );
      }
    } else if (step === 4) {
      return(
        formData.pricing_model === ''
      );
    }
    return false;
  };

  const handleNextClick = (e) => {
    console.log(formData);
    e.preventDefault();
    if (step === 4) {
      handleSubmit(allData);
      setIsOpen(true);
    } else {
      setStep((cur) => cur + 1);
    }
  };


  const handleBackClick = (e) => {
    e.preventDefault();
    setStep((cur) => cur - 1);
  };

  return (
    <>
      <BackWrapper>
        {step !== 0 && (
          <BackBtn onClick={handleBackClick}>
            <IconBtn src={ArrowBack} />
            <TextBtn>Atrás</TextBtn>
          </BackBtn>
        )}
      </BackWrapper>
      <RightPageContainer>
        <MainContent>
          <Container>{steps[step]}</Container>
          <BtnsContainer>
              <MainButton onClick={handleNextClick} disabled={isNextDisabled()}>
                {step === 4 ? "Crear clase" : "Siguiente"}
              </MainButton>
            <ArrowBtnWrapper>
              <ArrowBtn
                disabled={step === 0}
                onClick={(e) => {
                  e.preventDefault();
                  setStep((cur) => cur - 1);
                }}
              >
                <ArrowImgBack />
              </ArrowBtn>
              <ArrowBtn
                onClick={(e) => {
                  e.preventDefault();
                  setStep((cur) => cur + 1);
                }}
                disabled={isNextDisabled() || step === 4}
              >
                <ArrowImgForward />
              </ArrowBtn>
            </ArrowBtnWrapper>
          </BtnsContainer>
          {isOpen && (
            <Modal onClose={() => setIsOpen(false)}>
              <Overlay paymentUrl={paymentUrl} />
            </Modal>
          )}
        </MainContent>
      </RightPageContainer>
    </>
  );
};

export default MultiStepsForm;



// const steps = [
  //   <StepOne formData={formData} onDataChange={handleDataChange} />,
  //   (formData.class === "grupal" ? <StepTwoGroup formData={formData} onDataChange={handleDataChange} /> : <StepTwo formData={formData} onDataChange={handleDataChange} />),
  //   (formData.class === "individual" ? <StepThree formData={formData} onDataChange={handleDataChange} /> : <StepThreeGroup formData={formData} onDataChange={handleDataChange} />),
  //   (formData.class === "individual" ? <StepFour formData={formData} onDataChange={handleDataChange} /> : null),
  //   (formData.class === "individual" ? <StepFive formData={formData} onDataChange={handleDataChange} /> : null),
  //   (formData.class === "individual" ? <StepSix formData={formData} onDataChange={handleDataChange} /> : null),
  // ];

// const StepDisplay = () => {
//   if (step === 0) {
//     return <StepOne formData={formData} onFormDataChange={handleFormDataChange} />;
//   } else if (step === 1) {
//     if (formData.selectedRadio === "grupal") {
//       return <StepTwoGroup formData={formData} onFormDataChange={handleFormDataChange} />;
//     } else {
//       return <StepTwo formData={formData} onFormDataChange={handleFormDataChange} />;
//     }
//   } else if (step === 2) {
//     if (formData.selectedRadio === "individual") {
//       return <StepThree formData={formData} onFormDataChange={handleFormDataChange} />;
//     } else {
//       return <StepThreeGroup formData={formData} onFormDataChange={handleFormDataChange} />;
//     }
//   } else if (step === 3) {
//     if (formData.selectedRadio === "individual") {
//       return <StepFour formData={formData} onFormDataChange={handleFormDataChange} />;
//     } else {
//       return null;
//     }
//   } else if (step === 4) {
//     if (formData.selectedRadio === "individual") {
//       return <StepFive formData={formData} onFormDataChange={handleFormDataChange} />;
//     } else {
//       return null;
//     }
//   } else {
//     if (formData.selectedRadio === "individual") {
//       return <StepSix formData={formData} onFormDataChange={handleFormDataChange} />;
//     } else {
//       return null;
//     }
//   }
// };
