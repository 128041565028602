import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SelectComponent from "../../ui/Select";
import { Day } from "../MultiStepsForm/individual/StepFive/StepFiveElements";
import {
  AdditionalPrice,
  ClassInfo,
  ClassName,
  CyclePrice,
  PaymentContainer,
  PaymentInfo,
  PaymentWrapper,
  Price,
  Span1,
  SpanGreen,
  TimeInfo,
  Total,
  TotalPrice,
} from "../MultiStepsForm/individual/StepSix/StepSixElements";

const Wrapper = styled.div`
  height: 60vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`;
const SelectWrapper = styled.div`
  padding-top: 0.2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  @media screen and (max-width: 768px) {
    padding-top: 5rem;
  }
`;

function StepTwoGroupNew({ formData, onDataChange }) {
  const [prices, setPrices] = useState({});

  useEffect(() => {
    const id = localStorage.getItem("userId");
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/lesson/pricing?pricing_model=${formData.pricing_model}&lessons_per_week=${formData.lessons_per_week}&lesson_type=${formData.lesson_type}&user_id=${formData.student_id}&is_groupal=true&is_dance=false&discount=0`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Prices", response.data);
        setPrices(response.data);
      })
      .catch((error) => {
        console.error("Error fetching salon options from API:", error);
      });
  }, [formData.lesson_type, formData.lessons_per_week, formData.pricing_model]);

  useEffect(() => {}, [prices]);

  let volumeOptions = [];
  if (formData.lessons_per_week === "1") {
    volumeOptions = [
      { label: "Semestre (20 clases)", value: "semestre" },
      { label: "Trimestre (24 clases)", value: "trimestre" },
    ];
  }

  const paymentOptions =
    formData.volume === "semestre"
      ? [
          { label: "Contado", value: "SEMESTRAL" },
          { label: "5 cuotas", value: "CICLO" },
        ]
      : [{ label: "2 cuotas", value: "TRIMESTRAL" }];

  const handlePaymentTypeChange = (fieldName) => (event) => {
    const value = event.target.value;
    let updatedFormData = { ...formData, [fieldName]: value };

    onDataChange(updatedFormData);
    console.log(updatedFormData);
  };

  const amountValue = prices.total_value / 100;
  const amountTuition = prices.tuition / 100;
  const amountDiscount = prices.payment_discount / 100;
  const amountTotal = prices.payment / 100;

  const formatAmount = (amount) => {
    if (amount) {
      return (amount / 1000).toLocaleString("en-US", {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      });
    } else {
      return "";
    }
  };

  const totalValue = formatAmount(amountValue);
  const tuitionValue = formatAmount(amountTuition);
  const paymentDiscount = formatAmount(amountDiscount);
  const total = formatAmount(amountTotal);
  return (
    <Wrapper>
      <SelectWrapper>
        <SelectComponent
          label="Volumen"
          menuItems={volumeOptions}
          onChange={handlePaymentTypeChange("volume")}
          value={formData.volume}
          name="volume"
        />
        <SelectComponent
          label="Modalidad de pago"
          menuItems={paymentOptions}
          value={formData.pricing_model}
          onChange={handlePaymentTypeChange("pricing_model")}
        />
      </SelectWrapper>
      <PaymentWrapper>
        <PaymentContainer>
          <PaymentInfo style={{ borderRadius: "0.5rem", height: "38vh" }}>
            <ClassInfo>
              <ClassName>
                Clases de{" "}
                {formData.instrument_name.charAt(0).toUpperCase() +
                  formData.instrument_name.slice(1)}
              </ClassName>
            </ClassInfo>
            <TimeInfo>
              <Day>Intensidad Normal</Day>
              <Day>Clases grupales</Day>
            </TimeInfo>
            <Day>
              {formData.pricing_model === "SEMESTRAL"
                ? "Único pago"
                : formData.pricing_model === "CICLO"
                ? "Pagas cada 28 días"
                : formData.pricing_model
                ? "Pagas 2 cuotas"
                : ""}
            </Day>
            <div>
              <Day style={{ marginBottom: "1rem" }}>
                {formData.pricing_model === "CICLO"
                  ? "Pago primera cuota (1/5)"
                  : formData.pricing_model === "TRIMESTRAL"
                  ? "Pago primera cuota (1/2)"
                  : ""}
              </Day>
              <CyclePrice>
                <Span1>Valor del clases</Span1>
                <Price>$ {totalValue} COP</Price>
              </CyclePrice>
              <CyclePrice>
                <Span1>Valor del descuento</Span1>
                <Price>-$ {paymentDiscount} COP</Price>
              </CyclePrice>
              {formData.tuition > 0 ? (
                <AdditionalPrice>
                  <Span1>Valor matrícula (única vez)</Span1>
                  <Price>$ {tuitionValue} COP</Price>
                </AdditionalPrice>
              ) : (
                <AdditionalPrice></AdditionalPrice>
              )}
              <Total>
                <SpanGreen>TOTAL A PAGAR</SpanGreen>
                <TotalPrice>$ {total} COP</TotalPrice>
              </Total>
            </div>
          </PaymentInfo>
        </PaymentContainer>
      </PaymentWrapper>
    </Wrapper>
  );
}

export default StepTwoGroupNew;
