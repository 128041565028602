import React from "react";
import {
  BtnWrapperCancel,
  Icon,
  InfoTextContainer,
  InfoWrapper,
  Title,
  Wrapper,
} from "../../Overlay/OverlayElements";
import accept from "../../../images/Accept.svg";
import styled from "styled-components";

const UserInfo = styled.div`
  font-size: 1rem;
  font-weight: 400;
  color: #757575;
  text-align: center;
  width: 60vh;
`;

function ClassCreated({ title, info}) {
  return (
    <>
      <div
       style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "45vh",
      }}>
        <InfoTextContainer>
          <Icon src={accept} />
          <Title>{title}</Title>
          <UserInfo>{info}</UserInfo>
        </InfoTextContainer>
      </div>
    </>
  );
}

export default ClassCreated;
