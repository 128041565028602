import Button from "@mui/material/Button";
import { ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { FormContainerProf, FormProf, InputsWrapper } from './RegProfElements';
import axios from "axios";
import Checkmarks from '../../../ui/Checkmarks';
import { textFieldStyle, themeTable } from "../../Styles/FormStyles";

const RegFormProf = ({onSuccess}) => {
  const [fullName,setFullName] = useState('');
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    instrument_ids:[0]
  })
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e, fieldName) => {
    let updatedFormData = { ...formData };
    if (fieldName === "full_name") {
      setFullName(e.target.value);

      const names = e.target.value.split(" ");
      updatedFormData = {
        ...updatedFormData,
        first_name: names[0] || "",
        last_name: names.slice(1).join(" ") || "",
      };
    } else if (e.target) {
      updatedFormData[fieldName] = e.target.value;
    } else {
      updatedFormData[fieldName] = e;
    }
    setFormData({ ...formData, ...updatedFormData });

    const errors = { ...formErrors };
    delete errors[fieldName]; 
    if (fieldName === 'email') {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(e.target.value)) {
        errors[fieldName] = 'Dirección de correo electrónico no válida';
      }
    }

  if (fieldName === 'full_name' && e.target.value.trim() === '') {
    errors[fieldName] = 'El nombre completo no puede estar vacío';
  }

  if (fieldName === 'phone_number' && e.target.value.trim() === '') {
    errors[fieldName] = 'El número de contacto no puede estar vacío';
  }

  if (fieldName === 'instrument_ids') {
    if (!Array.isArray(e) || e.length === 0 || (e.length === 1 && e[0] === 0)) {
      errors[fieldName] = 'Debe elegir al menos un instrumento';
    } else {
      delete errors[fieldName]; 
    }
  }

    setFormErrors(errors);
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};
  if (fullName.trim() === '') {
    errors['full_name'] = 'El nombre completo no puede estar vacío';
  }
  if (formData.email.trim() === '') {
    errors['email'] = 'El correo electrónico no puede estar vacío';
  } else {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      errors['email'] = 'Correo electrónico inválido';
    }
  }
  if (formData.phone_number.trim() === '') {
    errors['phone_number'] = 'El número de contacto no puede estar vacío';
  }
  if (!Array.isArray(formData.instrument_ids) || formData.instrument_ids.length === 0 || (formData.instrument_ids.length === 1 && formData.instrument_ids[0] === 0)) {
    errors['instrument_ids'] = 'Debe elegir al menos un instrumento';
  }

  setFormErrors(errors);

    if (Object.keys(formErrors).length > 0) {
      console.error('Form has errors:', formErrors);
      return; 
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/teacher/anonymous`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );
      console.log('Form data submitted:', response.data);
      onSuccess(response.data); 
    } catch (error) {
      console.error('Error submitting form data:', error);
    }
  };
 
  return (
    <>
    <ThemeProvider theme={themeTable}>
      <FormProf >
        <FormContainerProf>
          <InputsWrapper>
            <TextField
              sx={textFieldStyle}
              label="Nombre completo"
              type="text"
              name= 'full_name'
              value={fullName}
              onChange={(e) => handleChange(e, 'full_name')}
              error={formErrors.full_name !== undefined} 
              helperText={formErrors.full_name}
            />
            <TextField
              sx={textFieldStyle}
              label="Correo electrónico"
              type="email"
              name='email'
              value={formData.email}
              onChange={(e) => handleChange(e, 'email')}
              error={formErrors.email !== undefined}
              helperText={formErrors.email}
            />
            <Checkmarks
              onChange={(e) => handleChange(e, "instrument_ids")}
              value={formData.instrument_ids}
              name="instrument_ids"
              error={formErrors.instrument_ids !== undefined}
              helperText={formErrors.instrument_ids}
              />
            <TextField
              sx={textFieldStyle}
              label="Número de contacto"
              type="text"
              name='phone_number'
              value={formData.phone_number}
              onChange={(e) => handleChange(e, 'phone_number')}
              error={formErrors.phone_number !== undefined}
              helperText={formErrors.phone_number}
            />
          </InputsWrapper>
          <Button
            disableElevation
            type="submit"
            variant="contained"
            sx={{
              height: "50px",
              borderRadius: "8px",
              background: "#4AA1B1",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#48C99A",
              },
            }}
            onClick={handleSubmit}
          >
            Solicitar admisión
          </Button>
        </FormContainerProf>
        </FormProf>
        </ThemeProvider>
    </>
  );
};

export default RegFormProf;


// const handleSubmit = async (e,data) => {
  //   e.preventDefault();
    
  //   try { 
  //       const response = await axios.post('${process.env.REACT_APP_API_URL}/user/teacher/anonymous', {
  //         headers: {
  //           "Content-Type": "application/json",
  //           'Accept': "application/json",
  //         }} ,data);
  //       console.log("Form data submitted:", response.data);
  //       const result = response.data;
  //       setFormData(result);
  //     } catch (error) {
  //       console.error("Error submitting form data:", error);
  //     }
  //   console.log("Form data submitted:", data);
  // };


  // const [fullName, setFullName] = useState(""); 
  // const [formErrors, setFormErrors] = useState({});
  // const [isLoading, setLoading] = useState(false)


  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   const fullNameParts = fullName.split(" ");
  //   const firstNameValue = fullNameParts[0];
  //   const lastNameValue = fullNameParts.slice(1).join(" ");

  //   setFormData({
  //     ...formData,
  //     first_name: firstNameValue,
  //     last_name: lastNameValue,
  //   });

  //   const errors = {};

  //   if (!fullName.trim()) {
  //     errors.name = "Nombre completo es obligatorio";
  //   }

  //   if (!formData.email.trim()) {
  //     errors.email = "Correo electrónico es obligatorio";
  //   }
  //   if (!formData.phone_number.trim()) {
  //     errors.phone_number = "Número de contacto es obligatorio";
  //   }
  //   if (Object.keys(errors).length > 0) {
  //     setFormErrors(errors);
  //   } else {
  //     setFormErrors({});
  //     setLoading(true);
  //   }
  //   axios.post('${process.env.REACT_APP_API_URL}/user/teacher/anonymous', formData)
  //     .then((response) => {
  //       console.log(response.data);
  //       setTimeout(() => {
  //         setLoading(false);
  //         onSuccess(); 
  //       }, 1000);
  //     })
  //     .catch((error) => {
  //       console.error("There was a problem with the Axios request:", error);
  //       setLoading(false);
  //     });
  //     console.log("Data collected:", formData);
  // };