import React from "react";
import { useNavigate } from "react-router-dom";
import support from "../../../images/Circle_Help.svg";
import logout from "../../../images/Log_Out.svg";
import Logo from "../../../ui/Logo";
import {
  BottomWrapper,
  BtnCalend,
  BtnGestionar,
  BtnInicio,
  BtnNotif,
  LogOutBtn,
  LogOutImg,
  Name,
  NavBtn,
  NavItem,
  NavMenu,
  NavProfile,
  NavbarContainer,
  ProfileImg,
  Span,
  SupportBtn,
  SupportImg,
  Type
} from "../../SideNavbar/SideNavbarElements";
import useUserName from "../../../utils/useUserName";

const SideNavbarProf = ({ loggedIn, onLogout }) => {
  const navigate = useNavigate();

  const {name} = useUserName(localStorage.getItem('userId'))

  const handleLogout = () => {
    onLogout();
    navigate("/");
  };

  return (
    <>
      <NavbarContainer>
        <Logo />
        <div>
          <NavProfile>
            <ProfileImg />
            <div style={{width:'9.5rem'}}>
              <Name>{name.first_name} {name.last_name}</Name>
              <Type>Profesor</Type>
            </div>
          </NavProfile>
          <NavMenu>
            <NavItem>
              <NavBtn to="/home-professor">
                <BtnInicio />
                <Span>Inicio</Span>
              </NavBtn>
            </NavItem>
            <NavItem>
              <NavBtn to="/manage-availibility">
                <BtnGestionar />
                <Span>Gestionar disponibilidad</Span>
              </NavBtn>
            </NavItem>
            <NavItem>
              <NavBtn to="/calendar-prof">
                <BtnCalend />
                <Span>Ver calendario</Span>
              </NavBtn>
            </NavItem>
            {/* <NavItem>
              <NavBtn to="/notifications-prof">
                <BtnNotif />
                <Span>Notificaciones</Span>
              </NavBtn>
            </NavItem> */}
          </NavMenu>
        </div>
        <BottomWrapper>
          <SupportBtn>
            <SupportImg src={support} />
            ¿Necesitas contactar a soporte? ¡Házlo aquí!
          </SupportBtn>
          {loggedIn && (
            <LogOutBtn onClick={handleLogout}>
              <LogOutImg src={logout} />
              Cerrar sesión
            </LogOutBtn>
          )}
        </BottomWrapper>
      </NavbarContainer>
    </>
  );
};

export default SideNavbarProf;
