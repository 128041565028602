import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { ThemeProvider } from "@emotion/react";
import { TableHeadStyle, themeTable } from "../../Styles/FormStyles";
import Modal from "../../../ui/Modal";
import NewCourse from "./NewCourse";
import { EditBtnWrapper } from "../Students/StudentsTablesElements";
import { Users } from "../../SideNavbar/SideNavbarElements";
import AddStudent from "./AddStudent";
import ExistedClasses from "./ExistedClasses";
import DeletePerson from "./DeletePerson";
import ChangeClass from "./ChangeClass";
import axios from "axios";

function TableMui({ group_request }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenActiveCourses, setIsOpenActiveCourses] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [levelId, setLevelId] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const [groupLevels, setGroupLevels] = useState([]);
  const [activeComponent, setActiveComponent] = useState("ExistedClasses"); // State to manage which component to render
  const [modalPropsText, setModalPropsText] = useState(`Clases existentes de ${group_request.instrument.name}`); // Dynamic prop for Modal
  const [index, setIndex] = useState(-1);

  const columns = [
    { id: "id", label: "id", align: "left", minWidth: 100 },
    {
      id: "name",
      label: "Estudiante",
      align: "left",

      format: (index, row) => (
        <p key={row.id}>
          {row.first_name} {row.last_name}
        </p>
      ),
    },
    {
      id: "status",
      label: "Estado",
      align: "left",
      minWidth: 100,
    },
    {
      id: "age",
      label: "Edad",
      align: "left",
      minWidth: 100,
    },
    {
      id: "phone_number",
      label: "Teléfono",
      align: "left",
    },
    {
      id: "modal",
      label: "Acciones",
      minWidth: 160,
      align: "center",
      headerAlign: "center",
      format: (index, row) => (
        <EditBtnWrapper>
          <button key={row.id} onClick={() => handleEditClick(row, index)}>
            <Users />
          </button>
        </EditBtnWrapper>
      ),
    },
  ];

  const data = (group_request.results.map((item) => item.student));
  // const handleChangePage = (event, newPage) => {
  //     setPage(newPage);
  //   };

  //   const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(+event.target.value);
  //     setPage(0);
  //   };

  const handleEditClick = (student, index) => {
    setSelectedUser(student);
    setIndex(index);
  };

  useEffect(() => {
    if (selectedUser && index > -1) {
      setIsOpenEdit(true);
    }
  }, [selectedUser, index]);

  const handleButtonClick = (componentType, propsText, props) => {
    setIsOpenActiveCourses(true);
    setActiveComponent(componentType);
    setModalPropsText(propsText);
    setLevelId(props.level_id);
    setGroupLevels(props.group_levels);
  };

  const handleCloseModal = () => {
    setIsOpenActiveCourses(false);
    setActiveComponent('ExistedClasses');
    setModalPropsText(`Clases existentes de ${group_request.instrument.name}`);
  };

  const handleDelete = () => {
    axios.delete(`${process.env.REACT_APP_API_URL}/lesson/level/${levelId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        setIsDeleted(true);
      })
      .catch((error) => {
        console.error("Error fetching salon options from API:", error);
      });
  };

  return (
    <>
      <ThemeProvider theme={themeTable}>
        <Paper sx={{ width: "100%", borderRadius: "0.5rem 0.5rem 0rem 0rem" }}>
          <TableContainer
            sx={{ maxHeight: 320, borderRadius: "0.5rem 0.5rem 0rem 0rem" }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead sx={TableHeadStyle}>
                <TableRow>
                  <TableCell align="left" colSpan={6}>
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            gap: "0.2rem",
                            alignItems: "center",
                          }}
                        >
                          <p>{group_request.instrument.label}</p>
                          <button
                            style={{
                              background: "#E3F1FE",
                              color: "#3591F5",
                              fontSize: "0.75rem",
                              borderRadius: " 0.75rem",
                              padding: "0.12rem 0.5rem",
                              fontWeight: "500",
                            }}
                            onClick={() => setIsOpenActiveCourses(true)}
                          >
                            Cursos activos
                          </button>
                        </div>
                        <p style={{ fontWeight: 400 }}>
                          {data.length} estudiante{data.length !== 1 ? "s" : ""} en espera
                        </p>
                      </div>
                      <div style={{ marginLeft: "auto", color: "#3591F5" }}>
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => setIsOpenModal((show) => !show)}
                        >
                          Crear nuevo curso
                        </p>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align="center"
                      style={{
                        top: 57,
                        minWidth: column.minWidth,
                        textAlign: column.headerAlign || column.align || "left",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={data.id}
                        >
                          {columns &&
                            columns.map((column) => {
                              const value = data[column.id];
                              return (
                                <TableCell key={value} align={column.align}>
                                  {column.format
                                    ? column.format(index, data)
                                    : value}
                                </TableCell>
                              );
                            })}
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
        </Paper>
      </ThemeProvider>
      {isOpenModal && (
        <Modal onClose={() => setIsOpenModal(false)} props={`Crear grupo de ${group_request.instrument.label}`}>
          <NewCourse group_request={group_request} />
        </Modal>
      )}
      {isOpenEdit && (
        <Modal onClose={() => setIsOpenEdit(false)} props={`Agregar estudiante a curso de ${group_request.instrument.label}`}>
          <AddStudent instrument={group_request.instrument} group_request={group_request.results[index]} />
        </Modal>
      )}
      {isOpenActiveCourses && (
        <Modal
          onClose={handleCloseModal}
          props={modalPropsText}
        >
          {activeComponent === "ExistedClasses" && <ExistedClasses
            group_request={group_request} onButtonClick={handleButtonClick} />}
          {activeComponent === "DeletePerson" && <DeletePerson handleDelete={handleDelete} isDeleted={isDeleted} setIsDeleted={setIsDeleted}/>}
          {activeComponent === "ChangeClass" && <ChangeClass groupLevels={groupLevels} levelId={levelId}/>}
        </Modal>
      )}
    </>
  );
}

export default TableMui;
