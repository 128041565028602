import axios from "axios";
console.log(process.env.REACT_APP_API_URL);

export const handleLogin = async ({ username, password, onLogin,setUsername,
    setPassword, setErrorMessage}) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/login`,
      {
        username,
        password,
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
        },
      }
    );

    const token = response.data.access_token;
    localStorage.setItem("access_token", token);
    localStorage.setItem("userId", response.data.user.id);
    localStorage.setItem("isLoggedIn", true);

    const userType = response.data.user.user_type;
    const userStatus = response.data.user.status;
    
    onLogin(token);
    setUsername("");
    setPassword("");

      if (userType === "ADMIN") {
        window.location.href = "/salones";
      } else if (userType === "STUDENT") {
        window.location.href = "/inicio";
      } else if( userType === 'TEACHER' && userStatus === 'ACCEPTED') {
        window.location.href = '/setting-up'
      }else if (userType === "TEACHER" ) {
        window.location.href = "/home-professor";
      } 
  } catch (error) {
    // console.error("Login failed:", error);
    if (error.response && error.response.status === 401) {
      setErrorMessage("Correo electrónico o contraseña incorrectos");
    } else {
      console.error("Login failed:", error);
    }
  }
};
