import styled from "styled-components";

export const RegSection = styled.div`
  height: 100%;
  width: 100%;
`;

export const RegContainer = styled.div`
  display: flex;
  height: 100vh;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ImageWrapperProf = styled.div`
  width: 50%;
  /* height: 100vh; */
  height: 100%;
  position: relative;

  @media screen and (max-width: 768px) {
    display: none;
  }

  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const ImageDesktopProf = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  @media screen and (max-width: 768px) {
    position: inherit;
    object-fit: cover;
  }
`;

export const RegWrapperProf = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 55%;
  padding: 7.5rem 0rem 5rem 0rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    padding: 0rem;
  }

  @media screen and (max-width: 480px) {
    margin-top: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 36px;

  @media screen and (max-width: 480px) {
    width: 327px;
    align-items: flex-start;
  }
`;

export const Logo = styled.img`
  width: 160px;
  height: auto;

  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const MobileLogo = styled.img`
  display: none;

  @media screen and (max-width: 480px) {
    display: block;
    margin-bottom: 30px;
    width: 144px;
    height: 74px;
  }
`;

export const RegTitle = styled.h1`
  margin-top: 34px;
  font-size: 20px;
  font-weight: 500;

  @media screen and (max-width: 480px) {
    margin-bottom: 16px;
  }
`;

export const Descr = styled.p`
  font-size: 0.75rem;
  font-weight: 400;
`;

export const FormProf = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
`;
export const FormContainerProf = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 1rem;
  @media screen and (max-width: 768px) {
    margin-bottom: 0rem;
  }
  @media screen and (max-width: 480px) {
    margin-bottom: 0rem;
  }
`;
