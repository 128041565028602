import React, { useEffect, useState } from "react";
import { FormContainer } from "../../login/LoginElements";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  ThemeProvider,
} from "@mui/material";
import {
  FormControlLabelStyle,
  radioStyle,
  textFieldStyle,
  theme,
} from "../../Styles/FormStyles";
import { Title } from "../CreateUser/CreateUserElements";
import SelectComponent from "../../../ui/Select";
import { Description } from "../../MultiStepsForm/individual/StepTwo/StepTwoElements";
import axios from "axios";

function StepOne({ formData, onDataChange }) {
  const [studentName, setStudentName] = useState([]);
  const [musicInst, setMusicInst] = useState([]);
  // const [selectedType, setSelectedType] = useState("");
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/user/?user_type=STUDENT&limit=1000&status=ACTIVE`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        const formattedStudents = response.data.map((student) => ({
          label: `${student.first_name} ${student.last_name}`,
          value: student.id,
          is_tuition_payed: student.is_tuition_payed,
        }));
        setStudentName(formattedStudents);
      })
      .catch((error) => {
        console.error("Error fetching salon options from API:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/instrument/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        const instruments = response.data.map((instrument) => ({
          label: instrument.name,
          value: instrument.id,
          art_form: instrument.art_form,
          name: instrument.name,
        }));
        setMusicInst(instruments);
      })
      .catch((error) => {
        console.error("Error fetching salon options from API:", error);
      });
  }, []);

  const type_class = [
    { label: "Música", value: "MUSICA" },
    { label: "Danza", value: "DANZA" },
    { label: "Artes plásticas", value: "PINTURA" },
    { label: "Teatro", value: "TEATRO" },
  ];

  const handleInputChange = (fieldName) => (event) => {
    const value = event.target.value;

    console.log(studentName);
    let instrumentName = "";
    let isTuitionPayed = "";
    if (fieldName === "instrument_id") {
      const selectedInstrument = musicInst.find((item) => item.value === value);
      if (selectedInstrument) {
        instrumentName = selectedInstrument.name.toLowerCase();
      }
    }
    if (fieldName === "student_ids") {
      const selectedStudent = studentName.find(
        (item) => item.value === parseInt(value)
      );
      console.log("Selected student:", selectedStudent);
      if (selectedStudent) {
        isTuitionPayed = selectedStudent.hasOwnProperty("is_tuition_payed")
          ? selectedStudent.is_tuition_payed
          : "";
      }
    }

    const updatedFormData = {
      ...formData,
      [fieldName]: value,
      ...(fieldName === "instrument_id" && { instrument_name: instrumentName }),
      ...(fieldName === "student_ids" && { is_tuition_payed: isTuitionPayed }),
    };

    onDataChange(updatedFormData);
    console.log(`Selected ${fieldName}:`, value);
  };

  return (
    <>
      <FormContainer
        style={{ gap: "0rem", maxHeight: "70vh", overflowY: "auto" }}
      >
        <ThemeProvider theme={theme}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "26rem",
              marginTop: "1rem",
            }}
          >
            <Title style={{ fontSize: "1rem" }}>Estudiante</Title>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={studentName}
              getOptionLabel={(option) => option.label}
              sx={{ width: "26rem" }}
              name="student_ids"
              value={
                studentName.find(
                  (option) => option.value === formData.student_ids[0]
                ) || null
              }
              onChange={(event, newValue) => {
                const value = newValue ? [newValue.value] : [];
                handleInputChange("student_ids")({ target: { value } });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={textFieldStyle}
                  label="Nombre del estudiante"
                />
              )}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: "416px",
              marginTop: "2rem",
            }}
          >
            <Title style={{ fontSize: "1rem" }}>Tipo de clase</Title>
            <SelectComponent
              label="Tipo de clase"
              menuItems={type_class}
              value={formData.class_type}
              onChange={handleInputChange("class_type")}
              name='class_type'
            />
            <SelectComponent
              label="Clase"
              name="instrument_id"
              menuItems={musicInst.filter(
                (item) => item.art_form === formData.class_type
              )}
              value={formData.instrument_id}
              onChange={handleInputChange("instrument_id")}
            />
          </div>
          <div style={{ width: "416px", marginTop: "2rem" }}>
            <Title style={{ fontSize: "1rem", marginBottom: "0.5rem" }}>
              Modalidad de clases
            </Title>
            <Description style={{ margin: "0rem" }}>
              Elije la modalidad en la que necesitas las clases de con el
              profesor
            </Description>
            <FormControl sx={{ marginBottom: "60px" }}>
              <RadioGroup
                name="lesson_type"
                value={formData.lesson_type}
                onChange={handleInputChange("lesson_type")}
              >
                <FormControlLabel
                  value="PRESENCIAL"
                  control={<Radio sx={radioStyle} />}
                  sx={FormControlLabelStyle}
                  label="Presencial"
                />
                <FormControlLabel
                  value="VIRTUAL"
                  control={<Radio sx={radioStyle} />}
                  sx={FormControlLabelStyle}
                  label="Virtual"
                />
                <FormControlLabel
                  value="DOMICILIO"
                  control={<Radio sx={radioStyle} />}
                  sx={FormControlLabelStyle}
                  label="Domicilio"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </ThemeProvider>
      </FormContainer>
    </>
  );
}

export default StepOne;
