import { useState, useEffect } from 'react';
import { getUserData } from './tools';

const useUserName = (userId) => {
  const [name, setName] = useState({
    first_name: '',
    last_name: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getUserData(localStorage.getItem('userId'));
        setName(result)
      } catch (error) {
        console.error("Error:", error.message);
      }
    };
    fetchData();
  }, [userId]);

  return { name };
};

export default useUserName;
