import styled from "styled-components";
import { ReactComponent as arrow } from "../../../../images/Arrow.svg";

export const BtnBackWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 0.62rem;
  margin-bottom: 2rem;
`;
export const BtnBack = styled.button``;
export const BtnText = styled.h2`
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
`;
export const BackArrow = styled(arrow)``;
export const BtnsContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem 0rem 0rem 0rem;
  gap: 1.5rem;
`;

export const CancelBtn = styled.button`
  width: 416px;
  border: 1px solid #4fb1c3;
  padding: 1rem;
  color: #4fb1c3;
  width: 20.4375rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  font-weight: 500;
`;
