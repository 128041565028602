import React from 'react'
import SettingUpAccount from '../components/SettingUpAccount/SettingUpAccount'

function SettingUpPage() {
  return (
    <>
     <SettingUpAccount />
    </>
  )
}

export default SettingUpPage
