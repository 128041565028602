import React from 'react'
import AdminPageContainer from '../../../ui/AdminPageContainer'
import { MainContent } from '../../MainSection/InactiveMainElements'
import { BtnContainer, BtnCreate, BtnLink, BtnWrapper, Title } from '../CreateUser/CreateUserElements'

function CreateIndividualClass() {
  return (
    <AdminPageContainer>
         <MainContent
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "2.5rem",
            width: "100%",
          }}
        >
          <div>
            <Title >Crear clase individual</Title>
          </div>
          <BtnContainer>
            <BtnWrapper>
              <BtnLink to="/crear-clase-individual/paso-1" style={{whiteSpace:'nowrap'}}>
                <BtnCreate />
                Crear nueva clase
              </BtnLink>
          </BtnWrapper>
          </BtnContainer>
          </MainContent>
    </AdminPageContainer>
  )
}

export default CreateIndividualClass
