import React, { useState } from "react";
import icon from "../../images/DeleteIcon.svg";
import info from "../../images/Info.svg";
import DeleteButton from "../../ui/DeleteButton";
import { deleteUser } from "../../utils/tools";
import {
  BtnsContainer,
  CancelBtn,
} from "./CreateUser/StudentForm/StudentFormElements";
import axios from "axios";
import AdminButtonContainer from "../../ui/AdminButtonContainer";
import AdminFormButton from "../../ui/AdminFormButton";

function DeleteScreen({ userId, onClose }) {
  console.log("userId:", userId);
  const [showUI,setShowUi] = useState(true)
  const [hasActiveClass, setHasActiveClasses] = useState(false)

  const handleDelete = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/lesson/?student_id=${userId}&status=ACTIVE`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if (response.data.length > 0) {
        setShowUi(false)
        setHasActiveClasses(true);
      } else {
        await deleteUser(userId);
        if (onClose) {
          onClose();
          window.location.reload();
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const handleDelete = () => {
  //   deleteUser(userId)
  //     .then(() => {
  //       console.log("User deleted successfully!");
  //       if (onClose) {
  //         onClose();
  //         window.location.reload();
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //     });
  // };

  function handleCancel() {
    if (onClose) {
      onClose();
    }
  }

  return (
    <>
    {showUI && 
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          minHeight: "20rem",
        }}
      >
        <img src={icon} alt="" style={{ marginBottom: "1.5rem" }} />
        <h2
          style={{
            fontSize: "1rem",
            fontWeight: 500,
            marginBottom: "0.5rem",
          }}
        >
          ¿Seguro deseas borrar a este perfil?
        </h2>
        <p
          style={{
            color: "#757575",
            fontWeight: 400,
          }}
        >
          Si borras este perfil los datos se perderán definitivamente y no
          podrás recuperarlos
        </p>
      </div>
      <BtnsContainer
        style={{
          width: "100%",
          padding: "1rem 0rem 0rem 0rem",
          gap: "1.5rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "20.4375rem" }}>
          <DeleteButton onClick={handleDelete}>Borrar</DeleteButton>
        </div>
        <CancelBtn onClick={handleCancel}>Mantener</CancelBtn>
      </BtnsContainer>
    </>
    }
    {hasActiveClass && 
    <>
      <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        minHeight: "20rem",
      }}
    >
      <img src={info} alt="" style={{ marginBottom: "1.5rem" }} />
      <h2
        style={{
          fontSize: "1rem",
          fontWeight: 500,
          marginBottom: "0.5rem",
        }}
      >
        No es posible eliminar este usuario
      </h2>
      <p
        style={{
          color: "#757575",
          fontWeight: 400,
        }}
      >
        Actualmente cuenta con clases activas por lo tanto no es posible eliminarlo 
      </p>
    </div>
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <AdminButtonContainer >
        <AdminFormButton onClick={onClose}>Entendido</AdminFormButton>
      </AdminButtonContainer>
    </div>
    </>
    }
    </>
  );
}

export default DeleteScreen;
