import { useState, useEffect } from "react";
import { getUserData } from "./tools";

export function useUserAuthentication() {
const [loggedIn, setLoggedIn] = useState(false);
const [userType, setUserType] = useState(null);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn) {
      setLoggedIn(true);
    }
    const fetchData = async () => {
      if (isLoggedIn !== null) {
        try {
          const userData = await getUserData(localStorage.getItem("userId"));

          const fetchedUserType = userData.user_type;
          setUserType(fetchedUserType);
          console.log(userData);
        } catch (error) {
          console.error("Error:", error);
        }
      }
    };
    fetchData();
    // console.log("test");
    console.log(isLoggedIn);
  }, []);

  const handleLogin = () => {
    setLoggedIn(true);
    localStorage.setItem("isLoggedIn", true);
  };

     const handleLogout = () => {
    setLoggedIn(false);
    localStorage.clear();
  };

  return {handleLogin, handleLogout, loggedIn, userType}
}
