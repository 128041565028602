import React, { useState } from "react";
import menu from "../../images/MobileMenu.svg";
import notifications from "../../images/notifications.svg";
import MobileMenu from "../MobileMenu/MobileMenu";
import {
  Logo,
  Menu,
  Nav,
  NavBtn,
  NavItem,
  NavLink,
  NavLogo,
  NavMenu,
  NavbarContainer,
  Notifications,
} from "./NavbarElements";
import logo from "../../images/Logo.png";
import { useEffect } from "react";

const Navbar = ({ loggedIn, onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [close, setClose] = useState(false);

  const showSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setClose(!close);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo>
            <Logo src={logo} />
          </NavLogo>
          <NavMenu>
            {/* <NavItem>
              <NavLink to="/notifications">
                <Notifications src={notifications} />
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavBtn onClick={showSidebar}>
                <Menu src={menu} />
              </NavBtn>
            </NavItem>
          </NavMenu>
          {isOpen && <Overlay onClick={showSidebar} />}
        </NavbarContainer>
      </Nav>
      {isOpen && (
        <MobileMenu
          isOpen={isOpen}
          onClose={handleClose}
          loggedIn={loggedIn}
          onLogout={onLogout}
        />
      )}
    </>
  );
};

const Overlay = ({ onClick }) => {
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 999,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.5)",
      }}
      onClick={onClick}
    />
  );
};

export default Navbar;
