import React, { useState } from "react";
import {
  ThemeProvider,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  TextField,
} from "@mui/material";
import {
  VisibilityOutlined as VisibilityOutlinedIcon,
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
} from "@mui/icons-material";
import MainButton from "../../ui/Button";
import { Form, FormContainer } from "./LoginElements";
import { boxStyle, textFieldStyle, theme } from "../Styles/FormStyles";
import TextInputField from "../../ui/TextInputField";
import { handleLogin } from "../../utils/loginUtil";

const LoginForm = ({ onLogin }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setUsernameError(false);
    setPasswordError(false);
    setErrorMessage("");

    if (!username) {
      setUsernameError(true);
      // return;
    }

    if (!password) {
      setPasswordError(true);
      // return;
    }

    if (usernameError || passwordError) {
      return;
    }

    handleLogin({ username, password, onLogin, setUsername,
      setPassword,setErrorMessage});
  };

  return (
    <ThemeProvider theme={theme}>
      <Form onSubmit={handleSubmit} style={{marginTop: '2rem'}}>
        <FormContainer>
          <TextInputField
            label="Correo electrónico"
            type="email"
            value={username}
            name="username"
            error={usernameError}
            helperText={usernameError && "Correo electrónico es inválido"}
            onChange={(event) => setUsername(event.target.value)}
          />
          <TextField
            sx={textFieldStyle}
            name="password"
            label="Contraseña"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            error={passwordError}
            helperText={passwordError && "Contraseña es inválida"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ fontSize: "18px", color: "#2C2E2F" }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errorMessage && <p style={{ color: '#d32f2f',fontSize: '0.75rem', marginLeft: '14px', marginTop:'-0.5rem' }}>{errorMessage}</p>}
          <Box sx={boxStyle}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: "#215E69"[600],
                    "&.Mui-checked": {
                      color: "#215E69",
                    },
                  }}
                />
              }
              label={
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Recordarme
                </Typography>
              }
            />
            <Link
              href="/cambio-de-contraseña"
              // href="/setting-up"
              color="black"
              underline="none"
              sx={{
                "&:hover": {
                  color: "#48C99A",
                },
              }}
            >
              ¿Olvidaste tu contraseña?
            </Link>
          </Box>
          <div style={{ marginTop: "30px" }}>
            <MainButton>Iniciar sesión</MainButton>
          </div>
        </FormContainer>
      </Form>
    </ThemeProvider>
  );
};

export default LoginForm;


