import React from 'react'
import styled from 'styled-components'

const StyledRequestButton = styled.button`
    width: 13.68rem;
    background-color: #EF8F00;
    color: #fff;
    text-align: center;
    padding: 0.25rem 1rem;
    border-radius: 0.75rem;

    &:disabled {
      background: #ADB8BA;
    }
`


function RequestButton({children, onClick, disabled}) {
  return (
    <>
      <StyledRequestButton onClick={onClick} disabled={disabled}>{children}</StyledRequestButton>
    </>
  )
}

export default RequestButton
