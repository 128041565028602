import React, { useEffect, useState } from "react";
// import StepOne from '../MultiStepsForm/StepOne/StepOne';
// import StepThreeGroup from '../MultiStepsForm/group/StepThreeGroup/StepThreeGroup';
import { useNavigate } from "react-router-dom";
import {
  ArrowBtn,
  ArrowBtnWrapper,
  ArrowImgBack,
  ArrowImgForward,
  BackBtn,
  BackWrapper,
  BtnsContainer,
  Container,
  IconBtn,
  MainContent,
  TextBtn,
} from "../MultiStepsForm/MultiStepFormElements";
import RightPageContainer from "../../ui/RightPageContainer";
import MainButton from "../../ui/Button";
import Modal from "../../ui/Modal";
import Overlay from "../Overlay/Overlay";
import ModuleImportant from "./ModuleImportant";
import ArrowBack from "../../images/ArrowBack.svg";
import StepOneGroup from "./StepOneGroup";
import StepTwoGroup from "./StepTwoGroup/StepTwoGroup";
import StepTwoGroupNew from "./StepTwoGroupNew";
import { useCreateStGroupContext } from "../../context/StudentsGroup";

function GroupClasses() {
  const [step, setStep] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { formData, setFormData, handleSubmit, paymentUrl, linkReady } = useCreateStGroupContext();
  const allData = { ...formData };


  const handleDataChange = (data) => {
    setFormData({ ...formData, ...data });
  };

  useEffect(() => {
    const params = new URLSearchParams();
    params.append("paso", step + 1);
    navigate({ search: `?${params.toString()}` });
  }, [step, navigate]);

  const steps = [
    <StepOneGroup formData={formData} onDataChange={handleDataChange}/>,
    <StepTwoGroupNew formData={formData} onDataChange={handleDataChange}/>,
  ];

  const handleNextClick = (e) => {
    //   console.log(formData);
    e.preventDefault();
    if (step === 1) {
      handleSubmit(allData);
      setIsOpen(true);
    } else if (step === 0) {
      setIsOpenModal(true);
    } else {
      setStep((cur) => cur + 1);
    }
  };

  const handleContinue = () => {
    setStep(1);
    setIsOpenModal(false);
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    setStep((cur) => cur - 1);
  };

  const isNextDisabled = () => {
    if (step === 0) {
      return (
        formData.instrument_id === ''
        );
    } else if (step === 1) {
        return(
          formData.pricing_model === ''
        )
    }
    return false;
  };

  return (
    <>
      <BackWrapper>
        {step !== 0 && (
          <BackBtn onClick={handleBackClick}>
            <IconBtn src={ArrowBack} />
            <TextBtn>Atrás</TextBtn>
          </BackBtn>
        )}
      </BackWrapper>
      <RightPageContainer>
        <MainContent>
          <Container>{steps[step]}</Container>
          <BtnsContainer>
            <div style={{ width: "416px" }}>
              <MainButton onClick={handleNextClick} disabled={isNextDisabled()}>
                {step === 4 || step === 5 ? "Ir a pagar" : "Siguiente"}
              </MainButton>
            </div>
            <ArrowBtnWrapper>
              <ArrowBtn
                disabled={step === 0}
                onClick={(e) => {
                  e.preventDefault();
                  setStep((cur) => cur - 1);
                }}
              >
                <ArrowImgBack />
              </ArrowBtn>
              <ArrowBtn
                onClick={(e) => {
                  e.preventDefault();
                  setStep((cur) => cur + 1);
                }}
                disabled={step === 1}
              >
                <ArrowImgForward />
              </ArrowBtn>
            </ArrowBtnWrapper>
          </BtnsContainer>
          {isOpen && linkReady && (
            <Modal onClose={() => setIsOpen(false)}>
              <Overlay paymentUrl={paymentUrl}/>
            </Modal>
          )}
          {isOpenModal && (
            <Modal onClose={() => setIsOpenModal(false)}>
              <ModuleImportant handleContinue={handleContinue} />
            </Modal>
          )}
        </MainContent>
      </RightPageContainer>
    </>
  );
}

export default GroupClasses;
