import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useFormContext } from '../../../../context/CreateStudentContext';
import AdminFormButton from "../../../../ui/AdminFormButton";
import CreateUserContainer from '../../../../ui/CreateUserContainer';
import {
  Container,
  MainContent
} from "../../../MultiStepsForm/MultiStepFormElements";
import {
  BackArrow,
  BtnBack,
  BtnBackWrapper,
  BtnText,
  BtnsContainer
} from "../StudentForm/StudentFormElements";
import StepTwo from "./StepTwo";
import Modal from '../../../../ui/Modal';
import UserCreated from '../Overlay/UserCreated';
import { useEmailExistenceCheck } from '../../../../utils/useEmailExistenceCheck';

function AdminForm({defaultStep}) {
  const { paso } = useParams();
  const currentStep = paso || defaultStep;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { formData, setFormData, handleSubmit, successMsg, errorMsg } = useFormContext();
  const allData = { ...formData };
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const { emailExists, checkEmailExistence } = useEmailExistenceCheck();

  useEffect(() => {
    if (formData.email) {
      checkEmailExistence(formData.email);
    }
  }, [formData.email, checkEmailExistence]);

  let stepNumber = 1;

  if (currentStep === "paso-1") {
    stepNumber = 1;
  } 

  const userType = 'ADMIN'

  const validateData = () => {
    let errors = {};

    if (!formData.email) {
      errors.email = "Correo electrónico es requerido";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Formato de correo electrónico inválido";
    } else if (emailExists) {
      errors.email = emailExists;
    }
    if (!formData.first_name) {
      errors.first_name = "Nombre es requerido";
    }
    if (!formData.identification_number_type) {
      errors.identification_number_type = "Tipo de documento es requerido";
    }
    if (!formData.identification_number) {
      errors.identification_number = "Número de documento es requerido";
    }
    if (!formData.date_of_birth) {
      errors.date_of_birth = "Fecha de nacimiento es requerida";
    }
    if (!formData.country) {
      errors.country = "País es requerido";
    }
    if (!formData.phone_number) {
      errors.phone_number = "Teléfono de contacto es requerido";
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  function handleSubmitForm (e) {
    e.preventDefault();
    if(validateData()) {
      handleSubmit(allData,`${userType.toLocaleLowerCase()}`)
      setIsOpenModal(true);
    }
  }

  const handleBackButtonClick = (e) => {
    e.preventDefault();
    if (currentStep === "paso-1") {
      navigate("/crear-usuario/");
    } else {
      let previousStep = "paso-1";
      navigate(`/crear-usuario/admin/${previousStep}`);
    }
  };


  const handleDataChange = (data) => {
    // setFormData({ ...formData, ...data });
    const updatedFormData = { ...formData, ...data };
    const updatedErrors = { ...error };
    Object.keys(data).forEach((fieldName) => {
      if (updatedErrors[fieldName]) {
        delete updatedErrors[fieldName];
      }
    });
    setError(updatedErrors);
    setFormData(updatedFormData);
  };
  
  return (
    <CreateUserContainer>
          <div>
            <BtnBackWrapper>
              <BtnBack onClick={handleBackButtonClick}>
                <BackArrow />
              </BtnBack>
              <BtnText>
              Creación de usuario administrador - Paso <span>{stepNumber}</span>
              </BtnText>
            </BtnBackWrapper>
          </div>
        <MainContent>
            <Container style={{padding: '0rem',overflowY: 'auto', width: '100%'}}>
              {currentStep === "paso-1" && <StepTwo formData={formData} onDataChange={handleDataChange} error={error}/>}
            </Container>
          <BtnsContainer style={{ width: "100%", padding: '1rem 0rem 0rem 0rem', justifyContent: 'none', gap: '1.5rem'}}>
            <div style={{ width: "20.4375rem"}}>
            <AdminFormButton onClick={handleSubmitForm}>
               Crear usuario
            </AdminFormButton>
            </div>
          </BtnsContainer>
          {isOpenModal && (
          <Modal onClose={() => setIsOpenModal(false)}>
            <UserCreated formData={formData}  userType={userType} errorMsg={errorMsg} successMsg={successMsg} />
          </Modal>
        )}
        </MainContent>
    </CreateUserContainer>
  );
}

export default AdminForm