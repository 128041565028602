import React from 'react'
import styled from 'styled-components'
import logo from '../images/Logo.png'

export const NavLogo = styled.div`
    padding-top: 1.5rem ;
    padding-left: 1.5rem ;
     @media screen and (max-width: 768px) {
    padding: 0rem;
  }
     @media screen and (max-width: 480px) {
    padding: 0rem;
  }
`
export const Img = styled.img`
    width: 144px;
    @media screen and (max-width: 768px) {
    width: 6rem;
  }
     @media screen and (max-width: 480px) {
      width: 6rem;
  }
`

function Logo() {
  return (
    <NavLogo>
        <Img src={logo} />
    </NavLogo>
  )
}

export default Logo
