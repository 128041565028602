import React, {useState} from 'react'
import { RegSection, RegContainer,ImageWrapperProf, ImageDesktopProf,RegWrapperProf, HeadingWrapper,Logo, MobileLogo, RegTitle, Descr } from './RegProfElements';
import desktop from '../../../images/Desktop_Teacher.png'
import RegFormProf from './RegFormProf'
import Spinner from '../../../ui/Spinner';
import Success from './Success';


const RegistrationProf = () => {
  const [isLoading, setLoading] = useState(false)
  const [isSuccess, setSuccess] = useState(false);

  const handleSuccess = () => {
    setLoading(false);
    setSuccess(true);
  };

  return (
    <RegSection>
      <RegContainer>
        <ImageWrapperProf >
            <ImageDesktopProf  src={desktop} />
        </ImageWrapperProf>
        <RegWrapperProf>
            {isLoading ? <Spinner /> : isSuccess ? <Success /> :
            <>
          <HeadingWrapper>
            <Logo src='./logo_desktop.svg'/>
            <MobileLogo src='./logo_mobile.svg'/>
            <RegTitle>Quiero ser profesor</RegTitle>
            <Descr>Deja tus datos para aplicar y ser profesor de nuestra academia</Descr>
          </HeadingWrapper>
          <RegFormProf onSuccess={handleSuccess} />
            </>
            }
        </RegWrapperProf>
      </RegContainer>
     </RegSection>
  )
}

export default RegistrationProf
