import { ThemeProvider } from "@emotion/react";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import AdminButtonContainer from "../../../ui/AdminButtonContainer";
import AdminFormButton from "../../../ui/AdminFormButton";
import AdminPageContainer from "../../../ui/AdminPageContainer";
import Modal from "../../../ui/Modal";
import { theme } from "../../Styles/FormStyles";
import {
  Content,
  InputsWrapper,
  SearchWrapper,
  TableWrapper,
  Title,
} from "../Students/StudentsTablesElements";
import CreateRoom from "./CreateRoom";
import moment from "moment";
import "moment/locale/es";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import axios from "axios";
import Cancellation from "../../CalendarStudents/OverlayContent/Cancellation";
import Spinner from "../../../ui/Spinner";
import { CancelBtn } from "../CreateUser/StudentForm/StudentFormElements";
import RescheduleClass from "./RescheduleClass";
import EditCourse from "./EditCourse";

function SalonesTable() {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [minTime, setMinTime] = useState(null);
  const [maxTime, setMaxTime] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventsFromAPI, setEventsFromAPI] = useState([]);
  const [classroomOptions, setClassroomOptions] = useState([]);
  const [selectedClassroom, setSelectedClassroom] = useState(null);
  const [showDetails, setShowDetails] = useState(true);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showReschedule, setShowReschedule] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  moment.locale("es");
  const localizer = momentLocalizer(moment);

  const onSelectEventHandler = (event) => {
    setSelectedEvent(event);
  };

  const handleCancel = async () => {
    const token = localStorage.getItem("access_token");
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/lesson/${selectedEvent.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      setSuccessMessage("Clase Cancelada correctamente");
    } catch (error) {
      console.error("Error:", error);
      setError(error.response.data.detail);
    }
  };

  const fetchClassroomLessons = async (classroomId, startDate, endDate) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/classroom/${classroomId}?start_date=${startDate}&end_date=${endDate}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      const selectedClassroomLessons = response.data.lessons || [];

      return selectedClassroomLessons.map((lesson) => ({
        id: lesson.id,
        title: `Clase ${lesson.id}`,
        start: new Date(lesson.start_time),
        end: new Date(lesson.end_time),
        instrument_name: lesson.instrument_name,
        teacher_name: lesson.teacher_name,
        students: lesson.students,
        status: lesson.status,
        teacher_id: lesson.teacher_id,
        classroom_id: lesson.classroom_id,
        classroom_name: lesson.classroom_name,
        start_time: lesson.start_time,
        end_time: lesson.end_time,
        lesson_type: lesson.lesson_type,
        instrument_id: lesson.instrument_id,
        intensity: lesson.intensity,
        level_id: lesson.level_ids,
      }));
    } catch (error) {
      console.error(
        "Error fetching selected classroom availability from API:",
        error
      );
      return [];
    }
  };

  const handleCancelAndHideDetails = async () => {
    await handleCancel();
    setShowDetails(false);
    await refreshCalendarData(startDate, endDate);
  };

  const refreshCalendarData = async (startDate, endDate) => {
    try {
      const formattedEvents = await fetchClassroomLessons(
        selectedClassroom,
        startDate,
        endDate
      );
      setEventsFromAPI(formattedEvents);
    } catch (error) {
      console.error("Error refreshing calendar data:", error);
    }
  };

  useEffect(() => {
    const now = moment();
    const startOfDay = now.clone().startOf("day").hour(9);
    const endOfDay = now.clone().endOf("day").hour(21);

    setMinTime(startOfDay.toDate());
    setMaxTime(endOfDay.toDate());

    axios
      .get(`${process.env.REACT_APP_API_URL}/classroom/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        setClassroomOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching salon options from API:", error);
      });
  }, []);

  const onSelectClassroom = async (classroomId, newDate) => {
    setIsLoading(true);
    setSelectedClassroom(classroomId);

    const startOfWeek = moment(newDate).startOf("week").format("YYYY-MM-DD");
    const endOfWeek = moment(newDate).endOf("week").format("YYYY-MM-DD");

    setStartDate(startOfWeek);
    setEndDate(endOfWeek);

    const formattedEvents = await fetchClassroomLessons(
      classroomId,
      startOfWeek,
      endOfWeek
    );
    setEventsFromAPI(formattedEvents);
    setIsLoading(false);
  };

  const handleCalendarNavigate = async (newDate, view) => {
    console.log("handleCalendarNavigate called");
    let startDate, endDate;

    if (view === "week") {
      startDate = moment(newDate).startOf("isoWeek").format("YYYY-MM-DD");
      endDate = moment(newDate).endOf("isoWeek").format("YYYY-MM-DD");
    } else if (view === "month") {
      startDate = moment(newDate).startOf("month").format("YYYY-MM-DD");
      endDate = moment(newDate).endOf("month").format("YYYY-MM-DD");
    } else if (view === "day") {
      startDate = moment(newDate).format("YYYY-MM-DD");
      endDate = moment(newDate).format("YYYY-MM-DD");
    }

    console.log("startDate:", startDate);
    console.log("endDate:", endDate);

    try {
      const formattedEvents = await fetchClassroomLessons(
        selectedClassroom,
        startDate,
        endDate
      );
      setEventsFromAPI(formattedEvents);
      setStartDate(startDate);
      setEndDate(endDate);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const eventStyleGetter = function (event, start, end, isSelected) {
    var backgroundColor = event.status === "PENDING" ? "#FDEACA" : "#E8F6EE";
    var backgroundColor =
      event.status === "COMPLETED" ? "#CCCCCC" : backgroundColor;
    var backgroundColor =
      event.status === "CANCELLED" ? "#FDCAD1" : backgroundColor;
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "0px",
      opacity: 0.8,
      color: "black",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };

  function handleOpenEdit() {
    setIsOpenEdit(true);
  }

  const handleCloseModal = () => {
    setSelectedEvent(null);
    setShowDetails(true);
    setShowReschedule(false);
    setError("");
    setSuccessMessage("");
  };

  const handleShowReschedule = () => {
    setShowDetails(false);
    setShowReschedule(true);
  };

  const handleRescheduleSuccess = async () => {
    await refreshCalendarData(startDate, endDate);
  };

  const handleEditSuccess = async () => {
    await refreshCalendarData(startDate, endDate);
  };

  console.log("selectedEvent", selectedEvent);

  return (
    <>
      <AdminPageContainer>
        <Content>
          <SearchWrapper>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                marginBottom: "1.5rem",
              }}
            >
              <Title style={{ margin: "0rem" }}>Salones</Title>
              <AdminButtonContainer>
                <AdminFormButton
                  onClick={() => setIsOpenModal((show) => !show)}
                >
                  Crear nuevo salón
                </AdminFormButton>
              </AdminButtonContainer>
            </div>
            <InputsWrapper>
              <ThemeProvider theme={theme}>
                <FormControl
                  sx={{
                    "& label.Mui-focused": {
                      color: "#215E69",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#215E69",
                      },
                    },
                  }}
                >
                  <InputLabel id="doc-type">Sálon</InputLabel>
                  <Select
                    labelId="document"
                    id="type-class-select"
                    label="Sálon"
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    sx={{ borderRadius: "8px", width: "20.4rem" }}
                    onChange={(e) => onSelectClassroom(e.target.value)}
                    value={selectedClassroom || ""}
                  >
                    {classroomOptions.map((classroom) => (
                      <MenuItem key={classroom.id} value={classroom.id}>
                        {classroom.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ThemeProvider>
            </InputsWrapper>
          </SearchWrapper>
          <TableWrapper style={{ height: "67vh", overflow: "auto" }}>
            {isLoading ? (
              <Spinner />
            ) : (
              <Calendar
                localizer={localizer}
                defaultDate={new Date()}
                defaultView="week"
                events={eventsFromAPI}
                onSelectEvent={onSelectEventHandler}
                min={minTime}
                max={maxTime}
                formats={{
                  timeGutterFormat: (date, culture, localizer) =>
                    localizer.format(date, "HH:mm", culture),
                }}
                messages={{
                  today: "Hoy",
                  previous: "Anterior",
                  next: "Siguiente",
                  month: "Mes",
                  week: "Semana",
                  day: "Día",
                }}
                eventPropGetter={eventStyleGetter}
                onNavigate={(newDate, view) => {
                  handleCalendarNavigate(newDate, view);
                }}
              />
            )}
          </TableWrapper>
        </Content>
      </AdminPageContainer>
      {selectedEvent && (
        <Modal onClose={handleCloseModal} props="Detalles del evento">
          {showDetails ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  width: "20rem",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1.5rem",
                  marginBottom: "2rem",
                }}
              >
                <>
                  <button
                    style={{
                      padding: "0rem",
                      color: "#4FB1C3",
                      textAlign: "left",
                      fontSize: "0.75rem",
                      marginTop: "-0.5rem",
                    }}
                    onClick={handleOpenEdit}
                  >
                    Editar día y hora del curso
                  </button>
                </>
                <p>Inicio: {moment(selectedEvent.start).format("LLL")}</p>
                <p>Fin: {moment(selectedEvent.end).format("LLL")}</p>
                <p>
                  {selectedEvent.students.length > 1
                    ? "Clases grupales"
                    : "Clase personalizada"}
                </p>
                <p>Instrumento: {selectedEvent?.instrument_name}</p>
                <p>Profesor(a): {selectedEvent?.teacher_name}</p>
                <p>Estudiante(s):</p>
                <ul>
                  {selectedEvent.students.map((a) => (
                    <li>{`${a.first_name} ${a.last_name}, id: ${a.id}`}</li>
                  ))}
                </ul>
              </div>
              {selectedEvent.status !== "COMPLETED" && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "1.5rem",
                  }}
                >
                  <AdminButtonContainer>
                    <AdminFormButton
                      onClick={handleShowReschedule}
                      disabled={selectedEvent.students.length > 1}
                    >
                      Re-programar clase
                    </AdminFormButton>
                  </AdminButtonContainer>
                  {selectedEvent.status !== "CANCELLED" && (
                    <AdminButtonContainer>
                      <CancelBtn onClick={handleCancelAndHideDetails}>
                        Cancelar clase
                      </CancelBtn>
                    </AdminButtonContainer>
                  )}
                </div>
              )}
            </div>
          ) : (
            <Cancellation
              error={error}
              successMessage={successMessage}
              onClose={handleCloseModal}
              admin="Se notificará al estudiante  y el profesor de la cancelación"
            />
          )}
          {showReschedule && (
            <RescheduleClass
              classData={selectedEvent}
              onClose={handleCloseModal}
              onRefresh={handleRescheduleSuccess}
            />
          )}
        </Modal>
      )}
      {isOpenEdit && (
        <Modal
          onClose={() => {
            setIsOpenEdit(false);
            handleCloseModal();
          }}
        >
          <EditCourse infoData={selectedEvent} onRefresh={handleEditSuccess} />
        </Modal>
      )}
      {isOpenModal && (
        <Modal onClose={() => setIsOpenModal(false)} props="Crear salón">
          <CreateRoom />
        </Modal>
      )}
    </>
  );
}

export default SalonesTable;
