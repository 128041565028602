import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { Outlet } from "react-router-dom";

const CreateStGroupContext = createContext();

export const useCreateStGroupContext = () => {
  return useContext(CreateStGroupContext);
};

export const StudentGroupProvider = () => {
  const [formData, setFormData] = useState({
    instrument_id: "",
    student_id: localStorage.getItem("userId") ? parseInt(localStorage.getItem("userId")): 0,
    lessons_per_week: '1',
    lesson_type: "PRESENCIAL",
    pricing_model: "",
    discount: 0,
  });


  const [paymentUrl, setPaymentUrl] = useState("");
  const [linkReady, setLinkReady] = useState(false);

const handleSubmit = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/lesson/request_group_level`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    console.log("Form data submitted:", response.data);
    const { payment_link } = response.data; 
    setPaymentUrl(payment_link);
    setLinkReady(true);
  } catch (error) {
    console.error("Error submitting form data:", error);
  }

  };

  return (
    <CreateStGroupContext.Provider
    value={{ formData, setFormData, handleSubmit, paymentUrl, linkReady }}
    >
      <Outlet />
    </CreateStGroupContext.Provider>
  );
};