import React from "react";
import {
  MainContainer,
  MainWrapper,
  MainContent,
} from "../../MainSection/InactiveMainElements";
import {
  Title,
  BtnContainer,
  BtnWrapper,
  BtnLink,
  BtnStudent,
  BtnTeacher,
  BtnAdmin,
} from "./CreateUserElements";

function CreateUser() {

  return (
    <MainContainer>
      <MainWrapper style={{ padding: "2.5rem", width: "100%" }}>
        <MainContent
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "2.5rem",
            width: "100%",
          }}
        >
          <div>
            <Title>Crear usuarios</Title>
          </div>
          <BtnContainer>
            <BtnWrapper>
              <BtnLink to="/crear-usuario/estudiante/paso-1">
                <BtnStudent />
                Estudiante
              </BtnLink>
              <BtnLink to="/crear-usuario/profesor/paso-1">
                <BtnTeacher />
                Profesor
              </BtnLink>
              <BtnLink to="/crear-usuario/admin">
                <BtnAdmin />
                Administrativo
              </BtnLink>
            </BtnWrapper>
          </BtnContainer>
        </MainContent>
      </MainWrapper>
    </MainContainer>
  );
}

export default CreateUser;
