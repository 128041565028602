import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { formBtn,theme } from "../components/Styles/FormStyles";

function AdminFormButton({ children, onClick, disabled }) {
  return (
    <>
    <ThemeProvider theme={theme}>
      <Button
        fullWidth
        disableElevation
        disabled={disabled}
        type="submit"
        variant="contained"
        sx={formBtn}
        onClick={onClick}
      >
        {children}
      </Button>
    </ThemeProvider>
    </>
  );
}

export default AdminFormButton;
