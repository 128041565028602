import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #fff;
  /* width: 64rem; */
  height: auto;
  border-radius: 0.5rem;
  z-index: 999;
`;
export const UpperWrapper = styled.div`
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
`;
export const Heading = styled.h1`
  font-size: 1.25rem;
  font-weight: 500;
`;
export const Title = styled.h1`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
`;
export const CloseBtn = styled.button`
  background-color: transparent;
  padding: 0rem;
`;
export const BtnImg = styled.img``;

export const MainWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  /* padding: 1rem 2rem; */
  flex-direction: column;
  max-height: 550px;
  overflow-y: auto;
  z-index: 999;
`;
export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* z-index: 999; */
`;

export const SubmitBtn = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem 2rem 0rem 2rem;
`;

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 2rem;
`;

export const InfoTitle = styled.h2`
  font-size: 1rem;
  font-weight: 500;
`;
export const InfoItem = styled.p`
  font-size: 1rem;
  font-weight: 400;
`;
export const ExpInfo = styled.p`
  color: #ea5a47;
  font-size: 1rem;
  font-weight: 400;
`;
