import React from "react";
import styled from "styled-components";

export const MainContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  background: #f6f8fe;

  @media screen and (max-width: 480px) {
    background: #fff;
    margin-top: 10px;
    height: 90vh;
    padding: 0px;
  }
  @media screen and (max-width: 768px) {
    background: #fff;
    /* margin-top: 10px; */
    /* height: 90vh; */
    padding: 0px;
  }
`;
const MainWrapper = styled.div`
  width: 100%;
  padding: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 8px;
  margin: 1.5rem 2rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin: 0;
    padding: 0px;
    padding-top: 16px;
    height: 100%;
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    margin: 0;
    padding: 0px;
    padding-top: 16px;
    /* min-height: 678px;
    height: 100%; */
    /* height: 80vh; */
  }
`;

function PageContainer({children}) {
  return (
    <MainContainer>
      <MainWrapper>
        {children}
      </MainWrapper>
    </MainContainer>
  );
}

export default PageContainer;
