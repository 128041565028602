import styled from "styled-components";

export const Content = styled.div`
  width: 100%;
`;
export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
`;
export const SearchWrapper = styled.div`
  margin-bottom: 1.5rem;
`;
export const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;
`;
export const EditBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const EditBtn = styled.button`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  border: none;
  background: transparent;
  font-size: 0.75rem;
  font-weight: 700;
`;
export const EditImg = styled.img``;

export const InputsWrapper = styled.div`
    display: flex;
    gap: 1rem;
`;
