import React from "react";
import TextInputField from "../../ui/TextInputField";
import { ThemeProvider } from "@mui/material";
import { theme } from "../Styles/FormStyles";
import { Heading } from "../EditProfile/EditProfileElements";

function Step2({ formData, onDataChange, error }) {

  const handleChange = (e, fieldName) => {
    let updatedFormData = { ...formData };
  
     if (e.target) {
      updatedFormData[fieldName] = e.target.value;
    } else {
      updatedFormData[fieldName] = e;
    }
  
    onDataChange(updatedFormData);
  };

  return (
    <div style={{height: '50vh', }}>
      <div>
        <ThemeProvider theme={theme}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              marginBottom: "1.5rem",
            }}
          >
            <TextInputField
              label="Dirección de residencia"
              onChange={(e) => handleChange(e, "address")}
              value={formData.address}
              name='address'
              error={error.address !== undefined}
              helperText={error.address}
            />

            <TextInputField
              label="Teléfono de contacto"
              onChange={(e) => handleChange(e, "phone_number")}
              value={formData.phone_number}
              name='phone_number'
              error={error.phone_number !== undefined}
              helperText={error.phone_number}
            />
            <Heading style={{marginTop: '1.5rem'}}>Contacto de emergencia</Heading>

            <TextInputField
              label="Nombre completo"
              onChange={(e) => handleChange(e, "emergency_contact_name")}
              value={formData.emergency_contact_name}
              name='emergency_contact_name'
              error={error.emergency_contact_name !== undefined}
              helperText={error.emergency_contact_name}
            />

            <TextInputField
              label="Número de contacto"
              onChange={(e) => handleChange(e, "emergency_contact_phone")}
              value={formData.emergency_contact_phone}
              name='emergency_contact_phone'
              error={error.emergency_contact_phone !== undefined}
              helperText={error.emergency_contact_phone}
            />
          </div>
        </ThemeProvider>
      </div>
    </div>
  );
}

export default Step2;
