import React from "react";
import {
  BtnWrapperCancel,
  Icon,
  InfoTextContainer,
  InfoWrapper,
  Title,
  Wrapper,
} from "../../Overlay/OverlayElements";
import info from "../../../images/Info.svg";
import errorImg from "../../../images/Exclamation_circle.svg";
import styled from "styled-components";
import AdminFormButton from "../../../ui/AdminFormButton";
import AdminButtonContainer from "../../../ui/AdminButtonContainer";
import { CancelBtn } from "../CreateUser/StudentForm/StudentFormElements";

const UserInfo = styled.div`
  font-size: 1rem;
  font-weight: 400;
  color: #757575;
  text-align: center;
  width: 60vh;
`;

function CreationConfirmation({ onClick, onCancel }) {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "45vh",
        }}>
        <InfoTextContainer>
          <Icon src={info} />
          <Title>Confirmación de venta de clase grupal</Title>
          <UserInfo>
            ¿Estas seguro de vender esta clase grupal? Recuerda que esto detona una
            solicitud que deberá atender la academia para crear la clase grupal a demanda.<br /><br />
            Cerciorate de haber recibido el dinero antes de continuar.
          </UserInfo>
        </InfoTextContainer>
        <div style={{ width: "80%", height: "100%" }}>
          <BtnWrapperCancel>
            <AdminButtonContainer>
              <AdminFormButton onClick={onClick}>
                Confirmar venta
              </AdminFormButton>
            </AdminButtonContainer>
            <CancelBtn onClick={onCancel}>Volver</CancelBtn>
          </BtnWrapperCancel>
        </div>
      </div>
    </>
  );
}

export default CreationConfirmation;
