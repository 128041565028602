import React, { useEffect, useState } from "react";
import { Steps, StepTitle } from "../../StepOne/StepOneElements";
import {
  Day,
  AdditionalInfo,
  ClassStarts,
  DateTime,
} from "../StepFive/StepFiveElements";
import {
  PaymentWrapper,
  PaymentContainer,
  PaymentInfo,
  ClassInfo,
  ClassName,
  ProfessorName,
  TimeInfo,
  CyclePrice,
  Price,
  Span1,
  SpanGreen,
  AdditionalPrice,
  Total,
  TotalPrice,
} from "./StepSixElements";
import SelectComponent from "../../../../ui/Select";
import axios from "axios";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 60vh; 
  overflow: auto;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
  }
`
const SelectWrapper = styled.div`
  padding-top: 0.2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  @media screen and (max-width: 768px) {
    padding-top: 5rem;
  }
`

const StepSix = ({ formData, onDataChange }) => {
  const [prices, setPrices] = useState({});

  useEffect(() => {
    const id = localStorage.getItem("userId");
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/lesson/pricing?pricing_model=${formData.pricing_model}&lessons_per_week=${formData.lessons_per_week}&lesson_type=${formData.lesson_type}&user_id=${id}&is_groupal=false&is_dance=false&discount=0`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Prices", response.data);
        setPrices(response.data);
      })
      .catch((error) => {
        console.error("Error fetching salon options from API:", error);
      });
  }, [formData.lesson_type, formData.lessons_per_week, formData.pricing_model]);

  useEffect(() => {
  }, [prices]);

  // const formatDate = (dateString) => {
  //   const options = { day: "numeric", month: "long", year: "numeric" };
  //   const date = new Date(dateString);
  //   return date.toLocaleDateString("es-ES", options);
  // };

  let closestStartDate = formData.lessons;

  if (formData.lessons.length > 1) {
    closestStartDate = formData.lessons.reduce((closest, current) => {
      return Math.abs(new Date(current.start_date) - new Date()) <
        Math.abs(new Date(closest.start_date) - new Date())
        ? current
        : closest;
    });
  } else if (formData.lessons.length === 1) {
    closestStartDate = formData.lessons[0];
  }

  const lessonInfo = formData.lessons.map((lessonItem, index) => {
    const startTimeParts = lessonItem.start_time.split(":");
    const startTime = startTimeParts[0] + ":" + startTimeParts[1];
    return (
      <>
        <Day key={index}>
          {lessonItem.day.charAt(0).toUpperCase() +
            lessonItem.day.slice(1).toLowerCase()}{" "}
          de cada semana - {startTime}
        </Day>
      </>
    );
  });

  // const formattedStartDate = formatDate(closestStartDate.start_date);

  let volumeOptions = [];
  if (formData.lessons_per_week === "1") {
    volumeOptions = [
      { label: "1 semestre (20 clases)", value: "semestre" },
      { label: "2 trimestres (24 clases)", value: "trimestre" },
    ];
  } else if (formData.lessons_per_week === "2") {
    volumeOptions = [
      { label: "1 semestre (40 clases)", value: "semestre" },
      { label: "2 trimestres (48 clases)", value: "trimestre" },
    ];
  }

  const paymentOptions =
    formData.volume === "semestre"
      ? [
          { label: "Contado", value: "SEMESTRAL" },
          { label: "5 cuotas", value: "CICLO" },
        ]
      : [{ label: "2 cuotas", value: "TRIMESTRAL" }];

  const handlePaymentTypeChange = (fieldName) => (event) => {
    const value = event.target.value;
    let updatedFormData = { ...formData, [fieldName]: value };

    onDataChange(updatedFormData);
    console.log(updatedFormData);
  };


  
  const amountValue = prices.total_value / 100;
  const amountTuition = prices.tuition / 100;
  const amountDiscount = prices.payment_discount / 100;
  const amountTotal = prices.payment / 100;

  const formatAmount = (amount) => {
    if (amount) {
      return (amount / 1000).toLocaleString('en-US', {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      });
    } else {
      return ''; 
    }
  };

  const totalValue = formatAmount(amountValue);
  const tuitionValue = formatAmount(amountTuition);
  const paymentDiscount = formatAmount(amountDiscount);
  const total = formatAmount(amountTotal);
    
  return (
    <>
      <Steps>Paso 5 de 5</Steps>
      <StepTitle>Detalle de compra</StepTitle>
      <Wrapper>
        <SelectWrapper 
          
        >
          <SelectComponent
            label="Volumen"
            menuItems={volumeOptions}
            onChange={handlePaymentTypeChange("volume")}
            value={formData.volume}
            name="volume"
          />
          <SelectComponent
            label="Modalidad de pago"
            menuItems={paymentOptions}
            value={formData.pricing_model}
            onChange={handlePaymentTypeChange("pricing_model")}
          />
        </SelectWrapper>
        <PaymentWrapper>
          <PaymentContainer>
            <PaymentInfo style={{ borderRadius: "0.5rem" }}>
              <ClassInfo>
                <ClassName>
                  Clases de{" "}
                  {formData.instrument_name.charAt(0).toUpperCase() +
                    formData.instrument_name.slice(1)}
                </ClassName>
                <ProfessorName>
                  Profesor(a) {formData.teacher_name}
                </ProfessorName>
              </ClassInfo>
              <TimeInfo>
                <Day>Intensidad {formData.intensityName}</Day>
                <Day>Clases Personalizada</Day>
                <Day>Modalidad de clases {formData.lesson_type}</Day>
                {lessonInfo}
                <AdditionalInfo>Horarios Fijos</AdditionalInfo>
                <ClassStarts>
                  Comienzo de clases<br></br>
                  {closestStartDate && (
                    <DateTime>{closestStartDate.start_date}</DateTime>
                  )}
                </ClassStarts>
              </TimeInfo>
              <Day style={{ marginBottom: "2rem" }}>
                {formData.pricing_model === "SEMESTRAL"
                  ? "Único pago"
                  : formData.pricing_model === "CICLO"
                  ? "Pagas cada 28 días"
                  : "Pagas 2 cuotas"}
              </Day>
              <div>
                <Day style={{ marginBottom: "1rem" }}>
                  {formData.pricing_model === "CICLO"
                    ? "Pago primera cuota (1/5)"
                    : formData.pricing_model === "TRIMESTRAL"
                    ? "Pago primera cuota (1/2)"
                    : ""}
                </Day>
                <CyclePrice>
                  <Span1>Valor del clases</Span1>
                  <Price>$ {totalValue} COP</Price>
                </CyclePrice>
                <CyclePrice>
                  <Span1>Valor del descuento</Span1>
                  <Price>-$ {paymentDiscount} COP</Price>
                </CyclePrice>
                {formData.tuition > 0 ? (
                  <AdditionalPrice>
                    <Span1>Valor matrícula (única vez)</Span1>
                    <Price>$ {tuitionValue} COP</Price>
                  </AdditionalPrice>
                ) : (
                  <AdditionalPrice></AdditionalPrice>
                )}
                <Total>
                  <SpanGreen>TOTAL A PAGAR</SpanGreen>
                  <TotalPrice>$ {total} COP</TotalPrice>
                </Total>
              </div>
            </PaymentInfo>
          </PaymentContainer>
        </PaymentWrapper>
      </Wrapper>
    </>
  );
};

export default StepSix;
