import { Button, ThemeProvider } from '@mui/material'
import React from 'react'
import { deleteBtn, theme } from '../components/Styles/FormStyles'

function DeleteButton({ children, onClick }) {
  return (
    <ThemeProvider theme={theme}>
    <Button
      fullWidth
      disableElevation
      type="submit"
      variant="contained"
      sx={deleteBtn}
      onClick={onClick}
    >
      {children}
    </Button>

  </ThemeProvider>
  )
}

export default DeleteButton
