import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import React, { useEffect, useState } from "react";
import AdminButtonContainer from "../../../ui/AdminButtonContainer";
import AdminFormButton from "../../../ui/AdminFormButton";
import { textFieldStyle, theme } from "../../Styles/FormStyles";
import dayjs from "dayjs";
import {
  FormContainer,
  MainWrapper,
  SubmitBtn,
  Title,
  Wrapper,
} from "../Students/OverlayElements";
// import Checkmarks from "../../../ui/Checkmarks";
import SelectComponent from "../../../ui/Select";
import { updateUser, getUserData } from "../../../utils/tools";
import axios from "axios";
import { Checkbox, ListItemText, OutlinedInput } from "@mui/material";

function Overlay({ userId }) {
  const [userData, setUserData] = useState({
    id: "",
    email: "",
    first_name: "",
    last_name: "",
    identification_number_type: "",
    identification_number: "",
    date_of_birth: null,
    phone_number: "",
    user_type: "",
    status: "",
    can_teach_at_home: false,
    emergency_contact_name: "",
    emergency_contact_phone: "",
    billing_mail: "",
    acquisition_channel: "",
    strikes: 0,
    address: "",
    instruments: [
      {
        id: 0,
        created_time: null,
        modified_time: null,
        name: "",
        art_form: "",
        can_teach_at_home: false,
      },
    ],
  });
  const [menuItems, setMenuItems] = useState([]);
  const [interactedFields, setInteractedFields] = useState({});

  const type_doc = [
    {
      label: "Cedula de ciudadania",
      value: "CEDULA",
    },
    {
      label: "Tarjeta de identidad",
      value: "TARJETA DE IDENTIDAD",
    },
    {
      label: "Registro Civil",
      value: "REGISTRO CIVIL",
    },
    {
      label: "Cedula de extranjeria",
      value: "CEDULA DE EXTRANJERIA",
    },
    {
      label: "Pasaporte",
      value: "PASAPORTE",
    },
    {
      label: "Permiso especial",
      value: "PERMISO ESPECIAL",
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getUserData(userId);
        setUserData(result);
        console.log("Result:", result);
      } catch (error) {
        console.error("Error:", error.message);
      }
    };
    fetchData();
  }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/instrument/`
        );
        const resultData = response.data;
        const modifiedData = resultData.map((item) => ({
          id: item.id,
          created_time: item.created_time,
          modified_time: item.modified_time,
          name: item.name,
          art_form: item.art_form,
          can_teach_at_home: item.can_teach_at_home,
        }));
        console.log('Instruments:', modifiedData)
        setMenuItems(modifiedData);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, []);

  const hasInteracted = Object.values(interactedFields).some(Boolean);

  const handleSaveChanges = async () => {
    if (hasInteracted) {
    try {
      const userDataWithoutInstruments = { ...userData };
      delete userDataWithoutInstruments.instruments;
      // await updateUser({ userId, userData});
      await updateUser({ userId, userData: userDataWithoutInstruments });
      console.log('Submitted Data:', userData)
      console.log("Changes saved successfully!");
    } catch (error) {
      console.error("Error saving changes:", error.message);
    }
  } else {
    console.log("No changes to save.");
  }
  };

  const handleChange = (e, fieldName) => {
    setInteractedFields((prevInteractedFields) => ({
      ...prevInteractedFields,
      [fieldName]: true,
    }));

    setUserData((prevUserData) => {
      let updatedUserData = { ...prevUserData };

      if (fieldName === "full_name") {
        const names = e.target.value.split(" ");
        updatedUserData = {
          ...updatedUserData,
          first_name: names[0] || "",
          last_name: names.slice(1).join(" ") || "",
        };
      } else if (fieldName === "instruments") {
        // const selectedInstrumentIds = e.target.value;
        // const updatedInstruments = menuItems.filter(item =>
        //   selectedInstrumentIds.includes(item.id)
        // );
        // updatedUserData = {
        //   ...updatedUserData,
        //   instruments: updatedInstruments,
        // };
        const selectedInstrumentIds = e.target.value;
        const updatedInstruments = menuItems.filter(item =>
          selectedInstrumentIds.includes(item.id)
        );
        updatedUserData = {
          ...updatedUserData,
          instruments: updatedInstruments,
          instrument_ids: updatedInstruments.map(item => item.id), // Update instrument_ids
        };
        // console.log('Updated instruments', updatedInstruments)
      } else {
        if (e.target) {
          updatedUserData[fieldName] = e.target.value;
        } else {
          updatedUserData[fieldName] = e;
        }
      }

      return updatedUserData;
    });
  };

  console.log('Data:',userData)

  return (
    <>
      <Wrapper>
        <MainWrapper>
          <Title>Datos Básicos</Title>
          <FormContainer>
            <ThemeProvider theme={theme}>
              <TextField
                id="outlined-basic"
                label="Nombre completo"
                variant="outlined"
                onChange={(e) => handleChange(e, "full_name")}
                value={`${userData.first_name || ""} ${
                  userData.last_name || ""
                }`}
                sx={textFieldStyle}
              />
              <div>
                <SelectComponent
                  label="Tipo de documento"
                  menuItems={type_doc}
                  onChange={(e) =>
                    handleChange(e, "identification_number_type")
                  }
                  value={userData.identification_number_type}
                  name="identification_number_type"
                />
              </div>

              <TextField
                id="outlined-basic"
                label="Número de documento"
                variant="outlined"
                onChange={(e) => handleChange(e, "identification_number")}
                value={userData.identification_number}
                name="identification_number"
                sx={textFieldStyle}
              />
              <TextField
                id="outlined-basic"
                label="Correo electrónico "
                variant="outlined"
                onChange={(e) => handleChange(e, "email")}
                value={userData.email || ""}
                name="email"
                sx={textFieldStyle}
              />
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DateField"]}>
                    <DateField
                      label="Fecha de nacimiento"
                      onChange={(e) => handleChange(e, "date_of_birth")}
                      value={dayjs(userData.date_of_birth)}
                      name="date_of_birth"
                      sx={{
                        [theme.breakpoints.down("sm")]: {
                          width: "327px",
                        },
                        [theme.breakpoints.up("sm")]: {
                          width: "416px",
                        },
                        "& label.Mui-focused": {
                          color: "#215E69",
                        },
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "8px",
                          "&.Mui-focused fieldset": {
                            borderColor: "#215E69",
                          },
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>

              <Title style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}>
                Información de contacto
              </Title>

              <TextField
                id="outlined-basic"
                label="Dirección de residencia"
                variant="outlined"
                onChange={(e) => handleChange(e, "address")}
                value={userData.address}
                name="address"
                sx={textFieldStyle}
              />

              <TextField
                id="outlined-basic"
                label="Número de contacto"
                variant="outlined"
                onChange={(e) => handleChange(e, "phone_number")}
                value={userData.phone_number}
                name="phone_number"
                sx={textFieldStyle}
              />
              <Title style={{ marginBottom: "0.5rem", marginTop: "0.5rem" }}>
                Clase a dictar
              </Title>

              {/* <Checkmarks
                onChange={(e) => handleChange(e, "instruments")}
                value={userData.instruments}
                name="instruments"
              /> */}

              <ThemeProvider theme={theme}>
                <FormControl
                  sx={{
                    "& label.Mui-focused": {
                      color: "#215E69",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#215E69",
                      },
                    },
                  }}
                >
                  <InputLabel>Clase a dictar</InputLabel>
                  <Select
                    IconComponent={KeyboardArrowDownOutlinedIcon}
                    multiple
                    value={userData.instruments.map((item) => item.id)}
                    onChange={(e) => handleChange(e, "instruments")}
                    input={<OutlinedInput label="Clase a dictar" />}
                    renderValue={(selected) => {
                      const selectedNames = selected.map(
                        (id) =>
                          menuItems.find((item) => item.id === id)?.name || ""
                      );
                      return selectedNames.join(", ");
                    }}
                    sx={{
                      borderRadius: "8px",
                      [theme.breakpoints.down("sm")]: {
                        width: "327px",
                      },
                      [theme.breakpoints.up("sm")]: {
                        width: "416px",
                      },
                    }}
                  >
                    {menuItems.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        <Checkbox
                          checked={userData.instruments.some(
                            (i) => i.id === item.id
                          )}
                        />
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ThemeProvider>
              <div style={{ marginBottom: "1rem" }}>
                <SelectComponent
                  onChange={(e) => handleChange(e, "can_teach_at_home")}
                  value={userData.can_teach_at_home}
                  name="can_teach_at_home"
                  label="¿Deseas dictar clases a domicilio?"
                  menuItems={[
                    { label: "Sí", value: true },
                    { label: "No", value: false },
                  ]}
                />
              </div>
            </ThemeProvider>
          </FormContainer>
        </MainWrapper>
        <SubmitBtn>
          <AdminButtonContainer>
            <AdminFormButton onClick={handleSaveChanges} disabled={!hasInteracted}>
              Guardar cambios
            </AdminFormButton>
          </AdminButtonContainer>
        </SubmitBtn>
      </Wrapper>
    </>
  );
}

export default Overlay;
