import React from "react";
import {
  HeadingWrapper,
  ImageDesktop,
  ImageWrapper,
  LoginContainer,
  LoginSection,
  LoginWrapper,
  Logo,
  MobileLogo,
} from "../login/LoginElements";
import desktop from "../../images/Desktop_Login.png";


function RecoveryContainer({ children }) {
  return (
    <LoginSection>
      <LoginContainer>
        <ImageWrapper>
          <ImageDesktop src={desktop} />
        </ImageWrapper>
        <LoginWrapper style={{ height: "100vh" }}>
          <HeadingWrapper>
            <Logo src="./logo_desktop.svg" />
            <MobileLogo src="./logo_mobile.svg" />
          </HeadingWrapper>
          {children}
        </LoginWrapper>
      </LoginContainer>
    </LoginSection>
  );
}

export default RecoveryContainer;
