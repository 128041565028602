import styled from "styled-components";

export const MainContainer = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    background: #F6F8FE;

    @media  screen and (max-width: 480px) {
       background: #fff;
       margin-top: 10px;
       height: 90vh;
       padding: 0px;
    }
`
export const MainWrapper = styled.div`
    width: 100%;
    padding: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-radius: 8px;
    margin: 1.5rem 2rem;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: flex-start;
        margin: 0;
        padding: 0px;
        padding-top: 16px;
        height: 100%;
    }
    @media screen and (max-width: 480px) {
        flex-direction: column;
         justify-content: flex-start;
        margin: 0;
        padding: 0px;
        padding-top: 16px;
        min-height: 678px;
        height: 100%;
    }
`
export const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @media screen and (max-width: 480px) {
        gap: 24px;
    }
`
export const UpperWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    @media  screen and (max-width: 480px) {
       flex-direction: column;
       justify-content: center;
       align-items: center;
       
    }
`
export const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    @media  screen and (max-width: 480px) {
       justify-content: center;
       align-items: center;
    }
`
export const UserName = styled.h1`
    font-size: 24px;
    font-weight: 500;
    color: #000;
    text-transform: capitalize;
`
export const UserStatus = styled.span`
    background: ${props => props.color === 'green' ? '#41A46D' : '#F3F3F3'};
    border-radius: 12px;
    padding: 4px 16px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.color === 'green' ? '#FFF' : '#818586'};
`
export const CoursesInfo = styled.div`
    display: flex;
    gap: 2rem;
    @media  screen and (max-width: 480px) {
       width: 304px;
       
    }
`
export const CoursesItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: 'Inter', sans-serif;
`
export const CoursesNum = styled.h1`
    font-size: 32px;
    font-weight: 500;
    text-align: center;
`
export const CoursesDesc = styled.p`
    max-width: 80px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
`

export const BottomWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 0.5rem; */
    /* overflow-y: auto; 
    overflow-x: hidden; */

    @media  screen and (max-width: 480px) {
       /* width: 340px; */
      
    }
`
export const BottomContainer = styled.div`
    /* height: 23.8rem; */
    @media  screen and (max-width: 480px) {
       /* height: 50vh; */
      
    }
`

export const CardsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    max-height: 360px;
    overflow-y: auto;
    padding: 1rem;
    /* margin-bottom: 2rem; */

    @media  screen and (max-width: 768px) {
       /* width: 30rem; */
       max-height: 460px;
      
    }
    @media  screen and (max-width: 480px) {
       /* width: 340px; */
       max-height: 460px;
      
    }
`
export const ClassTitle = styled.h1`
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0.5rem;
`


