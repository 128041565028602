import React from 'react'
import { Wrapper, Title, Description, ConfirmBtn, Icon, InfoWrapper,InfoTextContainer, BtnWrapper} from '../Overlay/OverlayElements'
import confirm from '../../images/Confirm.svg'
import { useNavigate } from 'react-router-dom';

function ChangedSuccessfully() {
  const navigate = useNavigate()
 function handleClick(){
  navigate('/')

 }
  return (
    <Wrapper style={{width: '100%'}}>
        <InfoWrapper>
            <InfoTextContainer>
              <Icon src={confirm}/>
              <Title>Contraseña cambiada correctamente</Title>
              <Description>Tu contraseña cambió, ahora deberás acceder con esta nueva credencial</Description>
            </InfoTextContainer>
        </InfoWrapper>
        <BtnWrapper style={{height: '0'}}>
            <ConfirmBtn onClick={handleClick}>Continuar</ConfirmBtn>
        </BtnWrapper>
      </Wrapper>
  )
}

export default ChangedSuccessfully
