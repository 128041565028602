import axios from "axios";

export function getUserData(id) {
  const token = localStorage.getItem("access_token");
  return axios
    .get(`${process.env.REACT_APP_API_URL}/user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error:", error.message);
      throw error;
    });
}

export const updateUser = ({userId, userData}) => {
  const token = localStorage.getItem("access_token");
  // const id = localStorage.getItem("userId");
  return axios
    .put(`${process.env.REACT_APP_API_URL}/user/${userId}`,userData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((response) => {
      console.log("User data updated successfully!");
      console.log(response.data);
    })
    .catch((error) => {
      console.error("Error updating user data:", error.message);
      throw new Error(`Error updating user data: ${error.message}`);
    });
};


export function deleteUser(userId) {
  const token = localStorage.getItem("access_token");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}/user/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "*/*",
      },
    })
    .then(() => {
      console.log('User was deleted!')
    })
    .catch((error) => {
      console.error("Error:", error);
      throw error;
    });
}


export const acceptUser = (userId) => {
  const token = localStorage.getItem("access_token");
  
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  return axios
    .put(`${process.env.REACT_APP_API_URL}/user/validate/${userId}`, {}, { headers })
    .then((response) => {
      console.log("User data updated successfully!");
      console.log(response.data);
      return response.data;
    })
    .catch((error) => {
      console.error("Error updating user data:", error.message);
      throw new Error(`Error updating user data: ${error.message}`);
    });
};