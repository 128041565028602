import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from 'react';
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { theme } from '../components/Styles/FormStyles';
import { ThemeProvider, FormHelperText } from '@mui/material';
import axios from 'axios';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT *4 + ITEM_PADDING_TOP,
    },
  },
};

function Checkmarks({onChange, value, error, helperText}) {
  const [data, setData] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/instrument/`,
          );
          const resultData = response.data
          const modifiedData = resultData.map(item => ({
            id: item.id,
            name: item.name,
          }));
          setData(modifiedData);
          // setData(resultData.map(item => item.name));
          setSelectedValues(Array.isArray(value) ? value : []);
        } catch (error) {
          console.error("Error:", error);
        }
      };
      fetchData();
      // setSelectedValues(value || []);
      // setSelectedValues(Array.isArray(value) ? value : []);
    }, [value]);

  const handleChange = (event) => {
    const selectedNames = event.target.value; 
    setSelectedValues(selectedNames);
  
    const selectedIds = data
      .filter((item) => selectedNames.includes(item.id))
      .map((item) => item.id);
  
    onChange(selectedIds);
  };
    

  return (
    <ThemeProvider theme={theme}>
      <FormControl  sx={{
              "& label.Mui-focused": {
                color: "#215E69",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#215E69",
                },
              },
            }}
            error={error}>
        <InputLabel>Clase a dictar</InputLabel>
        <Select
          IconComponent={KeyboardArrowDownOutlinedIcon}
          multiple
          value={selectedValues}
          onChange={handleChange}
          input={<OutlinedInput label="Clase a dictar" />}
          // renderValue={(selected) => selected.join(', ')}
          renderValue={(selected) => {
            // const selectedNames = selected.map((id) =>
            //   data.find((item) => item.id === id)?.name || ""
            // );
            // return selectedNames.join(", ");
            const selectedArray = Array.isArray(selected) ? selected : [selected];

            const selectedNames = selectedArray.map((id) =>
              data.find((item) => item.id === id)?.name || ""
            );
            
            return selectedNames.join(", ");
          }}
          sx={{
            borderRadius: "8px",
            [theme.breakpoints.down("sm")]: {
              width: "327px",
            },
            [theme.breakpoints.up("sm")]: {
              width: "416px",
            },
          }}
          MenuProps={MenuProps}
        >
          {data.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              <Checkbox checked={selectedValues.indexOf(item.id) > -1} />
              <ListItemText primary={item.name} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText sx={{color: error ? theme.palette.error.main : 'inherit'}}>{helperText}</FormHelperText>
      </FormControl>
    </ThemeProvider>
  )
}

export default Checkmarks
