import React, { useState } from 'react'
// import { FormContainer } from '../login/LoginElements';
import { ThemeProvider } from '@mui/material';
import { theme } from '../Styles/FormStyles';
import TextInputField from '../../ui/TextInputField';
import SelectComponent from '../../ui/Select';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';

function Step1({ formData, onDataChange,error }) {
  const [fullName, setFullName] = useState("");
  const type_doc = [
    {
      // id: 1,
      label: "Cedula de ciudadania",
      value: "CEDULA",
    },
    {
      // id: 2,
      label: "Tarjeta de identidad",
      value: "TARJETA DE IDENTIDAD",
    },
    {
      // id: 3,
      label: "Registro Civil",
      value: "REGISTRO CIVIL",
    },
    {
      // id: 4,
      label: "Cedula de extranjeria",
      value: "CEDULA DE EXTRANJERIA",
    },
    {
      // id: 5,
      label: "Pasaporte",
      value: "PASAPORTE",
    },
    {
      // id: 5,
      label: "Permiso especial",
      value: "PERMISO ESPECIAL",
    },
  ];

  const handleChange = (e, fieldName) => {
    let updatedFormData = { ...formData };
    if (fieldName === "full_name") {
      setFullName(e.target.value);

      const names = e.target.value.split(" ");
      updatedFormData = {
        ...updatedFormData,
        first_name: names[0] || "",
        last_name: names.slice(1).join(" ") || "",
      };
    } else {
      if (e.target) {
        updatedFormData[fieldName] = e.target.value;
      } else {
        updatedFormData[fieldName] = e;
      }
    }

    onDataChange(updatedFormData);
  };

  return (
    <div style={{height: '50vh'}}>
      <div
      >
        <ThemeProvider theme={theme}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.5rem", marginBottom: '1.5rem' }}
          >
            <TextInputField
              label="Nombre completo"
              onChange={(e) => handleChange(e, "full_name")}
              value={fullName}
              name="full_name"
              error={error.first_name !== undefined}
              helperText={error.first_name}
            />

            <SelectComponent
              label="Tipo de documento"
              menuItems={type_doc}
              onChange={(e) => handleChange(e, "identification_number_type")}
              value={formData.identification_number_type}
              name="identification_number_type"
              error={error.identification_number_type !== undefined}
              helperText={error.identification_number_type}
            />

            <TextInputField
              label="Número de documento"
              onChange={(e) => handleChange(e, "identification_number")}
              value={formData.identification_number}
              name="identification_number"
              error={error.identification_number !== undefined} 
              helperText={error.identification_number}
            />
            <div style={{ marginTop: "-0.5rem" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DateField"]}>
                  <DateField
                    label="Fecha de nacimiento"
                    onChange={(e) => handleChange(e, "date_of_birth")}
                    value={formData.date_of_birth}
                    name="date_of_birth"
                    sx={{
                      [theme.breakpoints.down("sm")]: {
                        width: "327px",
                      },
                      [theme.breakpoints.up("sm")]: {
                        width: "416px",
                      },
                      "& label.Mui-focused": {
                        color: "#215E69",
                      },
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px",
                        "&.Mui-focused fieldset": {
                          borderColor: "#215E69",
                        },
                      },
                    }}
                    error={error.date_of_birth !== undefined}
              helperText={error.date_of_birth}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
            <TextInputField
              label="País"
              onChange={(e) => handleChange(e, "country")}
              value={formData.country}
              name="country"
              error={error.country !== undefined}
              helperText={error.country}
            />
          </div>
        </ThemeProvider>
      </div>
    
    </div>
  )
}

export default Step1
