import React from "react";
import styled from "styled-components";
import alert from "../../../images/Alert.svg";
import AdminButtonContainer from "../../../ui/AdminButtonContainer";
import AdminFormButton from "../../../ui/AdminFormButton";
import { CancelBtn } from "../../admin/CreateUser/StudentForm/StudentFormElements";
import { BtnWrapper, BtnWrapperCancel } from "../../Overlay/OverlayElements";

const MessageText = styled.p`
  width: 70vh;
  text-align: center;
  @media screen and (max-width: 768px) {
    width: 50vh;
  }
  @media screen and (max-width: 480px) {
    width: 40vh;
  }
`;

function ConfirmCancel({
  toReschedule,
  onCancel,
  teacherText,
  teacherHeading,
  onClose
}) {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "45vh",
        }}
      >
        <img
          src={alert}
          style={{ width: "2.5rem", marginBottom: "1.5rem" }}
          alt=""
        />
        <h2
          style={{
            fontSize: "1rem",
            fontWeight: 500,
            marginBottom: "0.5rem",
          }}
        >
          {teacherHeading ? teacherHeading : "¿Y si mejor Re-agendas la clase?"}
        </h2>
        <MessageText>
          {teacherText
            ? teacherText
            : "Recuerda que todas las clases canceladas dentro del semestre deben re-agendarse antes de terminar el semestre para no perderlas."}
        </MessageText>
      </div>
      <BtnWrapperCancel>
        <AdminButtonContainer>
          {teacherHeading ? (
            <AdminFormButton onClick={onClose}>
            Mantener clase
          </AdminFormButton>
          ) : ( 
          <AdminFormButton onClick={toReschedule}>
            Re-programar clase
          </AdminFormButton>
          )}
        </AdminButtonContainer>
        <CancelBtn onClick={onCancel}>Cancelar clase</CancelBtn>
      </BtnWrapperCancel>
    </>
  );
}

export default ConfirmCancel;
