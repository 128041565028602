import React, { useEffect, useState } from 'react'
import { Title } from '../../Overlay/OverlayElements'
import SelectComponent from '../../../ui/Select'
import ClassesTables from './ClassesTables'
import axios from 'axios';
import styled from 'styled-components';

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 75vh;
  padding-bottom: 2rem;
  z-index: 1;
  overflow: auto;

  &&::-webkit-scrollbar {
    display: flex;
    scroll-behavior: smooth;
  }
`;

function ExistedClasses({ group_request, onButtonClick }) {
  const [groupLevels, setGroupLevels] = useState([]);
  const [groupLevelsFetched, setGroupLevelsFetched] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/lesson/group_levels?limit=100`,
          {
            params: {
              instrument_id: group_request.instrument.value,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        const group_levels = response.data;
        setGroupLevels(group_levels);
        setGroupLevelsFetched(true);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, []);

  // const handleOnChange = (event) => {
  //   const instrument = instruments.find((instrument) => instrument.value === event.target.value);
  //   setInstrumentLocal(instrument);
  // };

  return (
    <div>
      <TableWrapper>
        {groupLevelsFetched && groupLevels.length > 0 ?
          <ClassesTables groupLevels={groupLevels} onButtonClick={onButtonClick} /> :
          <Title>No hay clases existentes</Title>}
      </TableWrapper>
    </div>
  )
}

export default ExistedClasses
