import React, { useEffect, useState } from "react";
import NoClassesCard from "../ClassesCards/NoClassesCard";
import {
  BottomContainer,
  BottomWrapper,
  CardsWrapper,
  ClassTitle,
  CoursesDesc,
  CoursesInfo,
  CoursesItem,
  CoursesNum,
  MainContent,
  UpperWrapper,
  UserInfo,
  UserName,
  UserStatus,
} from "./InactiveMainElements";
import PageContainer from "../../ui/PageContainer";
import useUserName from "../../utils/useUserName";
import axios from "axios";
import Class from "../ClassesCards/Class";
import CancelledCard from "./Reschedule/CancelledCard";
import Modal from "../../ui/Modal";
import Reschedule from "./Reschedule/Reschedule";

const InactiveMain = () => {
  const { name } = useUserName(localStorage.getItem("userId"));
  const [stats, setStats] = useState("");
  const [nextLesson, setNextLesson] = useState("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);

  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const id = localStorage.getItem("userId");
        const token = localStorage.getItem("access_token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/${id}/levels_pending_reschedule`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        setClasses(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data for the next lesson:", error);
      }
    };
    fetchClasses();
  }, []);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const id = localStorage.getItem("userId");
        const token = localStorage.getItem("access_token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/${id}/stats`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        setStats(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data for the next lesson:", error);
      }
    };
    fetchStats();
  }, []);

  useEffect(() => {
    const fetchNextLesson = async () => {
      try {
        const id = localStorage.getItem("userId");
        const token = localStorage.getItem("access_token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/${id}/next_lesson`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        setNextLesson(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data for the next lesson:", error);
      }
    };
    fetchNextLesson();
  }, []);

  const status = nextLesson?.instrument_name
    ? nextLesson?.to_students.map((student) => student.status)[0]
    : "";

  const capitalizedInstrumentName = nextLesson?.instrument_name
    ? nextLesson?.instrument_name.charAt(0).toUpperCase() +
      nextLesson?.instrument_name.slice(1).toLowerCase()
    : "";

  const startDate = new Date(nextLesson?.start_time);
  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  let formattedStartDate = startDate.toLocaleDateString("es-ES", options);
  formattedStartDate = formattedStartDate.replace(
    /^(.)(.+?)\b(?! de )/g,
    (match, p1, p2) => p1.toUpperCase() + p2
  );
  formattedStartDate = formattedStartDate.replace(/,/g, "");
  const formattedStartTime = startDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  let modality = nextLesson?.lesson_type;
  if (nextLesson?.lesson_type === "PRESENCIAL") {
    modality = "Presencial en sede";
  } else if (nextLesson?.lesson_type === "VIRTUAL") {
    modality = "Virtual";
  } else if (nextLesson?.lesson_type === "DOMICILIO") {
    modality = "Domicilio";
  }

  const userStatusColor = nextLesson ? "green" : "red";
  const userStatusText = nextLesson ? "Activo" : "Inactivo";

  const handleCardClick = (classData) => {
    setSelectedClass(classData);
    setIsOpenModal(true);
  };

  return (
    <PageContainer>
      <MainContent>
        <UpperWrapper>
          <UserInfo>
            <UserName>Hola, {name.first_name}</UserName>
            <UserStatus color={userStatusColor}>
              Estudiante {userStatusText}
            </UserStatus>
          </UserInfo>
          <CoursesInfo>
            <CoursesItem>
              <CoursesNum>{stats.individual_levels}</CoursesNum>
              <CoursesDesc>Cursos Individuales Disponibles</CoursesDesc>
            </CoursesItem>
            <CoursesItem>
              <CoursesNum>{stats.groupal_levels}</CoursesNum>
              <CoursesDesc>Cursos Grupales Disponibles</CoursesDesc>
            </CoursesItem>
            <CoursesItem>
              <CoursesNum>{stats.cancelled_lessons}</CoursesNum>
              <CoursesDesc>Clases canceladas del ciclo</CoursesDesc>
            </CoursesItem>
          </CoursesInfo>
        </UpperWrapper>
        <BottomWrapper>
          <div>
            <ClassTitle style={{ textAlign: "center" }}>
              Próxima clase
            </ClassTitle>
            <BottomContainer>
              <CardsWrapper>
                {nextLesson ? (
                  <Class
                    status={status}
                    formattedStartDate={formattedStartDate}
                    capitalizedInstrumentName={capitalizedInstrumentName}
                    teacher_name={nextLesson?.teacher_name}
                    modality={modality}
                    formattedStartTime={formattedStartTime}
                    classroomName={nextLesson?.classroom_name}
                  />
                ) : (
                  <NoClassesCard />
                )}
              </CardsWrapper>
            </BottomContainer>
          </div>
          {classes.length > 0  && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "1.5rem",
              }}
            >
              <h2
                style={{
                  textAlign: "center",
                  fontWeight: 500,
                  marginBottom: "0.5rem",
                }}
              >
                Clases pendientes de re-agendar
              </h2>
              <div
                style={{
                  height: "25vh",
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                {classes.map((classItem, index) => (
                  <CancelledCard
                    key={index}
                    onClick={() => handleCardClick(classItem)}
                    classData={classItem}
                  />
                ))}
              </div>
            </div>
          )}
        </BottomWrapper>
      </MainContent>
      {isOpenModal && (
        <Modal onClose={() => setIsOpenModal(false)}>
          <Reschedule classData={selectedClass} />
        </Modal>
      )}
    </PageContainer>
  );
};

export default InactiveMain;
