import React from "react";
import { textFieldStyle, theme } from "../components/Styles/FormStyles";
import { TextField, ThemeProvider } from "@mui/material";

function TextInputField({ label, onChange, type, value, name, InputProps,error , helperText }) {
  return (
    <>
      <ThemeProvider theme={theme}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          label={label}
          type={type}
          sx={textFieldStyle}
          onChange={onChange}
          value={value}
          name={name}
          InputProps={InputProps}
          error={error}
          helperText={helperText}
        />
      </ThemeProvider>
    </>
  );
}

export default TextInputField;
