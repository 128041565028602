import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import { Outlet } from "react-router-dom";

const CreateFormContext = createContext();

export const useFormContext = () => {
  return useContext(CreateFormContext);
};

export const CreateFormProvider = ({ initialUserType }) => {
  const getDefaultFormData = (userType) => {
    switch (userType) {
      case "ADMIN":
        return {
          email: "",
          first_name: "",
          last_name: "",
          phone_number: "",
          identification_number_type: "",
          identification_number: "",
          date_of_birth: null,
        };
      case "TEACHER":
        return {
          email: "",
          first_name: "",
          last_name: "",
          phone_number: "",
          instrument_ids: [],
          address: "",
          country: "",
          identification_number_type: "",
          identification_number: "",
          date_of_birth: null,
          can_teach_at_home: "",
          can_teach_remote: '',
          bank: "",
          bank_account: "",
          account_type: "",
        };
      case "STUDENT":
      default:
        return {
          email: "",
          first_name: "",
          last_name: "",
          phone_number: "",
          address: "",
          country: "",
          identification_number_type: "",
          identification_number: "",
          date_of_birth: null,
          emergency_contact_name: "",
          emergency_contact_phone: "",
          billing_mail: "",
          acquisition_channel: "",
        };
    }
  };
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");


  const [formData, setFormData] = useState(getDefaultFormData(initialUserType));

  const handleSubmit = async (data,endpoint) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/${endpoint}`,
        
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("Form data submitted:", response.data);
      setSuccessMsg("creado correctamente");
    } catch (error) {
      console.error("Error submitting form data:", error);
      setErrorMsg(error.response.data.detail);
    }
    console.log("Form data submitted:", formData);
  };

  return (
    <CreateFormContext.Provider value={{ formData, setFormData, handleSubmit,successMsg, errorMsg }}>
      <Outlet />
    </CreateFormContext.Provider>
  );
};
