import axios from 'axios';

export const fetchAvailableTeachers = async (instrumentId, startTime, endTime, day) => {
  try {
    const token = localStorage.getItem("access_token");
    const uri = startTime ? `instrument_id=${instrumentId}&start_time=${startTime}&end_time=${endTime}&day=${day}` : `instrument_id=${instrumentId}`;
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/user/available_teachers/?${uri}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching available teachers:", error.message);
    throw error;
  }
};

export const fetchAvailableClassrooms = async (instrumentId, startTime, endTime, day) => {
  try {
    const token = localStorage.getItem("access_token");
    const uri = startTime ? `instrument_id=${instrumentId}&start_time=${startTime}&end_time=${endTime}&day=${day}` : `instrument_id=${instrumentId}`;
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/classroom/available?${uri}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching available classrooms:", error.message);
    throw error;
  }
};


export const updateLessonLevel = async (levelId, selectedId, selectedClassroom, selectedLessonInfo,lessonId) => {
  try {
    const token = localStorage.getItem("access_token");
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/lesson/level/${levelId[0]}`,
      {
        teacher_id: selectedId,
        classroom_id: selectedClassroom,
        times_updated: {
          start_time: selectedLessonInfo.start_time,
          end_time: selectedLessonInfo.end_time,
          day: selectedLessonInfo.day,
        }, 
        lesson_id: lessonId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating lesson level:", error);
    throw error;
  }
};