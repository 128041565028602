import React, { useState } from "react";
import RecoveryContainer from "./RecoveryContainer";
import TextInputField from "../../ui/TextInputField";
import MainButton from "../../ui/Button";
import Modal from "../../ui/Modal";
import ChangedSuccessfully from "./ChangedSuccessfully";
import axios from "axios";
import { IconButton, InputAdornment } from "@mui/material";
import {
  VisibilityOutlined as VisibilityOutlinedIcon,
  VisibilityOffOutlined as VisibilityOffOutlinedIcon,
} from "@mui/icons-material";
import styled from "styled-components";


const StepWrapper = styled.div`
  width: 26rem;
  max-height: 55vh;
  margin-top: 3rem;
  @media screen and (max-width: 768px) {
    display: flex;
  align-items: center;
  flex-direction: column;
  }
  @media screen and (max-width: 480px) {
    display: flex;
  align-items: center;
  flex-direction: column;
  }
  
`;
const BtnWrapper = styled.div`
  width: 26rem;
  @media screen and (max-width: 768px) {
    display: flex;
  align-items: center;
  flex-direction: column;
  }
  @media screen and (max-width: 480px) {
    display: flex;
  align-items: center;
  flex-direction: column;
  }
  
`;

function CreatePassword() {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [data, setData] = useState({ password: "", confirmPassword: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const handleClickPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleClickConfirmPass = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validatePassword = () => {
    if (data.password.length < 8) {
      setPasswordError("La contraseña debe tener al menos 8 caracteres");
      return false;
    }
    if (data.password !== data.confirmPassword) {
      setPasswordError("Las contraseñas no coinciden");
      return false;
    }
    setPasswordError("");
    return true;
  };

  const getTokenFromURL = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    if (token) {
      return token;
    } else {
      const tokenFromStorage = localStorage.getItem("access_token");
      return tokenFromStorage ? tokenFromStorage : null;
    }
  };

  const usedToken = getTokenFromURL();

  async function handleSubmit(e) {
    e.preventDefault();

    const isValid = validatePassword();
    if (!isValid) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/reset_password`,
        data,
        {
          headers: {
            Authorization: `Bearer ${usedToken}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      console.log("respons", response.data);
      console.log("Submitted");

      setIsOpenModal(true);
    } catch (error) {
      console.error("Error updating user data:", error.message);
      throw new Error(`Error updating user data: ${error.message}`);
    }
  }

  return (
    <>
      <RecoveryContainer>
        <StepWrapper>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.6rem",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h2 style={{ fontWeight: 500 }}>Debes cambiar tu contraseña</h2>
            <p style={{ color: "#757575" }}>
              Por seguridad es necesario que crees una nueva contraseña para
              ingresar a la plataforma
            </p>
          </div>
          <div
            style={{
              height: "50vh",
              marginTop: "2.13rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <TextInputField
              label="Nueva contraseña"
              onChange={handleInputChange}
              value={data.password}
              name="password"
              type={showPassword ? "text" : "password"}
              error={passwordError !== ""}
              helperText={passwordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ fontSize: "18px", color: "#2C2E2F" }}
                      aria-label="toggle password visibility"
                      onClick={handleClickPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextInputField
              label="Confirma nueva contraseña"
              onChange={handleInputChange}
              value={data.confirmPassword}
              name="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              error={passwordError !== ""}
              helperText={passwordError}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ fontSize: "18px", color: "#2C2E2F" }}
                      aria-label="toggle password visibility"
                      onClick={handleClickConfirmPass}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showConfirmPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </StepWrapper>
        <BtnWrapper>
          <MainButton onClick={handleSubmit}>Aplicar cambios</MainButton>
        </BtnWrapper>
      </RecoveryContainer>
      {isOpenModal && (
        <Modal onClose={() => setIsOpenModal(false)}>
          <ChangedSuccessfully />
        </Modal>
      )}
    </>
  );
}

export default CreatePassword;
