import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { Outlet } from "react-router-dom";

const FormContext = createContext();

export const useFormContext = () => {
  return useContext(FormContext);
};

export const FormProvider = () => {
  const [formData, setFormData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    confirmPassword: "",
    phone_number: "",
    address: "",
    country: "",
    identification_number_type: "",
    identification_number: "",
    date_of_birth: null,
    emergency_contact_name: "",
    emergency_contact_phone: "",
    billing_mail: "",
    acquisition_channel: "",
    password: "",
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const resetForm = () => {
    setFormData({});
  };

  const handleSubmit = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/student/anonymous`,
        data
      );
      console.log("FormProvider formData:", formData);
      console.log("Form data submitted:", response.data);
      setSuccessMsg("Registro exitoso ");
    } catch (error) {
      console.error("Error submitting form data:", error);
      setErrorMsg(error.response.data.detail);
    }
    console.log("Form data submitted:", data);
    resetForm();
  };


  return (
    <FormContext.Provider value={{ formData, setFormData, handleSubmit,successMsg, errorMsg }}>
      <Outlet />
    </FormContext.Provider>
  );
};
