import React from "react";
import calendar from "../../../images/Calendar_green.svg";
import errorImg from "../../../images/Exclamation_circle.svg";
import styled from "styled-components";
import AdminButtonContainer from "../../../ui/AdminButtonContainer";
import AdminFormButton from "../../../ui/AdminFormButton";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45vh;
`;

function Cancellation({
  error,
  successMessage,
  admin,
  onClose,
  teacherHeading,
  strikeDisclaimer,
  timeDifferenceDisclaimer
}) {
  return (
    <>
      {successMessage && (
        <Wrapper>
          <img
            src={calendar}
            style={{ width: "2.5rem", marginBottom: "1.5rem" }}
            alt=""
          />
          <h2
            style={{
              fontSize: "1rem",
              fontWeight: 500,
              marginBottom: "0.5rem",
            }}
          >
            {successMessage}
          </h2>
          {admin ? (
            <p style={{ width: "40rem", textAlign: "center" }}>
              Se notificará al estudiante y el profesor de la cancelación
            </p>
          ) : teacherHeading ? (
            <p style={{ width: "40rem", textAlign: "center" }}>
              Se notificará al estudiante de la cancelación
            </p>
          ) : strikeDisclaimer || timeDifferenceDisclaimer ? (
            <p style={{ width: "40rem", textAlign: "center" }}>
              Se notificará al profesor la novedad.<br></br>
              La clase fue consumida y no es posible re-agendarla
            </p>
          ) : (
            <p style={{ width: "40rem", textAlign: "center" }}>
              Se notificará al profesor la novedad, recuerda que puedes
              re-programar en el momento que quieras Recuerda que sólo puedes
              cancelar 2 veces dentro de un ciclo.
            </p>
          )}
        </Wrapper>
      )}
      {error && (
        <Wrapper>
          <img
            src={errorImg}
            style={{ width: "2.5rem", marginBottom: "1.5rem" }}
            alt=""
          />
          <h2
            style={{
              fontSize: "1rem",
              fontWeight: 500,
              marginBottom: "0.5rem",
            }}
          >
            No hemos podido cancelar tu clase
          </h2>
          <p style={{ width: "40rem", textAlign: "center" }}>{error}</p>
        </Wrapper>
      )}
      {!admin && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <AdminButtonContainer>
            <AdminFormButton onClick={onClose}>Confirmar</AdminFormButton>
          </AdminButtonContainer>
        </div>
      )}
    </>
  );
}

export default Cancellation;
