import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import { textFieldStyle, theme } from "../Styles/FormStyles";
import { Form, FormContainer } from "../login/LoginElements";

const RegForm = ({ formData, onDataChange, error}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  console.log("RegForm formData:", formData);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (e, fieldName) => {
  const updatedFormData = { ...formData, [fieldName]: e.target.value };
  onDataChange(updatedFormData);
  };

  return (
    <ThemeProvider theme={theme}>
      <Form autoComplete="off" style={{ height: "30vh", margin: "0rem" }}>
        <FormContainer>
          <TextField
             sx={textFieldStyle}
             label="Correo electrónico"
             type="email"
             name="email"
             value={formData?.email || ""}
             onChange={(e) => handleChange(e, "email")}
             error={error.email !== undefined}
             helperText={error.email}
          />
          <TextField
            sx={textFieldStyle}
            label="Contraseña"
            type={showPassword ? "text" : "password"}
            value={formData.password}
            onChange={(e) => handleChange(e, "password")}
            name="password"
            error={error.password !== undefined}
            helperText={error.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ fontSize: "18px", color: "#2C2E2F" }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={textFieldStyle}
            label="Confirma contraseña"
            type={showConfirmPassword ? "text" : "password"}
            value={formData.confirmPassword}
            name="confirmPassword"
            onChange={(e) => handleChange(e, "confirmPassword")}
            error={error.confirmPassword !== undefined}
            helperText={error.confirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ fontSize: "18px", color: "#2C2E2F" }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showConfirmPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormContainer>
      </Form>
    </ThemeProvider>
  );
};

export default RegForm;




  // const [formErrors, setFormErrors] = useState({});
  // const navigate = useNavigate();

  // const validateEmail = (email) => {
  //   const emailRegex =
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   return emailRegex.test(email);
  // };

  // const validateForm = () => {
  //   const errors = {};

  //   if (!formData.email) {
  //     errors.email = "Email is required";
  //   } else if (!validateEmail(formData.email)) {
  //     errors.email = "Invalid email address";
  //   }

  //   if (!formData.password) {
  //     errors.password = "Password is required";
  //   }

  //   if (!formData.confirmPassword) {
  //     errors.confirmPassword = "Confirm Password is required";
  //   } else if (formData.confirmPassword !== formData.password) {
  //     errors.confirmPassword = "Passwords do not match";
  //   }

  //   setFormErrors(errors);

  //   return Object.keys(errors).length === 0;
  // };



// const handlePreformSubmit = (event) => {
//   event.preventDefault();
//   const valid = validateForm();
//     if(valid) {
//       // handleSubmit(preformData)
//       // console.log(preformData)
//       navigate("/complite-form");
//     }
// };

// const errors = {};

// if (email.trim() === "") {
//   errors.email = "Email is required";
// } else if (!validateEmail(email)) {
//   errors.email = "Email is invalid";
// }
// if (password === "") {
//   errors.password = "Password is required";
// }
// if (confirmPassword === "") {
//   errors.confirmPassword = "Confirm password is required";
// } else if (password !== confirmPassword) {
//   errors.confirmPassword = "Passwords do not match";
// }
// setFormErrors(errors);

// if (Object.keys(errors).length === 0) {
// }
