import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormContext } from "../../../../context/CreateStudentContext";
import AdminFormButton from "../../../../ui/AdminFormButton";
import CreateUserContainer from "../../../../ui/CreateUserContainer";
import {
  Container,
  MainContent,
} from "../../../MultiStepsForm/MultiStepFormElements";
import StepThree from "./StepThree";
import StepTwo from "./StepTwo";
import {
  BackArrow,
  BtnBack,
  BtnBackWrapper,
  BtnText,
  BtnsContainer,
  CancelBtn,
} from "./StudentFormElements";
import Modal from "../../../../ui/Modal";
import UserCreated from "../Overlay/UserCreated";
import { useEmailExistenceCheck } from "../../../../utils/useEmailExistenceCheck";

function StudentForm({ defaultStep }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { paso } = useParams();
  const currentStep = paso || defaultStep;
  const { formData, setFormData, handleSubmit, successMsg, errorMsg } = useFormContext();
  const allData = { ...formData };
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const { emailExists, checkEmailExistence } = useEmailExistenceCheck();

  useEffect(() => {
    if (formData.email) {
      checkEmailExistence(formData.email);
    }
  }, [formData.email, checkEmailExistence]);

  let stepNumber = 1;
  const isStep2 = currentStep === "paso-2";

  if (currentStep === "paso-1") {
    stepNumber = 1;
  } else if (currentStep === "paso-2") {
    stepNumber = 2;
  }
  const userType = "STUDENT";

  const validateStep1Data = () => {
    let errors = {};

    if (!formData.email) {
      errors.email = "Correo electrónico es requerido";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Formato de correo electrónico inválido";
    } else if (emailExists) {
      errors.email = emailExists;
    }
    if (!formData.first_name) {
      errors.first_name = "Nombre es requerido";
    }
    if (!formData.identification_number_type) {
      errors.identification_number_type = "Tipo de documento es requerido";
    }
    if (!formData.identification_number) {
      errors.identification_number = "Número de documento es requerido";
    }
    if (!formData.date_of_birth) {
      errors.date_of_birth = "Fecha de nacimiento es requerida";
    }
    if (!formData.country) {
      errors.country = "País es requerido";
    }
    if (!formData.address) {
      errors.address = "Dirección de residencia es requerida";
    }
    if (!formData.phone_number) {
      errors.phone_number = "Teléfono de contacto es requerido";
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };
  const validateStep2Data = () => {
    let errors = {};

    if (!formData.emergency_contact_name) {
      errors.emergency_contact_name = "Nombre de contacto de emergencia es requerido";
    }
    if (!formData.emergency_contact_phone) {
      errors.emergency_contact_phone = "Número de contacto de emergencia es requerido";
    }
    if (!formData.billing_mail) {
      errors.billing_mail = "Este campo es obligatorio";
    }

    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleNextButtonClick = (e) => {
    e.preventDefault();

    let nextStep;
    if (currentStep === "paso-1") {
      if (validateStep1Data()) {
        nextStep = "paso-2";
        navigate(`/crear-usuario/estudiante/${nextStep}`);
      }
    } else if (currentStep === "paso-2") {
      if (validateStep2Data()) {
        handleSubmit(allData, `${userType.toLocaleLowerCase()}`);
        // setIsOpenModal(true);
        if (handleSubmit) {
          setIsOpenModal(true);
        }
      }
      // return;
    }

    // navigate(`/crear-usuario/estudiante/${nextStep}`);
  };

  const handleBackButtonClick = (e) => {
    e.preventDefault();
    let previousStep;

    if (currentStep === "paso-2") {
      previousStep = "paso-1";
    } else {
      navigate("/crear-usuario/");
      return;
    }

    navigate(`/crear-usuario/estudiante/${previousStep}`);
  };

  const handleDataChange = (data) => {
    // setFormData({ ...formData, ...data });
    const updatedFormData = { ...formData, ...data };
    const updatedErrors = { ...error };
    Object.keys(data).forEach((fieldName) => {
      if (updatedErrors[fieldName]) {
        delete updatedErrors[fieldName];
      }
    });
    setError(updatedErrors);
    setFormData(updatedFormData);
  };

  return (
    <CreateUserContainer>
      <div>
        <BtnBackWrapper>
          <BtnBack onClick={handleBackButtonClick}>
            <BackArrow />
          </BtnBack>
          <BtnText>
            Creación de usuario - Paso <span>{stepNumber}</span>
          </BtnText>
        </BtnBackWrapper>
      </div>
      <MainContent>
        <Container
          style={{ padding: "0rem", maxHeight: "68vh", overflow: "auto" }}
        >
          {currentStep === "paso-1" && (
            <StepTwo
              formData={formData}
              onDataChange={handleDataChange}
              error={error}
            />
          )}
          {currentStep === "paso-2" && (
            <StepThree
              formData={formData}
              onDataChange={handleDataChange}
              error={error}
            />
          )}
        </Container>
        <BtnsContainer
          style={{
            width: "100%",
            padding: "1rem 0rem 0rem 0rem",
            justifyContent: "none",
            gap: "1.5rem",
          }}
        >
          <div style={{ width: "20.4375rem" }}>
            <AdminFormButton onClick={handleNextButtonClick}>
              {isStep2 ? "Crear usuario" : "Siguiente"}
            </AdminFormButton>
          </div>
          {currentStep === "paso-2" && (
            <CancelBtn onClick={(e)=> {
              e.preventDefault()
              navigate('/crear-usuario')
            }}>Cancelar definitivamente</CancelBtn>
          )}
        </BtnsContainer>
        {isOpenModal && (
          <Modal onClose={() => setIsOpenModal(false)}>
            <UserCreated formData={formData} userType={userType} errorMsg={errorMsg} successMsg={successMsg} />
          </Modal>
        )}
      </MainContent>
    </CreateUserContainer>
  );
}

export default StudentForm;
