import styled from "styled-components";
import { Link } from "react-router-dom";

export const Nav = styled.nav`
  display: none;
  @media screen and (max-width: 768px) {
    /* height: 5.5rem; */
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
  }
`;
export const NavbarContainer = styled.div`
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 1.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 88px;
    padding: 24px 16px;
  }
`;
export const NavLogo = styled.p`
`;

export const Logo = styled.img`
  @media screen and (max-width: 768px) {
    width: 5rem;
    height: auto;
    /* height: 3.5rem; */
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1rem;
`;

export const NavItem = styled.li`
  display: flex;
`;

export const NavBtn = styled.button`
  border: none;
  background: transparent;
  display: flex;
  cursor: pointer;
`;

export const NavLink = styled(Link)`
  cursor: pointer;
`

export const Notifications = styled.img`
  display: flex;
  height: 1.5rem;
  width: 1.5rem;
`;

export const Menu = styled.img`
  height: 1.6rem;
  width: 1.6rem;
`;
