import React from 'react'
import { MainContainer } from './RightPageContainer'
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 2rem 2.5rem 2.5rem 2.5rem;
  margin: 1.5rem 2rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  border-radius: 8px;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    height: 100%;
  }
`;

function CreateUserContainer({children}) {
  return (
    <MainContainer>
      <Wrapper>
        {children}
      </Wrapper>
    </MainContainer>
  )
}

export default CreateUserContainer
