import React from "react";
import { ClassType, Left, Professor, Right } from "../../ClassesCards/CardsElements";
import styled from "styled-components";

const Wrapper = styled.div`
  background: #fdedef;
  width: 33rem;
  padding: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 60vh;
  }
  @media screen and (max-width: 480px) {
    width: 35vh;
  }
`;

function CancelledCard({ onClick, classData }) {
  return (
    <Wrapper onClick={onClick} style={{display:'flex', justifyContent: 'space-between'}}>
      <Left>
        <ClassType>Clase de {classData.instrument_name}</ClassType>
        <Professor style={{ marginBottom: "0rem" }}>
          Profesor {classData.teacher_name}
        </Professor>
      </Left>
      <Right style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <p style={{fontWeight:500, textAlign: 'center', background: '#EA5A47', color: '#fff', borderRadius: '0.75rem', padding: '0.12rem 0.5rem'}}>{classData.pending_lessons > 1 ? `${classData.pending_lessons} clases pendientes`: `${classData.pending_lessons} clase pendiente`} </p>
      </Right>
    </Wrapper>
  );
}

export default CancelledCard;
