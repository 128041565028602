import React, { useEffect, useState } from "react";
import MainButton from "../../ui/Button";
import { useNavigate } from "react-router-dom";
import RegForm from "./regForm";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { LoginTitle } from "../login/LoginElements";
import { useFormContext } from "../../context/FormContext";
import Modal from "../../ui/Modal";
import SuccessfullyRegistered from "./SuccessfullyRegistered";
import axios from "axios";
import { useEmailExistenceCheck } from "../../utils/useEmailExistenceCheck";

function RegistrationComponent({ currentStep }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { formData, setFormData, handleSubmit, successMsg, errorMsg } = useFormContext();
  const allData = { ...formData };
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const { emailExists, checkEmailExistence } = useEmailExistenceCheck();

  let stepNumber = currentStep
    ? parseInt(currentStep.replace("paso-", ""), 10)
    : 0;

  useEffect(() => {
    if (formData.email) {
      checkEmailExistence(formData.email);
    }
  }, [formData.email, checkEmailExistence]);

  const validateStep0Data = () => {
    let errors = {};
    if (!formData.email) {
      errors.email = "Correo electrónico es requerido";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Formato de correo electrónico inválido";
    } else if (emailExists) {
      errors.email = emailExists;
    }
    if (!formData.password) {
      errors.password = "Contraseña es requerida";
    }
    if (!formData.confirmPassword) {
      errors.confirmPassword = "Confirmar contraseña es requerida";
    } else if (formData.confirmPassword !== formData.password) {
      errors.confirmPassword = "Las contraseñas no coinciden";
    }
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const validateStep1Data = () => {
    let errors = {};

    if (!formData.first_name) {
      errors.first_name = "Nombre es requerido";
    }
    if (!formData.identification_number_type) {
      errors.identification_number_type = "Tipo de documento es requerido";
    }
    if (!formData.identification_number) {
      errors.identification_number = "Número de documento es requerido";
    }
    if (!formData.date_of_birth) {
      errors.date_of_birth = "Fecha de nacimiento es requerida";
    }
    if (!formData.country) {
      errors.country = "País es requerido";
    }
  
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const validateStep2Data = () => {
    let errors = {};

    if (!formData.address) {
      errors.address = "Dirección de residencia es requerida";
    }
    if (!formData.phone_number) {
      errors.phone_number = "Teléfono de contacto es requerido";
    }
    if (!formData.emergency_contact_name) {
      errors.emergency_contact_name = "Nombre de contacto de emergencia es requerido";
    }
    if (!formData.emergency_contact_phone) {
      errors.emergency_contact_phone = "Número de contacto de emergencia es requerido";
    }
  
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const validateStep3Data = () => {
    let errors = {};

    if (!formData.billing_mail) {
      errors.billing_mail = "Este campo es obligatorio";
    }
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  // const handleNextButtonClick = (e) => {
  //   e.preventDefault();

  //   let nextStep;
  //   if (stepNumber < 3) {
  //       nextStep = `paso-${stepNumber + 1}`;
  //       navigate(`/registro/${nextStep}`);
  //   } else if (stepNumber === 3) {
  //     handleSubmit(allData);
  //     if (handleSubmit){
  //       setIsOpenModal(true);
  //     }
  //   }
  // };
  const handleNextButtonClick = (e) => {
    e.preventDefault();

    let nextStep;
    if (stepNumber === 0) {
      if (validateStep0Data()) {
        nextStep = `paso-${stepNumber + 1}`;
        navigate(`/registro/${nextStep}`);
      }
    } else if (stepNumber === 1) {
      if (validateStep1Data()) {
      nextStep = `paso-${stepNumber + 1}`;
      navigate(`/registro/${nextStep}`);
      }
    } else if (stepNumber === 2) {
      if (validateStep2Data()) {
      nextStep = `paso-${stepNumber + 1}`;
      navigate(`/registro/${nextStep}`);
      }
    } 
    // else if (stepNumber < 3) {
    //   nextStep = `paso-${stepNumber + 1}`;
    //   navigate(`/registro/${nextStep}`);
    // } 
    else if (stepNumber === 3) {
      if (validateStep3Data()) {
      handleSubmit(allData);
      if (handleSubmit) {
        setIsOpenModal(true);
      }
      }
    }
  };

  const handleDataChange = (data) => {
    // setFormData({ ...formData, ...data });
    const updatedFormData = { ...formData, ...data };
    const updatedErrors = { ...error };
    Object.keys(data).forEach((fieldName) => {
      if (updatedErrors[fieldName]) {
        delete updatedErrors[fieldName];
      }
    });
    setError(updatedErrors);
    setFormData(updatedFormData);
  };

  const getTitleAndContent = () => {
    switch (stepNumber) {
      case 0:
        return {
          title: "Registro",
          content: "",
        };
      case 1:
        return {
          title: "Datos básicos",
          content: "Paso 1 de 3",
        };
      case 2:
        return {
          title: "Datos de contacto",
          content: "Paso 2 de 3",
        };
      case 3:
        return {
          title: "Datos complementarios",
          content: "Paso 3 de 3",
        };
      default:
        return {
          title: "",
          content: "",
        };
    }
  };

  const { title, content } = getTitleAndContent();

  return (
    <div>
      <div style={{ marginTop: "1rem" }}>
        <p style={{ textAlign: "center", fontSize: "0.75rem" }}>{content}</p>
        <LoginTitle
          style={{
            textAlign: "center",
            marginTop: "0",
            marginBottom: "1.5rem",
          }}
        >
          {title}
        </LoginTitle>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "50vh",
        }}
      >
        <div>
          {stepNumber === 0 && (
            <RegForm
              style={{ height: "0vh" }}
              formData={formData}
              onDataChange={handleDataChange}
              error={error}
            />
          )}
          {stepNumber === 1 && (
            <Step1 formData={formData} onDataChange={handleDataChange}  error={error}/>
          )}
          {stepNumber === 2 && (
            <Step2 formData={formData} onDataChange={handleDataChange} error={error} />
          )}
          {stepNumber === 3 && (
            <Step3 formData={formData} onDataChange={handleDataChange} error={error} />
          )}
        </div>
        <div style={{ marginBottom: "2rem" }}>
          <MainButton onClick={handleNextButtonClick}>
            {stepNumber === 3 ? "Finalizar registro" : "Siguiente"}
          </MainButton>
        </div>
      </div>
      {stepNumber !== 0 && <div style={{ height: "10vh" }}></div>}
      {isOpenModal && (
        <Modal onClose={() => {setIsOpenModal(false)
          navigate('/login');}}>
          <SuccessfullyRegistered errorMsg={errorMsg} successMsg={successMsg} />
        </Modal>
      )}
    </div>
  );
}

export default RegistrationComponent;
