import axios from "axios";
import React, { useEffect, useState } from "react";
import SelectComponent from "../../../ui/Select";
import { ClassType, StepTitle, Steps } from "./StepOneElements";


const StepOne = ({ formData, onDataChange }) => {
  const [musicInst, setMusicInst] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/instrument/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        const instruments = response.data.map((instrument) => ({
          label: instrument.name,
          value: instrument.id,
          art_form: instrument.art_form,
          name: instrument.name,
        }));
        setMusicInst(instruments);
      })
      .catch((error) => {
        console.error("Error fetching salon options from API:", error);
      });
  }, []);

  const type_class = [
    { label: "Música", value: "MUSICA" },
    { label: "Danza", value: "DANZA" },
    { label: "Pintura y artes plásticas", value: "PINTURA" },
    { label: "Teatro", value: "TEATRO" },
  ];

  const handleChange = (fieldName) => (event) => {
  const value = event.target.value;

  let instrumentName = "";
  if (fieldName === "instrument_id") {
    const selectedInstrument = musicInst.find((item) => item.value === value);
    if (selectedInstrument) {
      instrumentName = selectedInstrument.name.toLowerCase();
    }
  }
  
  const updatedFormData = {
    ...formData,
    [fieldName]: value,
    ...(fieldName === "instrument_id" && { instrument_name: instrumentName }),
    // ...(fieldName === "student_ids" && { is_tuition_payed: isTuitionPayed }),
  };
  onDataChange(updatedFormData);
  console.log(`Selected ${fieldName}:`, value);
  };

  return (
    <>
      <Steps>Paso 1 de 5</Steps>
      <StepTitle>Agendamiento de clase</StepTitle>
      <ClassType>Tipo de clase</ClassType>
      <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              // width: "416px",
            }}
          >
      <SelectComponent
              label="Tipo de clase"
              menuItems={type_class}
              value={formData.class_type}
              onChange={handleChange("class_type")}
              name='class_type'
            />
            <SelectComponent
              label="Clase"
              name="instrument_id"
              menuItems={musicInst.filter(
                (item) => item.art_form === formData.class_type
              )}
              value={formData.instrument_id}
              onChange={handleChange("instrument_id")}
            />
          </div>
    </>
  );
};

export default StepOne;
