import React from "react";
import styled from "styled-components";

const ClassWrapper = styled.div`
  background-color: ${(props) => {
    if (props.status === 'ACTIVE') return "#E8F6EE";
    if (props.status === "COMPLETED") return "#F3F3F3";
    if(props.status === "PENDING") return '#FDF2DF';
    if(props.status === "CANCELLED") return '#FDEDEF';
  }};
  height: 7.46rem;
  width: 32.8rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 28rem;
    height: 8.5rem;
  }
  @media screen and (max-width: 480px) {
    width: 21.4rem;
    height: 8.5rem;
  }
`;

const ClassInformation = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1.06rem 1rem 1rem 1rem;
`;

const Left = styled.div`
  /* Add styles for Left */
`;

const DateDay = styled.p`
  font-size: 0.75rem;
  margin-bottom: 1.06rem;
`;

const ClassType = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
`;

const Professor = styled.p`
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`;

const Place = styled.p`
  font-size: 0.625rem;
  font-weight: 400;
`;

const Right = styled.div`
  font-size: 0.875rem;
  text-align: center;
`;

const Time = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 1.87rem;
`;

const Status = styled.p`
  background-color: ${(props) =>
    props.status === "PENDING" ? '#EF8F00' : props.status === "ACTIVE"
      ? "#41A46D"
      : props.status === "COMPLETED"
      ? "#ADB8BA"
      : props.status === "CANCELLED" ? '#EA5A47' : 'none'};
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  padding: 0.12rem 0.5rem;
  border-radius: 0.75rem;
  color: #fff;
`;

const Room = styled.p`
  font-size: 0.625rem;
  font-weight: 400;
`;

function Class({
  status,
  students,
  start_time,
  onClick,
  instrument_name,
  lesson_type,
  classroom_name,
}) {
  const formatStudents = () => {
    return students
      .map((student) => `${student.first_name} ${student.last_name}`)
      .join(", ");
  };

  const capitalizedInstrumentName = instrument_name 
  ? instrument_name.charAt(0).toUpperCase() + instrument_name.slice(1).toLowerCase() 
  : '';

  const startDate = new Date(start_time);
  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  let formattedStartDate = startDate.toLocaleDateString("es-ES", options);
  formattedStartDate = formattedStartDate.replace(
    /^(.)(.+?)\b(?! de )/g,
    (match, p1, p2) => p1.toUpperCase() + p2
  );
  formattedStartDate = formattedStartDate.replace(/,/g, "");
  const formattedStartTime = startDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  let modality = lesson_type;
  if (lesson_type === "PRESENCIAL") {
    modality = "Presencial en sede";
  } else if (lesson_type === "VIRTUAL") {
    modality = "Virtual";
  } else if (lesson_type === "DOMICILIO") {
    modality = "Domicilio";
  }

  return (
    <>
      <ClassWrapper status={status} onClick={onClick}>
        <ClassInformation>
          <Left>
            <DateDay>{formattedStartDate}</DateDay>
            <ClassType>Clase de {capitalizedInstrumentName}</ClassType>
            <Professor>Estudiantes: {formatStudents()}</Professor>
            <Place>{modality}</Place>
          </Left>
          <Right>
            <Time>{formattedStartTime}</Time>
            <Status status={status}>
            {status === "PENDING"
                ? "Pendiente"
                : status === "ACTIVE"
                ? "Activa"
                : status === "COMPLETED"
                ? "Terminado"
                : status === 'CANCELLED' ? 'Cancelada' : ''}
            </Status>
            <Room>{classroom_name}</Room>
          </Right>
        </ClassInformation>
      </ClassWrapper>
    </>
  );
}

export default Class;
