import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import {
  FormControlLabelStyle,
  radioStyle,
  theme,
} from "../../../Styles/FormStyles";
import { Steps, StepTitle } from "../../StepOne/StepOneElements";
import { Description, Title } from "../StepTwo/StepTwoElements";
import axios from "axios";

const StepThree = ({ formData, onDataChange }) => {
  const [teacherName, setTeacherName] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/user/?limit=200&user_type=TEACHER&instrument_id=${formData.instrument_id}&modality=${formData.lesson_type}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        const formattedTeacher = response.data.map((teacher) => ({
          label: `${teacher.first_name} ${teacher.last_name}`,
          value: teacher.id,
          first_name: teacher.first_name,
          last_name: teacher.last_name,
        }));
        setTeacherName(formattedTeacher);
      })
      .catch((error) => {
        console.error("Error fetching salon options from API:", error);
      });
  }, [formData.instrument_id, formData.lesson_type]);

  const handleChange = (fieldName) => (event) => {
    const value = event.target.value;
    const selectedTeacher = teacherName.find(
      (teacher) => String(teacher.value) === String(value)
    );
    const teacherFullName = selectedTeacher
      ? `${selectedTeacher.first_name} ${selectedTeacher.last_name}`
      : "";

    const updatedFormData = {
      ...formData,
      [fieldName]: value,
      teacher_name: teacherFullName,
    };
    onDataChange(updatedFormData);
    console.log(`Selected ${fieldName}:`, value);
  };

  return (
    <>
      <Steps>Paso 3 de 5</Steps>
      <StepTitle>Agendamiento de clase</StepTitle>
      <Title>Profesores disponibles</Title>
      <Description>
        Estos son los profesores disponibles para clases de{" "}
        {formData.instrument_name
          ? formData.instrument_name.charAt(0).toUpperCase() +
            formData.instrument_name.slice(1)
          : ""}
      </Description>
      <ThemeProvider theme={theme}>
      <FormControl sx={{ marginBottom: "60px" }}>
          <RadioGroup
            name="teacher_id"
            value={formData.teacher_id}
            onChange={handleChange("teacher_id")}
          >
            {teacherName.map((teacher, index) => (
              <FormControlLabel
                key={index}
                value={teacher.value}
                control={<Radio sx={radioStyle} />}
                sx={FormControlLabelStyle}
                label={teacher.label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </ThemeProvider>
    </>
  );
};

export default StepThree;
