import { ThemeProvider } from "@emotion/react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { useEffect, useState } from "react";
import { TableHeadStyle, themeTable } from "../../components/Styles/FormStyles";
import { EditBtnWrapper } from "../../components/admin/Students/StudentsTablesElements";
import accept from "../../images/Accept.svg";
import reject from "../../images/Reject.svg";
import { acceptUser, deleteUser } from "../../utils/tools";
import { TablePagination } from "@mui/material";

function ApproveTable() {
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const columns = [
    {
      id: "id",
      label: "Profesor solicitante",
      align: "left",
      minWidth: 180,
      format: (value, row) => (
        <p key={row.id}>
          {row.first_name} {row.last_name}
        </p>
      ),
    },
    {
      id: "phone_number",
      label: "Teléfono",
      align: "left",
      minWidth: 160,
    },
    {
      id: "email",
      label: "Correo electrónico",
      align: "left",
      minWidth: 160,
    },
    {
      id: "",
      label: "Curso a dictar",
      align: "left",
      minWidth: 180,
      format: (value, row) => (
        <p key={row.id}>
          {row.instruments.length > 0
            ? row.instruments[0].name
            : "Ningún instrumento"}
        </p>
      ),
    },
    {
      id: "modal",
      label: "Acciones",
      minWidth: 180,
      align: "center",
      headerAlign: "center",
      format: (value, row) => (
        <EditBtnWrapper style={{ gap: "1rem" }}>
          <button
            style={{
              display: "flex",
              alignItems: "center",
              color: "#41A46D",
              fontWeight: 700,
            }}
            onClick={handleAccept}
          >
            <img src={accept} style={{ marginRight: "0.5rem" }} alt="" />
            Aceptar
          </button>
          <button
            style={{
              display: "flex",
              alignItems: "center",
              color: "#EA5A47",
              fontWeight: 700,
            }}
            onClick={handleReject}
          >
            <img src={reject} style={{ marginRight: "0.5rem" }} alt="" />
            Rechazar
          </button>
        </EditBtnWrapper>
      ),
    },
  ];
  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const handleUserSelection = (userId) => {
    setSelectedUserId(userId);
  };

  const handleAccept = async () => {
    try {
      if (!selectedUserId) {
        console.error('No user selected.');
        return;
      }
      await acceptUser(selectedUserId);
      setData((prevData) => prevData.filter((user) => user.id !== selectedUserId));
      console.log('User rejected and deleted!');
    }catch (error) {
      console.error('Error rejecting user:', error);
    }
  }

  const handleReject = async () => {
    try {
      if (!selectedUserId) {
        console.error('No user selected.');
        return;
      }

      await deleteUser(selectedUserId);
      setData((prevData) => prevData.filter((user) => user.id !== selectedUserId));
      console.log('User rejected and deleted!');
    } catch (error) {
      console.error('Error rejecting user:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/validate/`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
        const studentData = response.data;
        console.log(studentData);
        setData(studentData);
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div style={{ marginBottom: "2rem" }}>
        <ThemeProvider theme={themeTable}>
          <Paper
            sx={{ width: "100%", borderRadius: "0.5rem 0.5rem 0rem 0rem" }}
          >
            <TableContainer
              sx={{ maxHeight: 450, borderRadius: "0.5rem 0.5rem 0rem 0rem" }}
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead sx={TableHeadStyle}>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align="center"
                        style={{
                          minWidth: column.minWidth,
                          textAlign:
                            column.headerAlign || column.align || "left",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data
                      // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((data) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={data.id}
                            onClick={() => handleUserSelection(data.id)}
                          >
                            {columns &&
                              columns.map((column) => {
                                const value = data[column.id];
                                return (
                                  <TableCell
                                    key={`${data.id}-${column.id}`}
                                    align={column.align}
                                  >
                                    {column.format
                                      ? column.format(value, data)
                                      : value}
                                  </TableCell>
                                );
                              })}
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
          </Paper>
        </ThemeProvider>
      </div>
    </>
  );
}

export default ApproveTable;
