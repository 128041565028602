import styled from "styled-components";
import {ReactComponent as back} from '../../images/Back.svg'
import {ReactComponent as forward} from '../../images/Forward.svg'

export const BackWrapper = styled.div`
  height: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: flex-start;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const BackBtn = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  margin-left: 24px;
  cursor: pointer;
`;

export const IconBtn = styled.img``;

export const TextBtn = styled.p`
  font-weight: 500;
  font-size: 14px;
`;

export const MainContent = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 80vh;

  @media screen and (max-width: 768px) {
    align-items: center;
    height: 80vh;
  }
  @media screen and (max-width: 480px) {
    align-items: center;
    height: 80vh;
  }
`;

export const Container = styled.div`
  padding-top: 18px;
  /* width: 416px; */
  /* height: 540px; */
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    /* width: 100%; */
    padding: 0;
    justify-content: center;
    align-items: center;
  }
`;

export const BtnsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ArrowBtnWrapper = styled.div`
  display: flex;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    display: none;
  }
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const ArrowBtn = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem;
  border: solid 2px #f3f3f3;
  border-radius: 0.25rem;
  cursor: pointer;

  &:active {
        transition: 0.3s;
        transform: translate(0, -2.5px);
    }

  &:disabled {
    path {
        stroke: #f3f3f3; 
    }
  }
`;

export const ArrowImgBack = styled(back)`
  height: 1.5rem;
  width: 1.5rem;
`;
export const ArrowImgForward = styled(forward)`
  height: 1.5rem;
  width: 1.5rem;
`;

export const NextBtn = styled.button`
  font-size: 14px;
  font-weight: 500;
  width: 416px;
  padding: 16px 0;
  border-radius: 8px;
  border: none;
  background: #4aa1b1;
  color: #fff;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    width: 327px;
    margin-top: 30px;
    margin-bottom: 0px;
  }
`;
