import React from "react";
import {
  ClassInformation,
  ClassType,
  ClassWrapper,
  DateDay,
  Left,
  Place,
  Professor,
  Right,
  Room,
  Status,
  Time,
} from "./ClassStyle";

function ClassStudent({
  to_students,
  teacher_name,
  start_time,
  onClick,
  instrument_name,
  lesson_type,
  classroom_name,
}) {
  const status = to_students.map((student) => student.status)[0];

  const capitalizedInstrumentName = instrument_name
    ? instrument_name.charAt(0).toUpperCase() +
      instrument_name.slice(1).toLowerCase()
    : "";

  const startDate = new Date(start_time);
  const options = {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  let formattedStartDate = startDate.toLocaleDateString("es-ES", options);
  formattedStartDate = formattedStartDate.replace(
    /^(.)(.+?)\b(?! de )/g,
    (match, p1, p2) => p1.toUpperCase() + p2
  );
  formattedStartDate = formattedStartDate.replace(/,/g, "");
  const formattedStartTime = startDate.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  let modality = lesson_type;
  if (lesson_type === "PRESENCIAL") {
    modality = "Presencial en sede";
  } else if (lesson_type === "VIRTUAL") {
    modality = "Virtual";
  } else if (lesson_type === "DOMICILIO") {
    modality = "Domicilio";
  }
  
  return (
    <>
      <ClassWrapper status={status} onClick={onClick}>
        <ClassInformation>
          <Left>
            <DateDay>{formattedStartDate}</DateDay>
            <ClassType>Clase de {capitalizedInstrumentName}</ClassType>
            <Professor>Profesor(a) {teacher_name}</Professor>
            <Place>{modality}</Place>
          </Left>
          <Right>
            <Time>{formattedStartTime}</Time>
            <Status status={status}>
              {status === "PENDING"
                ? "Pendiente"
                : status === "ACTIVE"
                ? "Activa"
                : status === "COMPLETED"
                ? "Terminado"
                : status === 'CANCELLED' ? 'Cancelada' : ''}
            </Status>
            <Room>{classroom_name}</Room>
          </Right>
        </ClassInformation>
      </ClassWrapper>
    </>
  );
}

export default ClassStudent;
