import React from "react";
import { StepTitle, Steps } from "../../MultiStepsForm/StepOne/StepOneElements";
import { Description, Title } from "../../MultiStepsForm/individual/StepTwo/StepTwoElements";
import Table1 from "./Table";

const StepTwoGroup = ({ formData }) => {
  return (
    <>
      <Steps>Paso 2 de 3</Steps>
      <StepTitle>Agendamiento de clase</StepTitle>
      <Title>Gestionar disponibilidad</Title>
      <Description style={{width:'26rem'}}>
        Elige las horas que estarás disponible para recibir clases para poder
        crear el grupo con otras personas interesadas.
      </Description>
      <div style={{ height: "40vh" }}>
        <Table1 />
      </div>
    </>
  );
};

export default StepTwoGroup;

