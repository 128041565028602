import React from 'react'
import RecoveryContainer from '../RecoverPassword/RecoveryContainer'
import SettingUpForm from './SettingUpForm'
import { useParams } from 'react-router-dom';

function SettingUpAccount() {
    const { paso } = useParams();
  return (
    <RecoveryContainer>
      <SettingUpForm currentStep={paso} />
    </RecoveryContainer>
  )
}

export default SettingUpAccount
