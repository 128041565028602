import { ThemeProvider } from "@emotion/react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import styled from "styled-components";
import edit from "../../../images/Edit.svg";
import Modal from "../../../ui/Modal";
import { TableHeadStyle, themeTable } from "../../Styles/FormStyles";
import {
  EditBtn,
  EditBtnWrapper,
  EditImg,
} from "../../admin/Students/StudentsTablesElements";
import axios from "axios";
import RangeForm from "./RangeForm";
import { Box } from "@mui/material";

const NoTime = styled.p`
  color: #ea5a47;
  font-size: 0.875rem;
  font-weight: 400;
`;
const Range = styled.p`
  color: #3591f5;
  font-size: 0.875rem;
  font-weight: 400;
`;
const AllDay = styled.p`
  color: #41a46d;
  font-size: 0.875rem;
  font-weight: 400;
`;

function TimeTable() {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [availabilityData, setAvailabilityData] = useState({});

  function handleOpen(e, dayId) {
    e.preventDefault();
    setSelectedDay(dayId);
    setIsOpenModal(true);
  }

  const handleCloseModal = () => {
    setIsOpenModal(false);
  };

  const daysOfWeek = [
    { id: 1, name: "Lunes", value: "LUNES" },
    { id: 2, name: "Martes", value: "MARTES" },
    { id: 3, name: "Miércoles", value: "MIERCOLES" },
    { id: 4, name: "Jueves", value: "JUEVES" },
    { id: 5, name: "Viernes", value: "VIERNES" },
    { id: 6, name: "Sábado", value: "SABADO" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = localStorage.getItem("userId");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/${id}/availability`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );

        console.log("Data fetched successfully:", response.data);
        setAvailabilityData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const refetchData = async () => {
    try {
      const id = localStorage.getItem("userId");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/${id}/availability`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("Data fetched successfully:", response.data);
      setAvailabilityData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getAvailabilityForDay = (dayId) => {
    const dayValue = daysOfWeek.find((day) => day.id === dayId).value;

    let dayData;
    if (Array.isArray(availabilityData)) {
      dayData = availabilityData.filter((data) => data.day === dayValue);
    } else if (typeof availabilityData === "object") {
      dayData = availabilityData[dayValue] || [];
    } else {
      console.error(
        "Unexpected data type for availabilityData:",
        typeof availabilityData
      );
      return null;
    }

    if (dayData.length > 0) {
      const hasAllDayAvailability = dayData.some(
        (interval) =>
          (interval.start_time_ === "09:00:00" &&
            interval.end_time_ === "21:00:00") ||
          (dayValue === "SABADO" &&
            interval.start_time_ === "09:00:00" &&
            interval.end_time_ === "18:00:00")
      );

      if (hasAllDayAvailability) {
        return <AllDay>Todo el día estaré disponible</AllDay>;
      } else {
        const ranges = dayData
          .map((interval) => {
            const startTime = interval.start_time_.slice(0, 5);
            const endTime = interval.end_time_.slice(0, 5);
            return { startTime, endTime };
            // return `${startTime} - ${endTime}`;
          })
          .sort((a, b) => {
            if (a.startTime < b.startTime) return -1;
            if (a.startTime > b.startTime) return 1;
            return 0;
          })
          .map(({ startTime, endTime }) => `${startTime} - ${endTime}`)
          .join("; ");

        return <Range>{ranges}</Range>;
      }
    } else {
      return <NoTime>No disponible en todo el día</NoTime>;
    }
  };

  return (
    <>
      <Box sx={{ overflow: "auto" }}>
        <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
          <ThemeProvider theme={themeTable}>
            <Paper
              sx={{ width: "100%", borderRadius: "0.5rem 0.5rem 0rem 0rem", }}
            >
              <TableContainer
                sx={{
                  maxHeight: "47vh",
                  borderRadius: "0.5rem 0.5rem 0rem 0rem",
                  [themeTable.breakpoints.down("sm")]: {
                    maxHeight: '60vh',
                  },
                }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead sx={TableHeadStyle}>
                    <TableRow>
                      <TableCell align="left">Day</TableCell>
                      <TableCell align="left">Horarios disponibles</TableCell>
                      <TableCell align="center">Acciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {daysOfWeek.map((day) => (
                      <TableRow key={day.id} hover>
                        <TableCell align="left">{day.name}</TableCell>
                        <TableCell align="left">
                          {day.id && getAvailabilityForDay(day.id)}
                        </TableCell>
                        <TableCell align="center">
                          <EditBtnWrapper>
                            <EditBtn onClick={(e) => handleOpen(e, day.id)}>
                              <EditImg src={edit} /> Editar
                            </EditBtn>
                          </EditBtnWrapper>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            {isOpenModal && (
              <Modal
                onClose={() => {
                  setIsOpenModal(false);
                }}
                props="Agregar disponibilidad"
              >
                <RangeForm
                  selectedDay={daysOfWeek.find((day) => day.id === selectedDay)}
                  onClose={handleCloseModal}
                  availabilityData={availabilityData}
                  refetchData={refetchData}
                />
              </Modal>
            )}
          </ThemeProvider>
        </Box>
      </Box>
    </>
  );
}

export default TimeTable;
