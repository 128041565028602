import TextField from "@mui/material/TextField";
import { ThemeProvider } from "@mui/material/styles";
import React from 'react';
import SelectComponent from '../../../../ui/Select';
import { Heading } from "../../../EditProfile/EditProfileElements";
import { textFieldStyle, theme } from "../../../Styles/FormStyles";
import { FormContainer } from "../../../login/LoginElements";
import TextInputField from "../../../../ui/TextInputField";


function StepThree({formData, onDataChange, error}) {

  const social_media = [
    {
      label: "Facebook",
      value: "FACEBOOK",
    },
    {
      label: "Instagram",
      value: "INSTAGRAM",
    },
    {
      label: "WhatsApp",
      value: "WHATSAPP",
    },
    {
      label: "TikTok",
      value: "TIKTOK",
    },
    {
      label: "Referido",
      value: "REFERRED",
    },
    {
      label: "Volantes",
      value: "FLYERS",
    },
    {
      label: "Otros",
      value: "OTHER",
    },
  ];

      const handleChange = (e, fieldName) => {
        const updatedFormData = { ...formData };
    
        if (e.target) {
          updatedFormData[fieldName] = e.target.value;
        } else {
          updatedFormData[fieldName] = e;
        }
     
        onDataChange(updatedFormData);
      };

  return (
    <div>
    <Heading>Contacto de emergencia</Heading>
    <FormContainer style={{ gap: "0rem", maxHeight: '600px', overflowY: 'auto' }}>
      <ThemeProvider theme={theme}>
      <div style={{marginBottom: '0.5rem'}}>
        <TextInputField
              label="Nombre completo"
              onChange={(e) => handleChange(e, "emergency_contact_name")}
              value={formData.emergency_contact_name}
              name='emergency_contact_name'
              error={error.emergency_contact_name !== undefined}
              helperText={error.emergency_contact_name}
            />
          </div>
         
          <div style={{marginBottom: '1.5rem'}}>
          <TextInputField
              label="Número de contacto"
              onChange={(e) => handleChange(e, "emergency_contact_phone")}
              value={formData.emergency_contact_phone}
              name='emergency_contact_phone'
              error={error.emergency_contact_phone !== undefined}
              helperText={error.emergency_contact_phone}
            />
          </div>
         
          <Heading>Correo de recepción de Estado de cuenta</Heading>
          <div style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
          <TextField
              sx={textFieldStyle}
              label="Correo electrónico"
              onChange={(e) => handleChange(e, "billing_mail")}
              value={formData.billing_mail}
              name='billing_mail'
              error={error.billing_mail !== undefined}
              helperText={error.billing_mail}
            />
            <SelectComponent label='¿Cómo te enteraste de nosotros?' menuItems={social_media} onChange={(e) => handleChange(e, "acquisition_channel")}
              value={formData.acquisition_channel}
              name='acquisition_channel'/>
          </div>
      </ThemeProvider>
    </FormContainer>
  </div>
  )
}

export default StepThree