import React, { useEffect, useState } from "react";
import FirstForm from "./FirstForm";
import Extra from "./Extra";
import ConfirmAction from "./ConfirmAction";
import AdminFormButton from "../../../ui/AdminFormButton";
import AdminButtonContainer from "../../../ui/AdminButtonContainer";
import axios from "axios";
import { fetchOtherTeachers, fetchTeacherInfo } from "../../../utils/rescheduleTools";

function Reschedule({classData}) {
  const [step, setStep] = useState(0);
  const [teacherInfo, setTeacherInfo] = useState([]);
  const [teacherInfoFetched, setTeacherInfoFetched] = useState(false);
  const [otherTeachers, setOtherTeachers] = useState([]);
  const [otherTeacherButton, setOtherTeacherButton] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [checkBox, setCheckBox] = useState(false);
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [newData, setNewData] = useState({
    teacher_id: "",
    start_time: "",
    end_time: "",
    classroom_id: ''
  });

  // console.log('classData', classData)

  useEffect(() => {
    const fetchData = async () => {
      const teacherInfoResponse = await fetchTeacherInfo(classData);
      setTeacherInfo(teacherInfoResponse);
      setTeacherInfoFetched(true);
    };

    fetchData();
  }, [classData]);

  useEffect(() => {
    const fetchData = async () => {
      const otherTeachersResponse = await fetchOtherTeachers(classData);
      setOtherTeachers(otherTeachersResponse);
      // console.log(otherTeachersResponse);
    };

    fetchData();
  }, [classData]);

  useEffect(() => {
    setOtherTeacherButton(!checkBox && otherTeachers.length && !teacherInfo.length);
    setDisabled(!(checkBox || otherTeachers.length && !teacherInfo.length));
  }, [teacherInfo, otherTeachers, checkBox]);

  const handleSubmit = async () => {
    const token = localStorage.getItem("access_token");
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/lesson/level/${classData.level_id}/reschedule`,
          newData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        // console.log(response.data);
        setSuccess("Clase Re-agendada correctamente");
      } catch (error) {
        console.error("Error:", error);
        setError(error.response.data.detail)
      }
  }
  const handleDataUpdate = (data) => {
    setNewData({ ...newData, ...data })
    setCheckBox(true);
  };

  const steps = [
    <FirstForm classData={classData} teacherInfo={teacherInfo} handleDataUpdate={handleDataUpdate} newData={newData} teacherInfoFetched={teacherInfoFetched}/>,
    <Extra otherTeachers={otherTeachers} classData={classData} handleDataUpdate={handleDataUpdate} newData={newData} />,
    <ConfirmAction error={error} success={success} />
];

const handleClick = () => {
  if (step === 0) {
    if (teacherInfo === null || teacherInfo.length === 0) {
      setOtherTeacherButton(false);
      setStep(1);
      setDisabled(true);
  } else {
      handleSubmit();
      setStep(2)
  }
  } else if (step === 1) {
    handleSubmit()
    setStep(2)
  } else if( step === 2) {
    window.location.href = "/inicio"
  }
};

  return (
    <div>
      <div style={{ height: "50vh", overflow: "auto" }}>{steps[step]}</div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <AdminButtonContainer>
          <AdminFormButton disabled={disabled} onClick={handleClick}>
            {otherTeacherButton ? "Buscar horarios con otros profesores" : "Confirmar"}
          </AdminFormButton>
        </AdminButtonContainer>
      </div>
    </div>
  );
}

export default Reschedule;
