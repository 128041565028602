import { ThemeProvider } from "@emotion/react";
import { Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { themeTable } from "../components/Styles/FormStyles";
import Button from "@mui/material/Button";
import edit from '../images/Edit.svg'
import profile from '../images/StudentsIcon.svg'
import { EditImg } from "../components/admin/Students/StudentsTablesElements";
import del from '../images/Delete.svg'
import update from '../images/Update.svg'

function ActiveMenu({onClickEdit, onClickDelete, showAdditionalMenuItem, onClickWatch}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    onClickEdit();
    handleClose(); 
  };

  const handleDeleteClick = () => {
    onClickDelete();
    handleClose(); 
  }

  const handleWatchClick = () => {
    onClickWatch()
    handleClose()
  }

  const buttonStyles = {
    border: "none",
    textTransform: "none",
    marginTop: "0px",
    color: "#2C2E2F",
    padding: '0rem',
    fontSize: '0.75rem',
    fontWeight: 700,
    "&:hover": {
      border: 'none',
      background: 'none',
    },
  };

  return (
    <ThemeProvider theme={themeTable}>
      <Button
        disableElevation
        disableRipple
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={buttonStyles}
      >
        <EditImg src={edit} style={{marginRight: '0.5rem'}} /> Editar
      </Button>
      <Menu
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "0.5rem",
            width: "16rem",
            boxShadow:
              "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
          },
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {showAdditionalMenuItem && (
          <MenuItem onClick={handleWatchClick}><EditImg src={profile} style={{marginRight: '0.5rem', height:'1rem'}} /> Ver estudiante</MenuItem>
        )}
        <MenuItem onClick={handleEditClick}><EditImg src={update} style={{marginRight: '0.5rem'}} /> Actualizar datos</MenuItem>
        <MenuItem onClick={handleDeleteClick}><EditImg src={del} style={{marginRight: '0.5rem'}} /> Eliminar</MenuItem>
      </Menu>
    </ThemeProvider>
  );
}

export default ActiveMenu;