import React from "react";
import TextInputField from "../../ui/TextInputField";
import styled from "styled-components";

const StepWrapper = styled.div`
  width: 26rem;
  max-height: 55vh;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Text = styled.p`
  color: #757575;
  @media screen and (max-width: 768px) {
    width: 22rem;
  }
  @media screen and (max-width: 480px) {
    width: 19rem;
  }
`

function StepOne({ onChange, data,error}) {
  return (
    <StepWrapper>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.6rem",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <h2 style={{ fontWeight: 500 }}>Recupera tu contraseña</h2>
        <Text>
          Ingresa el correo electrónico con el cual estás registrado en la
          academia. Enviaremos un mensaje para que puedas solucionar tus
          problemas de acceso.
        </Text>
      </div>
      <div style={{ height: "50vh", marginTop: "2.13rem"}}>
        <TextInputField
          label="Correo electrónico"
          name="email"
          onChange={onChange}
          value={data.email}
          error={error}
          helperText={error}
        />
      </div>
    </StepWrapper>
  );
}

export default StepOne;
