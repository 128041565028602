import React from "react";
import calendar from '../../../images/Calendar_green.svg'
import styled from "styled-components";

import errorImg from "../../../images/Exclamation_circle.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45vh;
`;

function ConfirmAction({success,error}) {
  return (
    <>
      {success && (
        <Wrapper>
          <img
            src={calendar}
            style={{ width: "2.5rem", marginBottom: "1.5rem" }}
            alt=""
          />
          <h2
            style={{
              fontSize: "1rem",
              fontWeight: 500,
              marginBottom: "0.5rem",
            }}
          >
            {success}
          </h2>
          <p style={{ width: "40rem", textAlign: "center" }}>
            Ahora podrás visualizar tu clase en tu calendario
          </p>
        </Wrapper>
      )}
      {error && (
        <Wrapper>
           <img
            src={errorImg}
            style={{ width: "2.5rem", marginBottom: "1.5rem" }}
            alt=""
          />
          <h2 style={{
              fontSize: "1rem",
              fontWeight: 500,
              marginBottom: "0.5rem",
            }}>No hemos podido re agendar tu clase</h2>
          <p style={{fontWeight:500}}>{error}</p>
        </Wrapper>
      )}
    </>
  );
}

export default ConfirmAction;
